<template>
    <v-dialog persistent v-model="dialog" :max-width="size" @keydown.esc="cancel" style="z-index: 400">
        <v-card v-if="order && order.fulfillment_quantities">
            <v-card-title class="headline">{{ title }}</v-card-title>
            <v-card-text>To partially fulfill an order adjust the "Quantity to fulfill" value for each item below.</v-card-text>
            <v-row v-for="line_item in order.line_items" :key="line_item.id" no-gutters class="pl-5">
                <v-col cols="3" class="pl-5">
                    <v-text-field outlined dense clearable reverse ref="quantity" :rules="[rules.isNumber, () => order.fulfillment_quantities[line_item.id] <= line_item.fulfillable_quantity]" label="Quantity to fulfill" v-model="order.fulfillment_quantities[line_item.id]" class="mb-0"></v-text-field>
                </v-col>
                <v-col cols="9" class="pl-5">
                    {{ line_item.title }}
                    <div class="caption text-muted" v-if="line_item.sku">SKU: {{ line_item.sku }}</div>
                </v-col>
            </v-row>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn @click.native="cancel">Cancel</v-btn>
                <v-btn color="primary" size="large" @click.native="agree">Fulfill Order</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        order: null,
        title: null,
        rules: {
            required: value => !!value || 'Required.',
            isNumber: value => !isNaN(value) || 'Must be a number.'
        },

    }),
    methods: {
        open(order) {
            this.dialog = true
            this.order = order
            if(!this.order.fulfillment_quantities) {
                Vue.set(this.order, 'fulfillment_quantities', {})
                this.order['fulfillment_quantities'] = {}
                for(const item of order.line_items) {
                    Vue.set(this.order.fulfillment_quantities, item.id, item.fulfillable_quantity)
                }
            }
            this.title = `Fulfill Order - ${order.name}`
            return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
            })
        },
        agree() {
            for(let item in this.order.fulfillment_quantities) {
                if(this.order.fulfillment_quantities[item] == null) {
                    this.order.fulfillment_quantities[item] = 0
                }
            }
            this.resolve(true)
            this.dialog = false
        },
        cancel() {
            Vue.delete(this.order, 'fulfillment_quantities')
            this.resolve(false)
            this.dialog = false
        }
    },
    computed: {
        size () {
            const size = {xs:'95%',sm:'800',lg:'800',xl:'800'}[this.$vuetify.breakpoint.name];
            return size
        }
    }
}
</script>