import { initiateUnfulfilledOrdersRequest, pollBulkOperation, fetchResultsFromURL, parseJSONL } from '@/V2/helpers/shopifyOrders';

export default {
    namespaced: true,
    state: {
        orders: [],
    },
    mutations: {
        setOrders(state, orders) {
            state.orders = orders;
        }
    },
    getters: {
    },
    actions: {
        async init({ commit }) {
            try {
                console.log('aaa 1')
                const operationId = await initiateUnfulfilledOrdersRequest();
                console.log('aaa 2')
                const resultsUrl = await pollBulkOperation(operationId);
                console.log('aaa 3')
                
                const jsonlData = await fetchResultsFromURL(resultsUrl);
                console.log('aaa 4')
                const orders = parseJSONL(jsonlData);
                console.log('aaa 5')
    
                commit('setOrders', orders);
            } catch (error) {
                console.error(`Error fetching unfulfilled orders: ${(error as Error).message}`);
                throw error;
            }
        }
    },
    modules: {
    }
}
