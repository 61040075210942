<template>
    <v-card class="pa-5 mb-2" :style="{'font-size': this.invoice_options.heading_font || '18px'}">
        <v-row class="pb-0">
            <v-col cols="4">
                <v-row>
                    <v-col class="h1 pt-0 mb-0">
                        <span v-if="order.total_tax > 0">Tax </span>Invoice
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <Summary :order="order"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" class="pt-4">
                <Seller/>
            </v-col>
            <v-col cols="4" class="text-right">
                <Logo/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="5">
                <span class="h5" v-if="order.billing_address">Billing Address:</span>
                <BillingAddress :order="order" class="mt-3"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="5">
                <span class="h5">Shipping Address:</span>
                <ShippingAddress :order="order" class="mt-3"/>
            </v-col>
        </v-row>
        <Items :order="order" class="mt-3"/>
        <v-row :style="{'font-size': this.invoice_options.totals_font || '18px'}">
            <v-col cols="7" class="pt-5">
                <div v-if="order.note" class="mt-5">
                    <span class="h5">Order Notes:</span>
                    <div class="mt-5">{{ order.note }}</div>
                </div>
            </v-col>
            <v-col cols="5" class="pr-6">
                <hr class="thick" v-if="show_sub"/>
                <v-row v-if="show_sub">
                    <v-col cols="8" class="font-weight-bold">Subtotal</v-col>
                    <v-col cols="4" class="text-right">${{ Number(order.current_subtotal_price) + Number(order.current_total_discounts) | formatNumber(2)}}</v-col>
                </v-row>
                <v-row v-if="Number(order.total_discounts)">
                    <v-col cols="8" class="font-weight-bold">less Discounts</v-col>
                    <v-col cols="4" class="text-right">-${{ order.current_total_discounts | formatNumber(2) }}</v-col>
                </v-row>
                <v-row v-if="order.shipping_lines[0]">
                    <v-col cols="8" class="font-weight-bold">Shipping</v-col>
                    <v-col cols="4" class="text-right">${{ shipping_total | formatNumber(2) }}</v-col>
                </v-row>
                <v-row v-if="Number(order.total_tax)">
                    <v-col cols="8" class="font-weight-bold">{{ tax_name }} <span v-if="order.taxes_included">(included)</span></v-col>
                    <v-col cols="4" class="text-right">${{ order.current_total_tax | formatNumber(2) }}</v-col>
                </v-row>
                <hr class="thick" />
                <v-row v-if="refunds.other">
                    <v-col cols="8" class="font-weight-bold">Total</v-col>
                    <v-col cols="4" class="text-right">${{ Number(order.current_total_price) + refunds.other | formatNumber(2) }}</v-col>
                </v-row>
                <v-row v-if="refunds.other">
                    <v-col cols="8">less Refunds</v-col>
                    <v-col cols="4" class="text-right">${{ refunds.other | formatNumber(2) }}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="8" class="font-weight-bold">Invoice Total</v-col>
                    <v-col cols="4" class="text-right">${{ order.current_total_price | formatNumber(2) }}</v-col>
                </v-row>
                <hr class="thick" />
            </v-col>
        </v-row>
        <v-row :style="{'font-size': this.invoice_options.message_font || '18px'}">
            <v-col class="text-center">
                <div v-html="invoice_options.other.message"></div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Logo from '@/V2/components/Templates/invoice/Generic/logo'
import Seller from '@/V2/components/Templates/invoice/Generic/seller'
import Summary from '@/V2/components/Templates/invoice/Generic/summary'
import BillingAddress from '@/V2/components/Templates/invoice/Generic/billingAddress'
import ShippingAddress from '@/V2/components/Templates/invoice/Generic/shippingAddress'
import Items from '@/V2/components/Templates/invoice/Generic/items'
import orderTools from "@/V2/helpers/orderTools";

export default {
    props: ['order'],
	components: {
        Logo,
        Seller,
        Summary,
        BillingAddress,
        ShippingAddress,
        Items,
    },
    computed : {
        ...mapState('User', ['address', 'invoice_options']),
        refunds: function() {
            return orderTools.order_refunds(this.order)
        },
        tax_name: function() {
            try {
                if(this.address.country == 'AU') {
                    if(this.order.shipping_address.country_code == 'AU') {
                        return 'GST'
                    }
                }
            } catch (e) {
                return 'Tax'
            }
            return 'Tax'
        },
        show_sub: function () {
            if(Number(this.order.current_total_discounts) != 0) return true
            if(Number(this.shipping_total) != 0) return true
            if(Number(this.order.current_total_tax) != 0) return true
            if(Number(this.refunds.other) != 0) return true
            return false
        },
        shipping_total: function () {
            if (!this.order || !this.order.shipping_lines) return 0;

            return this.order.shipping_lines.reduce((total, line) => {
                return total + parseFloat(line.discounted_price);
            }, 0);            
        }
    }
}
</script>

<style>
    hr.thick {
        display: block; 
        height: 1px; 
        background: transparent; 
        width: 100%; 
        border: none; 
        border-top: solid 2px #333;
    }
    hr.thin {
        display: block; 
        height: 1px; 
        background: transparent; 
        width: 100%; 
        border: none; 
        border-top: solid 1px #333;
    }
</style>