<template>
    <div class="mt-n6">
        <v-row>
            <v-col cols="12" lg="12" xl="8"> 
                <Instructions :id="help_article" class="pb-4 mb-3"/>
<!--                <v-card class="pb-4 mb-3">
                    <CheapestRate :rules="rules" v-on:save="save()" />
                </v-card>           -->
                <v-card class="pb-4 mb-3">
                    <ShippingMethod :rules="rules" :domestic="false" v-on:save="save()" />
                </v-card>
                <v-card class="pb-4 mb-3">
                    <DefaultService :rules="rules" :domestic="false" v-on:save="save()" />
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import Response from '@/V2/components/Utilities/Response'
import Instructions from '@/V2/views/Instructions'
import CheapestRate from '@/V2/components/Rules/CheapestRate'
import DefaultService from '@/V2/components/Rules/DefaultService'
import ShippingMethod from '@/V2/components/Rules/ShippingMethod'

export default {
    data: function () {
        return {
            help_article: 'international_rules_instructions',
        }
    },
	components: {
//        Response,
        Instructions,
//        CheapestRate,
        DefaultService,
        ShippingMethod
    },
    methods: {
        save: function () {
            this.$store.dispatch('Rules/save', {}, { root: true })
        }
    },
    computed: {
		...mapGetters({
            all: 'Rules/all',
            rules: 'Rules/international'
        })
    }
}
</script>


