<template>
    <div>
        <v-lazy v-if="screen == 'xl'" :options="{threshold: .0}" transition="fade-transition"> 
            <XLview :order="order" v-on:action="action($event, [order])" v-on:close="close(order)" v-on:expand="expand(order)"/>
        </v-lazy>
        <v-lazy v-if="screen == 'lg' || screen == 'md'" :options="{threshold: .0}" transition="fade-transition"> 
            <MDview :order="order"  v-on:action="action($event, [order])" v-on:close="close(order)" v-on:expand="expand(order)"/>
        </v-lazy>
        <v-lazy v-if="screen == 'sm' || screen == 'xs'" :options="{threshold: .0}" transition="fade-transition"> 
            <SMview :order="order"  v-on:action="action($event, [order])" v-on:close="close(order)" v-on:expand="expand(order)"/>
        </v-lazy>
        <v-row no-gutters v-if="order.errors && order.errors.address">
            <v-col class="px-6 py-0">
                <v-alert text outlined dense icon="mdi-alert" type="error" class="mt-0 mb-1"><span v-html="order.errors.address"></span></v-alert>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="px-6 py-0"> 
                <v-lazy :options="{threshold: .0}" transition="fade-transition"> 
                    <Warnings :order="order"/>
                </v-lazy>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";
import Warnings from '@/V2/components/Orders/Warnings'
import XLview from '@/V2/components/Orders/TableView/Layouts/xl'
import MDview from '@/V2/components/Orders/TableView/Layouts/md'
import SMview from '@/V2/components/Orders/TableView/Layouts/sm'

export default {
    props: ['order'],
    data: function () {
        return {
            orderTools: orderTools,
        }
    },
    components: {
        Warnings,
        XLview,
        MDview,
        SMview
    },
    methods: {
        close: function (order) {
			if(order.layout) {
				Vue.delete(order, 'layout')
			} else {
				if(this.layout(order) == 'expanded') {
					Vue.set(order, 'layout', 'compact')
				} else if(this.layout(order) == 'compact') {
					Vue.set(order, 'layout', 'table')
				}
			}
        },
        expand: function () {
            Vue.set(this.order, 'layout', 'expanded')
        },
        show(section, subsection) {
			try {
				if(subsection) {
					return this.display_settings.compact[section].subsections[subsection].status
				} else {
					return this.display_settings.compact[section].status
				}
			} catch (e) {
				return true
			}
        },
		action: function (event, orders) {
            this.$emit('action', event, orders)
        },
    },
    computed: {
		...mapGetters({
			display_settings: 'Settings/display_settings'
		}),
        fulfillable_items: function () {
            return orderTools.count_fulfillable_line_items(this.order)
        },
        items: function () {
            return `${this.fulfillable_items} Item${ this.fulfillable_items > 1 ? 's' : ''}`
        },
        screen: function () {
            return this.$vuetify.breakpoint.name
        },
    }
}
</script>

<style scoped>
	.debug-borderx {
		border: 1px solid red;
	}
    .v-btn:hover:before, .v-btn:focus:before {
        background-color: transparent;
    }
    .v-btn::before {
        background-color: transparent;
    }
</style>