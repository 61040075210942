<template>
<div>
    <v-row class="ma-2">
        <v-col cols="2" class="d-flex flex-column">
        <v-card class="mb-1 flex-grow-1">
        Upper card
        </v-card>
        <v-card class="mt-1 flex-grow-1">
        Lower card
        </v-card>
        </v-col>
      <v-col md="4" class="pa-3">
        <v-card class="elevation-5">
          <v-card-text>
            <div class="body-1">{{ repeat_word(200) }}</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Test',
    data: function () {
        return {
            items: [
                { id: 1, name: 'A', description: 'Short', showButtons: true },
                { id: 2, name: 'B', description: 'Short', showButtons: false },
                { id: 3, name: 'C', description: 'Longer text that wraps onto multiple lines kjhkjh kjh jh kjh kjh kjh kjh kjh kj hkj hkj hkj hkj hk jhk jhk jhk jh kjhk jh', showButtons: true }
            ],
            word: 'abcdef'
        }
    },
	components: {
    },
    watch: {
    },
    methods: {
        repeat_word: function (count) {
            let text = ''
            for(let i = 0; i < count; i++) {
                text = text + ' ' + this.word
            }
            return text
        }
    },
	created: function () {
	},
	computed : {
        ...mapState([]),
	}
}
</script>

