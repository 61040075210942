<template>
    <div>
        <v-row class="px-5">
            <v-col cols="10" class="d-flex align-center">
                <v-card-title class="pa-0 info--text">Use Cheapest Rate</v-card-title>
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-end">
                <v-switch v-model="rules.cheapest" color="success" @change="$emit('save')" class="ma-0"></v-switch>
            </v-col>
        </v-row>
        <v-row class="px-5">
            <v-card-text class="pl-5 py-0">
                Enabling the "Use Cheapest Rate" option will mean that the service with the cheapest rate quote received from all connected carriers will always be selected as the default. As a cheapest rate would always be found all other rules defined below (if any) will be ignored.
            </v-card-text>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ['rules']
}
</script>
