<template>
    <div>
        <v-row no-gutters v-if="warning">
            <v-col class="py-0">
                <v-alert text outlined dense icon="mdi-alert" type="warning" class="mt-0 mb-1"><span v-html="warning"></span></v-alert>
            </v-col>
        </v-row> 
    </div>
</template>

<script>
import warnings from "@/V2/helpers/warnings";

export default {
    props: ['order'],
    computed: {
        warning: function () {
            return warnings.check_for_warnings(this.order)
        },
    }
}
</script>
