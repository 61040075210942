import Vue from 'vue'
import store from "@/V2/store/index"
import rules from "@/V2/helpers/rules";
import orderTools from "@/V2/helpers/orderTools";

export default {
    carrier_action: async function (orders, dialog, action) {
        store.commit('set_progress', { status: true, message: 'Preparing orders' }) 
        await store.dispatch('Orders/reset_errors', { orders: orders, type: 'label'}, { root: true })
        for(const order of orders) {
            await this.prepare_order(order)
            const rate = order.rate_selected || await orderTools.get_selected_rate(order)
            if(rate && !order.consignment.service) {
                Vue.set(order.consignment, 'service', rate)
            } else if ( !rate ){
                store.dispatch('Orders/init_error', { order: order, type: 'label', error: order.errors?.address ? order.errors.address : 'A shipping service must be selected before printing a label.' }, { root: true })
            }
        }
        const batches = this.batch_orders(orders)
        if(Object.keys(batches).length) {
            for(const batch in batches) {
                const carrier = batch.split('|')[0]
                try {
                    await store.dispatch(`Carriers/${carrier}/action_${action}`, { orders: batches[batch], dialog: dialog, action: action, carrier_id: batch })
                } catch (e) {
                    return true
                }
            }
        } else {
            store.commit('set_progress', { status: false, message: null })             
        }
    },
    delete_labels: async function (orders) {
        const batches = this.batch_orders(orders)
        for(const batch in batches) {
            const carrier = batch.split('|')[0]
            store.dispatch(`Carriers/${carrier}/delete_labels`, batches[batch])
        }
    },
    batch_orders: function (orders) {
        const batches = {}
        for(const order of orders) {
            //TODO return an error if no service selected
            if(order.consignment && order.consignment.service){
                const carrier_id = order.consignment.service.carrier_id
                batches[carrier_id] = batches[carrier_id] || [];
                batches[carrier_id].push(order)
            }
        }
        return batches
    },
    prepare_order: async function (order) {
        if(order && !order.consignment) {
            Vue.set(order, 'consignment', {
                service: null,
                data: {}
            })
        }
        if(!orderTools.is_domestic(order)) {
            await orderTools.get_international_data(order)
        }
        return true
    },
    get_tracking_number: function (order) {
        if(order.consignment && order.consignment.service){
            return store.getters[`Carriers/${order.consignment.service.carrier_type}/tracking_number`](order.consignment)
        }
    },
    get_tracking_company: function (order) {
        if(order.consignment && order.consignment.service){
            return store.getters[`Carriers/${order.consignment.service.carrier_type}/tracking_company`]
        }
    },
    get_tracking_url: function (order) {
        if(order.consignment && order.consignment.service){
            return store.getters[`Carriers/${order.consignment.service.carrier_type}/tracking_url`](order.consignment)
        }
    }
}