import axios from 'axios';
import {graphql} from "@/V2/helpers/shopify";

interface ShopifyConfig {
    storeUrl: string;
    accessToken: string;
}

export async function initiateUnfulfilledOrdersRequest(): Promise<string> {
    const query = `
      mutation {
        bulkOperationRunQuery(
          query: """
          {
            orders(query: "financial_status:paid fulfillment_status:unfulfilled") {
              edges {
                node {
                  id
                  name
                  lineItems(first: 250) {
                    edges {
                      node {
                        title
                        variantTitle
                        quantity
                        variant {
                          image {
                            originalSrc
                          }
                        }
                      }
                    }
                  }
                  shippingAddress {
                    address1
                    address2
                    city
                    province
                    country
                    zip
                  }
                }
              }
            }
          }
          """
        ) {
          bulkOperation {
            id
            status
          }
          userErrors {
            field
            message
          }
        }
      }
    `;
    const response = await graphql({
        data: {
            graphql: {
                query: query
            }
        }
    })
    const bulkOperationId = response.data.data.bulkOperationRunQuery.bulkOperation.id;
    return bulkOperationId;
}

export async function pollBulkOperation(operationId: string): Promise<string> {

    const query = `
      {
        currentBulkOperation {
          id
          status
          errorCode
          createdAt
          completedAt
          objectCount
          url
        }
      }
    `;

    let url = null;
    while (!url) {
        await new Promise(resolve => setTimeout(resolve, 10000));  // wait for 10 seconds before polling again

        const response = await graphql({
            data: {
                graphql: {
                    query: query
                }
            }
        })

        const bulkOperation = response.data.data.currentBulkOperation;

        if (bulkOperation.status === 'COMPLETED') {
            url = bulkOperation.url;
        } else if (bulkOperation.status === 'FAILED') {
            throw new Error(`Bulk operation failed with error code ${bulkOperation.errorCode}`);
        }
    }
    return url;
}


export async function fetchResultsFromURL(url: string): Promise<string> {
    const response = await axios.get(url);
    return response.data;
}

export function parseJSONL(data: string): any[] {
    const lines = data.split('\n').filter(line => line.trim() !== ''); // Split by newline and filter out any empty lines
    return lines.map(line => JSON.parse(line));
}

