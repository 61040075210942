<template>
    <div>
        <div v-if="show('products', 'open') || expand_products" class="ba-1">
            <div v-for="(item, idx) in merged.get_line_items(order)" :key="item.id">
                <v-row no-gutters class="pt-0 pb-1 px-1" v-if="item.fulfillable_quantity">
                    <v-col v-if="show('products', 'images')" cols="2" class="py-0 pr-2">
                        <v-responsive max-height="100%" max-width="100%" v-if="images[`${item.product_id}|${item.variant_id}`]">
                            <v-img
                                :src="images[`${item.product_id}|${item.variant_id}`].url" 
                                aspect-ratio="1"
                                class="grey lighten-2"
                                :max-height="display_settings.table.products.subsections.images.options.size.value"
                                :max-width="display_settings.table.products.subsections.images.options.size.value"
                                @error="reload_image(item.product_id, item.variant_id)"
                                >
                            </v-img>
                        </v-responsive> 
                    </v-col> 
                    <v-col cols="10" class="caption">
                        <v-row no-gutters>
                            <v-col :cols="title_space_available">
                                <span v-if="show('products', 'quantity')" class="font-weight-bold">{{ item.fulfillable_quantity }}</span> <span v-if="show('products', 'quantity') && show('products', 'title')">x </span>
                                <v-tooltip bottom v-if="show('products', 'title')">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ item.name.substring(0, title_length) }}</span>
                                    </template>
                                    <span>{{ item.name }}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2" v-if="show('products', 'unit_price')">
                                <div class="float-right">${{ item.price | formatNumber(2)}}</div>
                            </v-col>
                            <v-col cols="3" v-if="show('products', 'total_price')">
                                <div class="float-right font-weight-bold">${{ item.price * item.fulfillable_quantity | formatNumber(2)}}</div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <div v-if="show('products', 'sku')" class="caption grey--text">{{ item.sku }}</div>
                                <div v-if="show('products', 'properties') && item.properties">
                                    <v-row v-for="(p, index) of item.properties" v-bind:key="index" no-gutters>
                                        <v-col class="caption font-weight-bold">{{ p.name }}:</v-col>
                                        <v-col class="caption">{{ p.value }}</v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider v-if="item.fulfillable_quantity && merged.count_line_items(order) > 1 && (idx + 1) < merged.count_line_items(order)" class="mt-0 mb-2"></v-divider>
            </div>
            <div v-if="!show('products', 'open')" class="clickable text-right" @click="toggle_open()"><v-icon color="primary">mdi-chevron-up</v-icon></div>
        </div>
        <div v-if="!show('products', 'open') && !expand_products" class="caption clickable primary--text" @click="toggle_open()">
            <v-row no-gutters>
                <v-col>Line Items</v-col>
                <v-col>{{ orderTools.count_fulfillable_line_items(order) }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>Total Qty</v-col>
                <v-col>{{ orderTools.get_fulfillable_quantity(order) }}</v-col>
            </v-row>
        </div>
        <v-card flat>
            <v-card-actions class="pa-0">
                <small v-if="order.override_line_items && order.override_line_items.modified" class="orange--text"><v-icon small color="warning" class="mr-4 mt-n1">mdi-alert</v-icon>CAUTION: Original product names or values may have been overridden</small>
                <v-spacer></v-spacer>
                <v-btn icon v-if="!is_domestic" :class="order.override_line_items && order.override_line_items.modified ? 'mt-0': 'mt-n8'"><v-icon small color="blue" @click="edit = true">mdi-pencil</v-icon></v-btn>
            </v-card-actions>
        </v-card> 
        <v-dialog v-model="edit" width="600" min-height="500">
            <EditProducts :order="order" :edit="edit" v-on:close="edit=false"/>
        </v-dialog>
    </div>
</template>

<script>
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";
import EditProducts from "@/V2/components/Orders/EditProducts.vue"
import { mapGetters } from 'vuex'

export default {
    name: 'Items',
    props: ['order'],
    data: function () {
        return {
            orderTools: orderTools,
            expand_products: false,
            merged: mergeTools,
            edit: false
        }
    },
    components: {
        EditProducts,
    },
    methods: {
        show(section, subsection) {
            try {
                return this.display_settings.table[section].subsections[subsection].status
            } catch (e) {
                return false
            }
        },
        reload_image(product_id, variant_id) {
            this.$store.dispatch('Images/get_image', { image: `${product_id}|${variant_id}`, update: true })
        },
        toggle_open() {
            this.expand_products = !this.expand_products
            this.$emit('toggle_products', this.expand_products)
        }
    },
	computed : {
        ...mapGetters({
            display_settings: 'Settings/display_settings',
            images: 'Images/images'
        }),
        title_length() {
            try {
                return Number(this.display_settings.table.products.subsections.title.options.size.value)
            } catch (e) {
                return 999;
            }
        },
        title_space_available() {
            let space = 12
            if(this.show('products', 'unit_price')) {
                space = space - 2
            }
            if(this.show('products', 'total_price')) {
                space = space - 3
            }
            return space
        },
        is_domestic() {
            return orderTools.is_domestic(this.order)
        }
    }
}
</script>

<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>

