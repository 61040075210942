<template>
    <div>
        <div v-for="item in items" :key="item.id" class="pb-3">
            <v-row v-if="item.fulfillable_quantity">
                <v-col cols="2" v-if="show('images')" class="pt-0 pb-1">
                    <v-responsive max-height="100%" max-width="100%" v-if="images[`${item.product_id}|${item.variant_id}`]">
                        <v-img
                            :src="images[`${item.product_id}|${item.variant_id}`].url"
                            class="grey lighten-2"
                            :max-width="display_settings.compact.products.subsections.images.options.size.value"
                            :max-height="display_settings.compact.products.subsections.images.options.size.value"
                            @error="reload_image(item.product_id, item.variant_id)"
                            >
                        </v-img>
                    </v-responsive>
                </v-col>
                <v-col cols="10" class="py-0">
                    <v-row>
                        <v-col cols="1" class="pa-0 text-right">
                            <v-card-text v-if="show('quantity')" class="pa-0 pr-1 caption font-weight-bold">{{ item.fulfillable_quantity }} x </v-card-text>
                        </v-col>
                        <v-col cols="11" class="pa-0">
                            <v-row>
                                <v-col cols="9" class="py-0"><v-card-text class="py-0 pl-0 caption"><span class="font-weight-bold" v-if="show('title')">{{ item.name }}</span> <span v-if="item.sku && show('sku')">({{ item.sku }})</span></v-card-text></v-col>
                                <v-col cols="3" class="py-0 text-right"><v-card-text v-if="show('unit_price')" class="py-0 pl-0 caption font-weight-bold">${{ item.price  | formatNumber(2)}}</v-card-text></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <div v-if="item.properties && show('properties')">
                        <v-row v-for="(p, index) of item.properties" v-bind:key="index" no-gutters>
                            <v-col class="caption">{{ p.name }}:</v-col>
                            <v-col class="caption">{{ p.value }}</v-col>
                        </v-row>
                    </div>

                </v-col>
            </v-row>
        </div>
        <v-card flat>
            <v-card-actions class="pa-0">
                <small v-if="order.override_line_items && order.override_line_items.modified" class="orange--text"><v-icon small color="warning" class="mr-4 mt-n1">mdi-alert</v-icon>CAUTION: Original product names or values may have been overridden</small>
                <v-spacer></v-spacer>
                <v-btn icon v-if="!is_domestic" :class="order.override_line_items && order.override_line_items.modified ? 'mt-0': 'mt-n8'"><v-icon small color="blue" @click="edit = true">mdi-pencil</v-icon></v-btn>
            </v-card-actions>
        </v-card> 
        <v-dialog v-model="edit" width="600" min-height="500">
            <EditProducts :order="order" :edit="edit" v-on:close="edit=false"/>
        </v-dialog>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";
import EditProducts from "@/V2/components/Orders/EditProducts.vue"

export default {
    props: ['order', 'items'],
    data: function () {
        return {
            orderTools: orderTools,
            edit: false
        }
    },
    components: {
        EditProducts,
    },
    methods: {
        show(subsection) {
            return this.display_settings.compact.products.subsections[subsection].status
        },
        reload_image(product_id, variant_id) {
            this.$store.dispatch('Images/get_image', { image: `${product_id}|${variant_id}`, update: true })
        }
    },
	computed : {
        ...mapGetters({
            display_settings: 'Settings/display_settings',
            images: 'Images/images'
        }),
        is_domestic() {
            return orderTools.is_domestic(this.order)
        }

    }
}
</script>

