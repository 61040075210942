// Live Rates
import Vue from 'vue'
import axios from 'axios'
import orderTools from "@/V2/helpers/orderTools";
import _ from 'lodash';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { countries } from '@/V2/helpers/countries';

export default {
    namespaced: true,
    state: {
        fields: [
            {id: 'order_value', name: 'Order Value', hint: 'Enter a numeric value', validation: 'isNumber', format: 'currency', operator: 'numeric'},
            {id: 'order_weight', name: 'Order Weight', hint: 'Enter a weight (grams)', validation: 'isNumber', format: 'grams', operator: 'numeric'},
            {id: 'item_value', name: 'Item value', hint: 'Enter a numeric value', validation: 'isNumber', format: 'currency', operator: 'numeric'},
            {id: 'item_weight', name: 'Item weight', hint: 'Enter a weight (grams)', validation: 'isNumber', format: 'grams', operator: 'numeric'},
            {id: 'destination_postcode', name: 'Destination Postcode', hint: 'Enter a postcode', validation: 'required', format: 'none', operator: 'string'},
            {id: 'destination_state', name: 'Destination State', hint: 'Enter a state', validation: 'validState', format: 'none', operator: 'string'},
            {id: 'destination_country', name: 'Destination Country', hint: 'Enter a 2 character country code', validation: 'validCountryCode', format: 'none', operator: 'comparison'},
            {id: 'sku', name: 'SKU', hint: 'Enter a SKU', validation: 'required', format: 'none', operator: 'string'},
            {id: 'product_title', name: 'Product Title', hint: 'Enter a product title', validation: 'required', format: 'none', operator: 'string'},
            {id: 'quote', name: 'Original Quote Received', hint: 'Enter a numeric value', validation: 'isNumber', format: 'none', operator: 'string'},
   
        ],
        operators: {
            'numeric' : [
                {id: 'equal_to', name: 'Equal to'},
                {id: 'not_equal_to', name: 'Not equal to'},
                {id: 'greater_than', name: 'Greater than'},
                {id: 'greater_than_equal_to', name: 'Greater than or equal to'},
                {id: 'less_than', name: 'Less than'},
                {id: 'less_than_equal_to', name: 'Less than or equal to'},
            ],
            'string' : [
                {id: 'equal_to', name: 'Equal to'},
                {id: 'not_equal_to', name: 'Not equal to'},
                {id: 'starts_with', name: 'Starts with'},
                {id: 'contains', name: 'Contains'}
            ],
            'comparison' : [
                {id: 'equal_to', name: 'Equal to'},
                {id: 'not_equal_to', name: 'Not equal to'},
            ]

        },
        actions: [
            {id: 'add_amount', name: 'Add a fixed amount to the shipping price', hint: 'Enter a numeric value', value_required: true, validation: 'isNumber', format: 'currency'},
            {id: 'add_percentage', name: 'Increase the shipping price by a percentage value', hint: 'Enter a numeric value', value_required: true, validation: 'isNumber', format: 'percentage'},
            {id: 'deduct_amount', name: 'Deduct a fixed amount from the shipping price', hint: 'Enter a numeric value', value_required: true, validation: 'isNumber', format: 'currency'},
            {id: 'deduct_percentage', name: 'Decrease the shipping price by a percentage value', hint: 'Enter a numeric value', value_required: true, validation: 'isNumber', format: 'percentage'},
            {id: 'set_price', name: 'Set the shipping price to a specific value', hint: 'Enter a numeric value', value_required: true, validation: 'isNumber', format: 'currency'},
            {id: 'ignore', name: 'Do not provide a rate quote', value_required: false, format: 'none'},
            {id: 'delete_quote', name: 'Delete the rate quote', value_required: false, format: 'none'},
            {id: 'set_service_name', name: 'Set the name for the rate quote', hint: 'Enter a name', value_required: true, validation: 'nameRequired', format: 'none'},
            {id: 'set_service_description', name: 'Set the description for the rate quote', hint: 'Enter a description', value_required: true, validation: 'descriptionRequired', format: 'none'},
            {id: 'set_service_code', name: 'Set the code for the rate quote', hint: 'Enter a code', value_required: true, validation: 'codeRequired', format: 'none'},
        ],
        validation: {
            nameRequired: value => !!value || 'A name must be entered',
            descriptionRequired: value => !!value || 'A description must be entered',
            codeRequired: value => !!value || 'A code must be entered',
            required: value => !!value || 'A value must be entered',
            isNumber: value => !!value  && !isNaN(value) || 'Must be a number',
            numberOrEmpty: value => null || !isNaN(value) || 'Must be a number',
            validState: value => typeof value === 'string' || 'Must be a valid state abbreviation (ie VIC)',
            validCountryCode: value => value && countries.some(country => country.id === value.toUpperCase()) || 'Must be a valid 2 character country code (ie AU)',
        },
        formats: {
            currency: value => '$' + Number(value).toFixed(2),
            percentage: value => Number(value).toFixed(2) + '%',
            grams: value => Number(value).toFixed(0) + ' grams',
            none: value => value
        },
        common_rules: [
            {
                name: 'Minimum Order Value',
                text: [
                    'Only request rate quotes when the value of an order is equal to or greater than the Minimum Order Value.',
                ],
                status: false,
                conditions: [
                    { field: 'order_value', operator: 'less_than', value: null, title: 'Order Value is greater than', hint: 'Enter a value', validation: 'isNumber' }
                ],
                actions: [
                    { id: 'ignore', value: null, title: 'Obtain a rate quote if the order value is equal to or greater than the order value specified'}
                ],
                stop: false
            },
            {
                name: 'Maximum Order Value',
                text: [
                    'Only request rate quotes when the value of an order is equal to or less than the Maximum Order Value.',
                ],
                status: false,
                conditions: [
                    { field: 'order_value', operator: 'greater_than', value: null, title: 'Order Value is less than', hint: 'Enter a value', validation: 'isNumber' },
                ],
                actions: [
                    { id: 'ignore', value: null, title: 'Obtain a rate quote if the order value is less than or equal to the order value specified'}
                ],
                stop: false
            },
            {
                name: 'Minimum Order Weight',
                text: [
                    'Only request rate quotes when the weight of an order is equal to or greater than the Minimum Order Weight (grams).',
                ],
                status: false,
                conditions: [
                    { field: 'order_weight', operator: 'less_than', value: null, title: 'Order Weight (grams) is greater than', hint: 'Enter a value', validation: 'isNumber' }
                ],
                actions: [
                    { id: 'ignore', value: null, title: 'Obtain a rate quote if the order weight is equal to or greater than the order weight specified'}
                ],
                stop: false
            },
            {
                name: 'Maximum Order Weight',
                text: [
                    'Only request rate quotes when the weight of an order is equal to or less than the Maximum Order Weight (grams).',
                ],
                status: false,
                conditions: [
                    { field: 'order_weight', operator: 'greater_than', value: null, title: 'Order Weight (grams) is less than', hint: 'Enter a value', validation: 'isNumber' },
                ],
                actions: [
                    { id: 'ignore', value: null, title: 'Obtain a rate quote if the order weight is less than or equal to the order weight specified'}
                ],
                stop: false
            },
            {
                name: 'Set Minimum Rate Quote',
                text: [
                    'Any rate quotes received from this carrier that are less than the set price will be increased to the set price.'
                ],
                status: false,
                conditions: [
                    { field: 'quote', operator: 'less_than', value: null, title: 'Rate Quote is less than', hint: 'Enter a value', validation: 'isNumber' },
                ],
                actions: [
                    { id: 'set_price', value: null, title: 'Increase the rate quote to the set price of', hint: 'Enter a value', validation: 'isNumber' }
                ],
                stop: false
            },
            {
                name: 'Set Maximum Rate Quote',
                text: [
                    'Any rate quotes received from this carrier that are greater than the set price will be decreased to the set price.'
                ],
                status: false,
                conditions: [
                    { field: 'quote', operator: 'greater_than', value: null, title: 'Rate Quote is greater than', hint: 'Enter a value', validation: 'isNumber' },
                ],
                actions: [
                    { id: 'set_price', value: null, title: 'Decrease the rate quote to the set price', hint: 'Enter a value', validation: 'isNumber'}
                ],
                stop: false
            },
            {
                name: 'Shipping Margin (percentage)',
                text: [
                    'Add a percentage margin to the quotes received from this carrier when the order value is in the range specified.',
                    'Both the Minimum and Maximum Order Value settings are optional'
                ],
                status: false,
                conditions: [
                    { field: 'order_value', operator: 'greater_than_equal_to', value: null, title: 'Minimum Order Value (optional)', hint: 'Enter a value', validation: 'numberOrEmpty' },
                    { field: 'order_value', operator: 'less_than_equal_to', value: null, title: 'Maximum Order Value (optional)', hint: 'Enter a value', validation: 'numberOrEmpty' },
                ],
                actions: [
                    { id: 'add_percentage', value: null, hint: 'Enter a percentage value', validation: 'isNumber' },
                ],
                stop: false
            },
            {
                name: 'Shipping Margin (fixed amount)',
                text: [
                    'Add a fixed amount to the quotes received from this carrier when the order value is in the range specified.',
                    'Both the Minimum and Maximum Order Value settings are optional'
                ],
                status: false,
                conditions: [
                    { field: 'order_value', operator: 'greater_than_equal_to', value: null, title: 'Minimum Order Value (optional)', hint: 'Enter a value', validation: 'numberOrEmpty' },
                    { field: 'order_value', operator: 'less_than_equal_to', value: null, title: 'Maximum Order Value (optional)', hint: 'Enter a value', validation: 'numberOrEmpty' },
                ],
                actions: [
                    { id: 'add_amount', value: null, hint: 'Enter a fixed amount', validation: 'isNumber'},
                ],
                stop: false
            },
            {
                name: 'Shipping Discount (percentage)',
                text: [
                    'Discount quotes received from this carrier by a percentage value when the order value is in the range specified.',
                    'Both the Minimum and Maximum Order Value settings are optional'
                ],
                status: false,
                conditions: [
                    { field: 'order_value', operator: 'greater_than_equal_to', value: null, title: 'Minimum Order Value (optional)', hint: 'Enter a value', validation: 'numberOrEmpty' },
                    { field: 'order_value', operator: 'less_than_equal_to', value: null, title: 'Maximum Order Value (optional)', hint: 'Enter a value', validation: 'numberOrEmpty' },
                ],
                actions: [
                    { id: 'deduct_percentage', value: null, hint: 'Enter a percentage value', validation: 'isNumber'},
                ],
                stop: false
            },
            {
                name: 'Shipping Discount (fixed amount)',
                text: [
                    'Discount quotes received from this carrier by a fixed amount when the order value is in the range specified.',
                    'Both the Minimum and Maximum Order Value settings are optional'
                ],
                status: false,
                conditions: [
                    { field: 'order_value', operator: 'greater_than_equal_to', value: null, title: 'Minimum Order Value (optional)', hint: 'Enter a value', validation: 'numberOrEmpty' },
                    { field: 'order_value', operator: 'less_than_equal_to', value: null, title: 'Maximum Order Value (optional)', hint: 'Enter a value', validation: 'numberOrEmpty' },
                ],
                actions: [
                    { id: 'deduct_amount', value: null, hint: 'Enter a fixed amount', validation: 'isNumber'},
                ],
                stop: false
            },
            {
                name: 'Free Domestic Shipping',
                text: [
                    'Add a free shipping service to the rate quotes provided when the order total exceeds a certain value.'
                ],
                status: false,
                conditions: [
                    { field: 'destination_country', operator: 'equal_to', value: 'AU', locked: true },
                    { field: 'order_value', operator: 'greater_than_equal_to', value: null, title: 'Minimum Order Value', hint: 'Enter a value', validation: 'isNumber' },
                ],
                actions: [
                    { id: 'set_price', value: Number(0).toFixed(2), hint: '$', locked: true},
                    { id: 'set_service_name', value: 'Free Shipping', validation: 'required'},
                    { id: 'set_service_description', value: ''},
                    { id: 'set_service_code', value: ''},
                ],
                stop: false
            },
            {
                name: 'Free International Shipping',
                text: [
                    'Add a free international shipping service to the rate quotes provided when the order total exceeds a certain value.'
                ],
                status: false,
                conditions: [
                    { field: 'destination_country', operator: 'not_equal_to', value: 'AU', title: 'Country Code is other than', locked: true },
                    { field: 'order_value', operator: 'greater_than_equal_to', value: null, title: 'Minimum Order Value', hint: 'Enter a value', validation: 'isNumber' },
                ],
                actions: [
                    { id: 'set_price', value:  Number(0).toFixed(2), hint: '$', locked: true},
                    { id: 'set_service_name', value: 'Free International Shipping', validation: 'required'},
                    { id: 'set_service_description', value: ''},
                    { id: 'set_service_code', value: ''},
                ],
                stop: false
            },

        ],
        carrier_service: {},
        settings: {
            selected_carriers: [],
            backup_rates: [],
            defaults: {
                handling_time: 0,
                cutoff: 14,
                length: 5,
                width: 5,
                height: 5,
                item_weight: 0.25
            },
            rules: [],
            custom_rules: [],
            carrier_settings: {}
        }
    },
    mutations: {
        init_carrier_service: function (state, payload) {
            Vue.set(state, 'carrier_service', payload)
        },
		init: function (state, payload) {
            state.settings = _.merge(state.settings, payload.settings)
		},
        set: function (state, payload) {
            Vue.set(state.settings, 'carrier_service', payload.carrier_service)
        },
        setStatus: function (state, payload) {
            Vue.set(state.settings, 'status', payload)
        }
    },
    getters: {
        carrier_service: state => {
            return state.carrier_service
        },
		status: state => {
			return state.carrier_service.id
        },
    },
    actions: {
		init: async function ({ dispatch, rootGetters }) {
            await dispatch('get_carrier_services')
			await dispatch('get_settings')
			return true
		},
		save: async function({ state, rootGetters }) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/rates/settings`, {settings: state.settings}, { headers: { 'Authorization': token }})
			.then(response => {
				orderTools.log([{ id: 'RatesSettings', type: 'Save', data: { settings: state.settings, response: response.data }}])
                return true
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'rates/save', data: err})
			})

		},
		get_settings: async function({ commit, dispatch, rootGetters }) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.get(`${process.env.VUE_APP_V2_SHOPIFY_API}/rates/settings`, { headers: { 'Authorization': token }})
			.then(response => {
				if(response.data){
					commit('init', response.data.settings)
					return true
				} else {
					return false
				}
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'rates/get', data: err})
			})

		},
        get_carrier_services: async function ({ commit, rootGetters }) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.get(`${process.env.VUE_APP_V2_SHOPIFY_API}/carrier_services`, 
			{
				headers: { 
					'Authorization': token,
				}
			})
            .then(response => {
                if(response.data.carrier_services) {
                    for(const service of response.data.carrier_services) {
                        if(service.name == 'SellerDash Shipping' && service.callback_url) {
                            commit('init_carrier_service', service)
                        }
                    }
                }
                return response
            })
            .catch(err => {
                orderTools.log_error({message: 'Network Error', stack: 'carrier_services/post', data: err})
            })

        },
        enable: async function ({ commit, rootGetters }){
            const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carrier_services`, { carrier_service: { name: 'SellerDash Shipping', callback_url: `https://shopify.sellerdash.io/rates/quote/${rootGetters['Shopify/shop']}`, service_discovery: true }},
			{
				headers: { 
					'Authorization': token
				}
			})
            .then(response => {
                if(response.data.carrier_service) {
                    commit('init_carrier_service', response.data.carrier_service)
                    return response.data.carrier_service
                }
                return response.data
            })
            .catch(err => {
                console.log(err)
//                orderTools.log_error({message: 'Network Error', stack: 'carrier_services/post', data: err})
            })
        }, 
        disable: async function ({ state, commit, rootGetters }){
            const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.delete(`${process.env.VUE_APP_V2_SHOPIFY_API}/carrier_services/${state.carrier_service.id}`,
			{
				headers: { 
					'Authorization': token,
				}
			}).then(response => {
                if(!response.data.errors) {
                    commit('init_carrier_service', response.data)
                }
                return response.data
            })
            .catch(err => {
//                orderTools.log_error({message: 'Network Error', stack: 'carrier_services/post', data: err})
                return { errors: 'Network Error'}
            })
        }, 
    
    }
}