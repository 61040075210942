export const grouped_products = [
    {
        id: 'EXP',
        description: 'Express Post',
        domestic: true,
        services: ['B20','B21','BE9PB1','BE9PB2','BE9PB3','BE9PB4','BE9P05','BE9P10','BE9P30','BE9P50']
    },
    {
        id: 'PP',
        description: 'Parcel Post',
        domestic: true,
        services: ['B30','B31','BE1PB1','BE1PB2','BE1PB3','BE1PB4','BE1P05','BE1P10','BE1P30','BE1P50']
    },
    {
        id: 'EXP',
        description: 'Express',
        domestic: false,
        services: ['I67']
    },
    {
        id: 'PP',
        description: 'Standard',
        domestic: false,
        services: ['I62','I63','I64','I65']
    },

]

export const products = [
    {
        id: 'B30',
        description: 'Parcel Post',
        official_name: 'Parcel Post Parcels (BYO up to 5kg)',
        service_level: 'standard',
        volumetric: true,
        domestic: true,
        min_weight: 1,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'B31',
        description: 'Parcel Post',
        official_name: 'Parcel Post Parcels (over 5kg)', 
        service_level: 'standard',
        volumetric: true,
        domestic: true,
        min_weight: 5001,
        max_weight: 999999,
        data: {}
    },
    {
        id: 'B20',
        description: 'Express Post',
        official_name: 'Express Post Parcels (BYO up to 5kg)', 
        service_level: 'express',
        volumetric: true,
        domestic: true,
        min_weight: 1,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'B21',
        description: 'Express Post',
        official_name: 'Express Post Parcels (over 5kg)', 
        service_level: 'express',
        volumetric: true,
        domestic: true,
        min_weight: 5001,
        max_weight: 999999,
        data: {}
    },
    {
        id: 'I65',
        description: 'Intl. - Standard',
        official_name: 'International Standard', 
        service_level: 'standard',
        volumetric: true,
        domestic: false,
        min_weight: 1,
        max_weight: 20000,
        data: {}
    },
    {
        id: 'I67',
        description: 'Intl. - Express',
        official_name: 'International Express', 
        service_level: 'express',
        volumetric: true,
        domestic: false,
        min_weight: 1,
        max_weight: 20000,
        data: {}
    },
    {
        id: 'BE9PB1',
        description: 'Express Post Box (S)',
        official_name: 'Express Post Flat Rate Box (Small)', 
        service_level: 'express',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE9PB2',
        description: 'Express Post Box (M)',
        official_name: 'Express Post Flat Rate Box (Medium)', 
        service_level: 'express',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE9PB3',
        description: 'Express Post Box (L)',
        official_name: 'Express Post Flat Rate Box (Large)', 
        service_level: 'express',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE9PB4',
        description: 'Express Post Box (XL)',
        official_name: 'Express Post Flat Rate Box (Extra Large)', 
        service_level: 'express',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE9P05',
        description: 'Express Post Satchel (S)',
        official_name: 'Express Post Flat Rate Satchel 5kg (Small)', 
        service_level: 'express',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE9P10',
        description: 'Express Post Satchel (M)',
        official_name: 'Express Post Flat Rate Satchel 5kg (Medium)', 
        service_level: 'express',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE9P30',
        description: 'Express Post Satchel (L)',
        official_name: 'Express Post Flat Rate Satchel 5kg (Large)', 
        service_level: 'express',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE9P50',
        description: 'Express Post Satchel (XL)',
        official_name: 'Express Post Flat Rate Satchel 5kg (Extra Large)', 
        service_level: 'express',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE1PB1',
        description: 'Parcel Post Box (S)',
        official_name: 'Parcel Post Flat Rate Box (Small)', 
        service_level: 'standard',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE1PB2',
        description: 'Parcel Post Box (M)',
        official_name: 'Parcel Post Flat Rate Box (Medium)', 
        service_level: 'standard',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE1PB3',
        description: 'Parcel Post Box (L)',
        official_name: 'Parcel Post Flat Rate Box (Large)', 
        service_level: 'standard',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE1PB4',
        description: 'Parcel Post Box (XL)',
        official_name: 'Parcel Post Flat Rate Box (Extra Large)', 
        service_level: 'standard',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE1P05',
        description: 'Parcel Post Satchel (S)',
        official_name: 'Parcel Post Flat Rate Satchel 5kg (Small)', 
        service_level: 'standard',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE1P10',
        description: 'Parcel Post Satchel (M)',
        official_name: 'Parcel Post Flat Rate Satchel 5kg (Medium)', 
        service_level: 'standard',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE1P30',
        description: 'Parcel Post Satchel (L)',
        official_name: 'Parcel Post Flat Rate Satchel 5kg (Large)', 
        service_level: 'standard',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
    {
        id: 'BE1P50',
        description: 'Parcel Post Satchel (XL)',
        official_name: 'Parcel Post Flat Rate Satchel 5kg (Extra Large)', 
        service_level: 'standard',
        volumetric: false,
        domestic: true,
        min_weight: 0,
        max_weight: 5000,
        data: {}
    },
]