<template>
    <v-card class="mt-4" outlined>
        <v-alert v-if="!settings.selected_carriers.length" type="error" outlined dense border="left" class="mt-2 mb-2">You need to select a carrier in the CARRIER SETTINGS page to test rate quotes.</v-alert>
        <v-toolbar color="white" dense elevation="0">
            <v-alert type="warning" outlined dense dismissible border="left" class="mb-0">Note: Orders are displayed below regardless of current status.</v-alert>
            <v-btn @click="get_orders" icon><v-icon right dark>mdi-refresh</v-icon></v-btn>
        </v-toolbar>
        <v-data-table
            :items="orders"
            :headers="headers"
            :items-per-page="10"
        >
            <template v-slot:[`item.shipping_address`]="{ item }">
                <div>{{ item.shipping_address.address1 }}</div>
                <div>{{ item.shipping_address.address2 }}</div>
                <div>{{ item.shipping_address.city }} {{ item.shipping_address.province_code }} {{ item.shipping_address.zip }} {{ item.shipping_address.country_code }}</div>
            </template>
            <template v-slot:[`item.line_items`]="{ item }">
                <div v-for="(p, index) of item.line_items" :key="index">{{ p.quantity }} x {{ p.title }}</div>
            </template>
            <template v-slot:[`item.net_item_weight`]="{ item }">
                <div v-for="(p, index) of item.line_items" :key="index">{{ p.grams / 1000 | formatNumber(2)}}</div>
            </template>
            <template v-slot:[`item.gross_item_weight`]="{ item }">
                <div v-for="(p, index) of item.line_items" :key="index">{{ (p.grams * p.quantity) / 1000 | formatNumber(2)}}</div>
            </template>
            <template v-slot:[`item.total_order_weight`]="{ item }">
                {{ total_weight(item) / 1000 | formatNumber(2) }}
            </template>
            <template v-slot:[`item.shipping_lines[0].discounted_price`]="{ item }">
                ${{ (item.shipping_lines[0] ? item.shipping_lines[0].discounted_price : 0) | formatNumber(2) }}
            </template>
            <template v-slot:[`item.calculated`]="{ item }">
                <v-btn v-if="!item.calculated_shipping" small color="primary" @click="calculate(item)" class="text-none">Get Quote</v-btn>
                <div v-else>${{ item.calculated_shipping }}</div>
                <v-btn small color="primary" @click="view_details(item)" class="text-none ml-2">View Quote</v-btn>
            </template>
        </v-data-table>
        
        <v-dialog v-model="detailsDialog" max-width="900px">
            <QuoteDetails :order="selected"/>
        </v-dialog>
    </v-card>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import axios from 'axios'
import { getSessionToken } from '@shopify/app-bridge-utils';
import QuoteDetails from '@/V2/components/LiveRates/QuoteDetails.vue'

export default {
    data: function () {
        return {
            orders: [],
            headers: [
                { text: 'Order No', value: 'name' },
                { text: 'Address', value: 'shipping_address' },
                { text: 'Products', value: 'line_items' },
                { text: 'Item Weight (kg)', value: 'net_item_weight' },
                { text: 'Total Item Weight (kg)', value: 'gross_item_weight' },
                { text: 'Total Order Weight (kg)', value: 'total_order_weight' },
                { text: 'Shipping Method', value: 'shipping_lines[0].title' },
                { text: 'Shipping Charged', align: 'end', value: 'shipping_lines[0].discounted_price' },
                { text: 'Quoted Shipping', value: 'calculated' },
            ],
            detailsDialog: false,
            selected: {}
        }
    },
    components: {
        QuoteDetails,
    },
    methods: {
        get_orders: async function () {
            this.orders = []
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            await axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/shopify`, { 
                method: 'GET', 
                resource: 'orders.json',
                params: `fields=id,created_at,name,line_items,refunds,shipping_address,shipping_lines&status=any&limit=250`,
            },
			{
				headers: { 
					'Authorization': token,
				}
			}).then(response => {
                this.orders = response.data.result.orders
                this.orders.forEach(o => {
                    o.line_items = this.net_line_items(o)
                })
            })            
        },
        total_weight: function (order) {
            let weight = 0
            order.line_items.forEach( (item) => {
                weight = item.grams ? weight + item.grams * item.quantity : weight;
            })
            return weight
        },
        calculate: function(order) {
            Vue.set(order, 'calculated_shipping', 7.63)
        },
        view_details: function (order) {
            this.get_fulfillment_orders(order)
            this.selected = order
            this.detailsDialog = true
        },
        get_fulfillment_orders: async function(order) {
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            await axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/shopify`, { 
                method: 'GET', 
                resource: `orders/${order.id}/fulfillment_orders.json`,
            },
			{
				headers: { 
					'Authorization': token,
				}
			}).then(response => {
                Vue.set(order, 'fulfillment_orders', response.data.result.fulfillment_orders)
            })            

        },
        net_line_items: function (order) {
            let line_items = []
            if(order.refunds) {
                const idsFromRefunds = new Set(
                    order.refunds?.flatMap(refund =>
                        refund.refund_line_items?.map(item => item.line_item_id) || []
                    ) || []
                );
                line_items = order.line_items.filter(item => !idsFromRefunds.has(item.id));
            }
            return line_items
        }
    },
    created() {
        this.get_orders()
    },
    computed: {
        ...mapState('LiveRates', ['settings']), 
        appId: function () {
          return process.env.VUE_APP_ID  
        }, 
    }
}
</script>

