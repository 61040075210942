<template>
    <v-card>
        <v-tabs v-model="tab">
            <v-tab class="text-none" v-for="(carrier, index) in carriers_with_tracking" :key="index">{{ carrier.name || carrier.carrier }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(carrier, index) in carriers_with_tracking" :key="index">
                <keep-alive>
                    <Tracking :carrier="carrier"/>
                </keep-alive>
            </v-tab-item>
        </v-tabs-items>
        
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Tracking from '@/V2/components/Carriers/Tracking'

export default {
    data: function () {
		return {
            tab: 0
        }
    },
    components: {
        Tracking
    },
    methods: {

    },
	computed : {
		...mapGetters({
			carriers: 'Carriers/carriers',
		}),
        carriers_with_tracking() {
            const arr = []
            Object.keys(this.carriers).forEach(carrier => {
                if(this.$store.getters[`Carriers/${this.carriers[carrier].carrier}/has_tracking`]){
                    arr.push(this.carriers[carrier])
                }
            })
            return arr
        },
    }    
}
</script>