<template>
    <div>
        <!-- XXXXXXXXXXX XS and SM Screens XXXXXXXXXXX -->
        <v-row class="hidden-md-and-up">
            <v-col>
                <v-row>
                    <v-col cols="5" class="py-2">
                        <Customer :order="order"/>
                    </v-col>
                    <v-col cols="7" class="py-2">
                        <Summary v-if="show('order_summary')" :order="order" class="mb-2 flex-grow-1"/>
                    </v-col>
                </v-row>
                <v-divider class="ma-0"></v-divider>
                <v-row class="px-2">
                    <v-col cols="12" class="py-2">
                        <Consignments v-if="order.consignment" :order="order"/>
                        <Rates v-if="!order.consignment" :order="order"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <v-divider class="ma-0"></v-divider>
                        <Panels :order="order" :panels="['products', 'packages', 'order_options']"/>
                    </v-col>
                </v-row>            
            </v-col>
        </v-row>
        <!-- XXXXXXXXXXX MD Screens XXXXXXXXXXX -->
        <v-row class="hidden-sm-and-down hidden-lg-and-up">
            <v-col cols="4" class="py-2 pr-1">
                <v-row>
                    <v-col cols=12 class="pt-2"><Customer :order="order"/></v-col>
                    <v-col cols=12><Summary v-if="show('order_summary')" :order="order" class="mb-2 flex-grow-1"/></v-col>
                </v-row>
            </v-col>
            <v-col cols="8" class="py-2 pl-1 pr-3">
                <v-row>
                    <v-col cols=10 class="offset-2">
                        <Consignments v-if="order.consignment" :order="order"/>
                        <Rates v-if="!order.consignment" :order="order"/>
                    </v-col>
                    <v-col cols=12 class="pr-5">
                        <v-card outlined>
                            <Panels :order="order" :panels="['products', 'packages', 'order_options']"/>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- XXXXXXXXXXX LG Screens XXXXXXXXXXX -->
        <v-row class="hidden-md-and-down hidden-xl-only">
            <v-col cols="3" class="pt-0 pb-2 pl-2 pr-1">
                <v-row>
                    <v-col cols=12 class="pt-2"><Customer :order="order"/></v-col>
                    <v-col cols=12 class="pl-5"><Summary v-if="show('order_summary')" :order="order" class="mb-2 flex-grow-1"/></v-col>
                </v-row>
            </v-col>
            <v-col cols="5" class="py-2 px-1">
                <v-card outlined>
                    <Panels :order="order" :panels="['products']"/>
                </v-card>
            </v-col>
            <v-col cols="4" class="py-2 pl-1 pr-3">
                <Consignments v-if="order.consignment" :order="order"/>
                <Rates v-if="!order.consignment" :order="order"/>
                <v-card outlined class="mt-2 mr-2">
                    <Panels :order="order" :panels="['packages', 'order_options']"/>
                </v-card>
            </v-col>
        </v-row>
        <!-- XXXXXXXXXXX XL Screens XXXXXXXXXXX -->
        <v-row class="hidden-lg-and-down">
            <v-col cols="2" class="pt-0 pb-2 pr-1">
                <v-row>
                    <v-col cols=12 v-if="show('customer')" class="pt-2"><Customer :order="order" v-on:expand="expand"/></v-col>
                    <v-col cols=12 v-if="show('order_summary')"><Summary :order="order" class="mb-2 flex-grow-1"/></v-col>
                </v-row>
            </v-col>
            <v-col cols="4" class="py-2 px-1">
                <v-card outlined v-if="show('products')">
                    <Panels :order="order" :panels="['products']"/>
                </v-card>
            </v-col>
            <v-col cols="3" class="py-2 px-1">
                <v-card outlined>
                    <Panels :order="order" :panels="['packages', 'order_options']"/>
                </v-card>
            </v-col>
            <v-col cols="3" class="py-2 pr-5">
                <Consignments v-if="order.consignment" :order="order"/>
                <Rates v-if="!order.consignment" :order="order"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";
import Customer from '@/V2/components/Orders/Customer'
import Summary from '@/V2/components/Orders/Summary'
import Rates from '@/V2/components/Orders/Rates'
import Consignments from '@/V2/components/Orders/Consignments'
import Panels from '@/V2/components/Orders/Panels'

export default {
    props: ['order', 'selected'],
    data: function () {
        return {
            is_selected: null
        }
    },
    watch: {
        selected: function (value) {
            const selected = this.selected.find(item => {
                return item == this.order.id
            })
            this.is_selected = selected ? this.order.id : null
        }
    },
    components: {
        Customer,
        Summary,
        Rates,
        Consignments,
        Panels
    },
    methods: {
        expand: function () {
            Vue.set(this.order, 'layout', 'expanded')
        },
        show(section, subsection) {
			if(subsection) {
				return this.display_settings.compact[section].subsections[subsection].status
			} else {
				return this.display_settings.compact[section].status
			}
        },
		action: function (event, orders) {
            this.$emit('action', event, orders)
        }
    },
    computed: {
		...mapGetters({
			display_settings: 'Settings/display_settings'
		}),
    }
}
</script>

