<template>
    <div>
        <XSview  v-if="screen == 'xs'" :order="order"/>
        <SMview  v-if="screen == 'sm'" :order="order"/>
        <MDview  v-if="screen == 'md'" :order="order"/>
        <LGview  v-if="screen == 'lg'" :order="order"/>
        <XLview  v-if="screen == 'xl'" :order="order"/>
    </div>
</template>

<script>
import Vue from 'vue'
import XSview from '@/V2/components/Orders/Expanded/Layouts/xs'
import SMview from '@/V2/components/Orders/Expanded/Layouts/sm'
import MDview from '@/V2/components/Orders/Expanded/Layouts/md'
import LGview from '@/V2/components/Orders/Expanded/Layouts/lg'
import XLview from '@/V2/components/Orders/Expanded/Layouts/xl'

export default {
    props: ['order'],
    components: {
        XSview,
        SMview,
        MDview,
        LGview,
        XLview
    },
    computed: {
        screen: function () {
            return this.$vuetify.breakpoint.name
        }
    }
}
</script>

