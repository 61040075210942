import Vue from "vue"
import moment from 'moment'

Vue.filter('formatDate', function(value, format) {
    if (value) {
        return moment(String(value)).format(format)
    }
})

Vue.filter('formatNumber', function(value, decimals) {
    if (!isNaN(value)) {
        return Number(value).toFixed(decimals)
    }
})

Vue.filter('thousands', function(value) {
    if (!isNaN(value)) {
        return new Intl.NumberFormat().format(value)    
    }
})

Vue.filter('currencySymbol', function(value) {
    if (value == 'AUD') {
        return '$'
    } 
    return value
})

Vue.filter('inGrams', function(weight, unit) {
    switch (unit) {
        case 'GRAMS':
            return weight
        case 'KILOGRAMS':
            return weight * 1000
        case 'OUNCES':
            return weight * 28.3495
        case 'POUNDS':
            return weight * 453.592
        default:
            break;
    }
})

Vue.filter('mypostService', function(str) {
    if(str.match(/^Express/g)) {
        return 'Express Post'
    }
    if(str.match(/^Parcel/g)) {
        return 'Parcel Post'
    }
    if(str.match(/^International/g)) {
        if(str.match(/Standard/g)) {
            return 'Standard'
        }
        if(str.match(/Economy Air/g)) {
            return 'Economy'
        }
        if(str.match(/Express/g)) {
            return 'Express'
        }
    
    }
    return str
})

Vue.filter('mypostServiceID', function(str) {
    if(str.match(/^Express/g)) {
        return 'EXP'
    }
    if(str.match(/^Parcel/g)) {
        return 'PP'
    }
    if(str.match(/^International/g)) {
        if(str.match(/Standard/g)) {
            return 'STD'
        }
        if(str.match(/Economy Air/g)) {
            return 'AIR'
        }
        if(str.match(/Express/g)) {
            return 'EXP'
        }
    
    }
    return str
})

Vue.filter('mypostServiceName', function(str) {
    let products = {
        B20: 'Express Post',
        B21: 'Express Post',
        B30: 'Parcel Post',
        B31: 'Parcel Post',
        BE1P05: 'Parcel Post Satchel (Small)',
        BE1P10: 'Parcel Post Satchel (Medium)',
        BE1P30: 'Parcel Post Satchel (Large)',
        BE1P50: 'Parcel Post Satchel (Extra Large)',
        BE1PB1: 'Parcel Post Box (Small)',
        BE1PB2: 'Parcel Post Box (Medium)',
        BE1PB3: 'Parcel Post Box (Large)',
        BE1PB4: 'Parcel Post Box (Extra Large)',
        BE9P05: 'Express Post Satchel (Small)',
        BE9P10: 'Express Post Satchel (Medium)',
        BE9P30: 'Express Post Satchel (Large)',
        BE9P50: 'Express Post Satchel (Extra Large)',
        BE9PB1: 'Express Post Box (Small)',
        BE9PB2: 'Express Post Box (Medium)',
        BE9PB3: 'Express Post Box (Large)',
        BE9PB4: 'Express Post Box (Extra Large)',
        I63: 'International - Economy Air',
        I64: 'International - Standard',
        I65: 'International - Standard',
        I67: 'International - Express Merch'
    }
    if(products[str]){
        return products[str]
    }
    return str
})

Vue.filter('getID', function(str) {
    let arr = str.split("/");
    return arr[arr.length - 1]
})

Vue.filter('resizeImage', function(str, size) {
    // Sizes = thumb, small, medium, large, 1024x
    if(str && str.length){
        let res = str.replace(/\.jpg/i, `_${size}.jpg`);
        res = res.replace(/\.jpeg/, `_${size}.jpeg`);
        res = res.replace(/\.png/, `_${size}.png`);
        res = res.replace(/\.gif/, `_${size}.gif`);
        res = res.replace(/\?.*/, '')
        return res
    }
})

Vue.filter('gateway', function(str) {
    if(str && str.length) {
        let gateways = {
            shopify_payments: 'Credit Card',
            paypal: 'PayPal'
        }
        if(gateways[str]){
            return gateways[str]
        }
        str = str.toLowerCase().split(' ').map(function(word) {
          return (word.charAt(0).toUpperCase() + word.slice(1));
        });
    
        return str.join(' ')
    }
})