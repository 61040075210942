<template>
    <div class="mt-n6" v-if="selected">
        <component :is="componentFile" :account_credentials="{}" :carrier_account_name="null"/>
    </div>
</template>

<script>
export default {
    computed: {
        selected() {
            return this.$route.params.carrier_id
        },
        componentFile() {
            let selected = this.$route.params.carrier_id
            return () => import(`@/V2/integrations/${selected}/views/Add`);
		},
        carriers() {
            return this.$store.getters[`Carriers/carriers`]
        }
    } 
}
</script>
