import store from "@/V2/store/index"
import rules from "@/V2/helpers/rules";
import axios from 'axios'
import Vue from 'vue'
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
    get_package: function (order) {
        const packages = store.getters['Packages/packages']
        const packages_list = store.getters['Packages/list']
        if(order.package || (order.packages && order.packages.length) ) {
            const arr: Array<any> = []
            if(order.package && packages_list.details[order.package]) {
                arr.push({ selected: order.package, dimensions: order.dimensions, weight: this.get_order_weight(order) / 1000, materials: order.materials, name: packages_list.details[order.package]['name'], short_name: packages_list.details[order.package]['short_name'] })
            }
            if(order.packages && order.packages.length) {
                return order.packages
            } else {
                return arr
            }
        } else {
            if(packages.default) {
                return [{ selected: packages.default, dimensions: packages.dimensions, weight: this.get_order_weight(order) / 1000, materials: order.materials != undefined ? order.materials : packages.materials, name: packages_list.details[packages.default]['name'], short_name: packages_list.details[packages.default]['short_name'] }]
            } else {
                return [{ selected: 'custom', dimensions: order.dimensions ? order.dimensions : [5, 5, 5], weight: this.get_order_weight(order) / 1000, materials: 0, name: 'Custom Packaging', short_name: 'Custom'}]
            }
        }

    },
    get_line_item_weights: function (order) {
        let weight = 0
        order.line_items.forEach( (item) => {
            weight = item.grams ? weight + item.grams * item.fulfillable_quantity : weight;
        })
        return weight
    },
    get_order_weight: function (order) {
        if(order.weight !== undefined) {
            return order.weight
        }
        let weight = 0
        order.line_items.forEach( (item) => {
            weight = item.grams ? weight + item.grams * item.fulfillable_quantity : weight;
        })
        return weight
    },
    get_total_package_weight: function (order) {
        // returns weight in grams
        let order_weight = this.get_order_weight(order)
        const package_weight = (order.materials || this.get_package(order)[0].materials) * 1000 || 0
        if(order_weight + package_weight == 0 || isNaN(order_weight + package_weight)){
            order_weight = store.getters['Packages/defaults'].weight * 1000
            Vue.set(order, 'weight_forced', true) 
        } else {
            Vue.set(order, 'weight_forced', false) 
        }
        return order_weight + package_weight
    },
    get_cubic_weight: function (order, factor = 250) {
        const order_package = this.get_package(order)[0]
        try{
            return (order_package.dimensions[0] * order_package.dimensions[1] * order_package.dimensions[2]) / 1000 * factor 
        } catch (e) {
            return 0
        }
    },
    get_line_item_variant: function(id, order) {
        const line_item = order.line_items.find(item => {
            return item.id == id
        })
        return line_item.variant_id
    },
    get_order_total: function (order) {
        //TODO make sure this allows for discounts and refunds
        return order.total_line_items_price - order.total_discounts
    },
    is_domestic: function (order) {
        if(order.shipping_address) {
            if(order.shipping_address.country_code !== 'AU') {
                return false
            }
        }
        return true
    },
    get_all_prices: function (order) {
        const order_quotes = store.getters['Quotes/quote'](order)
        const arr: Array<any> = []
        for(const id in order_quotes.quotes) {
            const carrier_type = id.split('|')[0]
            const carrier = store.getters['Carriers/carrier'](id)
            const prices = store.getters[`Carriers/${carrier_type}/get_prices`]({ carrier: carrier, quotes: order_quotes.quotes[id].data })
            arr.push(prices)
        }
        return arr
    },
    get_selected_rate: async function (order, prices: Array<any> = []) {
        if(!prices.length) {
            prices = this.get_all_prices(order)
        }
        const order_quotes = store.getters['Quotes/quote'](order)
        let all_prices: Array<any> = []

        try {
            prices.forEach( ( arr ) => {
                all_prices = all_prices.concat(arr)
            })
        } catch (e) {
            const err = {message: e, order: order}
            this.log_error(err)
        }
        // see if a rate has already been manually selected
        let selected: Array<any> = []
        if(order.manually_selected) {
            selected = all_prices.filter( (price) => {
                return  price.carrier_id == order.manually_selected.carrier_id &&
                        price.code == order.manually_selected.code
            })
        } else if(order.consignment) {
            selected = all_prices.filter( (price) => {
                if(order.consignment.service){
                    return  price.carrier_id == order.consignment.service.carrier_id &&
                    price.code == order.consignment.service.code
                }
            })
        } else {
            // this is only required for transitioning from caching of selected rate to storing it against the order
            selected = all_prices.filter( (price) => {
                return  price.carrier_id == order_quotes.selected.carrier_id &&
                        price.code == order_quotes.selected.code
            })
        }

        if(selected.length){
            Vue.set(order, 'rate_selected', selected[0])
            return selected[0]
        } else {
            const selected_by_rule = await rules.run(order, all_prices)
            Vue.set(order, 'rate_selected', selected_by_rule)
            return selected_by_rule
        }

    },
    get_fulfillable_quantity: function (order) {
        const count = order.line_items.filter( (item) => {
            return item.fulfillable_quantity > 0 && item.requires_shipping;
        })
        let quantity = 0
        for(const o of count){
            quantity = quantity + o.fulfillable_quantity
        }
        return quantity
    },
    count_all_line_items: function (order) {
        return order.line_items.length
    },
    count_fulfillable_line_items: function (order) {
        const count = order.line_items.filter( (item) => {
            return item.fulfillable_quantity > 0 && item.requires_shipping;
        })
        return count.length
    },
    searchable_text: function (order) {
        const arr: Array<any> = []
        arr.push(order.name, order.email, order.phone)
        if(order.shipping_address) {
            const a = order.shipping_address
            arr.push(a.company, a.first_name, a.last_name, a.address1, a.address2, a.city, a.province_code, a.zip, a.country_code)
        }
        if(order.shipping_lines[0]) {
            arr.push(order.shipping_lines[0].title)
        }
        if(order.line_items) {
            order.line_items.forEach( (l) => { 
                if (l.sku) {
                    arr.push(l.sku) 
                }
                arr.push(l.name)
            })
        }
        return arr.join(' ')
    },
    reset_quotes: function(orders) {
        orders.forEach( (order) => {
            store.dispatch('Quotes/reset_quote', order)		
            store.commit('Quotes/select_quote', {order: order, selected: {}})
        })
    },
    get_image: function (product_id, variant_id, image_size) {
        const images = store.getters['Images/images']
        if(images[`${product_id}|${variant_id}`] && images[`${product_id}|${variant_id}`].url) {
            return Vue.filter('resizeImage')(images[`${product_id}|${variant_id}`].url, image_size)
        }
    },
    defer_orders: function (orders, deferred) {
        orders.forEach( order => {
            Vue.set(order, 'deferred', deferred)
            Vue.set(order, 'save_event', 'DeferOrder')
            store.dispatch('Orders/save', order)
        })
    },
    log: async function (events) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/log`, {
            events: events
        },
        {
            headers: { 
                'Authorization': token,
            }
        }).then(response => {
            return response.data
        })
    },
    log_debug: function (error) {
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/debuglog`, error)
    },
    log_error: async function (error) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/errorlog`, error,
        {
            headers: { 
                'Authorization': token
            }
        }).then(response => {
            return response.data
        })
    },
    create_task: async function (type, data = {}) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const task_id = Math.random().toString(16).substring(2) + Date.now().toString(16);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/tasks/save`, {
            task_id: task_id,
            type: type,
            data: data
        },
        {
            headers: { 
                'Authorization': token,
            }
        }).then(response => {
            localStorage.setItem('task', task_id)
            return task_id
        })
        .catch(err => {
            this.log_error({message: 'Network Error', stack: 'orderTools/create_task', data: err})
        })
    },
    get_task: async function (task_id) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/tasks/get`, {
            task_id: task_id
        },
        {
            headers: { 
                'Authorization': token,
            }
        }).then(task => {
            return task.data.body
        })
        .catch(err => {
            this.log_error({message: 'Network Error', stack: 'orderTools/get_task', data: err})
        })
    },
    loggable_data: function(order) {
        const order_fields = store.getters['Settings/order_fields']
        const loggable_fields = store.getters['Settings/loggable_fields']
        let loggable = JSON.parse(JSON.stringify(order))
        Object.keys(loggable).forEach(e => {
            if(!loggable_fields[e]) {
                delete loggable[e]
            }
        })
        try {
            if(loggable['consignment']) {
                loggable['consignment'] = store.getters[`Carriers/${loggable['consignment']['service']['carrier_type']}/redact_customer`](loggable['consignment'])
            }
        } catch (e) {

        }
        return loggable
    },
    get_reference: function(order, length, pkg = 0) {
        const label_options = store.getters['User/label_options']
        let reference = this.get_reference_field(order, label_options.preference_1, '', pkg)
        reference = this.get_reference_field(order, label_options.preference_2, reference, pkg)
        reference = this.get_reference_field(order, label_options.preference_3, reference, pkg)
        return this.truncate_string(reference, length)
    },
    get_reference_array: function(order, length, pkg = 0) {
        const arr: Array<any> = []
        const label_options = store.getters['User/label_options']
        if (label_options.preference_1 != 'nr') arr.push(this.truncate_string(this.get_reference_field(order, label_options.preference_1, '', pkg), length)) 
        if (label_options.preference_2 != 'nr') arr.push(this.truncate_string(this.get_reference_field(order, label_options.preference_2, '', pkg), length))
        if (label_options.preference_3 != 'nr') arr.push(this.truncate_string(this.get_reference_field(order, label_options.preference_3, '', pkg), length))
        return arr
    },
    get_reference_field: function (order, field, reference, pkg) {
        const arr: Array<any> = []
        let str = ''
        switch(field) {
            case 'order_number':
                str = order.name
                break
            case 'sku_list':
                order.line_items.forEach( l => { 
                    if (l.sku) {
                        arr.push(l.sku) 
                    }
                })
                str = arr.join(' ')
                break
            case 'sku_list_with_quantity':
                order.line_items.forEach( l => { 
                    if (l.sku) {
                        arr.push(l.fulfillable_quantity + 'x' + l.sku) 
                    }
                })
                str = arr.join(' ')
                break
            case 'product_title':
                order.line_items.forEach( l => { 
                    if (l.name) {
                        arr.push(l.name) 
                    }
                })
                str = arr.join(' ')
                break
            case 'package':
                str =  this.get_package(order)[pkg]['short_name']
                break
            case 'order_notes':
                str = order.note ? order.note : ''
                break
            case 'nr':
                str = ''
                break
        }
        return reference ? reference + ' ' + str : str
    },
    truncate_string: function(str, num) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num)
    },
    get_international_data: async function(order) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        for(const line_item of order.line_items){
            const query = `{
                productVariant(id: "gid://shopify/ProductVariant/${line_item.variant_id}") {
                    inventoryItem {
                        countryCodeOfOrigin 
                        harmonizedSystemCode 
                    }
                }
            }`
            await axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/graphql`, {
                graphql: {
                    query: query
                }
            },
            {
                headers: { 
                    'Authorization': token
                }
            })
            .then(response => {
                if(response.data.data.productVariant){
                    line_item['country_of_origin'] = response.data.data.productVariant.inventoryItem.countryCodeOfOrigin
                    line_item['hs_code'] = response.data.data.productVariant.inventoryItem.harmonizedSystemCode
                }
            })
            .catch(err => {
                this.log_error({message: 'Network Error', stack: 'orderTools/get_international_data', data: err})
            })
        }
        return true
    },
    get_fulfillment_status: function (order) {
        switch(order.fulfillment_status) {
            case 'fulfilled':
                return ['success', 'Fulfilled']
            case 'partial':
                return ['info', 'Partially']
            case null:
                return [null, 'Unfulfilled']
            default :
                return [null, order.fulfillment_status]
        }
    },
    abbreviated_state: function (state) {
        if(state) {
            state = state.trim()
        }
        const states = {
            'AUSTRALIAN CAPITAL TERRITORY': 'ACT',
            'NEW SOUTH WALES': 'NSW',
            'VICTORIA': 'VIC',
            'QUEENSLAND': 'QLD',
            'SOUTH AUSTRALIA': 'SA',
            'WESTERN AUSTRALIA': 'WA',
            'TASMANIA': 'TAS',
            'NORTHERN TERRITORY': 'NT',
        }

        if(state && states[state.toUpperCase()]) {
            return states[state.toUpperCase()]
        }
        return state ? state.toUpperCase() : state
    },
    order_refunds: function(order) {
        const refunds = {
            line_items: 0,
            other: 0
        }
        for(const refund of order.refunds){
            for(const trans of refund.transactions) {
                if(refund.refund_line_items.length) {
                    refunds.line_items = Number(refunds.line_items) + Number(trans.amount)
                } else {
                    refunds.other = Number(refunds.other) + Number(trans.amount)
                }
            }
        }
        return refunds
    },
    line_item_refunds: function(order) {
        try{
            const line_items = {}
            for(const refund of order.refunds){
                for(const line_item of refund.refund_line_items) {
                    if(!line_items[line_item.line_item_id]) {
                        line_items[line_item.line_item_id] = 0
                    }
                    line_items[line_item.line_item_id] = Number(line_items[line_item.line_item_id]) + Number(line_item.quantity)
                }
            }
            return line_items
    
        } catch (e) {
            console.log(e)
        }
    },
    discounted_item_price: function(item) {
        try {
            let discount = 0
            if(item.discount_allocations) {
                item.discount_allocations.forEach( disc => { 
                    if (disc.amount) {
                        discount = discount + Number(disc.amount)
                    }
                })
            }
            return Math.round((item.price - (discount/item.quantity))*100)/100
        } catch (e) {
            return item.price
        }
    },
    build_address_lines(lines) {
        const arr: Array<any> = []
        lines.forEach(l => {
            if(l) arr.push(l)
        })
        return arr
    },
    removeEmpty: function (obj)  {
        Object.keys(obj).forEach(key =>
            (obj[key] && typeof obj[key] === 'object') && this.removeEmpty(obj[key]) ||
            (obj[key] === undefined || obj[key] === null) && delete obj[key]
        );
        return obj;
    },
    trimmed: function(val) {
        return val ? val.trim() : ''
    },
    original_item(order, item){
        if(order.override_line_items) {
            return {price: order.override_line_items[item.id].orig_price, name: order.override_line_items[item.id].orig_name}
        }
        return { price: item.price, name: item.name }
    },
}