<template>
    <v-card flat>
        <v-card-title class="info--text">{{ article.title }}</v-card-title>
        <div class="body-1 pa-5" v-html="article.body"></div>
    </v-card>
</template>

<script>
import kenticoTools from "@/V2/helpers/kenticoTools.js";

export default {
    props: ['id'],
    data: function () {
        return {
            article: {
                codename: '',
                title: '',
                related: []
            },
            links: {},
        }
    },
	components: {
    },
    methods: {
        get: function (article) {
            kenticoTools.get_article(article).then(result => {
                this.article = result.content
                this.links = result.links
                localStorage.setItem('latest_news_read', result.content.last_modified)
            })
        }
    },
	mounted: function () {
        this.get('latest_news')
	},
}
</script>

