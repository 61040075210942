<template>
    <v-card class="my-2 pa-2">
<!--        <v-alert type="error" outlined prominent border="left" class="ma-2 py-1 px-5">
            <v-row>
                <v-col class="flex-grow-1 flex-shrink-0">
                    <strong>Caution:</strong> When orders are merged the oldest order in the group of orders is used as the <strong>Primary Order</strong>. The shipping address of the <strong>Primary Order</strong> will be used for the creation of shipping labels. Any changes made to merged orders, such as weights or packages, will be reflected in the <strong>Primary Order</strong> if the orders are subsequently unmerged. 
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-1">
                    <v-btn small color="error" class="text-none">Hide this warning</v-btn>
                </v-col>
            </v-row>
            
        </v-alert> -->
        <v-card class="ma-2 pa-1" v-if="errors.length">
            <v-app-bar dense flat color="error" dark>
                <v-toolbar-title>Errors</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="unmerge_all(errors)" color="success" class="text-none">Unmerge All Orders With Errors</v-btn>
            </v-app-bar>
            <Errors v-for="customer in errors" :key="customer.id" :customer="customer" type="errors" />
        </v-card>
        <v-card class="ma-2 pa-1">
            <v-app-bar dense flat color="info" dark>
                <v-toolbar-title>Unmerged Orders</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="unmerged.length" @click="merge_all(unmerged)" color="success" class="text-none">Merge All Unmerged Orders</v-btn>
            </v-app-bar>
            <Customer v-for="customer in unmerged" :key="customer.id" :customer="customer" type="unmerged" />
            <v-card-title v-if="!unmerged.length">There are no unmerged orders</v-card-title>
        </v-card>
        <v-card class="ma-2 pa-1">
            <v-app-bar dense flat color="warning" dark>
                <v-toolbar-title>Partially Merged Orders</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="partial.length" @click="merge_all(partial)" color="success" class="text-none">Merge All Partially Merged Orders</v-btn>
            </v-app-bar>
            <Customer v-for="customer in partial" :key="customer.id" :customer="customer" type="partial" />
            <v-card-title v-if="!partial.length">There are no partially merged orders</v-card-title>
        </v-card> 
        <v-card class="ma-2 pa-1">
            <v-app-bar dense flat color="success" dark>
                <v-toolbar-title>Fully Merged Orders</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="full.length" @click="unmerge_all(full)" color="error" class="text-none">Unmerge All Fully Merged Orders</v-btn>
            </v-app-bar>
            <Customer v-for="customer in full" :key="customer.id" :customer="customer" type="full" />
            <v-card-title v-if="!full.length">There are no fully merged orders</v-card-title>
        </v-card>
        <Response :data="merged"/>
    </v-card>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Customer from '@/V2/components/Merge/Customer'
import Errors from '@/V2/components/Merge/Errors'
import Response from '@/V2/components/Utilities/Response'

export default {
    data: function () {
        return {
        }
    },
	components: {
        Customer,
        Errors,
        Response
    },
    methods: {
        merge_all: function(customers) {
            customers.forEach(customer => {
                const arr = []
                customer.orders.forEach(o => {
                    arr.push(o.order)
                })
                this.$store.dispatch('Merge/merge', {customer: customer, orders: arr})
            })
        },
        unmerge_all: function(customers) {
            customers.forEach(customer => {
                this.$store.dispatch('Merge/merge', {customer: customer, orders: []})
            })
        },
    },
    computed: {
		...mapGetters({
			mergeable: 'Merge/mergeable',
            merged: 'Merge/merged',
            unmerged: 'Merge/unmerged',
            partial: 'Merge/partial',
            full: 'Merge/full',
            errors: 'Merge/errors'
		})
    }
}
</script>

