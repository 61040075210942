<template>
    <div v-if="order.consignment.service">
        <keep-alive>
            <component v-if="componentFile" v-bind:is="componentFile" :order="order"></component>
        </keep-alive>
    </div>
</template>

<script>

export default {
    props: ['order'],
	computed : {
		componentFile() {
            if(this.order.consignment.service) {
                return () => import(`@/V2/integrations/${this.order.consignment.service.carrier_type}/components/Consignments.vue`);
            } 
            return null
		},
	}
}
</script>

