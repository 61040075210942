import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import User from './user'
import Orders from './orders'
import Orders2 from './orders2'
import Merge from './merge'
import Templates from './templates'
import Images from './Images/images'
import Quotes from './Quotes/quotes'
import Settings from './settings'
import Shopify from './shopify'
import Carriers from './Carriers/carriers'
import Rules from './Rules/rules'
import Packages from './Packages/packages'
import LiveRates from './liverates'
import router from '@/V2/router'
import orderTools from "@/V2/helpers/orderTools";
import tasks from "@/V2/helpers/tasks";

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		User,
		Orders,
		Orders2,
		Merge,
		Templates,
		Images,
		Quotes,
		Settings,
		Shopify,
		Carriers,
		Rules,
		Packages,
		LiveRates
	},
	state: {
		app: {},
		env: {
			app_name: process.env.VUE_APP_APP_NAME,
			app_server: process.env.VUE_APP_APP_SERVER,
			api_server: process.env.VUE_APP_API_SERVER,
			
			shopify_scope: process.env.VUE_APP_SHOPIFY_SCOPE,
			redirectUri: `${process.env.VUE_APP_APP_SERVER}/authenticate`,
			maps_api_key: process.env.VUE_APP_MAPS_API_KEY,
			kentico_project: process.env.VUE_APP_V2_KENTICO_PROJECT,
			kentico_api: process.env.VUE_APP_KENTICO_API,
			initialised: false,
		},
		loading: {
			status: true,
			step: '',
			message: 'Verifying account',
			count: 0
		},
		progress: {
			status: false,
			message: null
		},
		warning: {
			status: false,
			heading: null,
			message: null
		},
		system_error: <any>[],
		printing: {
			invoice: false,
			picklist: false
		},
		search: '',
		tab: 'all',
		select_all: false,
		sortDesc: true,
		selected: [],
		page: 1,
		task: {},
		popup_blocked: '',
		system_error_message: '',
	},
    getters: {
		loading: state => {
			return state.loading.status
        },
		task: state => {
			return state.task
        },
		popup_blocked: state => {
			return state.popup_blocked
        },
		system_error: state => {
			return state.system_error
        },
		system_error_message: state => {
			return state.system_error_message
        },
    },
	mutations: {
		set_loading: function (state, payload) {
			state.loading.status = payload.status
			state.loading.message = payload.message 
			state.loading.step = payload.step
			state.loading.count = payload.count
		},
		set_progress: function (state, payload) {
			state.progress.status = payload.status
			state.progress.message = payload.message 
			return true
		},
		set_printing: function (state, payload) {
			state.printing[payload.type] = payload.status
		},
		set_page: function (state, page) {
			state.page = page
		},
		set_task: function (state, task) {
			state.task = task
		},
		set_popup_blocked: function(state, url) {
			state.popup_blocked = url
		},
		set_system_error: function(state, payload) {
			state.system_error.push(payload)
		},
		set_system_error_message: function(state, payload) {
			state.system_error_message = payload
		},
	},
	actions: {
		init: async function ({ state, dispatch, commit, getters }) {
			if(router.currentRoute.name !== 'Install') {
					try {
						this.dispatch('Settings/init')
						await this.dispatch('User/init')
						commit('set_loading', { status: true, message: 'Updating carrier connections', step: 'Carriers' })      
						await this.dispatch('Carriers/init')
						this.dispatch('LiveRates/init')
						await this.dispatch('Packages/init')
						await this.dispatch('Rules/init')
						commit('set_loading', { status: true, message: 'Importing orders', step: 'Orders' })      
						await this.dispatch('Orders/init')
						//await this.dispatch('Orders2/init')
						if(process.env.VUE_APP_DEBUG == 'true'){
							//await this.dispatch('Merge/init')
						}
						await this.dispatch('Images/init')
						commit('set_loading', { status: true, message: 'Retrieving saved quotes', step: 'Quotes' })
						await this.dispatch('Quotes/init')
						this.dispatch('Orders/get_selected')
	
						const task_id = localStorage.getItem('task')
						if(task_id) {
							localStorage.removeItem('task')
							const task = await orderTools.get_task(task_id)
							commit('set_task', task)
							tasks[task['type']](task)
						}
	
					} catch (e) {
						orderTools.log_error(e)
					}
		
//				}
			}
		},
		reset_system_errors: function({ state }, type) {
			state.system_error = state.system_error.filter( error => {{
				return  error.type != type
			}})
		},
	}
})
