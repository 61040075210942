<template>
    <div class="mt-n6">
        <v-row>
            <v-col cols="12" lg="4" class="pb-0">
                <Instructions :id="help_article" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="12" xl="8" class="py-0">
                <DefaultPackage v-on:save="save()" class="pb-4 mb-3"/>
                <DefinedPackages v-on:save="save()" class="pb-4 mb-3"/>
                <DefaultWeight v-on:save="save()" class="pb-4 mb-3"/>
            </v-col>
        </v-row>
    </div>
    
</template>

<script>
import Instructions from '@/V2/views/Instructions'
import { mapGetters } from 'vuex'
import DefaultPackage from '@/V2/components/Packages/Default'
import DefinedPackages from '@/V2/components/Packages/Defined'
import DefaultWeight from '@/V2/components/Packages/Weight'

export default {
    data: function () {
        return {
            help_article: '6510178',
        }
    },
	components: {
        Instructions,
        DefaultPackage,
        DefinedPackages,
        DefaultWeight
    },
    methods: {
        save: function () {
            this.$store.dispatch('Packages/save', {}, { root: true })
            this.$store.dispatch('Packages/set_default', {}, { root: true })
            this.$store.dispatch('Quotes/check_quotes_for_changes')
        },
    },
    computed: {
		...mapGetters({
            packages: 'Packages/packages',
            packages_list: 'Packages/list'
        }),
    }
}
</script>

