<template>
    <v-dialog v-if="componentFile" v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
        <v-card>
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <component :is="componentFile" :payload="payload" v-on:agree="respond('YES', $event)" v-on:disagree="respond('NO')" v-on:cancel="respond('CANCEL')"/> 
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        integration: '',
        component: '',
        title: null,
        options: {},
        payload: {}
    }),
    methods: {
        open(title, integration, component, payload, options = { color: 'primary', width: 800, zIndex: 200} ) {
            this.integration = integration
            this.component = component
            this.payload = payload
            this.dialog = true
            this.title = title
            this.options = Object.assign(this.options, options)
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        respond(response, payload) {
            this.resolve({ answer: response, payload: payload })
            this.dialog = false
        },
    },
	computed : {
        componentFile() {
            if(this.component) {
                return () => import(`@/V2/integrations/${this.integration}/components/${this.component}.vue`);
            } else {
                return null
            }
            
        },
    }    

}
</script>