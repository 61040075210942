<template>
    <v-row>
        <v-col cols="12" class="px-5">
            <Customer v-if="show('customer')" :order="order" class="mb-2 flex-grow-1"/>
            <Summary v-if="show('order_summary')" :order="order" class="mb-2 flex-grow-1"/>
            <v-lazy :options="{threshold: .0}" transition="fade-transition">
                <Consignments v-if="order.consignment" :order="order" class="pb-2"/>
            </v-lazy>
            <v-lazy :options="{threshold: .0}" transition="fade-transition">
                <Rates v-if="!order.consignment || !order.consignment.shipment" :order="order"/>
            </v-lazy>
            <v-card outlined class="mb-2">
                <Panels :order="order" :panels="['products', 'packages', 'order_options']"/>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Customer from '@/V2/components/Orders/Customer'
import Summary from '@/V2/components/Orders/Summary'
import Rates from '@/V2/components/Orders/Rates'
import Consignments from '@/V2/components/Orders/Consignments'
import Panels from '@/V2/components/Orders/Panels'

export default {
    props: ['order'],
    components: {
        Customer,
        Summary,
        Rates,
        Consignments,
        Panels
    },
    methods: {
        expand: function () {
            Vue.set(this.order, 'layout', 'expanded')
        },
        show(section, subsection) {
			if(subsection) {
				return this.display_settings.compact[section].subsections[subsection].status
			} else {
				return this.display_settings.compact[section].status
			}
        },
		action: function (event, orders) {
            this.$emit('action', event, orders)
        }
    },
    computed: {
		...mapGetters({
			display_settings: 'Settings/display_settings'
		}),
    }
}
</script>

