import store from "@/V2/store/index"
import Vue from 'vue'
import orderTools from "@/V2/helpers/orderTools";
import axios from 'axios'
import { products } from '@/V2/integrations/ParcelContract/store/parcelcontract_products'
import { errors } from '@/V2/integrations/ParcelContract/store/parcelcontract_errors'
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
    get_settings: function (orders) {
        const carrier = {
            account_number: null,
            merchant_token: null,
            printer: {},
            pickup: false,
            payment: 'auto',
            payment_method: 'STORED_PAYMENT',
            charge_to_account: null
        } 
        for(const order of orders) {
            if(order.consignment && order.consignment.service) {
                carrier.account_number = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).credentials.account_number
                carrier.merchant_token = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).credentials.merchant_token
                carrier.printer = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.printer
                carrier.pickup = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.pickup == 'Y' ? true : false
                carrier.payment = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.payment || 'auto'
                carrier.payment_method = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.payment_method || 'STORED_PAYMENT'
                carrier.charge_to_account = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.account_number
                return carrier
            }
        }
    },
    create_consignments: async function (orders) {
        const create: Array<any> = []
        const update: Array<any> = []
        const requests: Array<any> = []

        orders.forEach(order => {
            order.consignment.data = this.build_shipment({ order: order, carrier_id: order.consignment.service.carrier_id, service: order.consignment.service.code })
            if(this.get_shipment_id(order)) {
                if(!order.consignment.shipment.manifest_id) {
                    update.push(order)
                }
            } else {
                create.push(order)
            }
        })

        const request = this.submit_shipments(create, 'POST')
        requests.push(request)
        for(const order of update) {
            const request = this.submit_shipments([order], 'PUT', this.get_shipment_id(order))
            requests.push(request)
        }

        return Promise.all(requests).then( (values) => {
            const errors = values.find(element => element == true)
            return !errors
        })
    },
    get_hidden_services: function(carrier) {
        return {}
    },
    get_labels: async function (orders) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const shipments: Array<any> = []
        orders.forEach(order => {
            shipments.push(order.consignment.shipment.shipment_id)
        })

        const printer_settings = store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).settings.printer
        const request = {
            "shipment_ids": shipments,
            "preferences": {
                "format": "PDF",
                "layout": printer_settings.label,
                "left_offset": printer_settings.left_offset,
                "top_offset": printer_settings.top_offset
            }
        }
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/parcelcontract/post`, { 
            token: store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).data.access_token,
            resource: '/shipping/v2/labels',
            request: request
        },
        {
            headers: { 
                'Authorization': token,
            }
        })
        .then(response => {
            const labelWindow = window.open(response.data.body.label_url, '_blank');

            if(!labelWindow || labelWindow.closed || typeof labelWindow.closed=='undefined') 
            { 
                store.commit('set_popup_blocked', response.data.body.label_url)
            }
            store.commit('set_progress', { status: false, message: null }) 
        })
    },
    submit_shipments: async function(orders, method, shipment_id = '') {
        const token = await getSessionToken(store.getters['Shopify/app']);
        if(orders.length == 0) return false
        const shipments: Array<any> = []
        let hasErrors = false
        orders.forEach(order => {
            shipments.push(order.consignment.data)
        })

        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/parcelcontract/post`, { 
            token: store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).data.access_token,
            resource: '/shipping/v2/shipments' + (shipment_id ? `/${shipment_id}` : ''),
            method: method,
            request: (shipment_id ? shipments[0] : { shipments: shipments })
        },
        {
            headers: { 
                'Authorization': token,
            }
        })
        .then(response => {
            if(response.data.body.errors) {
                for(const error of response.data.body.errors) {
                    const shipment_index = this.get_shipment_index_from_error(error)
                    const order = orders[shipment_index ?? 0]
                    if(!shipment_id) {
                        Vue.delete(order, 'consignment')
                    }
                    const message = this.nicer_message(error)
                    store.dispatch('Orders/init_error', { order: order, type: 'label', error: message }, { root: true })
                    orderTools.log([{
                        id: order.id,
                        type: 'ERROR',
                        data: message
                    }])
                }
                hasErrors = true
            }

            if(response.data.body.shipments) {
                response.data.body.shipments.forEach( (shipment, idx) => {
                    const order = orders[idx]
                    Vue.set(orders[idx].consignment, 'shipment', shipment)
                    Vue.set(orders[idx], 'save_event', 'ShipmentCreated')
                    store.dispatch('Orders/save', orders[idx])
                })
            }
            if(response.data.body.shipment_id) {
                Vue.set(orders[0].consignment, 'shipment', response.data.body)
                Vue.set(orders[0], 'save_event', 'ShipmentUpdated')
                store.dispatch('Orders/save', orders[0])
            }
        }).catch((error) => {
            return true
        }).then(( ) => {
            return hasErrors
        });
    },
    get_shipment_index_from_error: function (error) {
        if(error.field) {
            const fields = error.field.split('/')
            const index = fields[2] ? fields[2].match(/\d+/) : null
            if(index) {
                return index[0]
            }
        }
    },

    create_manifest: async function (orders, retries = 0) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        if(retries > 10) {
            store.commit('set_system_error_message', 'An unidentified error has occurred while manifesting your consignments. Several retries have been attempted automatically. <br><br>Please try again in a few minutes and if the issue continues to occur contact our support team via online chat for assistance.' )
            store.commit('set_progress', { status: false, message: null }) 
            return
        }
        store.commit('set_progress', { status: true, message: 'Preparing manifest' }) 
        let retry = false 
        const shipments: Array<any> = []
        const manifests = {}
        orders.forEach(order => {
            if(order.consignment.shipment.manifest_id) {
                manifests[order.consignment.shipment.manifest_id] = store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).data.access_token
            } else {
                shipments.push(order.consignment.shipment.shipment_id)
            }
        })

        if(shipments.length) {
            const request = {
                "shipment_ids": shipments,
            }
            axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/parcelcontract/post`, { 
                token: store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).data.access_token,
                resource: '/shipping/v2/manifests',
                request: request
            },
            {
                headers: { 
                    'Authorization': token,
                }
            })
            .then(response => {
                if(response.data.body.errors) {
                    for(const error of response.data.body.errors) {
                        if(error.detail.includes('has already been manifested')) {
                            // Already manifested so update shipment
                            const shipment_index = this.get_shipment_index_from_error(error)
                            const order = orders[shipment_index ?? 0]
                            this.update_shipment(order)
                            retry = true
                        }
                    }
                }
                if(response.data.body.manifest_id) {
                    for(const order of orders) this.update_shipment(order)
                    this.print_manifest(response.data.body.manifest_id, store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).data.access_token)
                }
                if(retry) {
                    store.commit('set_progress', { status: true, message: 'Updating manifested shipments' }) 
                    setTimeout( () => { 
                        this.create_manifest(orders, retries + 1) 
                    }, 1000); 
                } else {
                    store.commit('set_progress', { status: false, message: null }) 
                }
            })
        }

        for (const manifest_id of Object.keys(manifests)) {
            this.print_manifest(manifest_id, manifests[manifest_id])
        }
        

    },
    print_manifest: async function (manifest_id, parcelcontract_token) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/parcelcontract/get`, { 
            token: parcelcontract_token,
            resource: `/shipping/v2/manifests/${manifest_id}/summary`,
        },
        {
            headers: { 
                'Authorization': token,
            }
        })
        .then(response => {
            if(response.data.body.errors) {
                console.log(response.data.body.errors)
            }
            if(response.data.body.manifest_id) {
                const labelWindow = window.open(response.data.body.manifest_summary_url, '_blank');

                if(!labelWindow || labelWindow.closed || typeof labelWindow.closed=='undefined') 
                { 
                    store.commit('set_popup_blocked', response.data.body.manifest_summary_url)
                }
            }
            store.commit('set_progress', { status: false, message: null }) 
        })
    },
    update_shipment: async function (order) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/parcelcontract/get`, { 
            token: store.getters['Carriers/carrier'](order.consignment.service.carrier_id).data.access_token,
            resource: `/shipping/v2/shipments/${order.consignment.shipment.shipment_id}`,
        },
        {
            headers: { 
                'Authorization': token,
            }
        })
        .then(response => {
            if(response.data.body.errors) {
                for(const error of response.data.body.errors) {
                    const message = this.nicer_message(error)
                    store.dispatch('Orders/init_error', { order: order, type: 'shipment', error: message }, { root: true })
                    orderTools.log([{
                        id: order.id,
                        type: 'ERROR',
                        data: message
                    }])
                }
            }
            if(response.data.body.shipments) {
                Vue.set(order.consignment, 'shipment', response.data.body.shipments[0])
                Vue.set(order, 'save_event', 'UpdateShipment')
                store.dispatch('Orders/save', order)
            }
        })
    },
    delete_shipments: async function (orders) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const shipments: Array<any> = []
        for(const order of orders) {
            if(order.consignment && order.consignment.shipment) {
                shipments.push({
                    id: order.consignment.shipment.shipment_id,
                    carrier_id: order.consignment.service.carrier_id,
                })
                Vue.set(order, 'deleted_consignment', order.consignment)
                Vue.delete(order, 'consignment')
                Vue.set(order, 'save_event', 'DeleteConsignment')
                store.dispatch('Orders/save', order)
            }
        }
        while(shipments.length > 0) {
            const shipment = shipments.splice(0, 1)[0]
            const query =  encodeURI(`shipment_ids=${shipment['id']}`)
            axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/parcelcontract/post`, { 
                token: store.getters['Carriers/carrier'](shipment['carrier_id']).data.access_token,
                resource: `/shipping/v2/shipments/${shipment['id']}`,
                method: 'DELETE'
            },
            {
                headers: { 
                    'Authorization': token,
                }
            })
        }

    },
    get_shipment_id: function(order) {
        try {
            return order.consignment.shipment.shipment_id
        } catch (e) {
            return null
        }
    },
    build_shipment: function ({ order, carrier_id, service }) {
        const articles: Array<any> = []
        const from = store.getters['User/address']
        const to = order.shipping_address
        this.set_order_options({ order, carrier_id })
        const features = this.build_domestic_features(order.orderOptions[carrier_id])
        const order_packages = orderTools.get_package(order)
        try {
            order_packages.forEach(p => {
                articles.push(
                    {
                        weight: Number(Vue.filter('formatNumber')(orderTools.get_total_package_weight(order) / 1000, 3)),
                        length: p.dimensions[0] ? Number(Vue.filter('formatNumber')(p.dimensions[0], 1)) : 5,
                        width: p.dimensions[1] ? Number(Vue.filter('formatNumber')(p.dimensions[1], 1)) : 5,
                        height: p.dimensions[2] ? Number(Vue.filter('formatNumber')(p.dimensions[2], 1)) : 5,
                        features: features.article_features,
                        article_references: orderTools.get_reference_array(order, 50).length ? orderTools.get_reference_array(order, 50) : [order.name],
                    }
                )
            })
    
        } catch (e) {
            console.log(e)
        }

        const shipment = {
            shipment_id: this.get_shipment_id(order),
            charge_account: store.getters['Carriers/credentials'](carrier_id)['charge_account'],
            addresses: {
                from: {
                    name: from.name || null,
                    business_name: from.company || null,
                    phone: from.phone.trim() || null,
                    email: from.email || null,
                    lines: orderTools.build_address_lines([
                        from.address1 || null,
                        from.address2 || null
                    ]),
                    suburb: from.suburb.trim() || null,
                    state: orderTools.abbreviated_state(from.state) || null,
                    postcode: from.postcode.trim() || null,
    
                },
                to: {
                    name: to.name || null,
                    business_name: to.company || null,
                    phone: to.phone ? to.phone.trim() : (order.customer && order.customer.phone ? order.customer.phone.trim() : null),
                    email: order.email || null,
                    lines: orderTools.build_address_lines([
                        to.address1 || null,
                        to.address2 || null
                    ]),
                    suburb: to.city.trim() || null,
                    state: orderTools.abbreviated_state(to.province_code) || null,
                    postcode: to.zip.trim() || null,
                },
//                return_to_sender: store.getters[`Carriers/settings`](carrier_id)['return_address']
            },
            sender_references: [order.id.toString()],
            service: {
                speed: service,
                features: features.shipment_features
            },
            shipment_contents: {
                type: 'NEUTRAL'
            },
            articles: articles
        }
        return orderTools.removeEmpty(shipment)
    },
    build_domestic_features: function (options) {
        const features: Array<any> = []
        const article_features: Array<any> = []

        if(options.domestic && options.domestic.transit_cover == true && Number(options.domestic.transit_cover_amount) >= 1) {
            article_features.push({
                type: 'TRANSIT_COVER',
                attributes: {
                    cover_amount: Number(options.domestic.transit_cover_amount)
                }
            })
            // Is this required
//            if(Number(options.domestic.transit_cover_amount) > 500){
//                options.domestic.signature_on_delivery = true     
//            }
        }
        if(options.domestic && options.domestic.delivery_options == 'LEAVE_IN_A_SAFE_PLACE') {
            features.push({
                type: 'LEAVE_IN_A_SAFE_PLACE',
            })
        } else {
            features.push({
                type: 'SIGNATURE_ON_DELIVERY',
                attributes: {
                    delivery_option: options.domestic.delivery_options
                }            
            })
        }
        if(options.domestic && options.domestic.id_options) {
            features.push({
                type: 'CAPTURE_ID',
                attributes: {
                    id_capture_option: options.domestic.id_options
                }            
            })
        }
        return {shipment_features: features, article_features: article_features}
    },
    set_order_options: function (payload) {
        const carrier = store.getters[`Carriers/carrier`](payload.carrier_id)
        if(carrier) {
            if(!payload.order.orderOptions) {
                Vue.set(payload.order, 'orderOptions', {})
            }
            if(!payload.order.orderOptions[payload.carrier_id] || !payload.order.orderOptions[payload.carrier_id].modified) {
                Vue.set(payload.order.orderOptions, payload.carrier_id, {
                    domestic: Object.assign({}, carrier.settings.domestic)
                })
                payload.order.orderOptions[payload.carrier_id].domestic['transit_cover_amount'] = Number(orderTools.get_order_total(payload.order) * (payload.order.orderOptions[payload.carrier_id].domestic.transit_cover_percentage / 100)).toFixed(2)
            }
        }
    },
    get_rate_request_body: function (payload) {
        if(payload.order && payload.order.shipping_address && orderTools.is_domestic(payload.order)){
            const shipments: Array<any> = []
            const services = ['STANDARD', 'PREMIUM_EXPRESS']

            for(const service of services) {
                try {
                    shipments.push(this.build_shipment({ order: payload.order, carrier_id: payload.carrier_id, service: service }))

                } catch (e) {
                    console.log(e)
                }
            }
            return {
                shipments: shipments
            }
        }
    },
    nicer_message: function (error) {
// Error codes look like they'll be too generic to use. Use the detail field instead.
//        if(errors[error.code]) {
//            return errors[error.code]
//        }
        if(errors[error.detail]) {
            return errors[error.detail]
        }
        for( const k in errors ) {
            if(error.detail.search(k) > -1){
                return errors[k]
            } 
        }
        return error.detail
    },
}


