<template>
    <v-card class="mt-6" flat>
        <v-toolbar color="blue">
            <v-toolbar-items class="pt-2">
                <v-toolbar-item>
                    <v-select
                        :items="items"
                        label="Select Report"
                        solo
                    ></v-select>
                </v-toolbar-item>
                <v-toolbar-item>
                    <v-select
                        :items="ranges"
                        label="Select Date Range"
                        solo
                    ></v-select>
                </v-toolbar-item>
                <v-toolbar-item>
                    <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              label="Date"
              hint="MM/DD/YYYY format"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              @blur="date = parseDate(dateFormatted)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
                </v-toolbar-item>
            </v-toolbar-items>
        </v-toolbar>
    </v-card>
</template>

<script>

export default {
    data: function () {
        return {
            items: ['Expenditure'],
            ranges: [
                { value: 'today', text: 'Today'}, 
                { value: 'yesterday', text: 'Yesterday'}, 
                { value: '', text: '' },
            ]
        }
    },
	components: {
    },
    methods: {
    },
}
</script>

