<template>
    <v-row no-gutters @click="expand()">
        <v-col cols="12" v-if="order.shipping_address" class="clickable caption">
            <v-row no-gutters class="font-weight-bold">
                <v-col cols="12" v-if="show('customer', 'name')">{{ company }}</v-col>
                <v-col cols="12">
                    <span v-if="show('customer', 'name')">{{ customer }} </span>
                    <div class="float-right pr-1">
                        <div>
                            <v-tooltip bottom v-if="show('customer', 'order_count') && order.customer && orders_count > 1">
                                <template v-slot:activator="{ on }">
                                    <v-chip v-if="orders_count > 1" v-on="on" x-small color="green" text-color="white">
                                        {{ orders_count }}
                                    </v-chip>
                                </template>
                                <span>Customer has had {{ orders_count }} orders!</span>
                            </v-tooltip>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="show('customer', 'address')">
                <v-col>{{ street1 }}</v-col>
                <v-col cols="auto" class="text-right">
                        <div v-if="order.note" class="text-center pr-2">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="success" dark v-bind="attrs" v-on="on">
                                        mdi-message-text
                                    </v-icon>
                                </template>
                                <span>{{ order.note }}</span>
                            </v-tooltip>
                        </div>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="show('customer', 'address')">
                <v-col cols="12">{{ street2 }}</v-col>
                <v-col cols="12">{{ city }} <span v-if="show('customer', 'country')">{{ country }}</span></v-col>
            </v-row>
            <v-row no-gutters v-if="show('customer', 'email')">
                <v-col cols="12">{{ email }}</v-col>
            </v-row>
            <v-row no-gutters v-if="show('customer', 'phone')">
                <v-col cols="12">{{ phone }}</v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Customer',
    props: ['order'],
    data: function () {
        return {
        }
    },
    methods: {
        expand: function () {
            Vue.set(this.order, 'layout', 'expanded')
        },
        show(section, subsection) {
            return this.display_settings[this.layout][section].subsections[subsection].status
        }
    },
    computed: {
		...mapGetters({
            display_settings: 'Settings/display_settings',
            customers: 'Orders/customers'
        }),
        layout: function () {
            return this.$store.getters[`Settings/order_layout`](this.order)
        },
        company() {
            return this.order.shipping_address.company
        },
        customer() {
            return `${this.order.shipping_address.first_name} ${this.order.shipping_address.last_name}`
        },
        street1(){
            return this.order.shipping_address.address1
        },
        street2(){
            return this.order.shipping_address.address2
        },
        city() {
            return `${this.order.shipping_address.city} ${this.order.shipping_address.province_code} ${this.order.shipping_address.zip}`
        },
        country() {
            return this.order.shipping_address.country_code
        },
        email() {
            return this.order.email
        },
        phone() {
            return this.order.shipping_address.phone ? this.order.shipping_address.phone : (this.order.customer ? this.order.customer.phone : '')
        },
        orders_count: function () {
            if(this.customers[this.order.customer.id]) {
                return this.customers[this.order.customer.id].orders_count
            }
            return null
        }
    }

}
</script>

<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>
