// Rules
import Vue from 'vue'
import axios from 'axios'
import orderTools from "@/V2/helpers/orderTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
	namespaced: true,
	state: {
        rules: {
            domestic: {
                cheapest: true,
                keywords: [],
                default: {
                    carrier: null,
                    service: null
                }
            },
            international: {
                cheapest: true,
                keywords: [],
                default: {
                    carrier: null,
                    service: []
                }
            },
        },

	},
	mutations: {
        init: function (state, payload) {
            Object.keys(payload.rules).forEach( (type) => {
                Object.keys(payload.rules[type]).forEach( (rule) => {
                    Vue.set(state.rules[type], rule, payload.rules[type][rule])
                })
            }) 
        }
	},
	getters: {
		all: state => {
			return state.rules
        },
		domestic: state => {
			return state.rules.domestic
        },
		international: state => {
			return state.rules.international
        },
	},
	actions: {
		save: async function({ state, rootGetters }) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/rules/save`, { rules: state.rules },
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				orderTools.log([{
					id: 'Rules',
					type: 'Save',
					data: { rules: state.rules, response: response.data }
				}])
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'rules/save', data: err})
			})

		},
		list: async function({ commit, rootGetters }) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/rules/list`, {},
			{
				headers: { 
					'Authorization': token,
				}
			})
            .then(response => {
                if(response.data.rules){
                    commit('init', response.data.rules)
                }
                return (response.data)
            })
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'rules/list', data: err})
			})

        },
        init: async function ({ dispatch }) {
            await dispatch('list')
			return true
        },
        
	},
	modules: {
	}
}
