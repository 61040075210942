<template>
    <div>
        <v-card v-if="layout == 'table'" flat height="100%" class="px-2 py-0 mt-0">
            <div>
                <v-row class="px-4 font-weight-bold">
                    <v-col cols="3" md="3" lg="12" class="pb-0 pt-0 pt-md-2 pt-lg-0 pr-2 pr-lg-7 text-right">
                        ${{ order.current_total_price | formatNumber(2) }}
                    </v-col>
                    <v-col class="py-0 text-right text-md-center text-lg-right text-xl-left">
                        <div class="text-caption pr-2">
                            <v-menu offset-x>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text v-bind="attrs" v-on="on" color="primary" class="caption text-none px-0">
                                    {{ items }} (Qty {{ fulfillable_quantity }})
                                    </v-btn>
                                </template>
                                <v-card width="500">
                                    <Items v-if="show('products')" :order="order"/>
                                </v-card>
                            </v-menu>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <v-card v-if="layout == 'expanded'" flat outlined height="100%" class="pa-2 mt-0">
            <v-card-title class="pa-0">Order Summary</v-card-title>
            <div class="caption my-3">
                <v-row v-if="show('order_summary', 'subtotal')" class="px-4">
                    <v-col cols="9" class="py-0">
                        Item Sub Total
                    </v-col>
                    <v-col class="text-right py-0">
                        {{ order.total_line_items_price | formatNumber(2)}}
                    </v-col>
                </v-row>
                <v-row v-if="show('order_summary', 'discounts') && order.total_discounts > 0" class="px-4">
                    <v-col cols="4" class="py-0">
                        less Discounts
                    </v-col>
                    <v-col cols="5" class="py-0">
                        <span v-for="(discount, index) in order.discount_codes" :key="index">
                            {{ discount.code }}<span v-if="index + 1 < order.discount_codes.length"> | </span>
                        </span>
                    </v-col>
                    <v-col cols="3" class="text-right py-0">
                        -{{ order.total_discounts  | formatNumber(2)}}
                    </v-col>
                </v-row>
                <v-row v-if="show('order_summary', 'shipping')" class="px-4">
                    <v-col cols="4" class="py-0">
                        Shipping
                    </v-col>
                    <v-col cols="5" class="py-0">
                        <span v-if="show('order_summary', 'shipping_method')">{{ order.shipping_lines[0] ? order.shipping_lines[0].title : 'Not specified' }}</span>
                    </v-col>
                    <v-col cols="3" class="text-right py-0">
                        {{ order.shipping_lines[0] ? order.shipping_lines[0].discounted_price : 0  | formatNumber(2) }}
                    </v-col>
                </v-row>
                <v-row v-if="show('order_summary', 'order_total')" class="px-4 font-weight-bold">
                    <v-col cols="4" class="py-0">
                        Order Total
                    </v-col>
                    <v-col cols="2" class="py-0">
                        Tax
                    </v-col>
                    <v-col cols="3" class="py-0 text-right">
                        {{ order.total_tax | formatNumber(2) }}
                    </v-col>
                    <v-col cols="3" class="text-right py-0">
                        {{ order.current_total_price | formatNumber(2) }}
                    </v-col>
                </v-row>
                <div v-if="show('order_summary', 'notes') && (order.note || editing_note)">
                    <v-card-title class="mt-5 pa-0 pt-5">Note from Customer</v-card-title>
                    <v-row class="px-4">
                        <v-col>
                            <div v-if="!editing_note" @click="editing_note = true" class="clickable primary--text">
                                <v-icon x-large color="success">mdi-message-text</v-icon> {{ order.note }}
                            </div>
                            <div v-else>
                                <v-row>
                                    <v-col cols="12" sm="9" class="py-0">
                                        <v-text-field v-model="order.note" outlined dense hide-details label="Note" class="mb-0 py-0"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" class="text-right py-1 pr-2">
                                        <v-btn @click="save" small color="primary">
                                            Save
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-btn @click="editing_note = true" small text color="primary" class="text-none mt-3">Add a note</v-btn>
                </div>
            </div>
        </v-card>
        <v-card v-if="layout == 'compact'" flat height="100%" class="pa-0 mt-0">
            <div class="caption">
                <v-row v-if="show('order_summary', 'subtotal')" class="px-4">
                    <v-col cols="9" class="py-0">
                        Item Sub Total
                    </v-col>
                    <v-col cols="3" class="text-right py-0">
                        {{ order.total_line_items_price | formatNumber(2)}}
                    </v-col>
                </v-row>
                <v-row v-if="show('order_summary', 'discounts') && order.total_discounts > 0" class="px-4">
                    <v-col cols="9" class="py-0">
                        less Discounts
                    </v-col>
                    <v-col cols="3" class="text-right py-0">
                        -{{ order.total_discounts  | formatNumber(2)}}
                    </v-col>
                </v-row>
                <v-row v-if="show('order_summary', 'shipping')" class="px-4">
                    <v-col cols="9" class="py-0">
                        Shipping <span v-if="show('order_summary', 'shipping_method')">{{ order.shipping_lines[0] ? `(${order.shipping_lines[0].title})` : '(Not specified)' }}</span>
                    </v-col>
                    <v-col cols="3" class="text-right py-0">
                        {{ order.shipping_lines[0] ? order.shipping_lines[0].discounted_price : 0  | formatNumber(2) }}
                    </v-col>
                </v-row>
                <v-row v-if="show('order_summary', 'order_total')" class="px-4 font-weight-bold">
                    <v-col cols="4" class="py-1">
                        Total
                    </v-col>
                    <v-col cols="5" class="py-1">
                        <v-row>
                            <v-col class="py-0">
                                Tax
                            </v-col>
                            <v-col class="py-0 text-right">
                                {{ order.total_tax | formatNumber(2) }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="3" class="text-right py-1">
                        {{ order.current_total_price | formatNumber(2) }}
                    </v-col>
                </v-row>
                <div v-if="show('order_summary', 'notes') && (order.note || editing_note)">
                    <v-row class="px-4">
                        <v-col>
                            <div v-if="!editing_note" @click="editing_note = true" class="clickable primary--text">
                                <v-icon x-large color="success">mdi-message-text</v-icon> {{ order.note }}
                            </div>
                            <div v-else>
                                <v-row>
                                    <v-col cols="12" sm="9" class="py-0">
                                        <v-text-field v-model="order.note" outlined dense hide-details label="Note" class="mb-0 py-0"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" class="text-right py-1 pr-2">
                                        <v-btn @click="save" small color="primary">
                                            Save
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-btn @click="editing_note = true" small text color="primary--text" class="text-none">Add a note</v-btn>
                </div>
            </div>
        </v-card>

    </div>
</template>

<script>
import orderTools from "@/V2/helpers/orderTools";
import { mapGetters } from 'vuex'
import Items from '@/V2/components/Orders/Items'

export default {
    props: ['order'],
    data: function () {
        return {
            editing_note: false
        }
    },
    components: {
        Items
    },
    methods: {
        save: function () {
            this.$store.dispatch('Orders/update_order', {
                data: {
                    order: {
                        note: this.order.note
                    }
                },
                id: this.order.id
            }).then(this.editing_note = false)
        },
        xshow(subsection = null) {
            if(this.layout != 'table') {
                if(subsection) {
                    return this.display_settings[this.layout].order_summary.subsections[subsection].status
                } else {
                    return this.display_settings[this.layout].order_summary.status
                }
            }
            return true
        },
        show(section, subsection) {
            try{
                if(subsection) {
                    return this.display_settings[this.layout][section].subsections[subsection].status
                } else {
                    return this.display_settings[this.layout][section].status
                }
            } catch (e) {
                console.log(e)
            }
        },

    },
	computed : {
		...mapGetters({
            display_settings: 'Settings/display_settings',
        }),
        layout: function () {
            return this.$store.getters[`Settings/order_layout`](this.order)
        },
        fulfillable_items: function () {
            return orderTools.count_fulfillable_line_items(this.order)
        },
        fulfillable_quantity: function () {
            return orderTools.get_fulfillable_quantity(this.order)
        },
        items: function () {
            return `${this.fulfillable_items} Item${ this.fulfillable_items > 1 ? 's' : ''}`
        },
        quantity: function () {
            return `${this.fulfillable_quantity} Item${ this.fulfillable_quantity > 1 ? 's' : ''}`
        },
        order_total () {
            return orderTools.order_total
        }
	}
}
</script>

<style scoped>
    .clickable {
        cursor: pointer;
    }
	.debug-border {
		border: 1px solid red;
	}
</style>
