// Carriers - MyPost
import Vue from 'vue'
import axios from 'axios'
import { products } from '@/V2/integrations/MyPost/store/mypost_products'
import { grouped_products } from '@/V2/integrations/MyPost/store/mypost_products'
import { packages } from '@/V2/integrations/MyPost/store/mypost_packages'
import mypostTools from "@/V2/integrations/MyPost/helpers/mypostTools";
import store from "@/V2/store/index"
import orderTools from "@/V2/helpers/orderTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
	namespaced: true,
	state: {
		beta: false,
		config: {
			name: 'Australia Post - MyPost Business',
			logo: '/img/mypost4.png',
		},
		products: products,
		grouped_products: grouped_products,
		packages: packages,
		settings: {
			hidden: [],
			domestic: {
				email_tracking: true,
				signature_on_delivery: false,
				transit_cover: false,
				transit_cover_percentage: 100,
				item_description: null,
				modified: null
			},
			international: {
				email_tracking: true,
				international_parcel_sender_name: null,
				signature_on_delivery: false,
				sms_tracking: false,
				transit_cover: false,
				transit_cover_percentage: 100,
				commercial_value: true,
				classification_type: 'SALE_OF_GOODS',
				description_of_other: null,
				description: null,
				non_delivery_action: 'RETURN',
				certificate_number: null,
				licence_number: null,
				export_declaration_number: '',
				import_reference_number: '',
				country_of_origin: 'AU',
				tariff_code: null,
				hide_weights: false,
				modified: null
			},
			printer: {
				parcel_post: {
					label: 'A4-4pp',
					branded: true,
					left_offset: 0,
					right_offset: 0
				},
				express_post: {
					label: 'A4-4pp',
					branded: true,
					left_offset: 0,
					right_offset: 0
				},
				international: {
					label: 'A4-2pp'
				} 
			},
			pickup: 'N',
			payment: 'auto',
			payment_method: 'STORED_PAYMENT',
			rates_settings_defaults: {
				use_flatrate_satchels: false,
				use_flatrate_boxes: true
			}
		},
		sync_unpaid_shipments_delay: 10000
	},
	mutations: {
		delay_unpaid_shipments: function (state, delay) {
			state.sync_unpaid_shipments_delay = delay
		}
	},
	getters: {
		config: state => {
			return state.config
		},
		beta: state => state.beta,
		grouped_products: state => {
			return state.grouped_products
		},
		logo: state => {
			return state.config.logo
		},
		has_tracking: () => {
			return true
		},
		settings: (state) => {
			return state.settings
		},
		rates_settings_defaults: (state) => {
			return state.settings.rates_settings_defaults
		},
		get_prices: (state, getters, rootState, rootGetters) => (payload) => {
			const arr: Array<any> = []
			const hidden = mypostTools.get_hidden_services(payload.carrier)
			if(payload.carrier && payload.quotes) {
				payload.quotes.items.forEach( ( item ) => {
					item.prices.forEach( ( price ) => {
						if(!hidden[price.product_id] && payload.carrier.products[price.product_id]){
							try {
								arr.push({
									carrier_id: payload.carrier.carrier_id,
									carrier_type: payload.carrier.carrier,
									carrier_name: payload.carrier.name,
									code: price.product_id,
									description: payload.carrier.products[price.product_id].description,
									price: price.calculated_price,
									additional_data: {
										"Send and Save": price.promotion_details ? price.promotion_details[0].discount_plan : 'Band 0',
										"Discount": price.promotion_details ? '$' + price.promotion_details[0].discount.gross_price.toFixed(2) : 'No discount',
									}
								})
							} catch (e) {
								console.log(e)
							}
						}
					})
				})
			}
			arr.sort(function(a, b) {
				return parseFloat(a.price) - parseFloat(b.price);
			});
			return arr
		},
		plan_name: () => (quotes) => {
			try {
				return quotes.data.items[0].prices[0].promotion_details[0].discount_plan
			} catch (e) {
				return null
			}
		},
		product: (state) => (id) => {
			return state.products.filter(product => product.id == id)[0]
		},
		redact_customer: () => (consignment) => {
			try {
				delete consignment.data.shipment_request.to
				return consignment
			} catch (e) {
				return consignment
			}
		},
		tracking_company: () => {
			return 'Australia Post'
		},
		tracking_number: () => (consignment) => {
			if(consignment.shipment && consignment.shipment.items) {
				return consignment.shipment.items[0].tracking_details.article_id
			}
		},
		tracking_url: () => (consignment) => {
			if(consignment.shipment && consignment.shipment.items) {
				return `https://auspost.com.au/track/${consignment.shipment.items[0].tracking_details.article_id}`
			}
		},
		next_step: () => (order) => {
			if(order.consignment && order.consignment.shipment && order.consignment.shipment.items && order.consignment.order) {
				return 'fulfill_order'
			}
			if(order.consignment && order.consignment.shipment && order.consignment.shipment.items && !order.consignment.order) {
				return 'pay_label'
			}
			return 'create_label'
		},
//		actions: () => (order) => {
//			return {
//				carrier: 'MyPost',
//				create: !order.consignment || order.consignment && !order.consignment.shipment ? ['Create Label', 'Label', 'create'] : null,
//				update: order.consignment && order.consignment.shipment && !order.consignment.order ? ['Update Label', 'Update', 'create'] : null, 
//				pay: order.consignment && order.consignment.shipment && !order.consignment.order ? ['Pay / Print Label', 'Pay / Print Label', 'pay_print'] : null,
//				reprint: order.consignment && order.consignment.order ? ['Reprint Label', 'Label', 'reprint'] : null
  //          } 
//		},
		actions: () => (order) => {
			return {
				create: !order.consignment || order.consignment && !order.consignment.shipment ? ['Create Label', 'Label', 'create'] : null,
				update: order.consignment && order.consignment.shipment && !order.consignment.order ? ['Update Label', 'Update', 'create'] : null, 
				pay: order.consignment && order.consignment.shipment && !order.consignment.order ? ['Pay / Print Label', 'Pay / Print Label', 'pay_print'] : null,
				reprint: order.consignment && order.consignment.order ? ['Reprint Label', 'Label', 'reprint'] : null
            } 
		},
		bulk_actions: (state, getters, rootState, rootGetters) => (payload) => {
			const actions: Array<string> = []
			if(payload.statuses['no_consignment'].length) {
				const payment = rootGetters['Carriers/carrier'](payload.id).settings.payment
				if(payment == 'manual') {
					actions.push('create')
				} else {
					actions.push('print')
				}
			}
			if(payload.statuses['unpaid_consignment'].length) {
				actions.push('update')
				actions.push('print')
			}
			if(payload.statuses['paid_consignment'].length) {
				actions.push('print')
			}
			return [... new Set(actions)]
		},
		consignment_status: () => (order) => {
			if(!order.consignment || (order.consignment && !order.consignment.shipment)){
				return 'no_consignment'
			}
			if(order.consignment && order.consignment.shipment && !order.consignment.order){
				return 'unpaid_consignment'
			}
			if(order.consignment && order.consignment.shipment && order.consignment.order){
				return 'paid_consignment'
			}
		},
		service_level: () => (service) => {
			const product = products.find( p => {
				return p.id == service.code
			})
			if(product) {
				return product.service_level
			}
			return null
		},
	},
	actions: {
		init: async function ({ state, dispatch }, payload) {
			const account = await dispatch('validate', payload.credentials)
			const products = await dispatch('get_products', account)
			dispatch('validate_sender_address', payload)
			dispatch('sync_unpaid_shipments', payload)
			return { data: account, products: products, grouped_products: grouped_products, packages: state.packages }
		},
		sync_unpaid_shipments: async function ({ state, dispatch, commit, rootGetters }, payload) {
			const orders = rootGetters['Orders/orders']
			if(orders.length){
				try {
					const unpaid = orders.filter(o => o.consignment && o.consignment.shipment && !o.consignment.order && o.consignment.service && o.consignment.service.carrier_type == 'MyPost')
					if(unpaid.length) {
						const mypost_orders = {}
						const shipment_ids = unpaid.map(function(o) {
							if(o.consignment && o.consignment.shipment){
								return o.consignment.shipment.shipment_id;
							}
						});
						while(shipment_ids.length > 0) {
							const ids = shipment_ids.splice(0, 100)
							await mypostTools.get_shipments(ids, payload).then(async (response) => {
								if(response.data.body && response.data.body.shipments) {
									for(const shipment of response.data.body.shipments) {
										if(shipment.order_id) {
											if(!mypost_orders[shipment.order_id]) {
												try {
													await mypostTools.get_order(shipment.order_id, payload).then(response => {
														mypost_orders[shipment.order_id] = response.data.body.order
													})
												} catch (e) {
													if (e instanceof Error) {
														orderTools.log_error({ message: {error: e.message, shipment: shipment, unpaid: unpaid}, stack: e.stack})
													}
												}
											}
											const order_summary = {
												order_id: mypost_orders[shipment.order_id].order_id,
												order_creation_date: mypost_orders[shipment.order_id].order_creation_date,
												order_summary: mypost_orders[shipment.order_id].order_summary
											}
											const order = unpaid.find( order => {
												return order.consignment.shipment.shipment_id == shipment.shipment_id
											})
											Vue.set(order.consignment, 'order', order_summary)
											Vue.set(order.consignment, 'locked', true)
											Vue.set(order.consignment.order, 'shipment', shipment)
											Vue.set(order, 'save_event', 'NoChange')
											store.dispatch('Orders/save', order)
							
											const events: Array<any> = []
											events.push({
												id: order.id,
												type: 'UnpaidOrderSynced',
												data: orderTools.loggable_data(order)
											})
		
											orderTools.log(events)
										}
									}
								}
							})
						}
					}
				} catch (error) {
					orderTools.log_error({message: 'Network Error', stack: 'mypost/sync_unpaid_shipments', data: error})					
				}
			}
			if(state.sync_unpaid_shipments_delay < 60000){
				state.sync_unpaid_shipments_delay = state.sync_unpaid_shipments_delay + 5000
			}
			commit('delay_unpaid_shipments', state.sync_unpaid_shipments_delay)
			setTimeout( () => { dispatch('sync_unpaid_shipments', payload) }, state.sync_unpaid_shipments_delay); 
		},
		update: function ({ state }, carrier) {
			carrier.settings.domestic = {...state.settings.domestic, ...carrier.settings.domestic}
			carrier.settings.international = {...state.settings.international, ...carrier.settings.international}
			carrier.settings.printer = {...state.settings.printer, ...carrier.settings.printer}
			return carrier
		},
		action_create: function({ commit }, payload) {
			commit('delay_unpaid_shipments', 60000)
			mypostTools.create_labels(payload)
		},
		action_update: function({ commit }, payload) {
			commit('delay_unpaid_shipments', 60000)
			// only process orders that have already had a consignment created
			const arr: Array<any> = []
			payload.orders.forEach(o => {
				if(o.consignment && o.consignment.shipment && !o.consignment.order) {
					arr.push(o)
				}
			})
			mypostTools.create_labels({action: payload.action, dialog: payload.dialog, orders: arr})
		},
		action_print: async function({ rootGetters }, payload) {
			// check if manual payment is set and any orders don't have a label yet
			const payment = rootGetters['Carriers/carrier'](payload.carrier_id).settings.payment
			if(payment == 'manual') {
				let no_consignments = 0
				payload.orders.forEach(o => {
					if(!o.consignment || (o.consignment && !o.consignment.shipment)) {
						no_consignments++
					}
				})
				if(no_consignments) {
					await payload.dialog.open('Confirm Payment', 'MyPost', 'PrintPayConfirmationDialog', { count: no_consignments }).then(async (response) => {
						if(response.answer == 'YES') {
							payload.action = 'pay_print'
						}
					})
				} else {
					payload.action = 'pay_print'
				}
			}
			mypostTools.create_labels(payload)
		},
		action_manifest: function() {
			return true
		},
		action_pay_print: function({ commit }, payload) {
			commit('delay_unpaid_shipments', 60000)
			mypostTools.create_labels(payload)
		},
		action_pay: function({ dispatch }, payload) {
			payload.action = 'pay_print'
			dispatch('action_pay_print', payload)
		},
		action_reprint: function({ commit }, payload) {
			commit('delay_unpaid_shipments', 60000)
			mypostTools.create_labels(payload)
		},
		delete_labels: function (context, payload) {
			mypostTools.delete_shipments(payload)
		},
		get_products: function ({ state }, payload) {
			const products = {}
			let international_update = false
            const year =  new Date().getUTCFullYear();
            const month = new Date().getUTCMonth() + 1;
            const day = new Date().getUTCDate()
            const hour = new Date().getUTCHours()
			if(year > 2023 || month > 7 || (month == 7 && (day > 2 || ( day == 2 && hour > 13)))) {
				international_update = true
			}
			state.products.forEach( ( product ) => {
				if(international_update && (product.id == 'I62' || product.id == 'I63' || product.id == 'I64' || product.id == 'I66')) return
				if(international_update && product.id == 'I65') {
					product.min_weight = 1
				}
				products[product.id] = {
					id: product.id,
					description: product.description,
					official_name: product.official_name,
					domestic: product.domestic,
					data: product
				}
			})
			return products
		},
		get_quote: async function ({ dispatch, rootGetters }, payload) {
			await dispatch('Orders/reset_errors', { orders: [payload.order], type: 'quote'}, { root: true })
			const request = await dispatch('get_rate_request_body', {order: payload.order, carrier_id: payload.carrier_id})
			if(request) {
				const address_error = await dispatch('validate_address', { order: payload.order, carrier: rootGetters['Carriers/carrier'](payload.carrier_id) })
				const result = address_error ? null : await dispatch('get_item_price', { order: payload.order, carrier: rootGetters['Carriers/carrier'](payload.carrier_id), request: request })
				if(result) {
					return ({
						request: JSON.stringify(request),
						data: address_error ? null : result.quote, 
						errors: result.errors,
						address_error: address_error,
						statusCode: result.statusCode
					})
				} else {
					return {
						request: JSON.stringify(request),
						data: null,
						errors: null,
						address_error: address_error,
						statusCode: null
					}
				}
			} 
			return {
				request: null,
				data: null,
				errors: null,
				address_error: 'xxx',
				statusCode: null
			}
		},
		get_prices_array: function ( { rootGetters }, payload) {
			const carrier = rootGetters['Carriers/carrier'](payload.carrier)
			const arr: Array<any> = []

			if(carrier) {
				payload.quotes.items.forEach( ( item ) => {
					item.prices.forEach( ( price ) => {
						arr.push({
							carrier_id: carrier.carrier_id,
							carrier_type: carrier.carrier,
							carrier_name: carrier.name,
							code: price.product_id,
							description: carrier.products[price.product_id].description,
							price: price.calculated_price
						})
					})
				})
			}
			arr.sort(function(a, b) {
				return parseFloat(a.price) - parseFloat(b.price);
			});
			return arr
		},
		get_rate_request_body: function (context, payload) {
			return mypostTools.get_rate_request_body(payload)
		},
		get_item_price: async function ({ dispatch, rootGetters }, payload ) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/post`, payload.request,
			{
				headers: { 
					'Authorization': token,
					'account_number': payload.carrier.credentials.account_number,
					'merchant_token': payload.carrier.credentials.merchant_token,
					'resource': '/shipping/v1/prices/items'
				}
			})
			.then(response => {
				if(response.data.body && !response.data.body.errors){
					const body = response.data.body
					if(response.data.body.items && response.data.body.items[0].errors) {
						dispatch('Orders/init_error', { order: payload.order, type: 'quote', error: `MyPost Error: ${mypostTools.nicer_message(response.data.body.items[0].errors[0])}` }, { root: true })
					}
					if(response.data.body.items) {
						return { 
							quote: response.data.body.items ? response.data.body : null,
							errors: response.data.body.errors ? response.data.body.errors : null,
							statusCode: response.data.statusCode
						}
					}
				} else {
					return {
						quote: null,
						errors: 'An error occurred requesting a rate quote from the Australia Post MyPost Business system.',
						statusCode: response.data.statusCode
					}
				}
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'mypost/get_item_price', data: err})
			})
		
		},
		validate: async function({ rootGetters }, payload) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/validate`, { 
				merchant_token: payload.merchant_token,
			},
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				return (response.data.body)
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'mypost/validate', data: err})
			})

		},
		validate_address: async function ({ dispatch, rootGetters }, payload) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			if(payload.order.shipping_address) {
				// Don't need to validate international addresses
				if(payload.order.shipping_address.country_code !== 'AU'){
					return null
				}
				// Do our own address validation first
				return dispatch('Quotes/validate_address', payload.order, { root: true }).then(validation_failed => {
					if(validation_failed) {
						// If we can't validate it try Australia Post's validation
						const query = [
							`suburb=${payload.order.shipping_address.city}`,
							`state=${orderTools.abbreviated_state(payload.order.shipping_address.province_code)}`,
							`postcode=${payload.order.shipping_address.zip}`
						].join('&')
			
						return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/get`, {
							query: encodeURI(query)
						},
						{
							headers: { 
								'Authorization': token,
								'account_number': payload.carrier.credentials.account_number,
								'merchant_token': payload.carrier.credentials.merchant_token,
								'resource': '/shipping/v1/address'
							}
						})
						.then(response => {
							if(response.data && response.data.body){
								if(response.data.body.found){
									return null
								}
								if(response.data.body.results && response.data.body.results.length) {
									dispatch('Orders/init_error', { order: payload.order, type: 'address', error: 'The suburb / postcode do not match. Possible matches to the postcode entered include ' + response.data.body.results.join(', ') }, { root: true })
								} else {
									dispatch('Orders/init_error', { order: payload.order, type: 'address', error: 'The suburb / postcode do not match. No suggested matches found.' }, { root: true })
								}
								return response.data.body
							}
							dispatch('Orders/reset_errors', { orders: [payload.order], type: 'address'}, { root: true })
							return null
						})
						.catch(err => {
							orderTools.log_error({message: 'Network Error', stack: 'mypost/validate_address', data: err})
						})
			
					}
					dispatch('Orders/reset_errors', { orders: [payload.order], type: 'address'}, { root: true })
					return null
				})
	
			}
		},
		validate_fulfillment: function (context, order) {
			const error_msg = 'You must create a shipping consignment/label before you can fulfill this order.'
			try {
				if(order.consignment.shipment.shipment_id) {
					return true
				} else {
					return error_msg
				}
			} catch (e) {
				return error_msg
			}
		},
        validate_sender_address: async function ({ rootGetters }, payload) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
            const query = [
                `suburb=${rootGetters['User/address'].suburb.trim()}`,
                `state=${orderTools.abbreviated_state(rootGetters['User/address'].state)}`,
                `postcode=${rootGetters['User/address'].postcode.trim()}`
            ].join('&')
            
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/get`, {
                query: encodeURI(query)
            },
            {
                headers: { 
                    'Authorization': token,
                    'account_number': payload.credentials.account_number,
                    'merchant_token': payload.credentials.merchant_token,
                    'resource': '/shipping/v1/address'
                }
            })
            .then(response => {
				const errors: Array<any> = []
				if(!rootGetters['User/address'].name){
					errors.push('<p>Australia Post require the Name field to be completed in your Sender Address.</p>')
				}
				if(rootGetters['User/address'].address1 && rootGetters['User/address'].address1.length > 40) {
					errors.push('<p>The "Address (line 1)" field of your Sender Address exceeds the maximum 40 characters allowed by AusPost. Try splitting the address over two lines.</p>')
				}

				if(rootGetters['User/address'].address2 && rootGetters['User/address'].address2.length > 40) {
					errors.push('<p>The "Address (line 2)" field of your Sender Address exceeds the maximum 40 characters allowed by AusPost. Try shortening the address.</p>')
				}
				if(!rootGetters['User/address'].address1){
					errors.push('<p>Australia Post require an address to be entered in the "Address (line 1)" field of your Sender Address.</p>')
				}
				if(response.data.body.found == false){
					let possible_matches = ''
					if(response.data.body.results.length) {
						possible_matches = response.data.body.results.join(', ')
					}
					
					errors.push(`<p>The suburb / postcode specified in your Sender Address on the PREFERENCES page do not match. </p>`)
					if(possible_matches.length) {
						errors.push(`<p>Possible matches to the postcode entered include ${possible_matches}</p>`)
					}
				}  
				if(errors.length){
					errors.push(`<p>You will need to correct any issues before you can create MyPost Business shipping labels. Go to the PREFERENCES page to fix the issue with your Sender Address.</p>`)
					store.commit('set_system_error', {
						type: 'sender_address',
						status: true,
						message: errors.join(''),
					})
				}

            })
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'mypost/validate_sender_address', data: err})
			})

        },
		set_order_options: function (context, payload) {
			mypostTools.set_order_options(payload)
		}
	},
	modules: {
	}
}
