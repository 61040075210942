import store from "@/V2/store/index"
import orderTools from "@/V2/helpers/orderTools";

export default {
    check_for_warnings: function (order) {
        let arr: Array<String> = []
        arr = arr.concat(this.carrier_warnings(order))
        arr = arr.concat(this.requires_express_rule(order))
        return arr.join('<br>')
    },
    requires_express_rule: function (order) {
        if(this.service_level(order) != 'express'){
            if(order.shipping_lines && order.shipping_lines.length && order.shipping_lines[0].title.toUpperCase().search('EXPRESS') > -1 && !this.has_express_rule(order)){
                return ['This order\'s shipping method contains the term "express" but no express shipping rule has been created. Go to SHIPPING RULES on the menu to create one.']
            }
        }
        return []
    },
    has_express_rule: function (order) {
        const rule_type = orderTools.is_domestic(order) ? 'domestic' : 'international'
        const rules = store.getters[`Rules/${rule_type}`]
        const found = rules.keywords.find(words => {
            if(words.keywords.toUpperCase().search('EXPRESS') > -1)  {
                return true
            }
        })
        if(found) {
            return true
        }
    },
    service_level (order) {
        try {
            if(order.rate_selected) {
                return store.getters[`Carriers/${order.rate_selected.carrier_type}/product`](order.rate_selected.code).service_level
            }
        } catch (e) {
            return null
        }
    },
    carrier_warnings (order) {
        try {
            return store.getters[`Carriers/${order.rate_selected.carrier_type}/warnings`](order)
        } catch (e) {
            return []
        }
        return []
    }
}