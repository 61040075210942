<template>
    <v-card v-if="order.override_line_items">
        <v-card-title class="headline">
            Override Product Data - {{ order.name }}
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" x-small icon @click="$emit('close')"><v-icon color="grey">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>Override product titles or values. Overridden data will not affect the original order in your Shopify store. The data will only be used for the production of shipping labels. </v-card-text>
        <v-card-text>Click a value to edit it.</v-card-text>
        <v-alert color="warning" dense outlined text v-if="order.total_discounts != 0" class="mx-2">CAUTION: This order contains discounts totalling ${{ order.total_discounts | formatNumber(2) }}. Discounts will be deducted from the amounts below.</v-alert>
        <template v-for="item in order.line_items">
            <v-row v-if="item.fulfillable_quantity" :key="item.id" no-gutters class="px-5 pb-2">
                <v-col cols="2" class="py-0 pr-2">
                    <v-responsive max-height="100%" max-width="100%" v-if="images[`${item.product_id}|${item.variant_id}`]">
                        <v-img
                            :src="images[`${item.product_id}|${item.variant_id}`].url"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            :max-height="50"
                            :max-width="50"
                            @error="reload_image(item.product_id, item.variant_id)"
                            >
                        </v-img>
                    </v-responsive>
                </v-col>
                <v-col cols="10" class="caption">
                    <v-row v-if="0">{{ item }}</v-row>
                    <v-row no-gutters>
                        <v-col cols="2" class="pr-2">
                            <v-text-field dense outlined hide-details v-model.number="order.override_line_items[item.id].fulfillable_quantity" class="caption text-right"></v-text-field>
                        </v-col>
                        <v-col cols="5" class="pr-2">
                            <v-text-field dense outlined hide-details v-model="order.override_line_items[item.id].name" class="caption"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense outlined hide-details reverse suffix="$" v-model.number="order.override_line_items[item.id].price" class="caption text-right"></v-text-field>
                        </v-col>
                        <v-col cols="3" class="pt-3">
                            <div class="float-right font-weight-bold">${{ order.override_line_items[item.id].price * order.override_line_items[item.id].fulfillable_quantity | formatNumber(2)}}</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-2">
                        <v-col cols="5" v-if="!item.requires_shipping">
                            <span class="red--text">Shipping not required.</span>
                        </v-col>
                        <v-col v-else cols="5">
                            <div class="caption grey--text">{{ item.sku }}</div>
                            <div v-if="item.properties">
                                <v-row v-for="(p, index) of item.properties" v-bind:key="index" no-gutters>
                                    <v-col class="caption font-weight-bold">{{ p.name }}:</v-col>
                                    <v-col class="caption">{{ p.value }}</v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col cols="2" class="pt-3 pr-2 text-right">
                            <span class="caption">Weight (kg)</span>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense outlined hide-details reverse prefix="kg" v-model.number="order.override_line_items[item.id].kilograms" class="caption"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <div class="pt-3 float-right">{{ order.override_line_items[item.id].kilograms * order.override_line_items[item.id].fulfillable_quantity | formatNumber(3)}}kg</div>
                        </v-col>

                    </v-row>
                </v-col>
            </v-row>
        </template>
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn size="large" @click.native="cancel">Reset</v-btn>
            <v-btn color="primary" size="large" @click.native="agree">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Vue from 'vue'
import mergeTools from "@/V2/helpers/mergeTools";
import { mapGetters } from 'vuex'

export default {
    props: ['order', 'edit'],
    data: () => ({
        merged: mergeTools,
    }),
    watch: {
        edit: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if(newVal) {
                    this.init()
                }
            }
        }
    },
    methods: {
        open(order) {
            return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
            })
        },
        agree() {
            this.order.line_items.forEach(i => {
                i.name = this.order.override_line_items[i.id].name
                i.price = this.order.override_line_items[i.id].price
                i.grams = this.order.override_line_items[i.id].kilograms * 1000
                this.order.override_line_items[i.id].grams = i.grams
                i.fulfillable_quantity = this.order.override_line_items[i.id].fulfillable_quantity

            })
            Vue.set(this.order.override_line_items, 'modified', true)
            this.$store.dispatch('Quotes/reset_quote', this.order)
            this.$store.dispatch('Orders/save', this.order)
            this.$emit("close")
        },
        cancel() {
            this.order.line_items.forEach(i => {
                if(this.order.override_line_items[i.id]) {
                    i.name = this.order.override_line_items[i.id].orig_name
                    i.price = this.order.override_line_items[i.id].orig_price
                    i.grams = this.order.override_line_items[i.id].orig_grams
                    i.fulfillable_quantity = this.order.override_line_items[i.id].orig_fulfillable_quantity
                }
            })
            Vue.delete(this.order, 'override_line_items')
            this.$emit("close")
        },
        init: function () {
            if(!this.order.override_line_items) {
                Vue.set(this.order, 'override_line_items', {})
                this.order.line_items.forEach(i => {
                    if(!this.order.override_line_items[i.id]) {
                        Vue.set(this.order.override_line_items, i.id, {})
                    }
                    this.order.override_line_items[i.id] = {
                        orig_name: i.name,
                        orig_price: i.price,
                        orig_fulfillable_quantity: i.fulfillable_quantity,
                        orig_grams: i.grams,
                        name: i.name,
                        price: i.price,
                        fulfillable_quantity: i.fulfillable_quantity,
                        grams: i.grams,
                        kilograms: Vue.filter('formatNumber')(i.grams / 1000, 3)

                    }
                })
            }
        }
    },
    mounted: function () {
        this.init()
    },
	computed : {
        ...mapGetters({
            display_settings: 'Settings/display_settings',
            images: 'Images/images'
        }),
    }
}
</script>