<template>
    <img :src="invoice_options.logo.url" :style="{ 'max-width': invoice_options.logo.max_width + 'px', 'max-height': invoice_options.logo.max_height + 'px' }"/>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed : {
        ...mapState('User', ['invoice_options']),
    }
}
</script>