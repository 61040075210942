<template>
    <v-card flat>
        <v-row class="px-4" align="center">
            <v-col cols="4">
                <v-responsive max-height="100%" max-width="100%">
                    <v-img
                        :src="carrier.logo" 
                        aspect-ratio="2.85"
                        class="grey lighten-2"
                        :max-width="100"
                        :max-height="35"
                        >
                    </v-img>
                </v-responsive>
            </v-col>
            <v-col cols="5">
                <v-card-title>{{ carrier.name }}</v-card-title>
                <v-card-subtitle>{{ connected.name }}</v-card-subtitle>
            </v-col>
            <v-col cols="3" class="right">
                <v-row class="flex-nowrap">
                    <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2 text-none" fab dark right small color="indigo" :to="`/carrier/edit/${connected.carrier}/${connected.carrier_id}`" v-bind="attrs" v-on="on">
                                    <v-icon dark>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Carrier</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2" fab dark right small :loading="deleting" color="red" @click="remove()" v-bind="attrs" v-on="on">
                                    <v-icon dark>cancel</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Carrier</span>
                        </v-tooltip>
                </v-row>
            </v-col>
        </v-row>
        <v-divider v-if="(count - 1) > seq"></v-divider>

        <v-snackbar v-model="snackbar" :color="alert_color" top timeout="2500">
            {{ alert }}
            <template v-slot:action="{ attrs }">
                <v-btn outlined v-bind="attrs" @click="snackbar = false">Close</v-btn>
            </template>
        </v-snackbar>

    </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: ['count', 'seq', 'connected'],
    data: function () {
        return {
            deleting: false,
            snackbar: false,
            alert: '',
            alert_color: ''
        }
    },
    methods: {
        remove: function() {
            this.deleting = true
            this.$store.dispatch('Carriers/remove', { carrier_id: this.connected.carrier_id } ).then(success => {
                if(success){
                    this.alert = 'Account deleted'
                    this.alert_color = 'success'
                    this.snackbar = true
                    this.deleting = false
                } else {
                    this.alert = 'Account deletion failed. Please try again.'
                    this.alert_color = 'red'
                    this.snackbar = true
                    this.deleting = false
                }
            })
        }
    },
    computed: {
        ...mapState({
            carrier (state, getters) {
                return getters[`Carriers/${this.connected.carrier}/config`]
            }
        })
    }    
}
</script>