import { render, staticRenderFns } from "./Dimensions.vue?vue&type=template&id=23e3028e&scoped=true&"
import script from "./Dimensions.vue?vue&type=script&lang=js&"
export * from "./Dimensions.vue?vue&type=script&lang=js&"
import style0 from "./Dimensions.vue?vue&type=style&index=0&id=23e3028e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e3028e",
  null
  
)

export default component.exports