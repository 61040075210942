<template>
    <v-card>
        <v-card-title class="info--text">Default Package</v-card-title>
        <v-row class="px-5">
            <v-col cols="12" md="5" lg="4" xl="4" class="pb-0">
                <v-select
                    v-model="packages.default"
                    :items="packages_list.items"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined 
                    dense
                    hide-details
                    :clearable="true"
                    label="Default Package"
                    placeholder="Select a package"
                    class="mb-0"
                    @change="select"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="5" lg="2" xl="2" class="pb-0">
                <v-text-field outlined dense :disabled="packages.default !== 'custom'" label="Length (cm)" v-model="packages.dimensions[0]" @change="save"></v-text-field>
            </v-col>
            <v-col cols="12" md="5" lg="2" xl="2" class="pb-0">
                <v-text-field outlined dense :disabled="packages.default !== 'custom'" label="Width (cm)" v-model="packages.dimensions[1]" @change="save"></v-text-field>
            </v-col>
            <v-col cols="12" md="5" lg="2" xl="2" class="pb-0">
                <v-text-field outlined dense :disabled="packages.default !== 'custom'" label="Height (cm)" v-model="packages.dimensions[2]" @change="save"></v-text-field>
            </v-col>
            <v-col cols="12" md="5" lg="2" xl="2" class="pb-0">
                <v-text-field outlined dense :disabled="packages.default !== 'custom'" label="Package Materials (kg)" v-model="packages.materials" @change="save"></v-text-field>
            </v-col>
        </v-row>

    </v-card>           
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: function () {
        return {
            help_article: 'packages_instructions',
        }
    },
	components: {
    },
    methods: {
        save: function() {
            if(this.packages.default == 'custom'){
                this.packages.custom_dimensions = this.packages.dimensions
            }
            this.$emit('save')
        },
        select: function (item) {
            if(item){
                this.packages.dimensions = this.packages_list.details[item].dimensions
            }
            this.$emit('save')
        },
    },
    computed: {
		...mapGetters({
            carriers: 'Carriers/carriers',
            packages: 'Packages/packages',
            packages_list: 'Packages/list'
        })
    },
}
</script>

