<template>
	<div></div>
</template>

<script>
import { mapState } from 'vuex'
import LogRocket from 'logrocket';
import {getSessionToken} from '@shopify/app-bridge-utils';

export default {
	data: function () {
		return {
			user: '',
            created: ''
        }
    },
    components: {

	},
    methods: {
        log_rocket: function() {
            if(this.created) {
                const dateFrom = new Date(this.created)
                const dateTo = new Date()
                const Difference_In_Time = dateTo.getTime() - dateFrom.getTime();
                const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                if(Difference_In_Days < 360) {
                    this.init_log_rocket()
                    return
                }
            }
            if(this.isSafari) {
                this.init_log_rocket()
                return
            }
            const enable_logging = localStorage.getItem('enable_logging')
            if(enable_logging == 1){
                this.init_log_rocket()
                return
            }
            if(this.version == 3){
                this.init_log_rocket()
                return
            }
            return
        },
        init_log_rocket: function () {
            LogRocket.init('qizehq/sellerdash-mypost');
            LogRocket.identify(this.user, {
                name: this.$store.getters['User/address']['name'],
                email: this.$store.getters['User/address']['email'],
            });
        },
        init_intercom: async function () {
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            if(this.$store.getters['Shopify/token'] && this.$store.getters['Shopify/token'].length > 4){
                return this.axios.get(`${process.env.VUE_APP_API_SERVER}/api/register/intercom.json`, {
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.$store.getters['Shopify/token']}`
                    }
                }).then(response => {
                    if(response.data.intercom) {
                        this.user = response.data.user
                        this.created = response.data.created
                        window.Intercom('boot', {
                            app_id: "o0zs2ie4",
                            user_id: response.data.user,
                            name: JSON.parse(response.data.shop)['shop']['name'],
                            email: this.$store.getters['User/address']['email'],
                            user_hash: response.data.intercom
                        });
                        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/o0zs2ie4';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
                    }
                })
            }
        }
    },
	mounted: async function () {
        if(this.app) {
            await this.$store.dispatch('init')
			await this.init_intercom()
			this.log_rocket()
			this.$router.push('/home')
        }
	},
	computed : {
        ...mapState('Shopify', {
            app: state => state.app,
            shop: state => state.shop,
            version: state => state.version
        }),
	}
}
</script>
