<template>
    <div id="y">
        <v-col cols="12" lg="12" xl="4" v-if="!show('packages', 'open')" class="py-2 pl-1">
            <v-menu v-model="show_package" :close-on-content-click="false" style="z-index: 400">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text class="text-caption text-none pl-0 py-8" :ripple="false" v-bind="attrs" v-on="on">
                        <div v-if="order.packages && order.packages.length">
                            <div v-if="order.packages.length > 1">
                                <div v-if="order.packages.length > 1" class="py-0 text-left"><strong>{{ order.packages.length }} Packages</strong></div>
                                <div class="py-0 text-left">Weight (kg): {{ multi_packages_weight | formatNumber(3) }}</div>
                            </div>
                            <div v-else class="py-0 text-left">             
                                <div class="py-0 text-left">{{ order_package.name }}</div>
                                ({{ order.packages[0].dimensions[0] }} x {{ order.packages[0].dimensions[1] }} x {{ order.packages[0].dimensions[2] }})
                                <div class="caption py-0">Weight (kg): {{ Number(order.packages[0].weight) + Number(order.packages[0].materials ? order_package.materials : 0) | formatNumber(3) }} </div>
                            </div>
                        </div>
                        <div v-if="(!order.packages || !order.packages.length) && order_package">
                            <div class="py-0 text-left">{{ order_package.name }}</div>
                            <div class="py-0 text-left">             
                                ({{ order_package.dimensions[0] }} x {{ order_package.dimensions[1] }} x {{ order_package.dimensions[2] }})
                                <div class="caption py-0">Weight (kg): {{ Number(weight) + Number(order_package.materials ? order_package.materials : 0) | formatNumber(3) }} <span v-if="order.weight_forced" class="orange--text">{{ package_defaults.weight | formatNumber(3) }} (Default)</span></div>
                            </div>
                        </div>
                    </v-btn>
                </template> 
                <v-card width="500" class="py-0">
                    <v-lazy :options="{threshold: .0}" transition="fade-transition">
                        <Packages :order="order" v-on:close="hide_packages"/> 
                    </v-lazy>
                </v-card>
            </v-menu>
            
        </v-col>
        <v-col v-else class="py-0">
            <Packages :order="order"/> 
        </v-col>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";
import Packages from '@/V2/components/Orders/Packages'

export default {
    props: ['order'],
    data: function () {
        return {
            show_package: false,
            orderTools: orderTools,
        }
    },
    watch: {
        show_package: function (val) {
            if(val == false) {
                this.$store.dispatch('Quotes/reset_quote', this.order)
            }
        }
    },
    components: {
        Packages,
    },
    methods: {
        expand: function () {
            Vue.set(this.order, 'layout', 'expanded')
        },
        show(section, subsection) {
			try {
				if(subsection) {
					return this.display_settings.table[section].subsections[subsection].status
				} else {
					return this.display_settings.table[section].status
				}
			} catch (e) {
				return false
			}
        },
        hide_packages: function () {
            this.show_package = false
        },
    },
    computed: {
		...mapGetters({
			display_settings: 'Settings/display_settings',
            package_defaults: 'Packages/defaults',
		}),
        order_package() {
            return orderTools.get_package(this.order)[0]
        },
        weight: function () {
            return mergeTools.get_order_weight(this.order) / 1000
        },
        multi_packages_weight() {
            let weight = 0
            this.order.packages.forEach(o => {
                weight = weight + Number(o.weight) + Number(o.materials)
            })
            return weight
        }
    }
}
</script>
