<template>
    <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
        <v-card>
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">ERROR</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4" v-html="system_error_message"></v-card-text>
            <v-card-actions class="pt-5">
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-1" text @click.native="agree">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        options: {
            color: 'red darken-2',
            width: 490,
            zIndex: 200
        }
    }),
    methods: {
        open() {
            this.dialog = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            this.resolve(true)
            this.dialog = false
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        }
    },
    computed : {
        ...mapGetters({
            system_error_message: 'system_error_message'
        }),
    }
}
</script>