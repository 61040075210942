export const grouped_products = [
    {
        id: 'EXP',
        description: 'Premium Express',
        domestic: true,
        services: ['PREMIUM_EXPRESS']
    },
    {
        id: 'STD',
        description: 'Standard',
        domestic: true,
        services: ['STANDARD']
    },
]

export const products = [
    {
        id: 'STANDARD',
        description: 'Standard',
        official_name: 'Standard',
        service_level: 'standard',
        volumetric: true,
        domestic: true,
        min_weight: 1,
        max_weight: 32000,
        data: {}
    },
    {
        id: 'PREMIUM_EXPRESS',
        description: 'Premium Express',
        official_name: 'Premium Express', 
        service_level: 'express',
        volumetric: true,
        domestic: true,
        min_weight: 1,
        max_weight: 32000,
        data: {}
    },
]