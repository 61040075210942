<template>
    <div class="mt-n6">
        <v-container fluid>
            <div v-if="contentById && contentById.type == 'index'">
                <v-row v-for="(section, index) in contentById.sections" :key="index">
                    <v-col cols="12">
                        <Help :codename="section.id" />
                    </v-col>
                </v-row>
            </div>
            <div v-if="contentById && contentById.type == 'section'">
                <h4>{{ contentById.title }}</h4>
                <v-row>
                    <v-col :cols="cols ? cols : 6" :sm="sm ? sm : 6" :md="md ? md : 4" :lg="lg ? lg : 3" :xl="xl ? xl : 2" class="px-0 py-2" v-for="(article, index) in contentById.articles" :key="index">
                        <v-card class="ma-1 elevation-2 d-flex flex-column" height="100%">
                            <div class="clickable primary--text font-weight-bold pl-3 pt-3 pb-0" v-on:click="view(article.id)">{{ article.title }}</div>
                            <v-card-subtitle class="my-0 py-0 pl-3">
                                <div v-html="article.sub_title" class="article_overview"></div>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <div v-if="contentById && contentById.type == 'article'" class="article">
                <h4>{{ contentById.title }}</h4>
                <v-row>
                    <v-col cols="12" lg="6">
                        <div v-html="processedBody"></div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import help_index from "@/V2/helpers/help.ts"
import Help from '@/V2/views/Help'
import axios from 'axios'

export default {
    name: 'Help',
    props: ['codename', 'hide_title', 'cols', 'sm', 'md', 'lg', 'xl'],
    data: function () {
        return {
            id: null,
            content: help_index.content,
            body: ''
        }
    },
	components: {
        Help,
    },
    watch: {
        $route(to, from) {
            this.get(to.params.id)
        }
    },
    methods: {
        view: function (id) {
            this.$router.push(`/help/${id}`, () => {})
        },
        get: function (id) {
            this.id = (this.codename || this.$route.params.id)
        },
        get_article_body: function (id) {
            axios.post(`${process.env.VUE_APP_API_SERVER}/admin/proxy.json`, 
            {
                headers: {
                    'Authorization': `Bearer ${process.env.VUE_APP_INTERCOM_KEY}`,
                    'Intercom-Version': '2.10',
                    'Accept': 'application/json'
                },

                method : 'GET',
                url: `https://api.intercom.io/articles/${this.id}`,
            },
            {
                headers: { 
                    'Authorization': process.env.VUE_APP_AUTH
                }
            }).then(response => {
                this.body = response.data.response.body
            })
        }
        
    },
	mounted: function () {
        this.get(this.codename || this.$route.params.id)
	},
	computed: {
        contentById() {
            const findContentById = (id, currentContent) => {
                if (currentContent.id === id) {
                    return currentContent;
                }

                if (currentContent.sections) {
                    for (const section of currentContent.sections) {
                        if (section.id === id) {
                            return section;
                        }
                        // Check articles within the section
                        if (section.articles) {
                            for (const article of section.articles) {
                                if (article.id == id) {
                                    this.get_article_body(id)
                                    return article;
                                }
                            }
                        }
                        // If sections are nested, search recursively in nested sections
                        if (section.sections) {
                            const found = findContentById(id, section);
                            if (found) {
                                return found;
                            }
                        }
                    }
                }
                return null;
            };
            this.body = ''
            return findContentById(this.id, this.content);
        },
        processedBody() {
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.body, 'text/html');

            doc.querySelectorAll('img').forEach(img => {
                img.style.maxWidth = '100%';
                img.style.height = 'auto';
            });

            return doc.body.innerHTML;
        }
    }
}
</script>

<style>
    .clickable {
        cursor: pointer;
    }
    .article_overview p {
        margin-bottom: 0px;
    }
    h1, h2 {
        font-size: 18px;
    }
</style>
