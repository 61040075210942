// Carriers - ParcelContract
import store from "@/V2/store/index"
import axios from 'axios'
import { products } from '@/V2/integrations/ParcelContract/store/parcelcontract_products'
import { grouped_products } from '@/V2/integrations/ParcelContract/store/parcelcontract_products'
import orderTools from "@/V2/helpers/orderTools";
import parcelContractTools from "@/V2/integrations/ParcelContract/helpers/parcelContractTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
	namespaced: true,
	state: {
		beta: true,
		config: {
			name: 'AusPost - Parcel Contract',
			logo: '/img/auspost2.png',
		},
		products: products,
        grouped_products: grouped_products,
        token: '',
        expires: 0,
		settings: {
			domestic: {
				delivery_options: 'LEAVE_IN_A_SAFE_PLACE',
				id_options: '',
				transit_cover: false,
				transit_cover_percentage: 100,
				item_description: null,
				modified: null
			},
			printer: {
				label: 'A6_1PP',
				left_offset: 0,
				top_offset: 0
			},
			return_address: {
				name: '',
                business_name: '',
                email: '',
                phone: '',
                lines: [],
                suburb: '',
                state: '',
                postcode: '',
                country: "AU"
			}
		},

	},
	mutations: {
	},
	getters: {
		config: state => {
			return state.config
		},
		beta: state => state.beta,
		products: state => {
			return state.products
		},
		grouped_products: state => {
			return state.grouped_products
		},
		settings: state => {
			return state.settings
		},
		return_address: state => {
			return state.settings.return_address
		},
		get_prices: (state, getters, rootState, rootGetters) => (payload) => {
			const arr: Array<any> = []
			const hidden = parcelContractTools.get_hidden_services(payload.carrier)
			if(payload.carrier && payload.quotes) {
				payload.quotes.shipments.forEach( ( shipment, index ) => {
					arr.push({
						carrier_id: payload.carrier.carrier_id,
						carrier_type: payload.carrier.carrier,
						carrier_name: payload.carrier.name,
						code: state.products[index].id,
						description: state.products[index].description,
						price: shipment.total_price_inc_gst,
					})
				})
			}
			arr.sort(function(a, b) {
				return parseFloat(a.price) - parseFloat(b.price);
			});
			return arr
		},
		has_tracking: () => {
			return false
		},
		logo: state => {
			return state.config.logo
		},
		next_step: () => (order) => {
			if(order.consignment.shipment && order.consignment.shipment.manifest_id) {
				return 'fulfill_order'
			}
			if(order.consignment.shipment && !order.consignment.order) {
				return 'pay_label'
			}
			return 'print_label'
		},
		product: (state) => (id) => {
			return state.products.filter(product => product.id == id)[0]
		},
		service_level: (state) => (service) => {
			const product = state.products.find( p => {
				return p.id == service.code
			})
			if(product) {
				return product.service_level
			}
			return null
		},
		actions: () => (order) => {
			try {
				return {
					carrier: 'Parcel Contract',
					create: !order.consignment || order.consignment && ! order.consignment.shipment ? ['Create Label', 'Label', 'create'] : null,
					update: order.consignment && order.consignment.shipment && !order.consignment.shipment.manifest_id ? ['Update Label', 'Update', 'create'] : null, 
					pay: order.consignment && order.consignment.shipment && !order.consignment.shipment.manifest_id ? ['Create Manifest', 'Manifest', 'pay'] : (order.consignment && order.consignment.shipment && order.consignment.shipment.manifest_id ? ['Reprint Manifest', 'Manifest', 'pay'] : null),
					reprint: order.consignment && order.consignment.shipment ? ['Reprint Label', 'Label', 'reprint'] : null
				} 
	
			} catch (e) {
				console.log(e)
			}
		},
		tracking_company: () => {
			return 'Australia Post'
		},
		tracking_number: () => (consignment) => {
			try {
				return consignment.shipment.articles[0].article_tracking_id
			} catch (e) {
				return null
			}
		},
		tracking_url: () => (consignment) => {
			try {
				return `https://auspost.com.au/track/track.html?id=${consignment.shipment.articles[0].article_tracking_id}`
			} catch (e) {
				return null
			}
		},
	},
	actions: {
		init: async function ({ state, dispatch, commit }, payload) {
            const account = await dispatch('validate', payload.credentials)
            commit('Carriers/update', {
                carrier_id: payload.carrier_id, 
                key: 'data',
                value: account, 
            }, {root: true} )
            setTimeout( () => { dispatch('init', payload) }, account.expires_in * 1000); 
            return { data: account, products: state.products, grouped_products: state.grouped_products, packages: [] }
		},
		action_create: async function(context, payload) {
			const success = await parcelContractTools.create_consignments(payload.orders)
			if(success) {
				const label = await parcelContractTools.get_labels(payload.orders)
				return true
			}
		},
		action_pay: async function({ dispatch }, payload) {
			await parcelContractTools.create_manifest(payload.orders)
		},
		action_reprint: async function({ dispatch }, payload) {
			await parcelContractTools.get_labels(payload.orders)
		},
		delete_labels: function (context, payload) {
			parcelContractTools.delete_shipments(payload)
		},
		get_quote: async function ({ dispatch, rootGetters }, payload) {
			await dispatch('Orders/reset_errors', { orders: [payload.order], type: 'quote'}, { root: true })
			const request = await dispatch('get_rate_request_body', {order: payload.order, carrier_id: payload.carrier_id})
			if(request) {
				const address_error = await dispatch('validate_address', { order: payload.order, carrier: rootGetters['Carriers/carrier'](payload.carrier_id) })
				const result = address_error ? null : await dispatch('get_item_price', { order: payload.order, carrier: rootGetters['Carriers/carrier'](payload.carrier_id), request: request })
				if(result) {
					return ({
						request: JSON.stringify(request),
						data: address_error ? null : result.quote, 
						errors: result.errors,
						address_error: address_error,
						statusCode: result.statusCode
					})
				} else {
					return {
						request: JSON.stringify(request),
						data: null,
						errors: null,
						address_error: address_error,
						statusCode: null
					}
				}
			} 
			return {
				request: null,
				data: null,
				errors: null,
				address_error: 'An error occurred validating the address',
				statusCode: null
			}
		},
		get_prices_array: function ( { rootGetters }, payload) {
			const carrier = rootGetters['Carriers/carrier'](payload.carrier)
			const arr: Array<any> = []
			if(carrier){

				payload.quotes.shipments.forEach( ( shipment ) => {
					arr.push({
						carrier_id: carrier.carrier_id,
						carrier_type: carrier.carrier,
						carrier_name: carrier.name,
						code: shipment.items[0].product_id,
						description: carrier.products[shipment.items[0].product_id].description,
						price: shipment.shipment_summary.total_cost
					})
				})
	
				arr.sort(function(a, b) {
					return parseFloat(a.price) - parseFloat(b.price);
				});
	
			}
			return arr
		},
		get_rate_request_body: function (context, payload) {
			return parcelContractTools.get_rate_request_body(payload)
		},
		update: function ({ state }, carrier) {
			carrier.settings = {...state.settings, ...carrier.settings}
			return carrier
		},
		get_item_price: async function ({ dispatch, rootGetters }, payload ) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/parcelcontract/post`, { 
				token: payload.carrier.data.access_token,
                resource: '/shipping/v2/prices',
                request: payload.request
			},
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				if(response.data.body && !response.data.body.errors){
					const body = response.data.body
					if(response.data.body.errors) {
						dispatch('Orders/init_error', { order: payload.order, type: 'quote', error: `ParcelContract Error: ${parcelContractTools.nicer_message(response.data.body.errors[0])}` }, { root: true })
					}
					if(response.data.body.shipments) {
						return { 
							quote: response.data.body.shipments ? response.data.body : null,
							errors: response.data.body.errors ? response.data.body.errors : null,
							statusCode: response.data.statusCode
						}
					}
				} else {
					return {
						quote: null,
						errors: 'An error occurred requesting a rate quote from the Australia Post eParcel system.',
						statusCode: response.data.statusCode
					}
				}
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'eparcel/get_item_price', data: err})
			})
		
		},
		get_shipment_price: async function ({ dispatch, rootGetters }, payload ) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/post`, payload.request,
			{
				headers: { 
					'Authorization': token,
					'account_number': payload.carrier.credentials.account_number,
					'api_key': payload.carrier.credentials.api_key,
					'secret': payload.carrier.credentials.secret,
					'resource': '/shipping/v1/prices/items'
				}
			})
			.then(response => {
				if(response.data.body){
					return { 
						quote: response.data.body.shipments ? response.data.body : null,
						errors: response.data.body.errors ? response.data.body : null
					}
				} else {
					return {
						quote: null,
						errors: 'An error occurred requesting a rate quote from the AusPost eParcel system.'
					}
				}
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'eparcel/get_shipment_price', data: err})
			})
		},
        validate: async function({ rootGetters }, payload) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/parcelcontract/validate`, { 
				client_id: payload.client_id,
				client_secret: payload.client_secret
			},
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				return (response.data.body)
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'parcelcontract/validate', data: err})
			})
		},
		validate_address: async function ({ dispatch, rootGetters }, payload) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			if(payload.order.shipping_address) {
				// Don't need to validate international addresses
				if(payload.order.shipping_address.country_code !== 'AU'){
					return null
				}
				// Do our own address validation first
				return dispatch('Quotes/validate_address', payload.order, { root: true }).then(address_error => {
					if(address_error) {
						// If we can't validate it try AusPost's validation
						const query = [
							`suburb=${payload.order.shipping_address.city}`,
							`state=${payload.order.shipping_address.province_code}`,
							`postcode=${payload.order.shipping_address.zip}`
						].join('&')
			
						return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/parcelcontract/get`, {
							query: encodeURI(query),
                            resource: '/shipping/v2/address',
                            token: payload.carrier.data.access_token, 
						},
						{
							headers: { 
								'Authorization': token,
							}
						})
						.then(response => {
							if(response.data && response.data.body){
								if(response.data.body.found){
									return null
								}
								if(response.data.body.results && response.data.body.results.length) {
									dispatch('Orders/init_error', { order: payload.order, type: 'address', error: 'The suburb / postcode do not match. Possible matches to the postcode entered include ' + response.data.body.results.join(', ') }, { root: true })
								} else {
									dispatch('Orders/init_error', { order: payload.order, type: 'address', error: 'The suburb / postcode do not match. No suggested matches found.' }, { root: true })
								}
								return response.data.body
							}
							dispatch('Orders/reset_errors', { orders: [payload.order], type: 'address'}, { root: true })
							return null
						})
						.catch(err => {
							orderTools.log_error({message: 'Network Error', stack: 'eparcel/validate_address', data: err})
						})
					}
					return address_error
				})
	
			}
		},
		validate_fulfillment: function (context, order) {
			const error_msg = 'You must create a shipping consignment/label and manifest it before you can fulfill this order.'
			try {
				if(order.consignment.shipment.manifest_id) {
					return true
				} else {
					return error_msg
				}
			} catch (e) {
				return error_msg
			}
		},
		set_order_options: function (context, payload) {
			parcelContractTools.set_order_options(payload)
		}
	},
	modules: {
	}
}
