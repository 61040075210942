<template>
    <v-card flat>
        <v-row no-gutters>
            <v-col cols="3">
                <v-card-title>{{ order.name }}</v-card-title> 
                <v-card-subtitle>Shipping Method: {{ order.shipping_lines[0].title }}</v-card-subtitle>       
            </v-col>
            <v-col cols="9" class="px-0">
                <v-expansion-panels flat accordion hover v-if="!options.show_products">
                    <v-expansion-panel>
                        <v-expansion-panel-header :min-height="24" class="primary--text pt-0 pb-0">
                            <div>{{ fulfillable_items }} Line Item<span v-if="fulfillable_items > 1">s </span> (Total Quantity {{ fulfillable_quantity }})</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <Items :items="order.line_items"/>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <Items v-else :items="order.line_items"/>
            </v-col>
        </v-row>
        <v-divider v-if="!last"></v-divider>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Items from '@/V2/components/Merge/Items'
import orderTools from "@/V2/helpers/orderTools";

export default {
    props: ['order', 'last'],
	components: {
        Items
    },
    methods: {
    },
    computed: {
        ...mapGetters({
			options: 'User/merge_options',
        }),
        fulfillable_items: function () {
            return orderTools.count_fulfillable_line_items(this.order)
        },
        fulfillable_quantity: function () {
            return orderTools.get_fulfillable_quantity(this.order)
        }
    },
}
</script>

