export const countries = [
    {id: 'AD', name: 'Andorra'},
    {id: 'AE', name: 'United Arab Emirates'},
    {id: 'AF', name: 'Afghanistan'},
    {id: 'AG', name: 'Antigua And Barbuda'},
    {id: 'AI', name: 'Anguilla'},
    {id: 'AL', name: 'Albania'},
    {id: 'AM', name: 'Armenia'},
    {id: 'AN', name: 'Netherlands Antilles'},
    {id: 'AO', name: 'Angola'},
    {id: 'AR', name: 'Argentina'},
    {id: 'AT', name: 'Austria'},
    {id: 'AU', name: 'Australia'},
    {id: 'AW', name: 'Aruba'},
    {id: 'AX', name: 'Aland Islands'},
    {id: 'AZ', name: 'Azerbaijan'},
    {id: 'BA', name: 'Bosnia And Herzegovina'},
    {id: 'BB', name: 'Barbados'},
    {id: 'BD', name: 'Bangladesh'},
    {id: 'BE', name: 'Belgium'},
    {id: 'BF', name: 'Burkina Faso'},
    {id: 'BG', name: 'Bulgaria'},
    {id: 'BH', name: 'Bahrain'},
    {id: 'BI', name: 'Burundi'},
    {id: 'BJ', name: 'Benin'},
    {id: 'BL', name: 'Saint Barthélemy'},
    {id: 'BM', name: 'Bermuda'},
    {id: 'BN', name: 'Brunei'},
    {id: 'BO', name: 'Bolivia'},
    {id: 'BQ', name: 'Caribbean Netherlands'},
    {id: 'BR', name: 'Brazil'},
    {id: 'BS', name: 'Bahamas'},
    {id: 'BT', name: 'Bhutan'},
    {id: 'BV', name: 'Bouvet Island'},
    {id: 'BW', name: 'Botswana'},
    {id: 'BY', name: 'Belarus'},
    {id: 'BZ', name: 'Belize'},
    {id: 'CA', name: 'Canada'},
    {id: 'CC', name: 'Cocos (Keeling) Islands'},
    {id: 'CD', name: 'Congo, The Democratic Republic Of The'},
    {id: 'CF', name: 'Central African Republic'},
    {id: 'CG', name: 'Congo'},
    {id: 'CH', name: 'Switzerland'},
    {id: 'CI', name: 'Côte d`Ivoire'},
    {id: 'CK', name: 'Cook Islands'},
    {id: 'CL', name: 'Chile'},
    {id: 'CM', name: 'Republic of Cameroon'},
    {id: 'CN', name: 'China'},
    {id: 'CO', name: 'Colombia'},
    {id: 'CR', name: 'Costa Rica'},
    {id: 'CU', name: 'Cuba'},
    {id: 'CV', name: 'Cape Verde'},
    {id: 'CW', name: 'Curaçao'},
    {id: 'CX', name: 'Christmas Island'},
    {id: 'CY', name: 'Cyprus'},
    {id: 'CZ', name: 'Czech Republic'},
    {id: 'DE', name: 'Germany'},
    {id: 'DJ', name: 'Djibouti'},
    {id: 'DK', name: 'Denmark'},
    {id: 'DM', name: 'Dominica'},
    {id: 'DO', name: 'Dominican Republic'},
    {id: 'DZ', name: 'Algeria'},
    {id: 'EC', name: 'Ecuador'},
    {id: 'EE', name: 'Estonia'},
    {id: 'EG', name: 'Egypt'},
    {id: 'EH', name: 'Western Sahara'},
    {id: 'ER', name: 'Eritrea'},
    {id: 'ES', name: 'Spain'},
    {id: 'ET', name: 'Ethiopia'},
    {id: 'FI', name: 'Finland'},
    {id: 'FJ', name: 'Fiji'},
    {id: 'FK', name: 'Falkland Islands (Malvinas)'},
    {id: 'FO', name: 'Faroe Islands'},
    {id: 'FR', name: 'France'},
    {id: 'GA', name: 'Gabon'},
    {id: 'GB', name: 'United Kingdom'},
    {id: 'GD', name: 'Grenada'},
    {id: 'GE', name: 'Georgia'},
    {id: 'GF', name: 'French Guiana'},
    {id: 'GG', name: 'Guernsey'},
    {id: 'GH', name: 'Ghana'},
    {id: 'GI', name: 'Gibraltar'},
    {id: 'GL', name: 'Greenland'},
    {id: 'GM', name: 'Gambia'},
    {id: 'GN', name: 'Guinea'},
    {id: 'GP', name: 'Guadeloupe'},
    {id: 'GQ', name: 'Equatorial Guinea'},
    {id: 'GR', name: 'Greece'},
    {id: 'GS', name: 'South Georgia And The South Sandwich Islands'},
    {id: 'GT', name: 'Guatemala'},
    {id: 'GW', name: 'Guinea Bissau'},
    {id: 'GY', name: 'Guyana'},
    {id: 'HK', name: 'Hong Kong'},
    {id: 'HM', name: 'Heard Island And Mcdonald Islands'},
    {id: 'HN', name: 'Honduras'},
    {id: 'HR', name: 'Croatia'},
    {id: 'HT', name: 'Haiti'},
    {id: 'HU', name: 'Hungary'},
    {id: 'ID', name: 'Indonesia'},
    {id: 'IE', name: 'Ireland'},
    {id: 'IL', name: 'Israel'},
    {id: 'IM', name: 'Isle Of Man'},
    {id: 'IN', name: 'India'},
    {id: 'IO', name: 'British Indian Ocean Territory'},
    {id: 'IQ', name: 'Iraq'},
    {id: 'IR', name: 'Iran, Islamic Republic Of'},
    {id: 'IS', name: 'Iceland'},
    {id: 'IT', name: 'Italy'},
    {id: 'JE', name: 'Jersey'},
    {id: 'JM', name: 'Jamaica'},
    {id: 'JO', name: 'Jordan'},
    {id: 'JP', name: 'Japan'},
    {id: 'KE', name: 'Kenya'},
    {id: 'KG', name: 'Kyrgyzstan'},
    {id: 'KH', name: 'Cambodia'},
    {id: 'KI', name: 'Kiribati'},
    {id: 'KM', name: 'Comoros'},
    {id: 'KN', name: 'Saint Kitts And Nevis'},
    {id: 'KP', name: 'Korea, Democratic People`s Republic Of'},
    {id: 'KR', name: 'South Korea'},
    {id: 'KW', name: 'Kuwait'},
    {id: 'KY', name: 'Cayman Islands'},
    {id: 'KZ', name: 'Kazakhstan'},
    {id: 'LA', name: 'Lao People`s Democratic Republic'},
    {id: 'LB', name: 'Lebanon'},
    {id: 'LC', name: 'Saint Lucia'},
    {id: 'LI', name: 'Liechtenstein'},
    {id: 'LK', name: 'Sri Lanka'},
    {id: 'LR', name: 'Liberia'},
    {id: 'LS', name: 'Lesotho'},
    {id: 'LT', name: 'Lithuania'},
    {id: 'LU', name: 'Luxembourg'},
    {id: 'LV', name: 'Latvia'},
    {id: 'LY', name: 'Libyan Arab Jamahiriya'},
    {id: 'MA', name: 'Morocco'},
    {id: 'MC', name: 'Monaco'},
    {id: 'MD', name: 'Moldova, Republic of'},
    {id: 'ME', name: 'Montenegro'},
    {id: 'MF', name: 'Saint Martin'},
    {id: 'MG', name: 'Madagascar'},
    {id: 'MK', name: 'North Macedonia'},
    {id: 'ML', name: 'Mali'},
    {id: 'MM', name: 'Myanmar'},
    {id: 'MN', name: 'Mongolia'},
    {id: 'MO', name: 'Macao'},
    {id: 'MQ', name: 'Martinique'},
    {id: 'MR', name: 'Mauritania'},
    {id: 'MS', name: 'Montserrat'},
    {id: 'MT', name: 'Malta'},
    {id: 'MU', name: 'Mauritius'},
    {id: 'MV', name: 'Maldives'},
    {id: 'MW', name: 'Malawi'},
    {id: 'MX', name: 'Mexico'},
    {id: 'MY', name: 'Malaysia'},
    {id: 'MZ', name: 'Mozambique'},
    {id: 'NA', name: 'Namibia'},
    {id: 'NC', name: 'New Caledonia'},
    {id: 'NE', name: 'Niger'},
    {id: 'NF', name: 'Norfolk Island'},
    {id: 'NG', name: 'Nigeria'},
    {id: 'NI', name: 'Nicaragua'},
    {id: 'NL', name: 'Netherlands'},
    {id: 'NO', name: 'Norway'},
    {id: 'NP', name: 'Nepal'},
    {id: 'NR', name: 'Nauru'},
    {id: 'NU', name: 'Niue'},
    {id: 'NZ', name: 'New Zealand'},
    {id: 'OM', name: 'Oman'},
    {id: 'PA', name: 'Panama'},
    {id: 'PE', name: 'Peru'},
    {id: 'PF', name: 'French Polynesia'},
    {id: 'PG', name: 'Papua New Guinea'},
    {id: 'PH', name: 'Philippines'},
    {id: 'PK', name: 'Pakistan'},
    {id: 'PL', name: 'Poland'},
    {id: 'PM', name: 'Saint Pierre And Miquelon'},
    {id: 'PN', name: 'Pitcairn'},
    {id: 'PS', name: 'Palestinian Territory, Occupied'},
    {id: 'PT', name: 'Portugal'},
    {id: 'PY', name: 'Paraguay'},
    {id: 'QA', name: 'Qatar'},
    {id: 'RE', name: 'Reunion'},
    {id: 'RO', name: 'Romania'},
    {id: 'RS', name: 'Serbia'},
    {id: 'RU', name: 'Russia'},
    {id: 'RW', name: 'Rwanda'},
    {id: 'SA', name: 'Saudi Arabia'},
    {id: 'SB', name: 'Solomon Islands'},
    {id: 'SC', name: 'Seychelles'},
    {id: 'SD', name: 'Sudan'},
    {id: 'SE', name: 'Sweden'},
    {id: 'SG', name: 'Singapore'},
    {id: 'SH', name: 'Saint Helena'},
    {id: 'SI', name: 'Slovenia'},
    {id: 'SJ', name: 'Svalbard And Jan Mayen'},
    {id: 'SK', name: 'Slovakia'},
    {id: 'SL', name: 'Sierra Leone'},
    {id: 'SM', name: 'San Marino'},
    {id: 'SN', name: 'Senegal'},
    {id: 'SO', name: 'Somalia'},
    {id: 'SR', name: 'Suriname'},
    {id: 'SS', name: 'South Sudan'},
    {id: 'ST', name: 'Sao Tome And Principe'},
    {id: 'SV', name: 'El Salvador'},
    {id: 'SX', name: 'Sint Maarten'},
    {id: 'SY', name: 'Syria'},
    {id: 'SZ', name: 'Eswatini'},
    {id: 'TC', name: 'Turks and Caicos Islands'},
    {id: 'TD', name: 'Chad'},
    {id: 'TF', name: 'French Southern Territories'},
    {id: 'TG', name: 'Togo'},
    {id: 'TH', name: 'Thailand'},
    {id: 'TJ', name: 'Tajikistan'},
    {id: 'TK', name: 'Tokelau'},
    {id: 'TL', name: 'Timor Leste'},
    {id: 'TM', name: 'Turkmenistan'},
    {id: 'TN', name: 'Tunisia'},
    {id: 'TO', name: 'Tonga'},
    {id: 'TR', name: 'Turkey'},
    {id: 'TT', name: 'Trinidad and Tobago'},
    {id: 'TV', name: 'Tuvalu'},
    {id: 'TW', name: 'Taiwan'},
    {id: 'TZ', name: 'Tanzania, United Republic Of'},
    {id: 'UA', name: 'Ukraine'},
    {id: 'UG', name: 'Uganda'},
    {id: 'UM', name: 'United States Minor Outlying Islands'},
    {id: 'US', name: 'United States'},
    {id: 'UY', name: 'Uruguay'},
    {id: 'UZ', name: 'Uzbekistan'},
    {id: 'VA', name: 'Holy See (Vatican City State)'},
    {id: 'VC', name: 'St Vincent'},
    {id: 'VE', name: 'Venezuela'},
    {id: 'VG', name: 'Virgin Islands, British'},
    {id: 'VN', name: 'Vietnam'},
    {id: 'VU', name: 'Vanuatu'},
    {id: 'WF', name: 'Wallis And Futuna'},
    {id: 'WS', name: 'Samoa'},
    {id: 'XK', name: 'Kosovo'},
    {id: 'YE', name: 'Yemen'},
    {id: 'YT', name: 'Mayotte'},
    {id: 'ZA', name: 'South Africa'},
    {id: 'ZM', name: 'Zambia'},
    {id: 'ZW', name: 'Zimbabwe'},
]

export const countriesByISO = {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua And Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AN: 'Netherlands Antilles',
    AO: 'Angola',
    AR: 'Argentina',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Aland Islands',
    AZ: 'Azerbaijan',
    BA: 'Bosnia And Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthélemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BQ: 'Caribbean Netherlands',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Congo, The Democratic Republic Of The',
    CF: 'Central African Republic',
    CG: 'Congo',
    CH: 'Switzerland',
    CI: 'Côte d`Ivoire',
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Republic of Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CW: 'Curaçao',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    EH: 'Western Sahara',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia And The South Sandwich Islands',
    GT: 'Guatemala',
    GW: 'Guinea Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard Island And Mcdonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle Of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran, Islamic Republic Of',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts And Nevis',
    KP: 'Korea, Democratic People`s Republic Of',
    KR: 'South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Lao People`s Democratic Republic',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libyan Arab Jamahiriya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova, Republic of',
    ME: 'Montenegro',
    MF: 'Saint Martin',
    MG: 'Madagascar',
    MK: 'North Macedonia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre And Miquelon',
    PN: 'Pitcairn',
    PS: 'Palestinian Territory, Occupied',
    PT: 'Portugal',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena',
    SI: 'Slovenia',
    SJ: 'Svalbard And Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'Sao Tome And Principe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syria',
    SZ: 'Eswatini',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TF: 'French Southern Territories',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'Timor Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania, United Republic Of',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'United States Minor Outlying Islands',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Holy See (Vatican City State)',
    VC: 'St Vincent',
    VE: 'Venezuela',
    VG: 'Virgin Islands, British',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis And Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
}