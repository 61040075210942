<template>
    <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
        <v-card>
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">Popup Blocked</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4">Your browser settings are blocking popup windows which is preventing the label file from being displayed.</v-card-text>
            <v-card-text class="pa-4">Change your browser settings to enable popup windows or click the button below to open the label file.</v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="primary darken-1" large :href="popup_blocked" target="_blank" @click.native="agree">Open Label File</v-btn>
            </v-card-actions>            
            <v-card-actions class="pt-5">
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-1" text @click.native="agree">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        options: {
            color: 'red darken-2',
            width: 490,
            zIndex: 200
        }
    }),
    methods: {
        open() {
            this.dialog = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            this.resolve(true)
            this.dialog = false
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        }
    },
    computed : {
        ...mapGetters({
            popup_blocked: 'popup_blocked'
        }),
    }
}
</script>