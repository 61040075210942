<template>
    <div>
        <div v-for="item in merged.get_line_items(order)" :key="item.id">
            <v-row no-gutters class="pa-3" v-if="item.fulfillable_quantity">
                <v-col v-if="show('images')" cols="2" class="py-0 pr-2">
                <v-responsive max-height="100%" max-width="100%" v-if="images[`${item.product_id}|${item.variant_id}`]">
                        <v-img
                            :src="images[`${item.product_id}|${item.variant_id}`].url"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            :max-height="display_settings.expanded.products.subsections.images.options.size.value"
                            :max-width="display_settings.expanded.products.subsections.images.options.size.value"
                            @error="reload_image(item.product_id, item.variant_id)"
                            >
                        </v-img>
                    </v-responsive>
                </v-col>
                <v-col cols="10" class="caption">
                    <v-row no-gutters>
                        <v-col cols="7">
                            <span v-if="show('quantity')" class="font-weight-bold">{{ item.fulfillable_quantity }}</span> <span v-if="show('quantity') && show('title')">x </span>
                            <v-tooltip top v-if="show('title')">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ item.name }}</span>
                                </template>
                                <span>{{ item.name }}</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="2">
                            <div v-if="show('unit_price')" class="float-right">${{ item.price | formatNumber(2)}}</div>
                        </v-col>
                        <v-col cols="3">
                            <div v-if="show('total_price')" class="float-right">${{ item.price * item.fulfillable_quantity | formatNumber(2)}}</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <div v-if="show('sku')" class="caption grey--text">{{ item.sku }}</div>
                            <div v-if="show('properties') && item.properties">
                                <v-row v-for="(p, index) of item.properties" v-bind:key="index" no-gutters>
                                    <v-col class="caption font-weight-bold">{{ p.name }}:</v-col>
                                    <v-col class="caption">{{ p.value }}</v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-card flat>
            <v-card-actions class="pa-0">
                <small v-if="order.override_line_items && order.override_line_items.modified" class="orange--text"><v-icon small color="warning" class="mr-4 mt-n1">mdi-alert</v-icon>CAUTION: Original product names or values may have been overridden</small>
                <v-spacer></v-spacer>
                <v-btn v-if="!is_domestic" icon :class="order.override_line_items && order.override_line_items.modified ? 'mt-0': 'mt-n8'"><v-icon small color="blue" @click="edit = true">mdi-pencil</v-icon></v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="edit" width="600" min-height="500">
            <EditProducts :order="order" :edit="edit" v-on:close="edit=false"/>
        </v-dialog>
    </div>
</template>

<script>
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";
import EditProducts from "@/V2/components/Orders/EditProducts.vue"
import { mapGetters } from 'vuex'

export default {
    name: 'Items',
    props: ['order'],
    components: {
        EditProducts,
    },
    data: function () {
        return {
            merged: mergeTools,
            orderTools: orderTools,
            edit: false
        }
    },
    methods: {
        show(subsection) {
            return this.display_settings.expanded.products.subsections[subsection].status
        },
        reload_image(product_id, variant_id) {
            this.$store.dispatch('Images/get_image', { image: `${product_id}|${variant_id}`, update: true })
        }
    },
	computed : {
        ...mapGetters({
            display_settings: 'Settings/display_settings',
            images: 'Images/images'
        }),
        is_domestic(){
            return orderTools.is_domestic(this.order)
        }
    }
}
</script>

