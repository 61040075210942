// Templates
export default {
	namespaced: true,
	state: {
        invoice: 'default',
        picklist: 'default'
	},
	mutations: {
	},
	getters: {
        template: (state) => (type) => {
            return state[type]
        }
	},
	actions: {
	},
	modules: {
	}
}
