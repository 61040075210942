<template>
    <v-card v-if="carrier" outlined elevation="1" align="center" class="pt-5 pb-0">
        <v-responsive max-height="100%" max-width="100%">
            <v-img
                :src="carrier.logo"
                aspect-ratio="2.85"
                class="grey lighten-2"
                :max-width="100"
                :max-height="35"
                >
            </v-img>
        </v-responsive>
        <v-card-subtitle class="mt-3 mb-1">{{ carrier.name }}</v-card-subtitle>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="indigo" :to="`/carrier/add/${carrier_id}`">
                <v-icon left>add</v-icon> Add Carrier
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: ['carrier_id'],
    methods: {

    },
    computed: {
        ...mapState({
            carrier (state, getters) {
                return getters[`Carriers/${this.carrier_id}/config`]
            }
        })
    }    
}

</script>