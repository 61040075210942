<template>
    <div class="mt-n6">
        <v-toolbar flat>
            <v-toolbar-title>Shipping Rules</v-toolbar-title>
            <template v-slot:extension>
                <v-tabs v-model="current_item" slider-color="white">
                    <v-tab href="#tab-0">Domestic Shipping Rules</v-tab>
                    <v-tab href="#tab-1">International Shipping Rules</v-tab>
                </v-tabs>
            </template>
        </v-toolbar>
        <v-tabs-items v-model="current_item">
            <v-tab-item value="tab-0" class="pa-2">
                <v-row>
                    <v-col cols="12" lg="4" class="py-0"> 
                        <Instructions id="6509988" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="12" xl="8" class="py-0"> 
                        <v-card class="pb-4 mb-3">
                            <ShippingMethod :rules="domestic_rules" :domestic="true" v-on:save="save()" />
                        </v-card>
                        <v-card class="pb-4 mb-3">
                            <DefaultService :rules="domestic_rules" :domestic="true" v-on:save="save()" />
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item value="tab-1" class="pa-2">
                <v-row>
                    <v-col cols="12" lg="4" class="py-0"> 
                        <Instructions id="6509988" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="12" xl="8" class="py-0"> 
                        <v-card class="pb-4 mb-3">
                            <ShippingMethod :rules="international_rules" :domestic="false" v-on:save="save()" />
                        </v-card>
                        <v-card class="pb-4 mb-3">
                            <DefaultService :rules="international_rules" :domestic="false" v-on:save="save()" />
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>            
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import Response from '@/V2/components/Utilities/Response'
import Instructions from '@/V2/views/Instructions'
import CheapestRate from '@/V2/components/Rules/CheapestRate'
import DefaultService from '@/V2/components/Rules/DefaultService'
import ShippingMethod from '@/V2/components/Rules/ShippingMethod'

export default {
    data: function () {
        return {
            current_item: 0
        }
    },
	components: {
//        Response,
        Instructions,
//        CheapestRate,
        DefaultService,
        ShippingMethod
    },
    methods: {
        save: function () {
            this.$store.commit('Orders/reset_rules', {}, { root: true })
            this.$store.dispatch('Rules/save', {}, { root: true })
        }
    },
    computed: {
		...mapGetters({
            all: 'Rules/all',
            domestic_rules: 'Rules/domestic',
            international_rules: 'Rules/international'
        })
    }
}
</script>

