<template>
    <v-row no-gutters>
        <v-col cols="8" class="pt-0">
            <v-card-subtitle class="pt-3 pb-1 font-weight-bold">{{ default_display_settings[view][section].description }}</v-card-subtitle>
        </v-col>
        <v-col cols="4">

        </v-col>
        <v-col v-if="default_display_settings[view][section].subsections" class="pl-4">
            <v-row v-for="(subsection, index) in Object.keys(default_display_settings[view][section].subsections)" :key="index" no-gutters class="text-caption text-sm-body-2 px-4">
                <v-col cols="10" class="py-1">
                    <v-card-subtitle class="pa-0">{{ display_settings[view][section].subsections[subsection].description }}</v-card-subtitle>
                </v-col>
                <v-col cols="2">
                    <v-switch v-model="display_settings[view][section].subsections[subsection].status" hide-details color="success" @click="$emit('save')" class="mt-0 pt-1"></v-switch>
                </v-col>
                <v-col v-if="default_display_settings[view][section].subsections[subsection].options">
                    <v-row v-for="(option, index) in Object.keys(default_display_settings[view][section].subsections[subsection].options)" :key="index">
                        <v-col cols="7">
                            <v-card-subtitle class="pa-0 pl-4">{{ display_settings[view][section].subsections[subsection].options[option].description }}</v-card-subtitle>
                        </v-col>
                        <v-col cols="5" class="pt-1 pb-1 pr-6">
                            <v-text-field v-model="display_settings[view][section].subsections[subsection].options[option].value" hide-details reverse dense outlined @change="$emit('save')"></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: ['view', 'section'],
    components: {
    },
    data: () => ({
    }),
    methods: {
    },
    computed: {
		...mapGetters({
			display_settings: 'Settings/display_settings',
			default_display_settings: 'Settings/default_display_settings',
		}),
    }
}
</script>
