<template>
    
</template>

<script>
import { mapState } from 'vuex'
import {graphql} from "@/V2/helpers/shopify";

export default {
    created: async function () {
        const query = `{
            currentAppInstallation{
                id
                activeSubscriptions {
                    createdAt
                }
            }
        }`
        try {
            await graphql({
                data: {
                    graphql: {
                        query: query
                    }
                }
            }).then(response => {
                if(response.data.data.currentAppInstallation.activeSubscriptions.length) {
                    this.$router.push('/init')
                } else {
                    this.$router.push('/billing/init')
                }
            });
            
        } catch (error) {
            console.error("Error fetching data:", error);
        }

    },
    computed: {
        ...mapState(['env'])
    }
}
</script>