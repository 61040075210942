import { Engine } from 'json-rules-engine'
import orderTools from "@/V2/helpers/orderTools";
import store from "@/V2/store/index"
import Vue from '*.vue';

export default {
    run: async function (order, prices) {
        order.rule_found = true
        const rule_type = orderTools.is_domestic(order) ? 'domestic' : 'international'
        const rules = store.getters[`Rules/${rule_type}`]
        if(rules.keywords) {
            const shipping_method = order.shipping_lines[0] ? order.shipping_lines[0].title : null
            const matching = await this.match_keywords(shipping_method, rules.keywords, prices, order)
            if(matching !== null) {
                if(matching.length && matching[0]){
                    return this.get_cheapest(matching)
                } else {
                    order.rule_keywords = []
                }
            }
        }
        const default_rate = this.get_cheapest(this.get_default_carrier(rules.default.carrier, rules.default.service, prices))
        if(default_rate) {
            order.rule_used = "Default Carrier Rule"
            return default_rate
        } else {
            order.rule_found = false
            return this.get_cheapest(prices)    
        }
        
    },
    get_cheapest: function (prices) {
        prices.sort(function(a, b) {
            return parseFloat(a.price) - parseFloat(b.price);
        });
        return prices[0]
    },
    get_default_carrier: function (carrier, services, prices) {
        let arr: Array<any> = []
        if(services) {
            services.forEach(service => {
                const price_arr = prices.filter( (price) => {
                    return  price.carrier_id === carrier &&
                            price.code === service
                })[0]
                arr = arr.concat(price_arr)
            })
        }
        return arr
    },
    match_keywords: function ( shipping_method, rules, prices, order) {
        const engine = this.start_engine()
        const facts = {
            str: shipping_method,
        }
        rules.forEach((rule, index) => {
            if(rule.keywords){
                const keywords = rule.keywords.split(",").map(function(item) {
                    return item.trim();
                });
                engine.addRule(this.add_rule('hasString', keywords, index))
            }
            
        });
        return engine.run(facts).then(results => {
            order.rule_keywords = []
            return results.events.map(event => {
                if(event.params){
                    const match = rules[event.params['index']]
    
                    const arr = prices.filter( item => {
                        return  item.carrier_id === match.carrier &&
                          match.service.some( s => s == item.code)
                    })
//                    order.rule_keywords = order.rule_keywords ? order.rule_keywords.concat(" & ", match.keywords) : match.keywords
                    order.rule_keywords.push(match.keywords.trim())
                    order.rule_keywords = [...new Set(order.rule_keywords)]
                    return this.get_cheapest(arr)
                } else {
                    return prices
                }
                    
            })
        })
    },
    start_engine: function () {
        const engine = new Engine()
        this.add_operators(engine)
        return engine
    },
    add_rule: function (operator, value, idx) {
        return {
            conditions: {
                all: [{
                    fact: 'str',
                    operator: operator,
                    value: value
                }]
            },
            priority: idx,
            event: {
                type: `rule ${idx}`,
                params: {
                    index: idx
                }
            }
        }
    },
    add_operators: function (engine){
        engine.addOperator('hasString', (factValue, ruleValue) => {
            let matches = 0
            ruleValue.forEach((word) => {
                if(word.trim()) {
                    const regex = new RegExp(word.trim(), 'ig');
                    if(factValue && factValue.match(regex)) {
                        matches++
                    }
                }
            })
            if(matches) {
                return true
            }
        })

    }
    
}
