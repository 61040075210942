export default {
    content: {
        id: 'main_help_page',
        type: 'index',
        sections: [
            {
                type: 'section',
                id: 'getting_started',
                title: "Getting Started - The Basics",
                articles: [
                    {
                        type: 'article',
                        id: 3768151,
                        title: "Quick Start Guide",
                        sub_title: "An easy to follow step by step guide to getting started"
                    },
                    {
                        type: 'article',
                        id: 6504317,
                        title: "Printing Labels and Fulfilling Orders",
                        sub_title: "A quick and easy guide to the basic steps required to print labels and fulfill orders."
                    }
                ]
            },
            {
                type: 'section',
                id: 'account_preferences',
                title: "Account Preferences",
                articles: [
                    {
                        type: 'article',
                        id: 9258444,
                        title: "Invoice Options",
                        sub_title: "How to add a logo to your invoices and configure other options."
                    },
                    {
                        type: 'article',
                        id: 9258442,
                        title: "Picklist Options",
                        sub_title: "How to configure your picklist options"
                    },
                    {
                        type: 'article',
                        id: 9258439,
                        title: "Fulfillment Options",
                        sub_title: "Control the delivery of notifications to your customers"
                    },
                    {
                        type: 'article',
                        id: 9258437,
                        title: "Label Options",
                        sub_title: "How to add additional data to labels such as customer notes or product details"
                    },
                    {
                        type: 'article',
                        id: 9258420,
                        title: "Sender Address",
                        sub_title: `Instructions on how to edit or add your "Sender Address"`
                    },
                ]
            },
            {
                type: 'section',
                id: 'dashboard',
                title: "Dashboard",
                articles: [
                    {
                        type: 'article',
                        id: 6504373,
                        title: "Overview",
                        sub_title: "An overview of the dashboard, how to configure the display and the features available."
                    },
                    {
                        type: 'article',
                        id: 6509791,
                        title: "Display Settings",
                        sub_title: "Customise the display settings of your orders to suit your needs"
                    },
                    {
                        type: 'article',
                        id: 6509820,
                        title: "Filters",
                        sub_title: "A guide to the filter features available on the Dashboard"
                    },
                    {
                        type: 'article',
                        id: 6509824,
                        title: "Sorting Orders",
                        sub_title: "How to sort orders on the Dashboard"
                    },
                    {
                        type: 'article',
                        id: 6509828,
                        title: "Actions",
                        sub_title: "An overview of the order actions and bulk order actions available on the Dashboard"
                    },
                    {
                        type: 'article',
                        id: 6509834,
                        title: "Pagination",
                        sub_title: "How to use and configure the pagination of orders on the Dashboard"
                    },
                ]
            },
            {
                type: 'section',
                id: 'other_menu_options',
                title: "Other Menu Options",
                articles: [
                    {
                        type: 'article',
                        id: 6509985,
                        title: "Carriers - Overview",
                        sub_title: "How to add, modify or delete carriers"
                    },
                    {
                        type: 'article',
                        id: 6509988,
                        title: "Shipping Rules - Overview",
                        sub_title: "Configure your Shipping Rules which define which shipping carrier and service will be used for each order received in your account."
                    },
                    {
                        type: 'article',
                        id: 6510178,
                        title: "Packages - Overview",
                        sub_title: "How to set up and use Packages to add dimensions to your orders to ensure accurate rate quoting for your consignments."
                    },
                ]
            },
            {
                type: 'section',
                id: 'frequently_asked_questions',
                title: "Frequently Asked Questions",
                articles: [
                    {
                        type: 'article',
                        id: 6504317,
                        title: "Printing Labels and Fulfilling Orders",
                        sub_title: "A quick and easy guide to the basic steps required to print labels and fulfill orders."
                    },
                    {
                        type: 'article',
                        id: 9258084,
                        title: "Carriers - Integrations Available",
                        sub_title: "View our list of current carrier integrations"
                    },
                    {
                        type: 'article',
                        id: 6513318,
                        title: "How to Partially Fulfill an Order",
                        sub_title: "How to perform a split shipment or partial fulfillment of an order."
                    },
                    {
                        type: 'article',
                        id: 6513358,
                        title: "How to Add Insurance Cover to an Order",
                        sub_title: "How to add insurance/transit cover to an order"
                    },
                    {
                        type: 'article',
                        id: 6510439,
                        title: "Changing Order Options",
                        sub_title: "How to change order options such as Signature on Delivery options, Insurance and more..."
                    },
                    {
                        type: 'article',
                        id: 6510421,
                        title: "How to Print Invoices and Picklists",
                        sub_title: "This quick guide shows you how to print invoices and picklists"
                    },
                    {
                        type: 'article',
                        id: 6510450,
                        title: "Editing an Order Address",
                        sub_title: "How to edit or change an order address"
                    },
                ]
            },
            {
                type: 'section',
                id: 'australia_post_mypost_business',
                title: "Australia Post MyPost Business",
                articles: [
                    {
                        type: 'article',
                        id: 6510191,
                        title: "Connecting Your Account",
                        sub_title: "How to obtain a Merchant Token and connect to your AusPost MyPost Business account"
                    },
                    {
                        type: 'article',
                        id: 9258102,
                        title: "Change Label Size to A4/A6",
                        sub_title: "How to set the label size for your MyPost Business consignments."
                    },
                    {
                        type: 'article',
                        id: 6510218,
                        title: "Payment by PayPal",
                        sub_title: "How to pay for consignments by PayPal."
                    },
                    {
                        type: 'article',
                        id: 6510222,
                        title: "Payment by Charge Account",
                        sub_title: "How to pay for consignments with an AusPost Charge Account."
                    },
                    {
                        type: 'article',
                        id: 6510274,
                        title: "Payment by Credit Card",
                        sub_title: "How to pay for consignments with a credit card"
                    },
                    {
                        type: 'article',
                        id: 6510326,
                        title: "Default Domestic Settings",
                        sub_title: "An explanation of the MyPost Business Domestic Settings available"
                    },
                    {
                        type: 'article',
                        id: 6510329,
                        title: "Default International Settings",
                        sub_title: "An explanation of the MyPost Business International Settings available"
                    },
                    {
                        type: 'article',
                        id: 6510346,
                        title: "Hiding Unused Services",
                        sub_title: "How to hide certain services provided by Australia Post MyPost Business"
                    },
                    {
                        type: 'article',
                        id: 6520646,
                        title: "How to Book a Pickup",
                        sub_title: "How to book a pickup of your MyPost consignments"
                    },
                ]
            },
            {
                type: 'section',
                id: 'australia_post_eparcel',
                title: "Australia Post eParcel/ParcelSend",
                articles: [
                    {
                        type: 'article',
                        id: 6958847,
                        title: "Connecting to eParcel/ParcelSend",
                        sub_title: "How to obtain an API key from Australia Post and connect your eParcel/ParcelSend account"
                    },
                    {
                        type: 'article',
                        id: 9258086,
                        title: "Change Label Size to A4/A6",
                        sub_title: "How to set the label size for your eParcel/ParcelSend consignments."
                    },
                    {
                        type: 'article',
                        id: 9257702,
                        title: "Default Domestic Settings",
                        sub_title: "An explanation of the eParcel/ParcelSend Domestic Settings available"
                    },
                    {
                        type: 'article',
                        id: 9073158,
                        title: "Default International Settings",
                        sub_title: "An explanation of the eParcel/ParcelSend International Settings available"
                    },
                ]
            }
        ]
    }
}