<template>
	<v-app class="lighten-4 fix-font">
		<Menu v-if="app"/>
		<v-container fluid class="my-15 px-0">
			<keep-alive>
				<router-view/>
			</keep-alive>
		</v-container>
		<Startup v-if="loading"/>
		<Tours/>
	</v-app>
</template>

<script>

import { mapState } from 'vuex'
import Menu from '@/V2/components/Utilities/Menu.vue'
import Tours from '@/V2/components/Utilities/Tours.vue'
import Startup from '@/V2/components/Utilities/Startup.vue'

export default {
	name: 'App',
	data: function () {
		return {
		}
	},
	components: {
		Menu,
		Tours,
		Startup
	},
	methods: {
	},
	computed: {
		...mapState('Shopify', {
			app: state => state.app,
			initialised: state => state.initialised,
			token: state => state.token
		}),
		loading: function () {
			return this.$store.getters['loading']
		}
	},
	mounted: function () {
	}
}
</script>

<style>
	.v-main {
		color: var(--v-primary-base);
		background-color: var(--v-background-base);
	}
	.mt-18 {
		margin-top: 72px;
	}
	label {
		margin-bottom: 0px;
	}
	label.v-label span.font-13 {
		font-size: 13px;
	}
	.row {
		margin-top: 0px;
		margin-bottom: 0px;
	}
    .clickable {
        cursor: pointer;
    }

</style>
