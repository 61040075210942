<template>
    <div class="mt-n6">
        <v-row>
            <v-col cols="12" lg="12" xl="8"> 
                <v-card>
                    <v-card-title>Packaging Rules</v-card-title>
                    <v-card-text>Text goes here</v-card-text>
                    <v-card-actions>action here</v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data: function () {
        return {
        }
    },
	components: {
    },
    watch: {
    },
    methods: {
    },
	created: function () {
	},
	computed : {
        ...mapState([]),
	}
}
</script>

