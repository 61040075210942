<template>
    <div>
        <v-card flat class="pa-0 pl-1 pr-1 d-flex" :class="button_justify">
            <v-card flat>
                <v-btn v-if="next_step == 'create_label'" :disabled="locked" :width="next_step_width" :large="layout == 'compact' || layout == 'expanded'" color="primary" @click.stop="locked = true; action('create')" class="mb-2">
                    <span v-if="$vuetify.breakpoint.width > 620">Create Label</span>
                    <span v-else class="px-1">Label</span>
                </v-btn>
                <v-btn v-if="next_step == 'print_label'" :disabled="locked" small :width="next_step_width" :large="layout == 'compact' || layout == 'expanded'" color="primary" @click.stop="locked = true; action('create')" class="mb-2">
                    <span v-if="$vuetify.breakpoint.width > 620">Print Label</span>
                    <span v-else class="px-1">Label</span>
                </v-btn>
                <v-btn v-if="next_step == 'pay_label'" :disabled="locked" small :width="next_step_width" :large="layout == 'compact' || layout == 'expanded'" color="primary" @click.stop="locked = true; action('pay')" class="mb-2">
                    <span v-if="$vuetify.breakpoint.width > 1250 || layout != 'table'">{{ carrier_actions['pay'] ? carrier_actions['pay'][0] : 'Pay &amp; Print Label' }}</span>
                    <span v-else class="px-1">{{ carrier_actions['pay'] ? carrier_actions['pay'][1] : 'Pay &amp; Print Label' }}</span>
                </v-btn> 
                <v-btn v-if="next_step == 'manifest_label'" :disabled="locked" small :width="next_step_width" :large="layout == 'compact' || layout == 'expanded'" color="primary" @click.stop="locked = true; action('pay')" class="mb-2">
                    <span v-if="$vuetify.breakpoint.width > 1250 || layout != 'table'">{{ carrier_actions['pay'] ? carrier_actions['pay'][0] : 'Manifest' }}</span>
                    <span v-else class="px-1">{{ carrier_actions['pay'] ? carrier_actions['pay'][1] : 'Manifest' }}</span>
                </v-btn> 
                <v-btn v-if="next_step == 'fulfill_order'" :disabled="locked" small :width="next_step_width" :large="layout == 'compact' || layout == 'expanded'" color="success" @click.stop="locked = true; action('fulfill')" class="mb-2">
                    <span v-if="$vuetify.breakpoint.lgAndUp || layout != 'table'">Fulfill Order</span>
                    <span v-else>Fulfill</span>
                </v-btn>
                <v-btn v-if="next_step == 'disabled'" :disabled="locked" small :width="next_step_width" disabled :large="layout == 'compact' || layout == 'expanded'" color="primary" @click.stop="locked = true; action('create')" class="mb-2">
                    <span v-if="$vuetify.breakpoint.width > 620">Print Label</span>
                    <span v-else class="px-1">Label</span>
                </v-btn>
            </v-card>
            <v-card flat>
                <v-menu	bottom offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark small :large="layout == 'compact' || layout == 'expanded'" v-on="on" class="ml-2 mb-2">
                            Actions
                            <v-icon v-if="$vuetify.breakpoint.width > 1250">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list class="pointer pl-5">
                        <v-subheader v-if="Object.keys(carrier_actions).length" class="text--primary font-weight-black ml-n5">Label Actions</v-subheader>
                        <template v-for="(action, index) in carrier_actions">
                            <v-list-item v-if="action" link :key="index" @click="$emit('action', action[2])">
                                <v-list-item-title>{{ action[0] }}</v-list-item-title>
                            </v-list-item>
                        </template>
                        <v-subheader class="text--primary font-weight-black ml-n5">Order Actions</v-subheader>
                        <v-list-item link @click="$emit('action', 'invoice')">
                            <v-list-item-title>Print Invoice</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="$emit('action', 'picklist')">
                            <v-list-item-title>Print Picklist</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="$emit('action', 'refresh')">
                            <v-list-item-title>Refresh Quote</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="order.consignment" link @click="$emit('action', 'fulfill')">
                            <v-list-item-title>Fulfill Order</v-list-item-title>
                        </v-list-item>
                        <v-list-item link v-if="order.deferred" @click="$emit('action', 'undefer')">
                            <v-list-item-title>Cancel Deferral</v-list-item-title>
                        </v-list-item>
                        <v-list-item link v-else @click="$emit('action', 'defer')">
                            <v-list-item-title>Defer Order</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="$emit('action', 'reset')">
                            <v-list-item-title>Reset Order</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card>
            <v-card flat>
                    <v-icon v-if="down_arrow()" @click.stop="$emit('expand')">mdi-chevron-down</v-icon>
                    <v-icon v-if="up_arrow()" @click.stop="$emit('close')">mdi-chevron-up</v-icon>
            </v-card>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
    props: ['order'],
    data: function () {
        return {
            locked: false,
            formHasErrors: false
        }
    },
    methods: {
        action: function (action) {
            this.$emit('action', action)
            setTimeout(() => this.locked = false, 1000)
        },
        down_arrow: function() {
            const layouts = this.layout + '|' + (this.order.layout ? this.order.layout : '')
            switch(layouts) {
				case 'table|': return true
				case 'table|table': return true
				case 'table|compact': return false
				case 'table|expanded': return false
				case 'compact|': return true
				case 'compact|compact': return true
				case 'compact|expanded': return false
				case 'expanded|': return false
				case 'expanded|compact': return true
				case 'expanded|expanded': return false
            } 
        },
        up_arrow: function() {
            const layouts = this.layout + '|' + (this.order.layout ? this.order.layout : '')
            switch(layouts) {
				case 'table|': return false
				case 'table|table': return false
				case 'table|compact': return true
				case 'table|expanded': return true
				case 'compact|': return false
				case 'compact|compact': return false
				case 'compact|expanded': return true
				case 'expanded|': return true
				case 'expanded|compact': return false
				case 'expanded|expanded': return true
            } 
        }
    },
    computed: {
        ...mapState({
            next_step (state, getters) {
                if(this.order.consignment && this.order.consignment.service) {
                    return getters[`Carriers/${this.order.consignment.service.carrier_type}/next_step`](this.order)
                } 
                if(this.order.rate_selected) {
                    return getters[`Carriers/${this.order.rate_selected.carrier_type}/next_step`](this.order)
                } 
                return 'disabled'
            }
        }),
		...mapGetters({
			layout: 'Settings/layout'
		}),
        breakpoint () {
            return this.$vuetify.breakpoint.name
        },
        layout: function () {
            return this.$store.getters[`Settings/order_layout`](this.order)
        },
        next_step_width () {
            switch(this.$vuetify.breakpoint.name) {
                case 'xs' : {
                    if(this.layout =='table') {
                        return '80px'
                    }
                    return '160px'
                }
                case 'sm' : {
                    if(this.layout =='table') {
                        return '110px'
                    }
                    return '160px'
                }
                case 'md' : {
                    if(this.layout =='table') {
                        return '110px'
                    }
                    return '150px'
                }
                case 'lg' : return '140px'
                case 'xl' : return '157px'
                default : return '100px'
            }
        },
        button_justify () {
            switch(this.layout) {
                case 'table'   : return 'justify-space-between'
                case 'compact' : return 'justify-end'
                case 'expanded': return 'justify-end'
            }
            return 'justify-end'
        },
        carrier_actions () {
            const carrier = this.order.consignment?.service? this.order.consignment.service.carrier_type : (this.order.rate_selected ? this.order.rate_selected.carrier_type : null)
            if(carrier) {
                return this.$store.getters[`Carriers/${carrier}/actions`](this.order)
            }
            return false
        },
    }
}
</script>

<style scoped>
    .skinny {
        min-width: 55px;
    }
</style>