<template>
    <div>
        <v-card class="mt-4" outlined>
            <v-toolbar color="indigo" dark>
                <v-toolbar-title>Product Dimensions</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <template v-if="not_activated == 6">
                    <v-row class="pa-3">
                        <v-alert outlined type="info" text>
                            <p>{{ info_text[0] }}</p>
                            <p>{{ info_text[1] }}</p>
                            <p>To create the necessary Shopify Metafields click the Activate Dimensions button below.</p>
                        </v-alert>
                    </v-row>
                    <v-row class="pa-3">
                        <v-btn class="text-none" color="primary" @click="activate">Activate Dimensions</v-btn> {{ not_activated }}
                    </v-row>
                </template>
                <template v-if="not_activated == 0">
                    <v-row class="pa-3">
                        <v-alert outlined type="info" text>
                            <p>{{ info_text[0] }}</p>
                            <p>{{ info_text[1] }}</p>
                            <p>{{ info_text[2] }}</p>
                        </v-alert>
                    </v-row>
                    <v-card elevation="4" class="ma-2 pa-3" cols="6" v-for="(fields, owner, index) in metafields" :key="index">
                        <v-card-title>{{ titles[owner] }}</v-card-title>
                        <v-card-text>
                            <v-row v-for="(values, fld, index) in fields" :key="index">
                                <v-col cols="3"> 
                                    <v-text-field v-model="values['name']" label="Field name" hide-details outlined dense @keydown="metafields_changed[owner][fld]=true"></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field v-model="values['description']" label="Field description" hide-details outlined dense @keydown="metafields_changed[owner][fld]=true"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-btn :disabled="!metafields_changed[owner][fld]" class="text-none" color="primary" @click="update(owner, fld)">Save</v-btn>
                                </v-col>
                                <v-col v-if="debug">
                                    <v-btn class="text-none" color="error" @click="remove(owner, fld)">Delete</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>
                <template v-if="not_activated < 6 && not_activated > 0">
                    <v-row class="pa-3">
                        <v-alert outlined type="info" text>
                            <p>{{ info_text[0] }}</p>
                            <p>{{ info_text[1] }}</p>
                            <p>{{ info_text[3] }}</p>
                        </v-alert>
                    </v-row>
                    <v-row class="pa-3">
                        <v-btn class="text-none" color="primary" @click="activate">Activate Dimensions</v-btn> {{ not_activated }}
                    </v-row>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import {getSessionToken} from '@shopify/app-bridge-utils';

export default {
    data: function () {
        return {
            info_text: [
                'Many carrier services require package dimensions to be declared to determine the cubic weight of a package. By entering the dimensions of your products a more accurate cubic weight can be calculated ensuring that a more accurate rate quote is obtained.',                
                'Product and variant dimensions are stored in Shopify Product and Variant Metafields and can be edited directly in your Shopify Admin Product or Variant pages. Metafields appear at the bottom of each product or variant page.',
                'The names and descriptions of the Metafields in use on your account appear below and can be edited if required. Please note, this is generally only necessary if metafield names clash with names used by other apps you have connected.',
                'One or more Metafields required for your account have been deleted or failed to create successfully. To try again click the button below. If the issue persists please contact our Support Team.'
            ],
            metafields: {
                PRODUCT: {
                    height: { name: 'Product Height (cm)', description: 'Product height for SellerDash shipping calculations', exists: false },
                    width: { name: 'Product Width (cm)', description: 'Product width for SellerDash shipping calculations', exists: false },
                    length: { name: 'Product Length (cm)', description: 'Product length for SellerDash shipping calculations', exists: false },
                },
                PRODUCTVARIANT: {
                    height: { name: 'Variant Height (cm)', description: 'Variant height for SellerDash shipping calculations', exists: false },
                    width: { name: 'Variant Width (cm)', description: 'Variant width for SellerDash shipping calculations', exists: false },
                    length: { name: 'Variant Length (cm)', description: 'Variant length for SellerDash shipping calculations', exists: false },
                }
            },
            metafields_changed: {
                PRODUCT: {
                    height: false,
                    width: false,
                    length: false
                },
                PRODUCTVARIANT: {
                    height: false,
                    width: false,
                    length: false
                }
            },
            titles: {
                PRODUCT: 'Product Metafields',
                PRODUCTVARIANT: 'Variant Metafields'
            },
            rules: {
                required: value => !!value || 'Required.',
                isNumber: value => !isNaN(value) || 'Must be a number.',
                numberOrEmpty: value => null || !isNaN(value) || 'Must be a number.'
            },
            formHasErrors: false,
        }
    },
    methods: {
        activate: function () {
            Object.keys(this.metafields).forEach(owner => {
                Object.keys(this.metafields[owner]).forEach(key => {
                    if(!this.metafields[owner][key].exists) {
                        this.createDefinitions(owner, key, this.metafields[owner][key].name, this.metafields[owner][key].description)
                    }
                })
            })
            axios.get(`${process.env.VUE_APP_API_SERVER}/api/shopify/enableDimensions.json`, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.$store.getters['Shopify/token']}`
                },
            })
        },
        update: function (owner, key) {
            this.updateDefinitions(owner, key, this.metafields[owner][key].name, this.metafields[owner][key].description)
            this.metafields_changed[owner][key] = false
        },
        remove: async function (owner, key) {
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            const query = `
            mutation DeleteMetafieldDefinition($id: ID!, $deleteAllAssociatedMetafields: Boolean!) {
                metafieldDefinitionDelete(id: $id, deleteAllAssociatedMetafields: $deleteAllAssociatedMetafields) {
                    deletedDefinitionId
                    userErrors {
                        field
                        message
                        code
                    }
                }
            }`;
            const variables = {
                id: this.metafields[owner][key].exists,
                deleteAllAssociatedMetafields: true
            }
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/graphql`, {
                graphql: {
                    query: query,
                    variables: variables
                }
            },
            {
                headers: { 
                    'Authorization': token
                }
            })
            .then(response => {
                if(!response.data.errors) {
                    this.metafields[owner][key].exists = ''    
                }
            })
        },
        createDefinitions: async function (owner, key, name, description) {
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            const query = `
                mutation createMetafieldDefinition($definition: MetafieldDefinitionInput!) {
                    metafieldDefinitionCreate(definition: $definition) {
                    createdDefinition {
                        id
                    }
                    userErrors {
                        field
                        message
                    }
                }}`;

            const variables = {
                definition: {
                    access: {
                        admin: "MERCHANT_READ_WRITE"
                    },
                    name: name,
                    namespace: "$app:shipping",
                    key: key,
                    pin: true,
                    description: description,
                    type: "number_decimal",
                    ownerType: owner
                }
            }
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/graphql`, {
                graphql: {
                    query: query,
                    variables: variables
                }
            },
            {
                headers: { 
                    'Authorization': token
                }
            })
            .then(response => {
                this.checkDefinitions(owner, key)  
            })
        },
        updateDefinitions: async function (owner, key, name, description) {
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            const query = `
                mutation metafieldDefinitionUpdate($definition: MetafieldDefinitionUpdateInput!) {
                    metafieldDefinitionUpdate(definition: $definition) {
                    updatedDefinition {
                        id
                    }
                    userErrors {
                        field
                        message
                    }
                }}`;

            const variables = {
                definition: {
                    access: {
                        admin: "MERCHANT_READ_WRITE"
                    },
                    name: name,
                    namespace: "$app:shipping",
                    key: key,
                    pin: true,
                    description: description,
                    ownerType: owner
                }
            }
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/graphql`, {
                graphql: {
                    query: query,
                    variables: variables
                }
            },
            {
                headers: { 
                    'Authorization': token
                }
            })
            .then(response => {
                this.checkDefinitions(owner, key)  
            })
        },
        getDefinition: async function (owner, key) {
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            const query = `{
                metafieldDefinitions(first: 100, namespace:"app--${this.appId}--shipping", ownerType:${owner}, key: "${key}" ) {
                    edges {
                        node {
                            name
                            ownerType
                            id
                            description
                        }
                    }
                }
            }`
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/graphql`, {
                graphql: {
                    query: query
                }
            },
            {
                headers: { 
                    'Authorization': token
                }
            })
            .then(response => {
                try {
                    if(response.data.data.metafieldDefinitions.edges.length > 0) {
                        this.metafields[owner][key].name = response.data.data.metafieldDefinitions.edges[0].node.name
                        this.metafields[owner][key].description = response.data.data.metafieldDefinitions.edges[0].node.description
                        this.metafields[owner][key].exists = response.data.data.metafieldDefinitions.edges[0].node.id
                    }
                } catch (e) {
                    console.log(e, response)
                }
            })

        },
        checkDefinitions: function (owner, key) {
            this.getDefinition(owner, key)
        }
    },
    mounted: function () {
        Object.keys(this.metafields).forEach(owner => {
            Object.keys(this.metafields[owner]).forEach(key => {
                this.getDefinition(owner, key)
            })
        })
    },
    computed: {
        ...mapState('LiveRates', ['settings']), 
        appId: function () {
          return process.env.VUE_APP_ID  
        }, 
        not_activated: function () {
            let inactive = 0
            Object.keys(this.metafields).forEach(owner => {
                Object.keys(this.metafields[owner]).forEach(key => {
                    if(!this.metafields[owner][key].exists) {
                        inactive++
                    }
                })
            })
            return inactive
        },
        debug: function () {
            return process.env.VUE_APP_DEBUG == 'true' ? true : false
        }
    }
}
</script>

