const DB_NAME = 'quotesdb';
const DB_VERSION = 1;
let DB;

export default {

	async getDb() {
		return new Promise((resolve, reject) => {
//            return reject('Error'); /*
			if(DB) { return resolve(DB); }
			let request = window.indexedDB.open(DB_NAME, DB_VERSION);
			
			request.onerror = e => {
				return reject('Error');
			};
	
			request.onsuccess = e => {
				DB = e.target.result;
				return resolve(DB);
			};
			
			request.onupgradeneeded = e => {
				let db = e.target.result;
				db.createObjectStore("quotes", { autoIncrement: true, keyPath:'id' });
			}; 
		});
	},
	async deleteQuote(id) {
        try {
            let db = await this.getDb();

            return new Promise(resolve => {

                let trans = db.transaction(['quotes'],'readwrite');
                trans.oncomplete = () => {
                    resolve();
                };

                let store = trans.objectStore('quotes');
                store.delete(id);
            });	
        } catch (err) {
            return err
        }
	},
	async getQuotes() {
        try {
            let db = await this.getDb();

            return new Promise(resolve => {

                let trans = db.transaction(['quotes'],'readonly');
                trans.oncomplete = () => {
                    resolve(quotes);
                };
                
                let store = trans.objectStore('quotes');
                let quotes = [];
                
                store.openCursor().onsuccess = e => {
                    let cursor = e.target.result;
                    if (cursor) {
                        quotes.push(cursor.value)
                        cursor.continue();
                    }
                };

            });
        } catch (err) {
            return err
        }
	},

	async saveQuote(quote) {
        try {
            let db = await this.getDb();

            return new Promise(resolve => {

                let trans = db.transaction(['quotes'],'readwrite');
                trans.oncomplete = () => {
                    resolve();
                };

                let store = trans.objectStore('quotes');
                store.put(quote);

            });
        } catch (err) {
            return err
        }
	
	}

}