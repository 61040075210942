
// Quotes
import Vue from 'vue'
import axios from 'axios'
import quotesdb from './quotesdb';
import orderTools from "@/V2/helpers/orderTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
	namespaced: true,
	modules: {
	},
	state: {
        requoting: false,
        quotes: {},
        cached_quotes: [],
        quote_check_interval: 0,
        quotes_to_save: [],
        quotes_required: {},
        intervals: {}
	},
	mutations: {
        create_quotes: function(state, orders) {
            // Create a quote 
            orders.forEach( ( order ) => {
                Vue.set(state.quotes, order.id, {
                    quotes: {},
                    selected: {}
                })
            })
        },
        update_quote: function(state, payload) {
            // Update a quote 
            Vue.set(state.quotes, payload.id, payload.quotes)
        },
        reset_quote: function(state, order) {
            Object.keys(state.quotes[order.id].quotes).forEach( (carrier_id) => {
                Vue.delete(state.quotes[order.id].quotes, carrier_id)
            })
        },
        reset_carrier_quote: function(state, payload) {
            Vue.delete(state.quotes[payload.order.id].quotes, payload.carrier)
        },
        save: function(state, payload) {
            Vue.set(state.quotes[payload.id].quotes, payload.carrier_id, payload.quote)
        },
        add_to_queue_quotes_required: function(state, payload) {
            Vue.set(state.quotes_required, payload.id, true)
        },
        delete_from_queue_quotes_required: function(state, payload) { 
            Vue.delete(state.quotes_required, payload)
        },
        select_quote: function (state, payload) {
            Vue.set(state.quotes[payload.order.id], 'selected', payload.selected)  
        },
        reset_selected: function (state, payload) {
            Vue.set(state.quotes[payload], 'selected', {})
        },
        requoting: function (state, payload) {
            state.requoting = payload
        }
	},
	getters: {
		quotes: state => {
			return state.quotes
        },
		quotes_required: state => {
			return state.quotes_required
        },
        quote: (state) => (order) => {
            return state.quotes[order.id]
        },
        requoting: (state) => {
            return state.requoting
        }

	},
	actions: {
        init: async function ({ commit, dispatch, rootGetters }) {
            commit('Quotes/create_quotes', rootGetters['Orders/orders'], { root: true })
            await dispatch('get_cached_quotes')
            await dispatch('update_quotes_from_cache')
            // need to clean up cache
            commit('set_loading', { status: true, message: 'Validating quotes', step: 'Validating Quotes' }, {root: true })
            await dispatch('check_quotes_for_changes')
            commit('set_loading', { status: true, message: 'Requesting new quotes', step: 'Requesting Quotes' }, {root: true })
            await dispatch('build_quotes_required_queue')
            await dispatch('process_quotes_required_queue')
            await dispatch('process_remote_quotes_queue')
            dispatch('check_if_quotes_finished')
        },
		check_if_quotes_finished: function({ state, commit, dispatch, rootGetters }) {
			const remaining = Object.keys(rootGetters['Quotes/quotes_required']).length
			if(remaining) {
				setTimeout( () => { dispatch('check_if_quotes_finished') }, remaining * 100); 
			} else {
                commit('set_loading', { status: false }, { root: true })
                commit('requoting', false)
			}
		},
        check_quotes_for_changes: async function ({ rootGetters, state, dispatch, commit }) {
            try {
                const connected_carriers = rootGetters['Carriers/carriers']
                for(const id in state.quotes) {
                    const order = rootGetters['Orders/order'](id)
                    if(state.quotes[id].quotes) {
                        for(const carrier in state.quotes[id].quotes) {
                            const cached_request = state.quotes[id].quotes[carrier].request
                            const carrier_type = carrier.split('|')[0]
                            let request = ''
                            if(connected_carriers[carrier]){
                                request = await dispatch(`Carriers/${carrier_type}/get_rate_request_body`, {order: order, carrier_id: carrier}, { root: true })
                            }
                            if(cached_request !== JSON.stringify(request)) {
                                dispatch('reset_quote', order )
                                dispatch('delete_cached_quote', id)
                            }
                        }
                    }
                }
    
            } catch (e) {
                console.log(e)
            }
        }, 
        update_quotes_from_cache: function ({ rootGetters, dispatch, state, commit }, quote) {
            state.cached_quotes.forEach( ( quote ) => {
                if(state.quotes[quote.id]){
                    commit('update_quote', quote)
                }
            })
        },
        build_quotes_required_queue: function ({ state, rootGetters, commit, dispatch }) {
            const carriers = rootGetters['Carriers/carriers']
            try {
                Object.keys(state.quotes).forEach( ( order_id ) => {
                    Object.keys(carriers).forEach( ( carrier_id ) => {
                        if(!state.quotes[order_id].quotes[carrier_id]) {
                            commit('add_to_queue_quotes_required', { id: `${order_id}|${carrier_id}` })
                        }
                    })
                })
            } catch (e) {
                orderTools.log_error({message: 'Debug', stack: 'quotes/build_quotes_required_queue', data: {carriers: carriers, quotes: state.quotes}})            
            }
        },
        process_quotes_required_queue: async function ({ state, commit, dispatch, rootGetters }) {
            const max = 8
            const quotes_required = Object.keys(state.quotes_required)

            if(quotes_required.length) {
                const max_concurrent = quotes_required.length > max ? max : quotes_required.length
                const requests: Array<any> = []
                for(let i = 0; i < max_concurrent; i++) {
                    const quote_id = quotes_required[i]
                    if(quote_id){
                        const q = quote_id.split('|')
                        const id = q[0]
                        const carrier_id = `${q[1]}|${q[2]}`
                        const order = rootGetters['Orders/order'](id)
    
                        const request = dispatch(`Carriers/${q[1]}/get_quote`, { order: order, carrier_id: carrier_id }, { root: true }).then(quote => {
                            if(quote.statusCode != 429){
                                dispatch('Quotes/save_quote', { id: id, carrier_id: carrier_id, quote: quote}, {root: true} )
                                commit('delete_from_queue_quotes_required', quote_id)
                            } else {
                                console.log('Too many requests', quotes_required.length)
                            }
                        }) 
                        requests.push(request)
                    } 
                }
                Promise.all(requests).then( (values) => {
                    dispatch('process_quotes_required_queue')
                })
            } else {
                commit('requoting', false)
                dispatch('check_if_quotes_finished')
                setTimeout( () => { dispatch('process_quotes_required_queue') }, 1000);
            }
        },
        select_quote: function ( { state, commit, dispatch }, payload) {
            commit('Orders/set_manually_selected', payload, {root: true})
            dispatch('Orders/save', payload.order, {root: true})
            commit('select_quote', payload)
            dispatch('save_quote_to_cache', payload.order.id) 
        },
        reset_quote: async function ({ commit, dispatch, rootGetters }, order) {
            commit('requoting', true)
            commit('reset_quote', order)
            dispatch('delete_cached_quote', order.id)
            const carriers = rootGetters['Carriers/carriers']
            Object.keys(carriers).forEach( ( carrier_id ) => {
                commit('add_to_queue_quotes_required', { id: `${order.id}|${carrier_id}` })
            })
            
        },
        reset_carrier_quotes: function ({commit, dispatch, rootGetters}, carrier) {
            const orders = rootGetters['Orders/orders']
            commit('requoting', true)
            orders.forEach( ( order ) => {
                commit('reset_carrier_quote', {order: order, carrier: carrier})
                commit('add_to_queue_quotes_required', { id: `${order.id}|${carrier}` })
            })
        },
        save_quote: function ({ state, commit, dispatch }, payload) {
            // We do this after requesting a quote from a carrier
            // Apply it to the order even if it failed (so we can see the error)
            commit('save', payload)
            // Only cache it if we actually got a quote back
            if(payload.quote.data) {
                dispatch('save_quote_to_cache', payload.id) 
            }
        },

        save_quote_to_cache: async function ({ state }, id) {
            // Save the quote to IndexedDB
            await quotesdb.saveQuote({ id: id.toString(), quotes: state.quotes[id] })
            // Save to DynamoDB too via queue
            state.quotes_to_save.push({ id: id.toString(), quotes: state.quotes[id] })
        },
        process_remote_quotes_queue: async function ({ state, dispatch, rootGetters }) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
            let delay = 100
            const quote = state.quotes_to_save.shift()
            if(quote) {
                axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/quotes/save`, {
                    order: quote.id, 
                    data: quote.quotes
                },
                {
                    headers: { 
                        'Authorization': token,
                    }
                })
                .catch(err => {
                    orderTools.log_error({message: 'Network Error', stack: 'quotes/process_remote_quotes_queue', data: err})
                })
    
            } else {
                delay = 5000
            }
            setTimeout(function(){ 
                dispatch('process_remote_quotes_queue')
            }, delay) 
        },
        delete_cached_quote: async function ({ rootGetters }, id) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
            // Delete the quote from IndexedDB
            await quotesdb.deleteQuote(id); 
            // Delete from DynamoDB too
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/quotes/delete`, {
                order: id 
            },
            {
                headers: { 
                    'Authorization': token,
                }
            })
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'quotes/delete_cached_quote', data: err})
			})

        },
        get_cached_quotes: async function ({ state, rootGetters, dispatch }) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
            // Get quotes from IndexedDB first
            const quotes = await quotesdb.getQuotes();
            if(Array.isArray(quotes)){
                // Received an array of results so looks ok
                quotes.forEach(quote => {
                    state.cached_quotes.push(quote);
                })
            } else {
                // Must be an error. get remote quotes from DynamoDB
                return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/quotes/list`, { },
                {
                    headers: { 
                        'Authorization': token,
                    }
                }).then(response => {
                    if(response.data.quotes && response.data.quotes.length){
                        response.data.quotes.forEach(quote => {
                            state.cached_quotes.push({
                                id: quote.order,
                                quotes: quote.data   
                            });
                        })
                    }                    
                })
                .catch(err => {
                    orderTools.log_error({message: 'Network Error', stack: 'quotes/get_cached_quotes', data: err})
                })
                        
            }

        },
        validate_address: function ({ rootGetters }, order) {
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/address/validate`, {
                suburb: `${order.shipping_address.city}|${orderTools.abbreviated_state(order.shipping_address.province_code)}|${order.shipping_address.zip}`
            },
            {
                headers: { 
                    'Content-Type': 'application/json', 
                }
            }).then(response => {
                if(response.data){
                    return null
                }
                return {error: true, message: 'Address does not validate'}
            })
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'quotes/validate_address', data: err})
			})

		}

	}
}
