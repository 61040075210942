export const packages = [
    {
        id: 'flat_rate_box_sm',
        name: 'Flat Rate Box (Small)',
        short_name: 'Box (S)',
        type: 'box',
        domestic_only: true,
        min_weight: 0,
        max_weight: 5000,
        dimensions: [22, 16, 7],
        services: ['BE1PB1', 'BE9PB1'] 
    },
    {
        id: 'flat_rate_box_md',
        name: 'Flat Rate Box (Medium)',
        short_name: 'Box (M)',
        type: 'box',
        domestic_only: true,
        min_weight: 0,
        max_weight: 5000,
        dimensions: [24, 19, 12],
        services: ['BE1PB2', 'BE9PB2'] 
    },
    {
        id: 'flat_rate_box_lg',
        name: 'Flat Rate Box (Large)',
        short_name: 'Box (L)',
        type: 'box',
        domestic_only: true,
        min_weight: 0,
        max_weight: 5000,
        dimensions: [39, 28, 14],
        services: ['BE1PB3', 'BE9PB3'] 
    },
    {
        id: 'flat_rate_box_xl',
        name: 'Flat Rate Box (Extra Large)',
        short_name: 'Box (XL)',
        type: 'box',
        domestic_only: true,
        min_weight: 0,
        max_weight: 5000,
        dimensions: [44, 27.7, 16.8],
        services: ['BE1PB4', 'BE9PB4'] 
    },
    {
        id: 'flat_rate_satchel_sm',
        name: 'Flat Rate Satchel (Small)',
        short_name: 'Sat (S)',
        type: 'satchel',
        domestic_only: true,
        min_weight: 0,
        max_weight: 5000,
        dimensions: [35.5, 22.5, null],
        services: ['BE1P05', 'BE9P05'] 
    },
    {
        id: 'flat_rate_satchel_md',
        name: 'Flat Rate Satchel (Medium)',
        short_name: 'Sat (M)',
        type: 'satchel',
        domestic_only: true,
        min_weight: 0,
        max_weight: 5000,
        dimensions: [39, 27, null],
        services: ['BE1P10', 'BE9P10'] 
    },
    {
        id: 'flat_rate_satchel_lg',
        name: 'Flat Rate Satchel (Large)',
        short_name: 'Sat (L)',
        type: 'satchel',
        domestic_only: true,
        min_weight: 0,
        max_weight: 5000,
        dimensions: [41.5, 31.5, null],
        services: ['BE1P30', 'BE9P30'] 
    },
    {
        id: 'flat_rate_satchel_xl',
        name: 'Flat Rate Satchel (Extra Large)',
        short_name: 'Sat (XL)',
        type: 'satchel',
        domestic_only: true,
        min_weight: 0,
        max_weight: 5000,
        dimensions: [51, 44, null],
        services: ['BE1P50', 'BE9P50'] 
    },
]