<template>
    <v-card class="mt-4" outlined>
        <v-toolbar color="blue-grey lighten-4" dense>
            <v-toolbar-title>Quote Details - Order {{ order.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="getRates" icon><v-icon right dark>mdi-refresh</v-icon></v-btn>
        </v-toolbar>
        <v-container fluid class="px-0">
            <v-card class="elevation-4 ma-4 pb-8">
                <v-toolbar dense color="teal" dark>
                    <v-toolbar-title>Rate Quoting Test Results</v-toolbar-title>
                </v-toolbar>
                <v-row no-gutters align="stretch">
                    <v-col cols="12" class="pa-2">
                        <v-card class="elevation-4 ma-4" style="height: 100%;">
                            <v-card-title>Shipping To</v-card-title>   
                            <v-card-subtitle>
                                <v-row>
                                    <v-col>
                                        {{ order.shipping_address.address1 }} {{ order.shipping_address.address2 }}<br>
                                        {{ order.shipping_address.city }} {{ order.shipping_address.province_code }} {{ order.shipping_address.zip }} {{ order.shipping_address.country_code }}
                                    </v-col>
                                </v-row>
                            </v-card-subtitle> 
                        </v-card>
                    </v-col>    
                    <v-col cols="12" class="pa-2">
                        <v-card class="elevation-4 ma-4" style="height: 100%;">
                            <v-card-title>Calculated Checkout Rates</v-card-title>  
                            <v-card-text class="py-0">
                                <v-row no-gutters v-for="(rate, i) in aggregateRates" :key="i">
                                    <v-col>
                                        {{ rate.service_name }}
                                    </v-col>
                                    <v-col class="text-right">
                                        ${{  rate.total_price / 100 | formatNumber(2)}}
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <div class="text-center" v-if="loading">
                                <v-progress-circular :size="70" :width="7" color="teal" indeterminate></v-progress-circular>  
                            </div>  
                        </v-card>   
                    </v-col>
                </v-row>    
            </v-card>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-card class="elevation-4 ma-4">
                        <v-toolbar dense color="purple" dark>
                            <v-toolbar-title>How Checkout Rates Were Calculated</v-toolbar-title>
                        </v-toolbar>
                        <v-alert v-if="locationsWithLineItems.length > 1" type="warning" outlined dense dismissible border="left" class="ma-2">Note: As this order would require items to be shipped from more than one location Shopify would make multiple quote requests and aggregate the results in checkout. Any rules defined would be applied to each quote request.</v-alert>
                        <v-row no-gutters>
                            <v-col>
                                <v-card v-for="(item, index) in locationsWithLineItems" :key="index" class="mt-2">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card class="ma-2" outlined>
                                                <v-toolbar dense color="blue lighten-1" dark>
                                                    <v-toolbar-title>Items Shipping From: {{ item.location.name }}</v-toolbar-title>
                                                </v-toolbar>
                                                <v-card-subtitle>{{ item.location.address1 }} {{ item.location.address2 }} {{ item.location.city }} {{ item.location.province_code }} {{ item.location.zip }} {{ item.location.country_code }}</v-card-subtitle>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <v-card-text class="py-0">
                                                            <v-row no-gutters class="font-weight-bold">
                                                                <v-col cols="1" class="pr-4 text-right">Qty</v-col>
                                                                <v-col cols="7" class="pl-2">Title</v-col>
                                                                <v-col cols="2" class="text-right">Net Weight</v-col>
                                                                <v-col cols="2" class="text-right">Total Weight</v-col>
                                                            </v-row>
                                                            <v-row no-gutters v-for="(product, idx) in item.lineItems" :key="idx">
                                                                <v-col cols="1" class="pr-4 text-right">{{ product.quantity }}</v-col>
                                                                <v-col cols="7" class="pl-2">{{ product.name }}<div v-if="product.sku"><small>{{ product.sku }}</small></div></v-col>
                                                                <v-col cols="2" class="text-right">{{ product.grams / 1000 | formatNumber(2) }}kg</v-col>
                                                                <v-col cols="2" class="text-right">{{ (product.grams * product.quantity) / 1000 | formatNumber(2) }}kg</v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card class="ma-2" outlined v-if="quotes[item.location_id]">
                                                <v-card-title>Packages Required</v-card-title>
                                                <v-card-text class="py-0">
                                                    <v-data-table
                                                        :items="quotes[item.location_id].debug[0].packages"
                                                        :headers="pkgHeaders"
                                                        :items-per-page="quotes[item.location_id].debug[0].packages.length"
                                                        show-expand
                                                        :single-expand="true"
                                                        item-key="packageNumber"
                                                        :hide-default-footer="true"
                                                        class="elevation-4 mb-4"
                                                        dense
                                                        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                                                    >
                                                        <template v-slot:[`item.totalActualWeight`]="{ item }">
                                                            {{ item.totalActualWeight / 1000 | formatNumber(2) }}
                                                        </template>
                                                        <template v-slot:[`item.totalCubicWeight`]="{ item }">
                                                            {{ item.totalCubicWeight / 1000 | formatNumber(2) }}
                                                        </template>
                                                        <template v-slot:[`item.items`]="{ item }">
                                                            {{ item.items.length }}
                                                        </template>
                                                        <template v-slot:[`item.quote`]="{ item }">
                                                            <v-row no-gutters v-for="(quote, q) in sortPrices(item.quote.formatted)" :key="q">
                                                                <v-col>{{ quote.productType }}</v-col>
                                                                <v-col class="text-right">{{  quote.price | thousands(2) }}</v-col>
                                                            </v-row>
                                                        </template>
                                                        <template v-slot:expanded-item="{headers, item }">
                                                            <td :colspan="headers.length">
                                                                <v-data-table
                                                                    :items="item.items"
                                                                    :headers="itemHeaders"
                                                                    :items-per-page="item.items.length"
                                                                    :hide-default-footer="true"
                                                                    class="elevation-4 my-4"
                                                                >
                                                                    <template v-slot:[`item.totalActualWeight`]="{ item }">
                                                                        {{ item.totalActualWeight / 1000 | formatNumber(2) }}
                                                                    </template>
                                                                    <template v-slot:[`item.totalCubicWeight`]="{ item }">
                                                                        {{ item.totalCubicWeight / 1000 | formatNumber(2) }}
                                                                    </template>
                                                                </v-data-table>
                                                            </td>
                                                        </template>
                                                    </v-data-table>
                                                </v-card-text>  
                                            </v-card>
                                            <v-alert v-if="!settings.selected_carriers.length" type="error" outlined dense border="left" class="mt-2 mb-2">You need to select a carrier in the CARRIER SETTINGS page to test rate quotes.</v-alert>
                                            <v-card class="ma-2" outlined v-if="quotes[item.location_id]">
                                                <v-card-title>Base Rate Quotes for all Packages</v-card-title>
                                                <v-card-text class="py-0">
                                                    <v-row no-gutters v-for="(rate, i) in quotes[item.location_id].evaluationSummary" :key="i">
                                                        <v-col>
                                                            {{ rate.originalServiceName }}
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            ${{  rate.originalRate / 100 | thousands(2)}}
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>  
                                            </v-card>
                                            <v-card class="ma-2" outlined v-if="quotes[item.location_id]">
                                                <v-card-title>Rules Applied</v-card-title>
                                                <v-card-text class="py-0" v-if="quotes[item.location_id].evaluationSummary">
                                                    <v-row no-gutters v-for="(rate, i) in quotes[item.location_id].evaluationSummary" :key="i" class="mb-4">
                                                        <v-col cols="12">
                                                            <span class="text-h6">{{ rate.originalServiceName }}</span>
                                                            <div class="pl-4" v-if="!rate.rulesApplied.length">No Rules Applied</div>
                                                            <v-row>
                                                                <v-col v-for="(rule, r) in rate.rulesApplied" :key="r">
                                                                    <div class="pl-4"><span class="font-weight-bold">Rule Name:</span> {{ rule.rule }}</div>
                                                                    <div class="pl-4 mt-4 font-weight-bold">
                                                                        When all of the following conditions are true
                                                                    </div>
                                                                    <div class="pl-8" v-for="(condition, c) in rule.conditions" key="c">
                                                                        {{ condition.title || get(fields, condition.field).name }} {{ condition.title ? '' : 'is ' + get(operators[get(fields, condition.field).operator], condition.operator).name }} {{ condition.value }}
                                                                    </div>
                                                                    <v-row no-gutters class="font-weight-bold mt-4">
                                                                        <v-col cols="8" class="pl-4">Apply the action</v-col>
                                                                        <v-col cols="2" class="text-right">Value</v-col>
                                                                        <v-col cols="2" class="text-right">New Price</v-col>
                                                                    </v-row>
                                                                    <v-row no-gutters v-for="(action, a) in rule.actionsApplied" :key="a + i">
                                                                        <v-col cols="8" class="pl-8">
                                                                            {{ action.title || get(actions, action.action).name }} 
                                                                        </v-col>
                                                                        <v-col cols="2" class="py-0 text-right" v-if="get(actions, action.action).value_required">
                                                                            {{ action.value }}
                                                                        </v-col>
                                                                        <v-col cols="2" class="py-0 text-right">
                                                                            {{ action.newPrice / 100 | thousands(2) }}
                                                                        </v-col>
                                                                    </v-row>
                                                                    
                                                                </v-col>

                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                 
                                            </v-card>
                                            <v-card class="ma-2" outlined v-if="quotes[item.location_id]">
                                                <v-card-title>Final Rate Quotes for all Packages</v-card-title>
                                                <v-card-text class="py-0">
                                                    <v-row no-gutters v-for="(rate, i) in quotes[item.location_id].rates" :key="i">
                                                        <v-col>
                                                            {{ rate.service_name }}
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            ${{  rate.total_price / 100 | thousands(2)}}
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>  
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-card outline>
            <Response :data="quotes"/>
        </v-card>
    </v-card>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import axios from 'axios'
import { getSessionToken } from '@shopify/app-bridge-utils';
import {graphql} from "@/V2/helpers/shopify";
import Response from '@/V2/components/Utilities/Response'
import liveRates from "@/V2/helpers/liveRates";
import settings from '@/V2/store/settings';

export default {
    props: ['order'],
    data: function () {
        return {
            quotes: {},
            liveRates: liveRates,
            pkgHeaders: [
                { text: 'Package', value: 'packageNumber', align: 'end' },
                { text: 'Actual Weight (kg)', value: 'totalActualWeight', align: 'end' },
                { text: 'Cubic Weight (kg)', value: 'totalCubicWeight', align: 'end' },
                { text: 'Line Items', value: 'items', align: 'end' },
                { text: 'Quotes', value: 'quote' },
                { text: '', value: 'data-table-expand'}
            ],
            itemHeaders: [
                { text: 'Name', value: 'name' },
                { text: 'Units', value: 'units', align: 'end' },
                { text: 'Total Actual Weight', value: 'totalActualWeight', align: 'end' },
                { text: 'Total Cubic Weight', value: 'totalCubicWeight', align: 'end' },
            ],
            loading: false
        }
    },
    components: {
        Response
    },
    watch: {
        locationsWithLineItems: {
            handler(newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.getRates()
                }
            },
            deep: true
        }
    },
    methods: {
        getRates: function() {
            this.loading = true
            this.quotes = {};
            if (this.settings.selected_carriers.length > 0 && Object.keys(this.locationsWithLineItems).length > 0) {
                this.locationsWithLineItems.forEach(item => {
                    liveRates.request(liveRates.build_request(this.order, item)).then(response => {
                        response.rates.sort((a, b) => a.total_price - b.total_price);
                        response.evaluationSummary.sort((a, b) => a.originalRate - b.originalRate);
                        Vue.set(this.quotes, item.location_id, response);
                        this.loading = false
                    });
                });
            }
        },
        findLocationById: function (fulfillmentOrders, lineItemId) {
            for (const order of fulfillmentOrders) {
                for (const lineItem of order.line_items) {
                    if (lineItem.line_item_id === lineItemId) {
                        return order.assigned_location;
                    }
                }
            }
            return null;
        },
        sortPrices: function (prices) {
            const arr = Object.values(prices);
            return arr.sort((a, b) => a.price - b.price);
        },
        get: function (options, val) {
            if(val) {
                return options.find(opt => {
                    return opt.id == val
                })
            } 
            return {}
        },
    },
    computed: {
        ...mapState('LiveRates', ['settings', 'fields', 'operators', 'actions']), 
        appId: function () {
          return process.env.VUE_APP_ID  
        }, 
        locationsWithLineItems() {
            const locationsMap = {};
            const itemTracking = {};

            this.order.line_items.forEach(item => {
                itemTracking[item.id] = item.quantity;
            });

            if(this.order.fulfillment_orders) {
                this.order.fulfillment_orders.forEach(fulfillmentOrder => {
                    const locationKey = JSON.stringify(fulfillmentOrder.assigned_location);
                    fulfillmentOrder.line_items.forEach(lineItem => {
                        if (itemTracking[lineItem.line_item_id] && itemTracking[lineItem.line_item_id] > 0) {
                            if (!locationsMap[locationKey]) {
                                locationsMap[locationKey] = {
                                    location_id: fulfillmentOrder.assigned_location_id,
                                    location: fulfillmentOrder.assigned_location,
                                    lineItems: []
                                };
                            }
                            const quantityToAdd = Math.min(lineItem.quantity, itemTracking[lineItem.line_item_id]);
                            const detailedLineItem = this.order.line_items.find(item => item.id === lineItem.line_item_id);
                            if (detailedLineItem) {
                                locationsMap[locationKey].lineItems.push({
                                    ...detailedLineItem,
                                    quantity: quantityToAdd
                                });
                                itemTracking[lineItem.line_item_id] -= quantityToAdd;
                            }
                        }
                    });
                });

                return Object.values(locationsMap);
            } 
            return {}
        },
        aggregateRates() {
            const aggregatedRates = {};

            Object.values(this.quotes).forEach(location => {
                location.rates.forEach(rate => {
                const key = `${rate.service_name}-${rate.service_code}`;
                if (!aggregatedRates[key]) {
                    aggregatedRates[key] = {
                        service_name: rate.service_name,
                        service_code: rate.service_code,
                        total_price: 0,
                        currency: rate.currency
                    };
                }
                aggregatedRates[key].total_price += rate.total_price;
                });
            });

            return Object.values(aggregatedRates);
        }      
    }
}
</script>

