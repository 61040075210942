<template>
    <div>
        <div v-if="layout == 'expanded'">
            <v-card flat outlined class="pa-2 mt-0" v-if="order.shipping_address && !editing">

                <v-card-title>
                    <v-fab-transition>
                        <v-btn color="primary mt-9" fab dark small absolute top right @click="edit">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </v-fab-transition>
                    Ship to:
                </v-card-title>

                <v-card-text class="pl-6" @click="edit()" v-if="order.shipping_address">
                    <div v-if="show('customer', 'name')">
                        <v-card-text class="clickable caption font-weight-bold py-0 primary--text">{{ order.shipping_address.company }}</v-card-text>
                        <v-card-text class="clickable caption font-weight-bold py-0 primary--text">{{ order.shipping_address.first_name }} {{ order.shipping_address.last_name }}
                            <v-tooltip bottom v-if="show('customer', 'order_count') && order.customer && orders_count > 1">
                                <template v-slot:activator="{ on }">
                                    <v-chip v-if="orders_count > 1" v-on="on" class="ma-2" x-small color="green" text-color="white">
                                        {{ orders_count }}
                                    </v-chip>
                                </template>
                                <span>Customer has had {{ orders_count }} orders!</span>
                            </v-tooltip>
                        </v-card-text>
                    </div>
                    <div v-if="show('customer', 'address')">
                        <v-card-text class="clickable caption py-0 primary--text">{{ order.shipping_address.address1 }}</v-card-text>
                        <v-card-text class="clickable caption py-0 primary--text">{{ order.shipping_address.address2 }}</v-card-text>
                        <v-card-text class="clickable caption py-0 primary--text">{{ order.shipping_address.city }} {{ order.shipping_address.province_code }} {{ order.shipping_address.zip }} <span v-if="show('customer', 'country')">{{ order.shipping_address.country_code }}</span></v-card-text>
                    </div>
                    <div v-if="show('customer', 'email')">
                        <v-card-text class="clickable caption py-0 primary--text">{{ order.email }}</v-card-text>
                    </div>
                    <div v-if="show('customer', 'phone')">
                        <v-card-text class="clickable caption py-0 primary--text">{{ order.shipping_address.phone ? order.shipping_address.phone : (order.customer ? order.customer.phone : '') }}</v-card-text>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <div v-else @click="edit()">
            <div v-if="order.shipping_address">
                <div v-if="!editing && show('customer', 'name')">
                    <v-card-text class="clickable caption font-weight-bold py-0 primary--text">{{ order.shipping_address.company }}</v-card-text>
                    <v-card-text class="clickable caption font-weight-bold py-0 primary--text">{{ order.shipping_address.first_name }} {{ order.shipping_address.last_name }}
                        <v-tooltip bottom v-if="show('customer', 'order_count') && order.customer && orders_count > 1">
                            <template v-slot:activator="{ on }">
                                <v-chip v-if="orders_count > 1" v-on="on" class="ma-2" x-small color="green" text-color="white">
                                    {{ orders_count }}
                                </v-chip>
                            </template>
                            <span>Customer has had {{ orders_count }} orders!</span>
                        </v-tooltip>
                    </v-card-text>
                </div>
                <div v-if="!editing && show('customer', 'address')">
                    <v-card-text class="clickable caption py-0 primary--text">{{ order.shipping_address.address1 }}</v-card-text>
                    <v-card-text class="clickable caption py-0 primary--text">{{ order.shipping_address.address2 }}</v-card-text>
                    <v-card-text class="clickable caption py-0 primary--text">{{ order.shipping_address.city }} {{ order.shipping_address.province_code }} {{ order.shipping_address.zip }} <span v-if="show('customer', 'country')">{{ order.shipping_address.country_code }}</span></v-card-text>
                </div>
                <div v-if="show('customer', 'email')">
                    <v-card-text class="clickable caption py-0 primary--text">{{ order.email }}</v-card-text>
                </div>
                <div v-if="show('customer', 'phone')">
                    <v-card-text class="clickable caption py-0 primary--text">{{ order.shipping_address.phone ? order.shipping_address.phone : (order.customer ? order.customer.phone : '') }}</v-card-text>
                </div>
            </div>
        </div>

        <v-card flat outlined class="pa-2 ml-2 mt-0" v-if="order.shipping_address && editing">
            <v-row>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.company" outlined dense hide-details label="Company" class="mb-0 py-0"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.first_name" outlined dense hide-details label="First Name" class="mb-0 py-0"></v-text-field>
                </v-col>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.last_name" outlined dense hide-details label="Last Name" class="mb-0"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.address1" outlined dense hide-details label="Address (Line 1)" class="mb-0"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.address2" outlined dense hide-details label="Address (Line 2)" class="mb-0"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.city" outlined dense hide-details label="City" class="mb-0"></v-text-field>
                </v-col>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.province_code" outlined dense hide-details label="State/Province" class="mb-0"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.zip" outlined dense hide-details label="Postcode/Zip" class="mb-0"></v-text-field>
                </v-col>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.country_code" outlined dense hide-details label="Country" class="mb-0"></v-text-field>        
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-1">
                    <v-text-field v-model="shipping_address.phone" outlined dense hide-details label="Telephone" class="mb-0"></v-text-field>        
                </v-col>
            </v-row>
            <v-card-actions v-if="editing">
                <v-spacer></v-spacer>
                <v-btn small @click="editing = false">Cancel</v-btn>
                <v-btn small @click="save" color="primary">Save</v-btn>
            </v-card-actions>
        </v-card>


        <v-alert v-if="order.errors && order.errors.address" text outlined dense icon="mdi-alert" type="error" class="mt-2">{{ order.errors.address }}</v-alert>
        
        <v-snackbar v-model="alert.status" :color="alert.color" top :timeout="alert.timeout">
            {{ alert.message }}
            <ul v-if="alert.error">
                <li> {{ alert.error }} </li>
            </ul>
            <template v-slot:action="{ attrs }">
                <v-btn outlined v-bind="attrs" @click="snackbar = false">Close</v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import mergeTools from "@/V2/helpers/mergeTools";

export default {
    name: 'Customer',
    props: ['order'],
    data: function () {
        return {
            editing: false,
            shipping_address: {
                company: '',
                first_name: '',
                last_name: '',
                address1: '',
                address2: '',
                city: '',
                province_code: '',
                zip: '',
                country_code: '',
                email: '',
                phone: ''             
            },
            alert: {
                status: false,
                message: '',
                error: null,
                timeout: 2500,
                color: 'success'
            }
        }
    },
    methods: {
        edit: function () {
            this.shipping_address = {
                company: this.order.shipping_address.company,
                first_name: this.order.shipping_address.first_name,
                last_name: this.order.shipping_address.last_name,
                address1: this.order.shipping_address.address1,
                address2: this.order.shipping_address.address2,
                city: this.order.shipping_address.city,
                province_code: this.order.shipping_address.province_code,
                zip: this.order.shipping_address.zip,
                country_code: this.order.shipping_address.country_code,
                phone: this.order.shipping_address.phone ? this.order.shipping_address.phone : (this.order.customer ? this.order.customer.phone : '')
            }
            this.editing = true
        },
        save: function () {
            Vue.set(this.order.shipping_address, 'name', [this.shipping_address.first_name, this.shipping_address.last_name].join(' '))
            const orders = mergeTools.orders(this.order)
            orders.forEach( o => {
                this.$store.dispatch('Orders/reset_errors', { orders: [o], type: 'address'}, { root: true })
                this.$store.dispatch('Orders/update_order', {
                    data: {
                        order: {
                            shipping_address: this.shipping_address
                        }
                    },
                    id: o.id
                }).then(response => {
                    if(response.data.errors) {
                        this.alert.message = 'Shopify rejected the address for the following reason:'
                        this.alert.error = response.data.errors.shipping_address[0]
                        this.alert.timeout = 100000                   
                        this.alert.color = "error"
                        this.alert.status = true
                    } else {
                        this.alert.message = 'Address saved'
                        this.alert.error = null
                        this.alert.timeout = 2500
                        this.alert.color = "success"
                        this.alert.status = true
                        this.order.shipping_address = Object.assign(this.order.shipping_address, this.shipping_address);
                        this.editing = false
                        this.$store.dispatch('Quotes/reset_quote', this.order)
                    }
                })

            })
        },
        show(section, subsection) {
            return this.display_settings[this.layout][section].subsections[subsection].status
        }
    },
    computed: {
		...mapGetters({
            display_settings: 'Settings/display_settings',
            customers: 'Orders/customers'
        }),
        layout: function () {
            return this.$store.getters[`Settings/order_layout`](this.order)
        },
        orders_count: function () {
            if(this.customers[this.order.customer.id]) {
                return this.customers[this.order.customer.id].orders_count
            }
            return null
        }
    }

}
</script>

<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>
