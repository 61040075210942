// User
import Vue from 'vue'
import axios from 'axios'
import orderTools from "@/V2/helpers/orderTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
	namespaced: true,
	state: {
        address: {
			name: '',
			company: '',
			address1: '',
			address2: '',
            suburb: '',
            state: '',
            postcode: null,
			country: '',
			phone: '',
			mobile: '',
			email: '',
			tax_id: ''
		},
		fulfillment_options: {
			notify_customer: true
		},
		invoice_options: {
			logo: {
				url: '',
				scale: 100,
				max_width: 400,
				max_height: 400
			},
			images: {
				show: true
			},
			other: {
				message: 'Thank you for your order.'
			},
			heading_font: '18px',
			items_font: '18px',
			totals_font: '18px',
			message_font: '18px',
			show_item_properties: true,
		},
		picklist_options: {
			name: 'Picklist',
			images: {
				show: true,
			},
			max_image_width: 100,
			max_image_height: 100,
			page_breaks: true,
			fulfilled: false,
			heading_font: '18px',
			items_font: '18px',
			notes_font: '18px',
			show_prices: false,
			show_item_properties: false,
			show_notes: true,
			show_note_attributes: false,
			show_orders_count: false,
		},
		label_options: {
			preference_1: 'order_number',
			preference_2: 'nr',
			preference_3: 'nr'
		},
		import_options: {
			pending: false,
			authorized: false
		},
		merge_options: {
			auto: false,
			show_products: true
		}
	},
	mutations: {
		init: function (state, payload) {
			Vue.set(state, 'address', payload.settings.address)
			Vue.set(state, 'fulfillment_options', payload.settings.fulfillment_options)
			state.invoice_options = Object.assign(state.invoice_options, payload.settings.invoice_options);
			state.picklist_options = Object.assign(state.picklist_options, payload.settings.picklist_options);
			state.label_options = Object.assign(state.label_options, payload.settings.label_options);
			state.import_options = Object.assign(state.import_options, payload.settings.import_options);
			state.merge_options = Object.assign(state.merge_options, payload.settings.merge_options);
			return true
		},
		use_shop: function (state, payload) {
			const address = {
				name: payload.shop_owner,
				company: payload.name,
				address1: payload.address1,
				address2: payload.address2,
				suburb: payload.city,
				state: payload.province_code,
				postcode: payload.zip,
				country: payload.country_code,
				phone: payload.phone,
				mobile: payload.phone,
				email: payload.email
			}
			Vue.set(state, 'address', address)
		}
	},
	getters: {
		address: state => {
			return state.address
		},
		fulfillment_options: state => {
			return state.fulfillment_options
		},
		label_options: state => {
			return state.label_options
		},
		import_options: state => {
			return state.import_options
		},
		merge_options: state => {
			return state.merge_options
		}
	},
	actions: {
		init: async function ({ commit, dispatch }) {
			const setup_complete = await dispatch('get')
			if(setup_complete) {
				return setup_complete
			} else {
				const shop = await dispatch('shop')
				commit('use_shop', shop)
				return true
			}
		},
		save: async function({ state, rootGetters }) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/user/settings`, {settings: state},
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				orderTools.log([{
					id: 'Preferences',
					type: 'Save',
					data: { preferences: state, response: response.data }
				}])
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'user/save', data: err})
			})

		},
		get: async function({ commit, dispatch, rootGetters }) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/user/get`, {},
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				if(response.data){
					commit('init', response.data.settings)
					return true
				} else {
					return false
				}
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'user/get', data: err})
			})

		},
		shop: async function({ state, rootGetters }) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/user/shop`, {},
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				return response.data.shop
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'user/shop', data: err})
			})

		},
	},
	modules: {
	}
}
