<template>
    <div class="mt-n6" v-if="carriers[carrier_account_id]">
        <component :is="componentFile" :account_credentials="carriers[carrier_account_id].credentials" :carrier_account_name="carriers[carrier_account_id].name"/> 
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            carriers: 'Carriers/carriers',
        }),
        carrier_id () {
            return this.$route.params.carrier_id
        },
        carrier_account_id () {
            return this.$route.params.carrier_account_id
        },
        componentFile() {
            const e = import(`@/V2/integrations/${this.carrier_id}/views/Add`);
            return () => e
		},
    } 
}
</script>
