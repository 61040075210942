<template>
    <component :is="componentFile" :carrier="carrier"/> 
</template>

<script>

export default {
    props: ['carrier'],
	computed : {
        componentFile() {
            return () => import(`@/V2/integrations/${this.carrier.carrier}/components/Tracking.vue`);
        },
    }    
}
</script>