import axios from 'axios'
import orderTools from "@/V2/helpers/orderTools";

export default {
    get_article: function (article) {
        return axios.get(`${process.env.VUE_APP_KENTICO_API}/${process.env.VUE_APP_V2_KENTICO_PROJECT}/items`, {
            params: {
                'system.codename': article
            },
            headers: { 
                'Content-Type': 'application/json' 
            }
        }).then(response => {
            return {
                json: response.data,
                type: response.data.items[0].system.type,
                content: this.build_article(response.data),
                links: this.build_links(response.data)
            }
        })
        .catch(err => {
            orderTools.log_error({message: 'Network Error', stack: 'kenticoTools/get_article', data: err})
        })
    },
    build_article: function (data) {
        let item = data.items[0]
        return {
            codename: item.system.codename,
            type: item.system.type,
            title: item.system.name,
            last_modified: item.system.last_modified, 
            overview: item.elements.overview ? item.elements.overview.value : null,
            body: item.elements.body ? item.elements.body.value : null,
            where_to_find: item.elements.where_to_find ? item.elements.where_to_find.value : null,
            related: item.elements.related_articles ? item.elements.related_articles.value : null
        }
    },
    build_links: function (data) {
        let links = {}
        let content = data.modular_content
        for(let link of Object.keys(content)){
            links[content[link].system.codename] = {
                title: content[link].system.name,
                article_title: content[link].elements.article_title && content[link].elements.article_title.value ? content[link].elements.article_title.value : content[link].system.name,
                codename: content[link].system.codename,
                overview: content[link].elements.overview ? content[link].elements.overview.value : null,
                body: content[link].elements.body ? content[link].elements.body.value : null
            }
        }
        return links
    },
}
