<template>
    <div v-if="!title && this.Shopify.app">
        <v-card class="mx-auto" max-width="600" flat>
            <v-container fill-height>
                <v-row justify="center" align="center" class="mb-12">
                    <v-col cols="2"><v-img src="../../assets/sellerdash_logo_sm.png" contain height="80"></v-img></v-col>
                    <v-col cols="10" align="center">
                        <v-row no-gutters>
                            <v-col class="text-h5 text-sm-h4">SellerDash: AusPost Shipping</v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
            <div class="text-center pa-5">
                <v-progress-circular
                    indeterminate
                    size="100"
                    width="10"
                    color="red"
                ></v-progress-circular>
                <h6 class="red--text mt-8">{{ loading.message }} {{ counter ? ' - ' : '' }} {{ counter }}</h6>
            </div>

        </v-card>

        <v-card class="mx-auto" width="90%">

        </v-card>


        <v-card class="mx-auto" max-width="444" v-if="0">
            <v-toolbar dark color="primary" dense flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-subtitle>{{ message }}</v-card-subtitle>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
    props: ['message', 'title'],
    computed: {
        ...mapState(['Shopify', 'loading']),
        ...mapGetters({
            orders: 'Orders/orders',
            quotes: 'Quotes/quotes',
            quotes_required: 'Quotes/quotes_required',
            carriers: 'Carriers/carriers',
            validated_carriers: 'Carriers/validated_carriers'
        }),
        counter: function () {
            switch(this.loading.step) {
                case 'Orders':
                    return `${this.orders.length} imported`
                case 'Quotes':
                    return `${Object.keys(this.quotes).length}`
                case 'Carriers': 
                    return `${this.validated_carriers} / ${Object.keys(this.carriers).length} completed`
                case 'Requesting Quotes': 
                    return `${Object.keys(this.quotes_required).length} remaining`
                default:
                    return null
            }
        }

    }

}
</script>