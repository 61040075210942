<template>
    <v-row justify="center">
        <v-dialog persistent v-model="progress.status" max-width="500">
            <v-card color="primary" dark>
                <v-card-text class="p-4 pt-3">
                    {{ progress.message }} 
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog> 
    </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed : {
        ...mapState(['progress']), 
    }
}
</script>