<template>
    <Startup title="Updating Account Status" message="Please wait while we update your account status..."/>
</template>

<script>
import { mapState } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";
import {Redirect} from '@shopify/app-bridge/actions';
import Startup from '@/V2/components/Utilities/Startup.vue'

export default {
    name: 'BillingInit',
    components: {
        Startup
    },
    created: function () {
        this.axios.post(`${this.env.api_server}/api/billings/initiate.json`, {return_url: '/billing/activate'}, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.$store.getters['Shopify/token']}`
            }
        }).then(response => {
            if(response.data.billing){
                const redirect = Redirect.create(this.Shopify.app);
                redirect.dispatch(Redirect.Action.REMOTE, {
                    url: response.data.billing.confirmation_url,
                    newContext:false 
                })
            }
        })
        .catch(error => {
			orderTools.log_error({message: 'Network Error', stack: 'BillingInit/created', data: error})
        })

    },
    computed: {
        ...mapState(['env', 'Shopify']),
    }
}
</script>