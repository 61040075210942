<template>
    <v-card flat>
        <v-row no-gutters>
            <v-col cols="12">{{ address.company }}</v-col>
            <v-col cols="12">
                <div v-if="address.tax_id">
                    <span v-if="address.country == 'AU'">ABN: </span><span v-else>Tax ID: </span>{{ address.tax_id }}
                </div>
            </v-col>
            <v-col cols="12">{{ address.address1 }}</v-col>
            <v-col cols="12">{{ address.address2 }}</v-col>
            <v-col cols="12">{{ address.suburb }} {{address.state }} {{ address.country }} {{ address.postcode }}</v-col>
            <v-col cols="12" class="mt-3">
                <span v-if="address.phone"><span class="font-weight-bold">T: </span>{{ address.phone }} </span>
                <span v-if="address.mobile"><span class="font-weight-bold">M: </span>{{ address.mobile }} </span>
            </v-col>
            <v-col cols="12" v-if="address.email"><span class="font-weight-bold">Email: </span>{{ address.email }}</v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed : {
        ...mapState('User', ['address']),
    }
}
</script>