<template>
    <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="close">
        <v-card v-if="display_settings">
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">Settings</v-toolbar-title>
                    <template v-slot:extension>
                        <v-tabs v-model="tab">
                            <v-tab href="#tab-table" class="text-none">Dense View<span v-if="display_settings.layout == 'table'" class="pl-2">(Enabled)</span></v-tab>
                            <v-tab href="#tab-compact" class="text-none">Open View<span v-if="display_settings.layout == 'compact'" class="pl-2">(Enabled)</span></v-tab>
                            <v-tab href="#tab-expanded" class="text-none">Expanded View<span v-if="display_settings.layout == 'expanded'" class="pl-2">(Enabled)</span></v-tab>
                        </v-tabs>
                    </template>
            </v-toolbar>
            <v-row no-gutters>
                <v-col class="pl-2">
                    <v-card-subtitle class="font-weight-bold">Fullscreen Mode</v-card-subtitle>
                </v-col>
                <v-col class="pt-3">
                    <v-switch v-model="display_settings.fullscreen" hide-details color="success" @click="save_fullscreen" class="mt-0 pt-1"></v-switch>
                </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-expanded">
                    <v-card flat>
                        <v-card-title class="mb-2">"Expanded" View Options</v-card-title>
                        <v-row no-gutters>
                            <v-col class="pl-4">
                                <v-card-subtitle class="font-weight-bold">Number of orders shown on a page</v-card-subtitle>
                            </v-col>
                            <v-col class="pt-2">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn dark outlined color="primary" v-bind="attrs" v-on="on" class="text-none px-3">
                                            {{ display_settings.items.expanded }} per page
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="updateItemsPerPage('expanded', number)">
                                            <v-list-item-title>{{ number }} items per page</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-card-subtitle class="pb-1">Choose which sections and information to display for each order when in Expanded View.</v-card-subtitle>
                        <v-row no-gutters class="px-4">
                            <v-col cols="6">
                                <Section view="expanded" section="customer" v-on:save="save('customer', 'expanded')"/>
                                <Section view="expanded" section="packages" v-on:save="save('packages', 'expanded')" class="pt-5"/>
                                <Section view="expanded" section="order_options" v-on:save="save('order_options', 'expanded')" class="pt-5"/>
                            </v-col>
                            <v-col cols="6">
                                <Section view="expanded" section="order_summary" v-on:save="save('order_summary', 'expanded')"/>
                                <Section view="expanded" section="products" v-on:save="save('products', 'expanded')" class="pt-5"/>
                            </v-col>

                        </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-compact">
                    <v-card flat>
                        <v-card-title class="mb-0">"Open" View Options</v-card-title>
                        <v-row no-gutters>
                            <v-col class="pl-4">
                                <v-card-subtitle class="font-weight-bold">Number of orders shown on a page</v-card-subtitle>
                            </v-col>
                            <v-col class="pt-2">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn dark outlined color="primary" v-bind="attrs" v-on="on" class="text-none px-3">
                                            {{ display_settings.items.compact }} per page
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="updateItemsPerPage('compact', number)">
                                            <v-list-item-title>{{ number }} items per page</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-card-subtitle class="pb-1">Choose which sections and information to display for each order when in Compact View.</v-card-subtitle>
                        <v-row no-gutters class="px-4">
                            <v-col cols="6">
                                <Section view="compact" section="customer" v-on:save="save('customer', 'compact')"/>
                                <Section view="compact" section="packages" v-on:save="save('packages', 'compact')" class="pt-5"/>
                                <Section view="compact" section="order_options" v-on:save="save('order_options', 'compact')" class="pt-5"/>
                            </v-col>
                            <v-col cols="6">
                                <Section view="compact" section="order_summary" v-on:save="save('order_summary', 'compact')"/>
                                <Section view="compact" section="products" v-on:save="save('products', 'compact')" class="pt-5"/>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-table">
                    <v-card flat>
                        <v-card-title class="mb-0">"Dense" View Options</v-card-title>
                        <v-row no-gutters>
                            <v-col class="pl-4">
                                <v-card-subtitle class="font-weight-bold">Number of orders shown on a page</v-card-subtitle>
                            </v-col>
                            <v-col class="pt-2">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn dark outlined color="primary" v-bind="attrs" v-on="on" class="text-none px-3">
                                            {{ display_settings.items.table }} per page
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="updateItemsPerPage('table', number)">
                                            <v-list-item-title>{{ number }} items per page</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-card-subtitle class="pb-1">Choose which sections and information to display for each order when in Condensed View.</v-card-subtitle>
                        <v-row no-gutters class="px-4">
                            <v-col cols="6">
                                <Section view="table" section="customer" v-on:save="save('customer', 'table')"/>
                                <Section view="table" section="packages" v-on:save="save('packages', 'table')" class="pt-5"/>
                            </v-col>
                            <v-col cols="6">
                                <Section view="table" section="order_summary" v-on:save="save('order_summary', 'table')"/>
                                <Section view="table" section="products" v-on:save="save('products', 'table')" class="pt-5"/>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>

            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Section from './Section'
import _ from 'lodash';

export default {
    components: {
        Section
    },
    data: () => ({
        dialog: false,
        options: {
            color: 'primary',
            width: 800,
            zIndex: 400
        },
        tab: 'tab-expanded',
        itemsPerPageArray: [10, 25, 50, 100, 200],
    }),
    methods: {
        open(layout) {
            this.tab = `tab-${layout}`
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        save(section, view) {
            let enabled = false
            for(const subsection in this.display_settings[view][section].subsections) {
                if(this.display_settings[view][section].subsections[subsection].status) {
                    enabled = true
                }
            }
            this.display_settings[view][section].status = enabled
            this.$store.dispatch('Settings/save_display_settings')
        },
        save_fullscreen: function () {
            this.$store.dispatch('Settings/save_display_settings')
        },
        updateItemsPerPage: function (layout, items) {
            this.$store.commit('Settings/set_items', {layout: layout, items: items } )
            this.$store.dispatch('Settings/save_display_settings')
        }
    },
    computed: {
		...mapGetters({
			display_settings: 'Settings/display_settings',
		}),
    }
}
</script>

