<template>
    <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel" :retain-focus="false">
        <v-card>
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="white darken-1" icon @click.native="agree"><v-icon color="white">mdi-close</v-icon></v-btn>  
            </v-toolbar>
            <v-card-text v-show="!!message" v-html="message" class="pa-4"></v-card-text>
            <v-row v-for="(order, index) in orders" :key="index">
                <v-col cols="3" class="py-0">
                    <v-card-text class="font-weight-bold pt-0">{{ order.name }}</v-card-text>
                </v-col>
                <v-col cols="9" class="py-0">
                    <v-card-text class="pt-0" v-html="order.errors[type]"></v-card-text>
                </v-col>
            </v-row>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click.native="agree">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'

export default {
  props: ['orders'],
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    question: null,
    title: null,
//    orders: [],
    type: null,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, orders, type, options) {
      Vue.set(this, 'dialog', true)
      Vue.set(this, 'title', title)
      Vue.set(this, 'message', message)
      this.options = Object.assign(this.options, options)
//      Vue.set(this, 'orders', orders)
      Vue.set(this, 'type', type)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>