<template>
    <v-card class="mb-2" outlined v-if="article">
        <v-row class="pl-6">
            <v-icon x-large color="primary">help</v-icon>
            <v-card-title class="clickable primary--text" @click="show = !show">{{ contentById.title }}</v-card-title> 
        </v-row>
        <v-card-subtitle v-html="contentById.sub_title" class="pt-2 pb-0 pl-5"></v-card-subtitle>
        <v-card-actions v-if="!show" class="pr-5 pb-2">
            <v-spacer></v-spacer>
            <v-btn class="text-none primary--text" text @click="show = !show" :disabled="show">
                <span v-show="!show">Show More...</span>
            </v-btn>
        </v-card-actions>

        <v-expand-transition>
            <div v-show="show">
                <v-card-text v-if="show">
                    <div class="body-1 px-5" v-html="processedBody"></div> 
                    <v-row v-if="0">
                        <v-col cols="12" md="6" lg="4" v-for="(link, index) in article.related" :key="index">
                            <HelpLink v-bind:link="links[link]"/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions v-if="show" class="pr-5">
                    <v-spacer></v-spacer>
                    <v-btn class="text-none primary--text" text @click="show = !show">
                        Show Less
                    </v-btn>
                </v-card-actions>
            </div>
        </v-expand-transition> 
    </v-card>    
</template>

<script>
import kenticoTools from "@/V2/helpers/kenticoTools.js";
import help_index from "@/V2/helpers/help.ts"
import axios from 'axios'

export default {
    props: ['id'],
    data: function () {
        return {
            show: false,
            article: {
                codename: '',
                title: '',
                related: []
            },
            links: {},
            content: help_index.content,
        }
    },
	components: {
        //HelpLink
    },
    methods: {
        get_article_body: function (id) {
            axios.post(`${process.env.VUE_APP_API_SERVER}/admin/proxy.json`, 
            {
                headers: {
                    'Authorization': `Bearer ${process.env.VUE_APP_INTERCOM_KEY}`,
                    'Intercom-Version': '2.10',
                    'Accept': 'application/json'
                },

                method : 'GET',
                url: `https://api.intercom.io/articles/${this.id}`,
            },
            {
                headers: { 
                    'Authorization': process.env.VUE_APP_AUTH
                }
            }).then(response => {
                this.body = response.data.response.body
            })
        }
    },
	mounted: function () {
        
	},
    computed: {
        contentById() {
            const findContentById = (id, currentContent) => {
                if (currentContent.id === id) {
                    return currentContent;
                }

                if (currentContent.sections) {
                    for (const section of currentContent.sections) {
                        if (section.id === id) {
                            return section;
                        }
                        // Check articles within the section
                        if (section.articles) {
                            for (const article of section.articles) {
                                if (article.id == id) {
                                    this.get_article_body(id)
                                    return article;
                                }
                            }
                        }
                        // If sections are nested, search recursively in nested sections
                        if (section.sections) {
                            const found = findContentById(id, section);
                            if (found) {
                                return found;
                            }
                        }
                    }
                }
                return null;
            };
            this.body = ''
            return findContentById(this.id, this.content);
        },
        processedBody() {
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.body, 'text/html');

            doc.querySelectorAll('img').forEach(img => {
                img.style.maxWidth = '100%';
                img.style.height = 'auto';
            });

            return doc.body.innerHTML;
        }

    }
}

</script>

<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>
