<template>
    <v-card flat>
        <v-row no-gutters>
            <v-col cols="12">{{ order.shipping_address?.company }}</v-col>
            <v-col cols="12">{{ order.shipping_address?.name }}</v-col>
            <v-col cols="12">{{ order.shipping_address?.address1 }}</v-col>
            <v-col cols="12">{{ order.shipping_address?.address2 }}</v-col>
            <v-col cols="12">{{ order.shipping_address?.city }} {{ order.shipping_address?.province_code }} {{ order.shipping_address?.country_code }} {{ order.shipping_address?.zip }}</v-col>
            <v-col cols="12" class="mt-3">
                <span v-if="order.shipping_address?.phone"><span class="font-weight-bold">Telephone: </span>{{ order.shipping_address?.phone }} </span>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: ['order'],
    computed : {
        ...mapState('User', ['address']),
    }
}
</script>