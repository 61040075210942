import Vue from 'vue'
import store from "@/V2/store/index"
import axios from 'axios'
import orderTools from "@/V2/helpers/orderTools"
import consignments from "@/V2/helpers/consignments"
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
    variant_locations: {},
    split_path: function (gid) {
        const parts = gid.split('/')
        return parts[4]
    },
    valid_fulfillment: async function (order) {
        if(order && order.consignment) {
            const valid = await store.dispatch(`Carriers/${order.consignment.service.carrier_type}/validate_fulfillment`, order)
            return valid
        } 
        return 'A consignment must be created before the order can be fulfilled.'
    },
    fulfill_orders: async function (orders) {
        const fulfilled_order_list: Array<any> = []
        await store.dispatch('Orders/reset_errors', { orders: orders, type: 'fulfillment'}, { root: true })
        store.commit('set_progress', { status: true, message: `Fulfilling Orders - Checking available locations` }) 
        const locations = await this.get_locations()
        let fulfillment_count = orders.length
        store.commit('set_progress', { status: true, message: `Fulfilling Orders - ${fulfillment_count} remaining` }) 
        for(const order of orders) {
            store.commit('Orders/set_delay_new_orders', true)
            const is_valid = await this.valid_fulfillment(order)
            if(is_valid == true) {
                if(!order.fulfillment_quantities) {
                    Vue.set(order, 'fulfillment_quantities', {})
                    order['fulfillment_quantities'] = {}
                    for(const item of order.line_items) {
                        Vue.set(order.fulfillment_quantities, item.id, item.fulfillable_quantity)
                    }
                }
                const fulfillments = await this.get_fulfillment_orders(order, locations)
                for(const f of fulfillments) {
                    const fulfillment = await this.create_fulfillment(order, f)
                    if(fulfillment) {
                        await store.dispatch('Orders/reset_overrides', order)
                        await store.dispatch('Orders/get_order', order.id)
                        fulfilled_order_list.push(order.id)
                    }
                }
                fulfillment_count--
                store.commit('set_progress', { status: true, message: `Fulfilling Orders - ${fulfillment_count} remaining` }) 
            } else {
                store.dispatch('Orders/init_error', { order: order, type: 'fulfillment', error: is_valid }, { root: true })
            }
        }
        if(fulfilled_order_list.length) {
            store.commit('set_progress', { status: true, message: `Fulfilling Orders - Updating order data` })
            for(const id of fulfilled_order_list){
                if(store.getters['Orders/order'](id)) {
                    setTimeout(async function(){ 
                        try {
                            await store.dispatch('Orders/get_order', id)
                        } catch (e) {
                            console.log(e)
                        }
                    }, 3000)
                }
            }
        }
        store.commit('set_progress', { status: false }) 
        
    },
    create_external_fulfillments: async function(fulfillments, order, locations) {
        const line_items: Array<any> = []
        const ext_locations = {}
        for(const id of Object.keys(order.fulfillment_quantities)) {
            if(order.fulfillment_quantities[id]) {
                const location_id = await this.get_line_item_location(id, order)
                if(!ext_locations[location_id]){
                    ext_locations[location_id] = []
                }
                ext_locations[location_id].push({
                    id: id,
                    quantity: order.fulfillment_quantities[id]
                })
            }
        }
        for(const each_location of Object.keys(ext_locations)) {
            const fulfillment = {
//                location_id: each_location,
                tracking_info: {
                    number: consignments.get_tracking_number(order),
                    url: consignments.get_tracking_url(order),
                    company: consignments.get_tracking_company(order),
                },
                notify_customer: store.getters['User/fulfillment_options'].notify_customer,
                line_items_by_fulfillment_order: [{
//                    fulfillment_order_id: this.split_path(fulfillmentOrder.node.id),
                    fulfillment_line_items: ext_locations[each_location]
                }]
//                line_items_by_fulfillment_order: ext_locations[each_location]
            }
            fulfillments.push(fulfillment)
        }

        return fulfillments
    },
    get_line_item_location: async function(id, order) {
        const variant_id = orderTools.get_line_item_variant(id, order)
        if(variant_id && this.variant_locations[variant_id]) {
            return this.variant_locations[variant_id]
        }
        if(variant_id && !this.variant_locations[variant_id]) {
            const variant = await this.get_variant(variant_id) 
            const inventory = await this.get_inventory(variant.inventory_item_id)
            if(inventory) {
                for(const location of inventory.inventory_levels) {
                    if(location.available){
                        this.variant_locations[variant_id] = location.location_id
                        return location.location_id
                    }
                }
                this.variant_locations[variant_id] = inventory.inventory_levels[0].location_id
                return inventory.inventory_levels[0].location_id
            }
        }
    },
    get_variant: async function(id) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/variant`, {
            id: id
        },
        {
            headers: { 
                'Authorization': token
            }
        })
        .then(response => {
            if(response.data.variant) {
                return response.data.variant
            }
        })
    },
    get_inventory: async function(id) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/variant/inventory`, {
            id: id
        },
        {
            headers: { 
                'Authorization': token
            }
        })
        .then(response => {
            if(response.data.inventory) {
                return response.data.inventory
            }
        })
    },
    create_fulfillment: async function (order, body) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/fulfillments`, {
            version: process.env.VUE_APP_SHOPIFY_VERSION_FULFILLMENTS, 
            fulfillment: { fulfillment: body }
        },
        {
            headers: { 
                'Authorization': token
            }
        })
        .then(async (response) => {
            if(response.data.fulfillment) {
//                if(!order.completed_fulfillments) {
//                    order['completed_fulfillments'] = []
//                    order.completed_fulfillments.push(response.data.fulfillment)
//                }
//                if(!order.completed_consignments) {
//                    order['completed_consignments'] = []
//                    order.completed_consignments.push(order.consignment)
//                }
                Vue.set(order, 'save_event', 'CreateFulfillment')
                await store.dispatch('Orders/save', order)
                return true
            } 
            if(response.data.errors) {
                store.dispatch('Orders/init_error', { order: order, type: 'fulfillment', error: `Shopify rejected your request to fulfill this order and returned the error message ${response.data.errors}. Check to ensure the line item or location the item is being fulfilled from still exists.` }, { root: true })
                return false
            }
        })
        .catch(err => {
            orderTools.log_error({message: 'Network Error', stack: 'Fulfillments/create_fulfillment', data: err})
        })

    },
    get_locations: async function () {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const query = `{
            locations(first: 100) {
                edges {
                    node {
                        id
                    }
                }
            }
        }`
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/graphql`, {
            graphql: {
                query: query
            }
        },
        {
            headers: { 
                'Authorization': token
            }
        })
        .then(response => {
            const arr: Array<any> = []
            try {
                response.data.data.locations.edges.forEach( location => {
                    arr.push(this.split_path(location.node.id))
                })
            } catch (e) {
                console.log(e)
            }
            return arr
        })
        .catch(err => {
            orderTools.log_error({message: 'Network Error', stack: 'Fulfillments/get_locations', data: err})
        })

    },
    get_fulfillment_orders: async function (order, locations) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const query = `{
            order(id: "gid://shopify/Order/${order.id}") {
                fulfillmentOrders(first: ${locations.length + 3}, query:"status:OPEN OR status:IN_PROGRESS") {
                    pageInfo {
                        hasNextPage
                    }
                    edges {
                        cursor
                        node {
                            id
                            requestStatus
                            status
                            assignedLocation {
                                name
                                location {
                                    id
                                }
                            } 
                            lineItems(first: ${orderTools.count_all_line_items(order)}) {
                                edges {
                                    node {
                                        totalQuantity
                                        remainingQuantity
                                        id
                                        lineItem {
                                            id
                                            variant {
                                                id
                                                displayName
                                            }
                                        }
                                    }
                                }
                            }                                  
                        }
                    }
                }
            }
        }`
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/graphql`, {
            graphql: {
                query: query
            }
        },
        {
            headers: { 
                'Authorization': token
            }
        })
        .then(response => {
            const arr: Array<any> = []
            try {
                response.data.data.order.fulfillmentOrders.edges.forEach( fulfillmentOrder => {
                    const line_items: Array<any> = []
                    fulfillmentOrder.node.lineItems.edges.forEach( fulfillment_line_item => {
                        if(fulfillment_line_item.node.remainingQuantity > 0){
                            if(order.fulfillment_quantities) {
                                if(order.fulfillment_quantities[this.split_path(fulfillment_line_item.node.lineItem.id)] > 0) {
                                    line_items.push({
                                        id: this.split_path(fulfillment_line_item.node.id),
                                        quantity: order.fulfillment_quantities[this.split_path(fulfillment_line_item.node.lineItem.id)]
                                    })

                                }
                            } else {
                                line_items.push({
                                    id: this.split_path(fulfillment_line_item.node.id)
                                })
                            }
                            order.fulfillment_quantities[this.split_path(fulfillment_line_item.node.lineItem.id)] = 0
                        }
                    })
                    const fulfillment = {
                        line_items_by_fulfillment_order: [{
                            fulfillment_order_id: this.split_path(fulfillmentOrder.node.id),
                            fulfillment_order_line_items: line_items
                        }],
                        tracking_info: {
                            number: consignments.get_tracking_number(order),
                            url: consignments.get_tracking_url(order),
                            company: consignments.get_tracking_company(order),
                        },
                        notify_customer: store.getters['User/fulfillment_options'].notify_customer,
                    }
                    if(line_items.length) {
                        arr.push(fulfillment)
                    }
                })
            } catch (e) {
                console.log(e)
            }
            return arr
        })

    }
}