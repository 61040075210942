<template>
    <v-menu	bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" :disabled="selected.length == 0" dark v-bind="[attrs]" v-on="on" class="ml-2">
                Bulk Actions
                <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
        </template>

        <v-list class="pointer pl-5">
            <v-subheader v-if="Object.keys(actions).length" class="text--primary font-weight-black ml-n5">Label Actions</v-subheader>
            <template v-for="(item, index) in Object.keys(label_menu)">
                <v-list-item v-if="actions[item]" :key="index" link @click="$emit('action', item)">
                    <v-list-item-title>{{ label_menu[item] }}</v-list-item-title>
                </v-list-item>
            </template>
            <v-subheader class="text--primary font-weight-black ml-n5">Order Actions</v-subheader>
            <v-list-item link @click="$emit('action', 'bulk_fulfill')">
                <v-list-item-title>Fulfill Orders</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('action', 'invoice')">
                <v-list-item-title>Print Invoices</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('action', 'picklist')">
                <v-list-item-title>Print Picklists</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('action', 'refresh')">
                <v-list-item-title>Refresh Quotes</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('action', 'defer')">
                <v-list-item-title>Defer Orders</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="has_deferrals" link @click="$emit('action', 'undefer')">
                <v-list-item-title>Cancel Deferrals</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('action', 'reset')">
                <v-list-item-title>Reset Orders</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>							
</template>

<script>
export default {
    props: ['has_deferrals', 'selected'],
	data: function () {
		return {
            label_menu: {
                create: 'Create Labels',
                update: 'Update Labels',
                print: 'Print Labels',
                manifest: 'Manifest Labels'
            }
		}
	},
	computed : {
		button_size () {
			const size = { xs: 'large', sm: 'large', md: 'large', lg: null, xl: 'large'}[this.$vuetify.breakpoint.name];
			return size ? { [size]: true } : {}
		},	
        actions () {
            let carrier = {} // {no_carrier: {no_consignment: []}}
            this.selected.forEach(id => {
                const order = this.$store.getters['Orders/order'](id)
                let carrier_id = order.rate_selected ? order.rate_selected.carrier_id : (order.consignment?.service ? order.consignment.service.carrier_id : null)
                let carrier_type = order.rate_selected ? order.rate_selected.carrier_type : (order.consignment?.service ? order.consignment.service.carrier_type : null)
                if(!carrier_id) {
                    return
                }

                if(!carrier[carrier_id]){
                    carrier[carrier_id] = {
                        no_consignment: [],
                        unpaid_consignment: [],
                        paid_consignment: [],
                        actions: []
                    }
                }
                const status = this.$store.getters[`Carriers/${carrier_type}/consignment_status`](order)
                carrier[carrier_id][status].push(order)
            })
            let actions = []
            Object.keys(carrier).forEach(c => {
                carrier[c].actions = this.$store.getters[`Carriers/${c.split('|')[0]}/bulk_actions`]({ statuses: carrier[c], id: c })
                actions = actions.concat(carrier[c].actions)
            })
            
            actions = [... new Set(actions)]
            const items = {}
            actions.forEach(a => {
                items[a] = this.label_menu[a]
            })
            return items
        }
	}	
}
</script>

