export const display_settings = {
    items: {
        table: 25,
        compact: 10,
        expanded: 10
    },
    layout: 'table',
    fullscreen: false,
    table: {
        customer: {
            description: "Customer Details",
            status: true,
            subsections: {
                name: { description: "Name", status: true },
                address: { description: "Address", status: true },
                country: { description: "Country", status: true },
                order_count: { description: "Order Count", status: true },
                email: {description: "Email Address", status: false},
                phone: {description: "Telephone", status: false}
            }
        },
        order_summary: {
            description: "Order Summary",
            status: true,
            subsections: {
                order_number_prefix: { description: "Order Number Prefix", status: false },
                shipping_method: { description: "Shipping Method", status: true },
                shipping_price: { description: "Shipping Price", status: false },
                order_total: { description: "Order Total", status: true },
                partially_fulfilled: { description: "Partially Fulfilled", status: true },
                notes: { description: "Notes", status: true },
            }
        },
        products: {
            description: "Products",
            status: true,
            subsections: {
                open: { description: "Open by default", status: true },
                images: { description: "Images", status: true, options: {
                    size: {description: "Size in pixels (max 100)", value: 50},
                }},
                title: { description: "Title", status: true, options: {
                    size: {description: "Max title length (characters)", value: 999},
                } },
                sku: { description: "SKU", status: true },
                properties: { description: "Properties", status: true },
                quantity: { description: "Quantity", status: true },
                unit_price: { description: "Unit Price", status: true },
                total_price: { description: "Total Price", status: true },
            },
        },
        packages: {
            description: "Packages",
            status: true,
            subsections: {
                list: { description: "Package List", status: true },
                dimensions: { description: "Dimensions", status: true },
                item_weight: { description: "Item Weight", status: true },
                packaging_weight: { description: "Package Materials", status: true },
                cubic_weight: { description: "Cubic Weight", status: true },
                total_weight: { description: "Total Weight", status: true },
            }
        },
        order_options: {
            description: "Order Options",
            status: true,
            subsections: {
                open: { description: "Open by default", status: false },
                all: { description: "All Carrier Options", status: true },
            }
        }
    },
    compact: {
        customer: {
            description: "Customer Details",
            status: true,
            subsections: {
                name: { description: "Name", status: true },
                address: { description: "Address", status: true },
                country: { description: "Country", status: true },
                order_count: { description: "Order Count", status: true },
                email: {description: "Email Address", status: false},
                phone: {description: "Telephone", status: false}
            }
        },
        order_summary: {
            description: "Order Summary",
            status: true,
            subsections: {
                subtotal: { description: "Item Sub Total", status: true },
                discounts: { description: "Discounts", status: true },
                shipping: { description: "Shipping", status: true },
                shipping_method: { description: "Shipping Method", status: true },
                order_total: { description: "Order Total", status: true },
                notes: { description: "Notes", status: true },
            }
        },
        products: {
            description: "Products",
            status: true,
            subsections: {
                open: { description: "Open by default", status: true },
                images: { description: "Images", status: true, options: {
                    size: {description: "Size in pixels (max 100)", value: 50},
                }},
                title: { description: "Title", status: true },
                sku: { description: "SKU", status: true },
                properties: { description: "Properties", status: true },
                quantity: { description: "Quantity", status: true },
                unit_price: { description: "Unit Price", status: true },
            },
        },
        packages: {
            description: "Packages",
            status: true,
            subsections: {
                open: { description: "Open by default", status: true },
                list: { description: "Package List", status: true },
                dimensions: { description: "Dimensions", status: true },
                item_weight: { description: "Item Weight", status: true },
                packaging_weight: { description: "Package Materials", status: true },
                cubic_weight: { description: "Cubic Weight", status: true },
                total_weight: { description: "Total Weight", status: true },
            }
        },
        order_options: {
            description: "Order Options",
            status: true,
            subsections: {
                open: { description: "Open by default", status: false },
                all: { description: "All Carrier Options", status: true },
            }
        }
    },
    expanded: {
        customer: {
            description: "Customer Details",
            status: true,
            subsections: {
                name: { description: "Name", status: true },
                address: { description: "Address", status: true },
                country: { description: "Country", status: true },
                order_count: { description: "Order Count", status: true },
                email: {description: "Email Address", status: false},
                phone: {description: "Telephone", status: false}
            }
        },
        order_summary: {
            description: "Order Summary",
            status: true,
            subsections: {
                subtotal: { description: "Item Sub Total", status: true },
                discounts: { description: "Discounts", status: true },
                shipping: { description: "Shipping", status: true },
                shipping_method: { description: "Shipping Method", status: true },
                order_total: { description: "Order Total", status: true },
                notes: { description: "Notes", status: true },
            }
        },
        products: {
            description: "Products",
            status: true,
            subsections: {
                open: { description: "Open by default", status: true },
                images: { description: "Images", status: true, options: {
                    size: {description: "Size in pixels (max 100)", value: 100},
                }},
                title: { description: "Title", status: true },
                sku: { description: "SKU", status: true },
                properties: { description: "Properties", status: true },
                quantity: { description: "Quantity", status: true },
                unit_price: { description: "Unit Price", status: true },
                total_price: { description: "Total Price", status: true },
            }
        },
        packages: {
            description: "Packages",
            status: true,
            subsections: {
                open: { description: "Open by default", status: true },
                list: { description: "Package List", status: true },
                dimensions: { description: "Dimensions", status: true },
                item_weight: { description: "Item Weight", status: true },
                packaging_weight: { description: "Package Materials", status: true },
                cubic_weight: { description: "Cubic Weight", status: true },
                total_weight: { description: "Total Weight", status: true },
            }
        },
        order_options: {
            description: "Order Options",
            status: true,
            subsections: {
                open: { description: "Open by default", status: true },
                all: { description: "All Carrier Options", status: true },
            }
        }
    }
}

