// Merge
import Vue from 'vue'
import axios from 'axios'
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
    namespaced: true,
    state: {
//        merged: {},
        errors: []
    },
    mutations: {
        init: function(state, payload) {
			Vue.set(state, 'merged', payload)
        },
        add_to_merged: function(state, payload) {
            Vue.set(state.merged, payload.id, {is_parent: payload.id == payload.data.parent ? true : false, parent: payload.data.parent, children: payload.data.children})
        },
        add_to_errors: function(state, payload) {
            state.errors.push(payload)
        }
    },
    getters: {
        order: (state, getters, rootState, rootGetters) => (order) => {
            if(order && getters['is_merged'](order.id)) {
                const o = rootGetters['Orders/order'](order.id)
                if(getters['is_parent'](order.id)){
                    return o
                } else {
                    return null
                }
            }
            return order
        },
        mergeable: (state, getters, rootState, rootGetters) => {
            const orders = rootGetters['Orders/orders']
            const mergeable = {}
            orders.forEach( order => {
                const key = mergeTools.address_key(order.shipping_address)
                if(!mergeable[key]) {
                    mergeable[key] = {
                        id: key,
                        address: order.shipping_address,
                        orders: [],
                        selected: [],
                        parent: null,
                        children: []
                    }
                }
                mergeable[key].orders.push({ order: order, is_merged: order.merge_data ? order.merge_data.is_merged : false })
            })
            const arr = {}
            for (const k in mergeable) {
                if(mergeable[k].orders.length > 1){
                    arr[k] = mergeable[k]
                }
            }
            return arr
        },
        unmerged: (state, getters) => {
            const arr : any[] = [];
            const mergeable = getters['mergeable']
            for(const m in mergeable) {
                const merged = mergeable[m].orders.find(o => {
                    const is_merged = getters['is_merged'](o.order.id)
                    if(is_merged) return true
                })
                if(!merged) {
                    arr.push(mergeable[m])
                }
            }
            return arr
        },
        partial: (state, getters) => {
            const arr : any[] = [];
            const mergeable = getters['mergeable']
            for(const m in state.merged) {
                let has_merged = false
                let has_unmerged = false
                if(mergeable[m] && mergeable[m].orders){
                    mergeable[m].orders.forEach(o => {
                        const is_merged = getters['is_merged'](o.order.id)
                        if(is_merged) {
                            has_merged = true
                        } else {
                            has_unmerged = true
                        }
                    })
                    if(has_unmerged && has_merged) {
                        arr.push(mergeable[m])
                    }
                }
            }
            return arr
        },
        full: (state, getters) => {
            const arr : any[] = [];
            const mergeable = getters['mergeable']
            for(const m in state.merged) {
                let all_merged = true
                if(mergeable[m] && mergeable[m].orders){
                    mergeable[m].orders.forEach(o => {
                        const is_merged = getters['is_merged'](o.order.id)
                        if(!is_merged) all_merged = false
                    })
                    if(all_merged) {
                        const errors = state.errors.find(e => {
                            return e.group.id == m
                        })
                        if(!errors){
                            arr.push(mergeable[m])
                        }
                    }
                }
            }
            return arr
        },
        is_merged: (state) => (id) => {
            if(state.merged) {
                let found = false
                Object.keys(state.merged).forEach(k => {
                    state.merged[k].children.forEach(c => {
                        if(c.id == id) {
                            found = true
                        }
                    })
                })
                return found
            }
        },
        is_parent: (state) => (id) => {
            if(state.merged) {
                let parent = null
                Object.keys(state.merged).forEach(k => {
                    state.merged[k].children.forEach(c => {
                        if(c.id == id) {
                            parent = state.merged[k].children.sort((a,b) => a.id - b.id)[0].id
                        }
                    })
                })
                if(parent == id) {
                    return true
                }
                return false
            }
        },
        merged: state => {
            return state.merged
        },
        errors: (state, getters) => {
            const arr : any[] = [];
            for(const m in state.merged) {
                const mergeable = getters['mergeable']
                const errors = state.errors.find(e => {
                    return e.group.id == m
                })
                if(errors) {
                    arr.push({
                        id: m,
                        address: state.merged[m].address,
                        errors: errors.errors 
                    })
                }
            }
            return arr
        },
        merge_data: (state) => (key) => {
            return state.merged[key]
        }
    },
    actions: {
        init: async function ({ state, dispatch, commit, rootGetters }) {
			await dispatch('get')
            await dispatch('check_orders_exist')
            await dispatch('check_addresses')
        },
        update_errors: function ({state, dispatch}) {
            Vue.set(state, 'errors', [])
            dispatch('check_orders_exist')
            dispatch('check_addresses')
        },
		get: async function({ commit, rootGetters }) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/merged/list`, {},
			{
				headers: { 
					'Authorization': token,
				}
			})
            .then(response => {
                commit('init', response.data.merged ? response.data.merged.data : {})
                return true
            })
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'merged/get', data: err})
			})

        },
		save: async function({ state, rootGetters }) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/merged/save`, { data: state.merged },
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				orderTools.log([{
					id: 'Merged',
					type: 'Save',
					data: state.merged
				}])
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'merged/save', data: err})
			})

		},
        check_orders_exist: async function({state, commit, rootGetters}) {
            Object.keys(state.merged).forEach(id => {
                const errors : any[] = [];
                state.merged[id].children.forEach(o => {
                    const order = rootGetters['Orders/order'](o.id)
                    if(!order){
                        errors.push({id: o.id, name: o.name, message: 'This order is no longer has an unfulfilled status so it can no longer be merged.'})
                    }                      
                })
                if(errors.length){
                    commit('add_to_errors', { group: state.merged[id], errors: errors })
                }
            })
        },
        check_addresses: async function({state, commit, dispatch, rootGetters}) {
            Object.keys(state.merged).forEach(id => {
                const errors : any[] = []
                const keys : string[] = []
                state.merged[id].children.forEach(o => {
                    const order = rootGetters['Orders/order'](o.id)
                    if(order){
                        const key = mergeTools.address_key(order.shipping_address)
                        keys.push(key)
                        if(key != id) {
                            errors.push({id: o.id, name: o.name, message: 'The address of this order does not match other orders so it can no longer be merged'})
                        }
                    }                     
                })
                const all_keys = [... new Set(keys)]
                if(errors.length && all_keys.length != 1){
                    commit('add_to_errors', { group: state.merged[id], errors: errors })
                } else if(errors.length) {
                    state.merged[id].key = all_keys[0]
                    delete Object.assign(state.merged, {[all_keys[0]]: state.merged[id] })[id];
                    dispatch('save')
                }
            })
        },
        merge: function({state, dispatch}, payload) {
            Vue.set(state.merged, payload.customer.id, {
                id: payload.customer.id,
                address: payload.customer.address,
                children: payload.orders.map(({id, name}) => {return {id: id, name: name}})
            })
            dispatch('save')
        },
        unmerge: function({state, dispatch}, payload) {
            state.merged[payload.id].children = state.merged[payload.id].children.filter(c => c.id != payload.order)
            if(state.merged[payload.id].children.length == 1){
                Vue.set(state.merged[payload.id], 'children', [])
            }
        }
    },
    modules: {
    }
}
