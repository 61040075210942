<template>
    <div>
        <v-tour name="mainTour" :steps="tour_steps" :options="tour_options" :callbacks="tour_callbacks"></v-tour>
		<MyPostTour/>
    </div>
</template>

<script>

import MyPostTour from '@/V2/integrations/MyPost/components/Tour.vue'
import orderTools from "@/V2/helpers/orderTools";

export default {
	data: function () {
		return {
			tour_options: {
				useKeyboardNavigation: false,
				labels: {
					buttonSkip: 'Skip tour',
					buttonPrevious: 'Previous',
					buttonNext: 'Next',
					buttonStop: 'Finish'
				},
				highlight: false
			},
			tour_steps: [
				{
					target: '#v-step-start',
					content: `Take a tour of the MyPost Business app.<br><br>If you close the tour now you can open it again later by clicking TOUR on the main menu.`,
				},
				{
					target: '#v-step-dashboard',
					content: `This is where everything happens from requesting rate quotes to printing invoices and shipping labels.<br><br>There is way too much to cover here so go to SUPPORT on the menu and scroll down to the Dashboard section for more info.`,
				},
				{
					target: '#v-step-carriers',
					content: `Before you can start shipping you'll need to add a CARRIER.<br><br>Each section of the app includes Help articles to guide you through each process. `,
				},
				{
					target: '#v-step-rules',
					content: `SHIPPING RULES allow you to tell the app which carrier and service to use based on the Shipping Method in an order.<br><br>For example, tell the app to use Express Post if the order's shipping method contains the word "Express".`,
				},
				{
					target: '#v-step-packages',
					content: `Most carriers require you to specify your package dimensions.<br><br>Set your default package size, define your own packages or select carrier specific package sizes here.`,
				},
				{
					target: '#v-step-settings',
					content: `The PREFERENCES page allows you to configure your account. In particular you should check your Sender Address but then browse the other tabs like Label, Fulfillment, Invoice and Picklist Options.<br><br>Each tab has its own help article for more info.`,
				},
				{
					target: '#v-step-tracking',
					content: `The TRACKING page provides a quick way to view the current tracking for any consignments created through a connected carrier.`,
				},
				{
					target: '#v-step-help',
					content: `The SUPPORT section is full of short articles that will guide you through all of the features of the app.<br><br>We recommend you spend some time browsing these articles to get the most out of the app.`,
				},
				{
					target: '#v-step-views',
					content: `There are 3 different views available with each view showing a different amount of order data.<br><br>Try each view to see what suits your needs best.`,
				},
				{
					target: '#v-step-display',
					content: `You can also customise the amount of data shown for each order and the number of orders displayed per page.<br><br>Click the gear icon to hide, show or expand components of each order.`,
				},
				{
					target: '.intercom-lightweight-app-launcher-icon',
					content: `If you ever need help contact us via Chat. We're available during normal business hours but ask your question anytime and we'll get back to you as soon as we can.`,
				},
			],
			tour_callbacks: {
				onStart: this.log_tour_step,
				onPreviousStep: this.log_tour_step,
				onNextStep: this.log_tour_step,
				onSkip: this.skipped_tour,
				onFinish: this.finish_tour
			}
		}
	},
	components: {
		MyPostTour
	},
	methods: {
		skipped_tour: function () {
			localStorage.setItem('TourViewed', true)
			this.log_tour_step('Skipped')
		},
		finish_tour: function () {
			localStorage.setItem('TourViewed', true)
			this.log_tour_step('Finished')
		},
		log_tour_step: function (currentStep) {
//            orderTools.log([{
//                id: 'TOUR',
//                type: 'Tour Step',
//                data: currentStep
//            }])
		}
	},
	computed: {
	},
	mounted: function () {
		try{
			const tourViewed = localStorage.getItem('TourViewed')
			if(!tourViewed){
				this.$tours['mainTour'].start()
			}
		} catch (e) {}
	}
}
</script>

