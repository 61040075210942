<template>
    <v-tour name="myPostTour" :steps="tour_steps" :options="tour_options"></v-tour>
</template>

<script>

import orderTools from "@/V2/helpers/orderTools";

export default {
	data: function () {
		return {
			tour_options: {
				useKeyboardNavigation: false,
				labels: {
					buttonSkip: 'Skip tour',
					buttonPrevious: 'Previous',
					buttonNext: 'Next',
					buttonStop: 'Finish'
				},
				highlight: false,
				enableScrolling: false
			},
			tour_steps: [
				{
					target: '#mypost-step-start',
					content: `Congratulations on connecting your MyPost Business account.<br><br><h3>Before printing labels you must:</h3><br><ul><li>Set your payment options</li><li>Select your label size (ie A6 for thermal printers)</li></ul>View the rest of this quick tour to see all options available.`,
					params: {
						enableScrolling: false
					}				
				},
				{
					target: '#mypost-step-payment',
					content: `Specify the payment option to use when creating labels.<br><br>Options are Automatic (credit cards) or Manual (PayPal and Charge Accounts).`,
					params: {
						enableScrolling: false
					}				
				},
				{
					target: '#mypost-step-print',
					content: `Change the label size created here.<br><br>The default is A4 - 4 per page but if you have a thermal printer you'll need to change it to A6 - 1 per page. `,
					params: {
						enableScrolling: false
					}				
				},
				{
					target: '#mypost-step-pickups',
					content: `You can book pickups through the app.<br><br>Change the setting here and you'll be prompted to book a pickup when you create labels.`,
					params: {
						enableScrolling: false
					}
				},
				{
					target: '#mypost-step-settings',
					content: `You can set default international and domestic settings here.<br><br>These will be automatically applied to every order but can be overridden on individual orders.`,
					params: {
						enableScrolling: false
					}
				},
				{
					target: '#mypost-step-services',
					content: `If you don't use some of the MyPost services available you can hide them here.<br><br>For example, you may want to hide International Express if you never use it.`,
					params: {
						enableScrolling: false
					}
				},
				{
					target: '#v-step-packages',
					content: `If you use the MyPost flat rate satchels or boxes you can specify one of them as your default Package on the PACKAGES page.<br><br>You can also select a flat rate package on each individual order.`,
					params: {
						enableScrolling: false
					}
				},
                
			],
			tour_callbacks: {
				onStart: this.log_tour_step,
				onPreviousStep: this.log_tour_step,
				onNextStep: this.log_tour_step,
				onSkip: this.skipped_tour,
				onFinish: this.finish_tour
			}
		}
	},
	components: {
	},
	methods: {
		skipped_tour: function () {
			this.log_tour_step('Skipped')
		},
		finish_tour: function () {
			this.log_tour_step('Finished')
		},
		log_tour_step: function (currentStep) {
            orderTools.log([{
                id: 'TOUR',
                type: 'MyPost Tour Step',
                data: currentStep
            }])
		}
	},
	computed: {
	},
	mounted: function () {
	}
}
</script>

<style>
	.v-step { 
		background: #1976D2 !important;
		max-width: 600px !important; 
	}
	.v-step ul {
		text-align: left;
	}
</style>