<template>
    <Startup title="Activating Account" message="Please wait while we activate your account..."/>
</template>

<script>
import { mapState } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";
import Startup from '@/V2/components/Utilities/Startup.vue'

export default {
    name: 'BillingActivate',
    components: {
        Startup
    },
    created: function () {
        if(this.$route.query.charge_id){
            const path = this.$route.path.split('/')
            this.axios.post(`${this.env.api_server}/api/billings/activate.json`, {app: path[3], shop: path[4], charge_id: this.$route.query.charge_id}, {
                headers: { 
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                if(response.data.billing && response.data.billing.status == 'active') {
                    this.$store.dispatch('Shopify/initAppBridge', response.data.host).then(result => {
			            this.$router.push('/init')
		            })
                }
            })
            .catch(error => {
                orderTools.log_error({message: 'Network Error', stack: 'BillingActivate/created', data: error})
            })

        }
    },
    computed: {
        ...mapState(['env'])
    }
}
</script>