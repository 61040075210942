<template>
    <div class="mt-n6">
        <v-row>
            <v-col cols="12" lg="6" class="py-0">
                <v-row>
                    <v-col>
                        <Instructions id="6509985" />
                    </v-col>
                    <v-col>
                        <Instructions id="9258084" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="6">
                <v-card>
                    <v-card-title>Connected Carriers</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="text-subtitle-1" v-if="carrier_count == 0">You haven't connected any carriers yet.</v-card-text>
                    <Connected v-for="(carrier, name, index) in carriers" :key="carrier.carrier_id" :connected="carrier" :seq="index" :count="carrier_count"/>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="6">
                <v-card>
                    <v-card-title>Available Carriers</v-card-title>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col cols="6" md="6" lg="4" xl="4" v-for="carrier_id of available" :key="carrier_id" class="px-6">
                            <Available :carrier_id="carrier_id"/>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import Instructions from '@/V2/views/Instructions'
import Connected from '@/V2/components/Carriers/Summary/Connected'
import Available from '@/V2/components/Carriers/Summary/Available'

export default {
    components: {
        Connected,
        Available,
        Instructions
    },
    data: function () {
        return {
        }
    },
    computed : {
        ...mapState('Carriers', ['carriers']),
        ...mapGetters({
            available: 'Carriers/available_carriers'
        }),
        carrier_count: function() {
            return Object.keys(this.carriers).length
        } 
    }
}
</script>