<template>
    <div>
        <v-app-bar fixed dense class="my-0 py-0" style="z-index: 300">
            <v-tabs show-arrows>
                <div v-if="show_screen_size == 'true'">
                {{  $vuetify.breakpoint.name  }} {{ $vuetify.breakpoint.width }}
                </div>
                <v-tab class="caption font-weight-bold px-md-1" id="v-step-dashboard" to="/home">Dashboard</v-tab>
                <v-tab class="caption font-weight-bold px-md-1" id="v-step-carriers" to="/carrier/summary">Carriers</v-tab>
                <v-tab class="caption font-weight-bold px-md-1" id="v-step-rules" to="/rules">Shipping Rules</v-tab>
                <v-tab class="caption font-weight-bold px-md-1" id="v-step-packages" to="/packages">Packages</v-tab>               
                <v-tab class="caption font-weight-bold px-md-1" id="v-step-settings" to="/settings">Preferences</v-tab>
                <v-tab class="caption font-weight-bold px-md-1" id="v-step-tracking" to="/tracking">Tracking</v-tab>
                <v-tab class="caption font-weight-bold px-md-1" id="v-step-liverates" to="/liverates" v-if="show_live_rates == 'true'">Live Rates</v-tab>
<!--                <v-tab class="caption font-weight-bold px-md-1" id="v-step-reports" to="/reports" v-if="show_reports == 'true'">Reports</v-tab> -->
                <v-tab class="caption font-weight-bold px-md-1" id="v-step-help" to="/help/main_help_page">Support</v-tab>
                <v-tab class="caption font-weight-bold px-md-1" id="v-step-start" @click="start_tour()">View Tour</v-tab>
<!--                <v-tab class="caption font-weight-bold px-md-1" id="v-step-news" @click="news_read = true" to="/news">News <v-icon small color="amber" v-if="!news_read && latest_news != latest_news_read">mdi-star</v-icon></v-tab> -->
                <v-spacer></v-spacer>
                <v-btn v-if="!fullscreen_status" icon @click="fullscreen_enter"><v-icon>mdi-fullscreen</v-icon></v-btn>
                <v-btn v-if="fullscreen_status" icon @click="fullscreen_exit"><v-icon>mdi-fullscreen-exit</v-icon></v-btn>
                <v-btn id="v-step-display" icon @click="settings"><v-icon>mdi-cog</v-icon></v-btn> 
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn id="v-step-views" text v-bind="attrs" v-on="on" class="caption font-weight-bold mt-1 mb-0 pt-3 pb-2">
                        {{ layout == 'compact' ? 'Open' : layout == 'table' ? 'Dense' : 'Expanded'}}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in views" :key="index" @click="set_view(index)">
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-tabs>
        </v-app-bar>
        <SettingsDialog ref="settings"/>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapState } from 'vuex'
import SettingsDialog from '@/V2/components/Utilities/SettingsDialog'
import kenticoTools from "@/V2/helpers/kenticoTools.js";
import {Fullscreen} from '@shopify/app-bridge/actions';

export default {
    name: 'Menu',
    data: () => ({
      items: [
        { title: 'Domestic Shipping Rules', to: '/rules/domestic' },
        { title: 'International Shipping Rules', to: '/rules/international' },
      ],
      views: [
          { text: 'Dense View', value: 'table' },
          { text: 'Open View', value: 'compact'}, 
          { text: 'Expanded View', value: 'expanded' },
      ],
      latest_news_read: '',
      latest_news: '',
      news_read: false,
      fullscreen_mode: null,
      fullscreen_status: false
    }),
    watch: {
        fullscreen: function(newVal, oldVal) {
            if(newVal) {
                this.fullscreen_enter()
            }
            if(!newVal && oldVal) {
                this.fullscreen_exit()
            }
        }    
    },
    components: {
        SettingsDialog
    },
    methods: {
        set_view: function (index) {
            this.$store.commit('Settings/set_layout', this.views[index].value)
            this.$store.dispatch('Settings/save_display_settings')
        },
        settings: function () {
            this.$refs.settings.open(this.layout)
        },
        start_tour: function () {
            this.$tours['mainTour'].start()
        },
        fullscreen_enter: function () {
            this.fullscreen_mode.dispatch(Fullscreen.Action.ENTER);
            this.fullscreen_status = true
        },
        fullscreen_exit: function () {
            this.fullscreen_mode.dispatch(Fullscreen.Action.EXIT);
            this.fullscreen_status = false
        },
        get: function (article) {
            kenticoTools.get_article(article).then(result => {
                this.latest_news = result.content.last_modified
            })
        }
    },
	mounted: function () {
        const app = this.$store.getters['Shopify/app']
        this.fullscreen_mode = Fullscreen.create(app);
//        this.latest_news_read = localStorage.getItem('latest_news_read')
//        this.get('latest_news')
	},
    computed: {
        ...mapState(['credentials']),
		...mapGetters({
			layout: 'Settings/layout',
		}),
        fullscreen() {
            return this.$store.getters['Settings/fullscreen']
        },
        show_screen_size() {
            return process.env.VUE_APP_SHOW_SCREEN_SIZE
        },
        show_live_rates() {
            return process.env.VUE_APP_SHOW_LIVE_RATES
        },
        show_reports() {
            return process.env.VUE_APP_SHOW_REPORTS
        },
        debug() {
            return process.env.VUE_APP_DEBUG
        },
        route() {
            return this.$route
        }
    }
}
</script>

