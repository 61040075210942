<template>
    <v-card class="mt-4" outlined>
        Manual Tests
    </v-card>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import axios from 'axios'
import { getSessionToken } from '@shopify/app-bridge-utils';
import {graphql} from "@/V2/helpers/shopify";

export default {
    data: function () {
        return {
        }
    },
    components: {
    },
    methods: {
    },
    created() {
    },
    computed: {
        ...mapState('LiveRates', ['settings']), 
        appId: function () {
          return process.env.VUE_APP_ID  
        }, 
    }
}
</script>

