<template>
    <div>
        <div v-if="consignment && consignment.service && consignment.service.carrier_id">
            <component :is="componentFile" :order="order" :selected="{carrier_id: consignment.service.carrier_id}"/>
        </div>
        <div v-else>
            <div v-if="selected && selected.carrier_id">
                <component :is="componentFile" :order="order" :selected="selected"/>
            </div>
            <div v-else>
                <div v-if="selected_by_rules && selected_by_rules.carrier_type">
                    <component :is="componentFile" :order="order" :selected="selected_by_rules"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['order'],
    computed: {
        selected () {
            const order_quotes = this.$store.getters['Quotes/quote'](this.order)
            return order_quotes.selected
        },
        quotes () {
            return this.$store.getters['Quotes/quote'](this.order)
        },
        consignment () {
            return this.order.consignment
        },
        componentFile() {
            let carrier_type = ''
            if(this.consignment && this.consignment.service && this.consignment.service.carrier_id){
                carrier_type = this.consignment.service.carrier_id.split('|')[0]
            }
            else if(this.selected && this.selected.carrier_id) {
                carrier_type = this.selected.carrier_id.split('|')[0]
            }
            else if(this.selected_by_rules && this.selected_by_rules.carrier_type){
                carrier_type = this.selected_by_rules.carrier_type
            }
            return () => import(`@/V2/integrations/${carrier_type}/components/OrderOptions.vue`);
        },
        selected_by_rules() {
            return this.order.rate_selected
        },
        layout: function () {
            return this.$store.getters[`Settings/order_layout`](this.order)
        }


	}	

}
</script>
