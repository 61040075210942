<template>
    <v-card flat :style="{'font-size': picklist_options.items_font || '18px'}">
        <hr class="thick" />
        <v-row dense class="font-weight-bold px-6">
            <v-col v-if="picklist_options.images.show" :cols="cols.images">Image</v-col>
            <v-col :cols="cols.description">Item</v-col>
            <v-col :cols="cols.quantity" class="text-right">Qty</v-col>
            <v-col v-if="picklist_options.show_prices" :cols="cols.prices" class="text-right">Price</v-col>
        </v-row>
        <hr class="thin" />
        <v-row v-for="item in order.line_items" :key="item.id" class="px-6">
            <template v-if="item.fulfillable_quantity || picklist_options.fulfilled">
                <v-col v-if="picklist_options.images.show" :cols="cols.images">
                    <img v-if="images[`${item.product_id}|${item.variant_id}`]" :src="resizeImage(images[`${item.product_id}|${item.variant_id}`].url)" :style="{ 'max-width': (picklist_options.max_image_width || 100) + 'px', 'max-height': (picklist_options.max_image_height || 100) + 'px' }"/>
                </v-col>
                <v-col :cols="cols.description">
                    <div>{{ item.name }}</div>
                    <div>{{ item.sku }}</div>
                    <div v-if="item.properties && picklist_options.show_item_properties">
                        <v-row v-for="(p, index) of item.properties" v-bind:key="index" no-gutters>
                            <v-col class="caption font-weight-bold">{{ p.name }}:</v-col>
                            <v-col class="caption">{{ p.value }}</v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col :cols="cols.quantity" class="text-right pr-5">{{ item.fulfillable_quantity }}</v-col>
                <v-col v-if="picklist_options.show_prices" :cols="cols.prices" class="text-right pr-5">${{ orderTools.original_item(order, item).price | formatNumber(2)}}</v-col>
                <hr class="thin" />
            </template>
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";

export default {
    props: ['order'],
    data: function () {
        return {
            orderTools: orderTools,
        }
    },
    methods: {
        resizeImage(url) {
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.set('width', this.picklist_options.max_image_width || '100');
            urlObj.searchParams.set('height', this.picklist_options.max_image_height || '100');
            return urlObj.toString();

        } catch (e) {
            return null
        }
      },

    },
    computed : {
        ...mapState('User', ['invoice_options', 'picklist_options']),
        ...mapGetters({
            images: 'Images/images',
        }),
        cols: function () {
            return {
                images: (this.picklist_options.images.show ? 3 : 0),
                description: (this.picklist_options.images.show ? (this.picklist_options.show_prices ? 5 : 8) : 11),
                prices: (this.picklist_options.show_prices ? 3 : 0),
                quantity: 1
            }
        },
    }
}
</script>

