<template>
    <div>
        <v-row class="px-5">
            <v-col class="d-flex align-center">
                <v-card-title class="pa-0 info--text">Default Carrier / Service</v-card-title>
            </v-col>
        </v-row>
        <v-row class="px-5">
            <v-card-text class="pl-5 py-0">
                The Default Carrier / Service will be used when no other matching rules are found.
            </v-card-text>
        </v-row>
        <v-row class="px-5">
            <v-col cols="6">
                <v-select
                    v-model="rules.default.carrier"
                    :items="carrier_list"
                    item-text="description"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    :clearable="true"
                    @change="update_services()"
                ></v-select> 
            </v-col>
            <v-col cols="6" v-if="rules.default.carrier">
                <v-select
                    v-model="rules.default.service"
                    multiple
                    :items="services"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    :clearable="true"
                    @blur="save()"
                ></v-select> 
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['rules', 'domestic'],
    data: function () {
        return {
            services: []
        }
    },
    methods: {
        save: function () {
            this.$emit('save')
            this.update_services()
        },
        update_services: function () {
            this.services = this.$store.getters['Carriers/services_list'](this.rules.default.carrier, this.domestic)
        },
        ev(e){
            console.log(e)
        }
    },
    computed: {
		...mapGetters({
            carrier_list: 'Carriers/carrier_list',
        })
    },
    mounted: function () {
        this.update_services()
    }
}
</script>
