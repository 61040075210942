import store from "@/V2/store/index"
import axios from 'axios'
import Vue from 'vue'
import orderTools from "./orderTools"

export default {
    address_key(arr) {
        try{
            const fields: Array<any> = [
                arr.first_name, 
                arr.last_name, 
                arr.company, 
                arr.address1, 
                arr.address2, 
                arr.city, 
                arr.province_code, 
                arr.country_code, 
                arr.zip
            ]
            let str = fields.join("")
            return str.replace(/ /g, '')
    
        } catch (e) {
            return ""
        }
    },
    is_merged: function(order) {
        if(order.merge_data && order.merge_data.is_merged) {
            return true
        } return false
    },
    is_parent: function(order) {
        if(order.merge_data && order.merge_data.parent == order.id) {
            return true
        } return false

    },
    get_line_items: function(order) {
        if(this.is_merged(order) && this.is_parent(order)) {
            return this.merged_orders_line_items(order)
        } else {
            return order.line_items
        }
        
    },
    visible: function(order) {
        if(this.is_merged(order)) {
            if(this.is_parent(order)) {
                return true
            } return false
        } return true
    },
    mergeable_orders: function(order) {
        try{
            return store.getters['Orders/mergeable_orders'][this.address_key(order.shipping_address)].orders
        } catch (e) {
            return []
        }
    },
    merged_orders: function (order) {
        try{
            const arr: Array<any> = []
            const orders = this.mergeable_orders(order)
            orders.forEach(o => {
                if(o.is_merged) {
                    arr.push(o.order)
                }
            })
            return arr
        } catch (e) {
            return []
        }
    },
    merged_orders_line_items: function(order) {
        try {
            let arr = []
            const orders = this.merged_orders(order)
            orders.forEach(o => {
                arr = arr.concat(o.line_items)
            })
            return arr
        } catch (e) {
            console.log(e)
        }
    },
    count_line_items: function (order) {
        const count = this.get_line_items(order).filter( (item) => {
            return true
       })
        return count.length
    },
    count_fulfillable_line_items: function (order) {
        const count = this.get_line_items(order).filter( (item) => {
            return item.fulfillable_quantity > 0 && item.requires_shipping;
        })
        return count.length
    },
    get_fulfillable_quantity: function (order) {
        const count = this.get_line_items(order).filter( (item) => {
            return item.fulfillable_quantity > 0 && item.requires_shipping;
        })
        let quantity = 0
        for(const o of count){
            quantity = quantity + o.fulfillable_quantity
        }
        return quantity
    },
    orders: function (order) {
        if(this.is_merged(order) && this.is_parent(order)) {
            const arr: Array<any> = []
            const orders = this.merged_orders(order)
            orders.forEach(o => {
                arr.push(o)
            })
            if(arr.length){
                return arr
            } return [order]
        } else {
            return [order]
        }
    },
    order_names: function (order) {
        if(this.is_merged(order) && this.is_parent(order)) {
            try {
                const arr: Array<any> = []
                const orders = this.merged_orders(order)
                orders.forEach(o => {
                    arr.push(o.name)
                })
                return arr.sort()
            } catch (e) {
                console.log(e)
            }
        } else {
            return [order.name]
        }
    },
    order_numbers: function (order) {
        if(this.is_merged(order) && this.is_parent(order)) {
            try {
                const arr: Array<any> = []
                const orders = this.merged_orders(order)
                orders.forEach(o => {
                    arr.push(o.order_number)
                })
                return arr.sort()
            } catch (e) {
                console.log(e)
            }
        } else {
            return [order.order_number]
        }
    },
    get_order_weight: function (order) {
        let weight = 0
        if(this.is_merged(order) && this.is_parent(order)) {
            if(order.weight !== undefined) {
                return order.weight
            }
            const orders = this.merged_orders(order)
            orders.forEach(o => {
                if(o.weight !== undefined) {
                    return o.weight
                } else {
                    weight = weight + orderTools.get_order_weight(o)
                }
            })
        } else {
            return orderTools.get_order_weight(order)
        }
        return weight    
    },

}