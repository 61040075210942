// Packages
import Vue from 'vue'
import axios from 'axios'
import orderTools from "@/V2/helpers/orderTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
	namespaced: true,
	state: {
        packages: {
			default: null,
			custom_dimensions: [5, 5, 5],
			dimensions: [null, null, null],
			materials: 0,
			defined: []
        },
		defaults: {
			weight: 0.25,
		}
	},
	mutations: {
        init: function (state, payload) {
			Vue.set(state, 'packages', payload.packages)
			if(payload.defaults) {
				state.defaults = payload.defaults
			}
		},
	},
	getters: {
		packages: state => {
			return state.packages
		},
		defaults: state => {
			return state.defaults
		},
		list: (state, getters, rootState, rootGetters) => {
			const carriers = rootGetters['Carriers/carriers']

			function addItem(id, name, short_name, dimensions, materials) {
                return {
                    id: id,
                    name: name,
					short_name: short_name,
					dimensions: dimensions,
					materials: materials
                }
            }
            
			const items: Array<any> = []
			const details = {}

			// Add a default Custom Package
            items.push(addItem('custom', 'Custom Packaging', 'Custom', [state.packages.custom_dimensions[0], state.packages.custom_dimensions[1], state.packages.custom_dimensions[2]], 0))
            details['custom'] = addItem('custom', 'Custom Packaging', 'Custom', [state.packages.custom_dimensions[0], state.packages.custom_dimensions[1], state.packages.custom_dimensions[2]], 0)

            // Add Defined Packages
            if(state.packages.defined.length > 0) {
                items.push(
                    {
                        header: 'Defined Packages'
                    }
                )
                state.packages.defined.forEach( item => {
                    if(!state.packages[item.id]) {
                        items.push(addItem(item.id, item.name, item.name, item.dimensions, item.materials))
                        details[item.id] = addItem(item.id, item.name, item.name, item.dimensions, item.materials)
                    }
                })
            }


            // Add unique carrier specific packages
            Object.keys(carriers).forEach(carrier => {
                if(carriers[carrier].packages.length > 1) {
                    const packages = carriers[carrier].packages
                    items.push(
                        {
                            header: carriers[carrier].carrier
                        }
                    )
                    packages.forEach( item => {
                        if(!state.packages[`${carriers[carrier]}_${item.id}`]) {
                            items.push(addItem(`${carriers[carrier].carrier}_${item.id}`, item.name, item.short_name, item.dimensions, 0))
                            details[`${carriers[carrier].carrier}_${item.id}`] = addItem(item.id, item.name, item.short_name, item.dimensions, 0)
                        }
                    })
                }
			})
			
			return { items: items, details: details }
		}
	},
	actions: {
		set_default: function ({ state }) {
			if(state.packages.default !== 'custom') {
				const defined_package = state.packages.defined.filter( p => {{
					return  p.id === state.packages.default
				}})
				if(defined_package[0]) {
					state.packages.dimensions = defined_package[0].dimensions
					state.packages.materials = defined_package[0].materials
				}
			}
		},
		save: async function({ state, rootGetters }) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/packages/save`, { packages: state.packages, defaults: state.defaults },
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				orderTools.log([{
					id: 'Packages',
					type: 'Save',
					data: { packages: state.packages, defaults: state.defaults, response: response.data }
				}])
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'packages/save', data: err})
			})

		},
		get: async function({ commit, rootGetters }) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/packages/list`, {},
			{
				headers: { 
					'Authorization': token,
				}
			})
            .then(response => {
                if(response.data.packages){
                    commit('init', response.data.packages)
                }
                return (response.data)
            })
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'packages/get', data: err})
			})

        },
		init: async function ({ dispatch, rootGetters }) {
			await dispatch('get')
			await dispatch('set_default')
			return true
        },
        
	},
	modules: {
	}
}
