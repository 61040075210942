<template>
    <v-row no-gutters>
        <v-col cols="8">
            <v-card class="mt-4" outlined>
                <v-toolbar color="indigo" dark>
                    <v-toolbar-title>Add a Rule</v-toolbar-title>
                </v-toolbar>
                <div class="pa-3" v-if="1">
                    <v-row>
                        <v-col>
                            <v-card class="mb-3">
                                <v-toolbar flat color="blue-grey lighten-3">
                                    Create a commonly used rule
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <div class="pa-3">
                                    <v-row>
                                        <v-col>
                                            <v-select 
                                                v-model="selected_common_rule"
                                                ref="common_rule_selector"
                                                :items="common_rules"
                                                item-text="name"
                                                item-value="name"
                                                :menu-props="{ bottom: true, offsetY: true }" 
                                                outlined 
                                                dense
                                                hide-details
                                                label="Select a Common Rule"
                                                placeholder="Select a Common Rule"
                                                class="mb-0 caption"
                                                @change="create_common_rule"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-form ref="common_rule">
                                        <div v-if="selected_common_rule">
                                            <v-row>
                                                <v-col>
                                                    <div class="pb-3" v-for="(line, index) in common_rule.text" :key="index" v-html="line"></div>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="pt-0 font-weight-bold">
                                                    Rule Name
                                                </v-col>
                                                <v-col class="pt-0 pl-8">
                                                    <v-text-field dense outlined label="Rule name" v-model="common_rule.name" :rules="[validation.nameRequired]"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="pt-0 font-weight-bold">
                                                    Rule Conditions
                                                </v-col>
                                            </v-row>
                                            <v-row v-for="(condition, index) in common_rule.conditions" :key="'condition' + index">
                                                <v-col class="py-0 pl-8">
                                                    {{ condition.title || get(fields, condition.field).name }}
                                                </v-col>
                                                <v-col class="py-0">
                                                    <div v-if="condition.locked" class="pb-3">{{ condition.value}}</div>
                                                    <v-text-field v-else dense outlined :label="condition.hint || get(fields, condition.field).name" v-model="condition.value" :rules="condition.validation ? [validation[condition.validation]] : null"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="pt-0 font-weight-bold">
                                                    Rule Actions
                                                </v-col>
                                            </v-row>
                                            <v-row v-for="(action, index) of common_rule.actions" :key="'action' + index">
                                                <v-col class="py-0 pl-8">
                                                    {{ action.title || get(actions, action.id).name }}
                                                </v-col>
                                                <v-col class="py-0" v-if="get(actions, action.id).value_required">
                                                    <div v-if="action.locked" class="pb-3">{{ action.hint }}{{ action.value}}</div>
                                                    <v-text-field v-else dense outlined :label="action.hint" v-model="action.value" :rules="action.validation ? [validation[action.validation]] : null"></v-text-field>
                                                </v-col>
                                            </v-row> 
                                            <v-row>
                                                <v-col class="pt-8 font-weight-bold">
                                                    Stop processing rules
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="8" class="pt-0 pl-8">
                                                    If enabled no further rules will be processed if the conditions of this rule are all true
                                                </v-col>
                                                <v-col class="pt-0">
                                                    <v-switch v-model="common_rule.stop" color="error" class="pt-0 mt-0"></v-switch>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="text-right">
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="selected_common_rule=null" class="mr-2">Cancel</v-btn>
                                                    <v-btn dark color="indigo" @click="save_common_rule">Save</v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-form>
                                </div>
                            </v-card>
                            <v-card>
                                <v-toolbar flat color="blue-grey lighten-3">
                                    Create a custom rule
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-form ref="custom_rule">
                                    <div class="pa-3">
                                        <v-row>
                                            <v-col cols="3" class="pt-0 font-weight-bold">
                                                Rule Name
                                            </v-col>
                                            <v-col cols="6" class="pt-0 pl-8">
                                                <v-text-field dense outlined label="Rule Name" v-model="custom_rule.name" :rules="[validation.nameRequired]"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="pt-0 font-weight-bold">
                                                When all of the following conditions are true
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="(condition, index) of custom_rule.conditions" :key="'condition' + index">
                                            <v-col class="pl-8">
                                                <v-icon color="green darken-3" class="pb-1">mdi-arrow-right-bold</v-icon> {{ condition.title || get(fields, condition.field).name }} is {{ condition.title || get(operators[get(fields, condition.field).operator], condition.operator).name.toLowerCase() }}
                                            </v-col>
                                            <v-col class="pl-8 text-right">
                                                {{ formats[get(fields, condition.field).format](condition.value) }}
                                            </v-col>
                                            <v-col cols="3" class="text-right">
                                                <v-spacer></v-spacer>
                                                <v-btn dark color="red" @click="delete_condition(index)">Delete condition</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-form ref="new_condition">
                                            <v-row>
                                                <v-col cols="3" class="py-0 pl-8">
                                                    <v-select 
                                                        v-model="new_condition.field"
                                                        ref="new_condition_field"
                                                        :rules="[validation.required]"
                                                        :items="fields"
                                                        item-text="name"
                                                        item-value="id"
                                                        :menu-props="{ bottom: true, offsetY: true }" 
                                                        outlined 
                                                        dense
                                                        label="Select a field"
                                                        placeholder="Select a field"
                                                        class="mb-0 caption"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="3" class="py-0">
                                                    <v-select 
                                                        v-if="new_condition.field"
                                                        v-model="new_condition.operator"
                                                        ref="new_condition_operator"
                                                        :rules="[validation.required]"
                                                        :items="operators[get(fields, new_condition.field).operator]"
                                                        item-text="name"
                                                        item-value="id"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                        outlined 
                                                        dense
                                                        label="Select an operator"
                                                        placeholder="Select an operator"
                                                        class="mb-0 caption"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="3" class="py-0">
                                                    <v-text-field v-if="new_condition.field" dense outlined :label="new_condition.field ? get(fields, new_condition.field).hint : null" v-model="new_condition.value" :rules="new_condition.field && get(fields, new_condition.field) ? [validation[get(fields, new_condition.field).validation]] : []"></v-text-field>
                                                </v-col>
                                                <v-col cols="3" class="text-right py-0">
                                                    <v-spacer></v-spacer>
                                                    <v-btn :disabled="!new_condition.field || !new_condition.operator" dark color="green" @click="add_condition">Save condition</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                        <v-row>
                                            <v-col class="pt-0 font-weight-bold">
                                                Apply the following actions
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="(action, index) of custom_rule.actions" :key="'action' + index">
                                            <v-col class="pt-2 pb-0 pl-8">
                                                <v-icon color="green darken-3" class="pb-1">mdi-arrow-right-bold</v-icon> {{ action.title || get(actions, action.action).name }}
                                            </v-col>
                                            <v-col class="pt-2 pb-0 text-right" v-if="get(actions, action.action).value_required">
                                                {{ formats[get(actions, action.action).format](action.value) }}
                                            </v-col>
                                            <v-col cols="3" class="pt-0 text-right">
                                                <v-spacer></v-spacer>
                                                <v-btn dark color="red" @click="delete_action(index)">Delete action</v-btn>
                                            </v-col>
                                        </v-row> 
                                        <v-form ref="new_action">
                                            <v-row>
                                                <v-col cols="3" class="py-0 pl-8">
                                                    <v-select 
                                                        v-model="new_action.action"
                                                        ref="new_action_action"
                                                        :rules="[validation.required]"
                                                        :items="actions"
                                                        item-text="name"
                                                        item-value="id"
                                                        :menu-props="{ bottom: true, offsetY: true }" 
                                                        outlined 
                                                        dense
                                                        label="Select an action"
                                                        placeholder="Select an action"
                                                        class="mb-0 caption"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="3" class="py-0">
                                                    <v-text-field v-if="new_action.action && get(actions, new_action.action).value_required" dense outlined :label="new_action.action ? get(actions, new_action.action).hint : null" v-model="new_action.value" 
                                                        :rules="new_action.action && get(actions, new_action.action) ? [validation[get(actions, new_action.action).validation]] : []"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="text-right py-0">
                                                    <v-spacer></v-spacer>
                                                    <v-btn :disabled="!new_action.action" dark color="green" @click="add_action">Save action</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                        <v-row>
                                            <v-col class="py-0 font-weight-bold">
                                                Stop processing rules
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="8" class="pl-8">
                                                If enabled no further rules will be processed if the conditions of this rule are all true
                                            </v-col>
                                            <v-col class="pt-0 mt-n1">
                                                <v-switch v-model="custom_rule.stop" color="error" class="pt-0"></v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="text-right">
                                                <v-spacer></v-spacer>
                                                <v-btn @click="reset_custom_rule" class="mr-2">Cancel</v-btn>
                                                <v-btn dark color="indigo" @click="save_custom_rule">Save Rule</v-btn>
                                            </v-col>
                                        </v-row>

                                    </div>
                                </v-form>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card class="mt-4" outlined v-if="1">
                <v-toolbar color="indigo" dark>
                    <v-toolbar-title>Rules Created</v-toolbar-title>
                </v-toolbar>
                <div class="pa-3">
                    <v-expansion-panels v-if="settings.rules.length">
                        <v-expansion-panel v-for="(rule, index) of settings.rules" :key="index">
                            <v-expansion-panel-header class="py-0">
                                <v-row no-gutters>
                                    <v-col class="pt-5">
                                        {{ rule.name }}
                                    </v-col>
                                    <v-col cols="1" class="text-right">
                                        <v-tooltip bottom color="indigo" v-if="index > 0">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon x-large color="indigo" v-bind="attrs" v-on="on" @click.stop="move_up(index)"><v-icon>arrow_circle_up</v-icon></v-btn>
                                            </template>
                                            <span>Move Rule Up</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="1" class="text-center">
                                        <v-tooltip bottom color="indigo" v-if="index < settings.rules.length - 1">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon x-large color="indigo" v-bind="attrs" v-on="on" @click.stop="move_down(index)"><v-icon>arrow_circle_down</v-icon></v-btn>
                                            </template>
                                            <span>Move Rule Down</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="1" class="text-left">
                                        <v-tooltip bottom color="red">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon x-large color="red" v-bind="attrs" v-on="on" @click.stop="delete_rule(index)"><v-icon>delete_forever</v-icon></v-btn>
                                            </template>
                                            <span>Delete Rule</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col class="pt-0 font-weight-bold">
                                        When all of the following conditions are true
                                    </v-col>
                                </v-row>
                                <v-row v-for="(condition,index) in rule.conditions" :key="'condition' + index">
                                    <v-col class="py-0 pl-8">
                                        {{ condition.title || get(fields, condition.field).name }} {{ condition.title ? '' : 'is ' + get(operators[get(fields, condition.field).operator], condition.operator).name }} {{ condition.value }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-8 font-weight-bold">
                                        Apply the following actions
                                    </v-col>
                                </v-row>
                                <v-row v-for="(action, index) of rule.actions" :key="'action' + index">
                                    <v-col class="py-0 pl-8">
                                        {{ action.title || get(actions, action.action).name }} 
                                    </v-col>
                                    <v-col class="py-0" v-if="get(actions, action.action).value_required">
                                        <v-text-field dense outlined :label="action.hint" v-model="action.value" :rules="action.validation ? [validation[action.validation]] : null"></v-text-field>
                                    </v-col>
                                </v-row> 
                                <v-row>
                                    <v-col class="pt-8 font-weight-bold">
                                        Stop processing rules
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="8" class="py-0 pl-8">
                                        If enabled no further rules will be processed if the conditions of this rule are all true
                                    </v-col>
                                    <v-col class="pt-0 mt-n4">
                                        <v-switch v-model="rule.stop" color="error" class="pt-0" @change="$emit('save')"></v-switch>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-row v-else>
                        <v-col>You haven't created any rules yet</v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card class="mt-4" outlined v-if="0">
                <v-toolbar color="indigo" dark>
                    <v-toolbar-title>Inactive Rules</v-toolbar-title>
                </v-toolbar>
                <div class="pa-3" v-if="1">
                    <v-row>
                    </v-row>
                </div>
            </v-card>
            <v-snackbar v-model="alert.enabled" :color="alert.color" top timeout="5000">
                {{ alert.message }}
                <template v-slot:action="{ attrs }">
                    <v-btn outlined v-bind="attrs" @click="alert.enabled = false">Close</v-btn>
                </template>
            </v-snackbar>
        </v-col>
    </v-row>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
    data: function () {
        return {
            selected_common_rule: null,
            common_rule: {},
            custom_rule: {
                name: '',
                text: [],
                status: false,
                conditions: [],
                actions: [],
                stop: false
            },
            new_condition: {
                field: null,
                operator: null,
                value: null
            },
            new_action: {
                action: null,
                value: null
            },
            alert: {},
        }
    },
    components: {
    },
    methods: {
        isDirty: function(ref) {
            console.log('ref', ref)
        },
        save_common_rule: function () {
            if(this.$refs.common_rule.validate()) {
                this.settings.rules.push(JSON.parse(JSON.stringify(this.common_rule)))
                this.$emit('save')
                this.create_common_rule()
                this.$refs.common_rule_selector.reset()
            }
        },
        add_condition: function() {
            if(this.$refs.new_condition.validate()) {
                this.custom_rule.conditions.push(JSON.parse(JSON.stringify(this.new_condition)))
                this.$refs.new_condition.reset()
                return true
            }
            return false
        },
        add_action: function() {
            if(this.$refs.new_action.validate()) {
                this.custom_rule.actions.push(JSON.parse(JSON.stringify(this.new_action)))
                this.$refs.new_action.reset()
                return true
            }
            return false
        },
        delete_rule: function (index) {
            this.settings.rules.splice(index, 1)
            this.$emit('save')
        },
        move_up: function (index) {
            if(index > 0) {
                const rule = this.settings.rules.splice(index, 1)[0]
                this.settings.rules.splice(index - 1, 0, rule)
                this.$emit('save')
            }
        },
        move_down: function (index) {
            if(index < this.settings.rules.length - 1) {
                const rule = this.settings.rules.splice(index, 1)[0]
                this.settings.rules.splice(index + 1, 0, rule)
                this.$emit('save')
            }
        },
        delete_condition: function() {

        },
        delete_action: function () {

        },
        reset_custom_rule: function () {
            this.custom_rule = {
                name: '',
                text: [],
                status: false,
                conditions: [],
                actions: [],
                stop: false
            }
            this.new_condition = {
                field: null,
                operator: null,
                value: null
            }
            this.new_action = {
                action: null,
                value: null
            }
            this.$nextTick(() => {
                this.$refs.custom_rule.resetValidation()
                this.$refs.new_condition.resetValidation()
                this.$refs.new_action.resetValidation()
            });
        },
        save_custom_rule: function () {
            let error = false
            if(!Object.keys(this.new_condition).every((k) => !this.new_condition[k])) {
                if(!this.add_condition()) {
                    error = true
                }
            }
            if(!Object.keys(this.new_action).every((k) => !this.new_action[k])) {
                if(!this.add_action()) {
                    error = true
                }
            }
            
            if(!error && (this.custom_rule.conditions.length == 0 || this.custom_rule.actions.length == 0)) {
                error = true
                this.alert = {
                    enabled: true,
                    color: 'red',
                    message: `A rule must have at least one Condition and one Action before it can be saved.`
                }

            }
            if(!error && this.$refs.custom_rule.validate()) {
                this.settings.rules.push(JSON.parse(JSON.stringify(this.custom_rule)))
                this.reset_custom_rule()
                this.$emit('save')
            }
        },
        create_common_rule: function () {
            this.$refs.common_rule.reset()
            const rule = this.common_rules.find(r => {
                return r.name == this.selected_common_rule
            })
            this.common_rule = JSON.parse(JSON.stringify(rule))
        },
        get: function (options, val) {
            if(val) {
                return options.find(opt => {
                    return opt.id == val
                })
            } 
            return {}
        },

    },
    computed: {
		...mapState('LiveRates', ['settings', 'common_rules', 'validation', 'fields', 'operators', 'actions', 'formats']), 
        columns () {
                const size = {xs:'12', sm:'12', md:'6', lg:'4', xl:'3'}[this.$vuetify.breakpoint.name];
                return size
        },
    },

}
</script>

