// Images
import Vue from 'vue'
import axios from 'axios'
import imagesdb from './imagesdb';
import orderTools from "@/V2/helpers/orderTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
	namespaced: true,
	modules: {
	},
	state: {
        images: {},
        idb_images: [],
	},
	mutations: {
        create_images: function(state, payload) {
            payload.forEach((order) => {
                try {
                    order.line_items.forEach((line_item) => {
                        if(line_item.product_id || line_item.variant_id) {
                            const img_name = `${line_item.product_id}|${line_item.variant_id}`
                            if( !state.images[img_name] ) {
                                const local_image = state.idb_images.filter(img => img.id == img_name)
                                Vue.set(state.images, img_name, {
                                    url: local_image[0] ? local_image[0].url : '',
                                    failed: false
                                })
                            } 
                        }
                    })
                } catch (error) {
                    orderTools.log_error({message: 'System Error', stack: 'images/create_images', data: {order: order, error: error }})                    
                }
            })
        },
        update_image: function(state, payload) {
            state.images[payload.img].url = payload.url
            state.images[payload.img].failed = payload.failed
        },
	},
	getters: {
		images: state => {
			return state.images
        },
	},
	actions: {
        init: async function ({ state, commit, dispatch, rootGetters }) {
            commit('create_images', rootGetters['Orders/orders'])
            dispatch('get_uncached_images')
        },
        get_image: async function ({ commit, dispatch, rootGetters }, payload) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
            const product_id = payload.image.split('|')[0]
            const variant_id = payload.image.split('|')[1]
            if(product_id || variant_id){
                return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/images`, {
                    product_id: payload.image.split('|')[0],
                    variant_id: payload.image.split('|')[1],
                    update: payload.update ? true : false
                },
                {
                    headers: { 
                        'Authorization': token,
                    }
                })
                .then(response => {
                    const success = response.data.status == 200 || response.data.status == 429 ? true : false
                    commit('update_image', {img: `${payload.image}`, url: response.data.body, failed: !success}) 
                    return (response.data)
                })
                .catch(err => {
                    orderTools.log_error({message: 'Network Error', stack: 'images/get_image', data: err})
                })
            } else {
                commit('update_image', {img: `${payload.image}`, url: null, failed: false}) 
            }
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/images`, {
                product_id: payload.image.split('|')[0],
                variant_id: payload.image.split('|')[1],
                update: payload.update ? true : false
            },
            {
                headers: { 
                    'Authorization': token,
                }
            })
            .then(response => {
                const success = response.data.status == 200 || response.data.status == 429 ? true : false
                commit('update_image', {img: `${payload.image}`, url: response.data.body, failed: !success}) 
                return (response.data)
            })
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'images/get_image', data: err})
			})

        },
        get_uncached_images: function ({ state, dispatch, getters  }) {
            let interval = 100

            const getAnImage = function() {
                if(!images_required.length){
                    setTimeout( () => {
                        dispatch('get_uncached_images')
                    }, 5000);
                } else {
                    const image: string = images_required[0]
                    images_required.shift()
                    if(image && !state.images[image].url && !state.images[image].failed){
                        dispatch('get_image', { image: image }).then(response => {
                            if(response && response.api_rate) {
                                const rate = response.api_rate.split("/")
                                interval = rate[0] < 20 ? 0 : rate[0] / rate[1] * 1000
                            }
                            setTimeout( getAnImage, interval);
                        })
                    } else {
                        getAnImage()
                    }
                }
            }

            const images_required: Array<string> = []
            Object.keys(state.images).forEach( ( image ) => {
                if(!state.images[image].url && !state.images[image].failed) {
                    images_required.push(image)
                }
            })
            if(images_required.length){
                setTimeout( getAnImage, 1000);
            }
        },
	}
}
