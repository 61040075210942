<template>
    <div>
        <v-expansion-panels flat accordion hover multiple :value="show_open(panels)" class="condensed bordered">
            <v-expansion-panel v-for="(panel, index) of panels" :key="index">
                <template v-if="panel == 'products'">
                    <v-expansion-panel-header :min-height="24" class="primary--text pt-0 pb-0">
                        <div>{{ fulfillable_items }} Line Item<span v-if="layout == 'table' && fulfillable_items > 1">s </span><span v-if="layout != 'table'">s </span> (Total Quantity {{ fulfillable_quantity }})</div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="layout == 'expanded'">
                        <Items v-if="show('products')" :order="order"/>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else>
                        <CompactItems v-if="show('products')" :order="order" :items="order.line_items"/>
                    </v-expansion-panel-content>
                </template>
                <template v-if="panel == 'packages'">
                    <v-expansion-panel-header :min-height="24" class="primary--text pt-0 pb-0">Packages</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <Packages v-if="show('packages')" :order="order"/>
                    </v-expansion-panel-content>
                </template>
                <template v-if="panel == 'order_options'">
                    <v-expansion-panel-header :min-height="24" class="primary--text pt-0 pb-0">Order Options</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <OrderOptions v-if="(order.rate_selected || order.consignment) && show('order_options')" :order="order"/>
                    </v-expansion-panel-content>
                </template>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Items from '@/V2/components/Orders/Items'
import CompactItems from '@/V2/components/Orders/Compact/Items'
import OrderOptions from '@/V2/components/Orders/OrderOptions'
import Packages from '@/V2/components/Orders/Packages'
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";

export default {
    props: ['order', 'panels'],
    components: {
        Items,
        CompactItems,
        OrderOptions,
        Packages,
    },
    data: function () {
        return {
            merged: mergeTools,
        }
    },
    methods: {
        show(section, subsection) {
            if(this.layout != 'table') {
                if(subsection) {
                    return this.display_settings[this.layout][section].subsections[subsection].status
                } else {
                    return this.display_settings[this.layout][section].status
                }
            } else {
                return true
            }
        },
        show_open(sections) {
            if(this.layout == 'table'){
                return null
            }
            if(sections.length > 1){
                const arr = []
                for(let i = 0; i < sections.length; i++){
                    if(this.display_settings[this.layout][sections[i]].subsections.open.status) {
                        arr.push(i)
                    }
                }
                return arr
            } else {
                return (this.display_settings[this.layout][sections[0]].subsections.open.status ? [0] : [null])
            }
        },
    },
    computed: {
		...mapGetters({
			display_settings: 'Settings/display_settings'
        }),
        enabled: function() {
            const panels = {}
            for(const panel of this.panels){
                panels[panel] = true
            }
            return panels
        },
        layout: function () {
			return this.$store.getters[`Settings/order_layout`](this.order)
        },
        fulfillable_items: function () {
            return mergeTools.count_fulfillable_line_items(this.order)
        },
        fulfillable_quantity: function () {
            return mergeTools.get_fulfillable_quantity(this.order)
        },
    }
}
</script>

<style scoped>
    .v-expansion-panels.condensed .v-expansion-panel-header {
        padding-top: 2px;
        padding-bottom: 2px;
        min-height: 36px;
    }
    .bordered .v-expansion-panel {
        border-bottom: 1px solid #dddddd;
    }
    .bordered .v-expansion-panel:last-child {
        border-bottom: none;
    }
    .bordered .v-expansion-panel .v-expansion-panel-content__wrap { padding: 0 !important; }
</style>