<template>
    <v-card>
        <v-card-title class="info--text">Defined Packages</v-card-title>
        <v-row class="px-5">
            <v-col class="d-flex align-center">
                <v-card-title class="pa-0">Add a new package</v-card-title>
            </v-col>
        </v-row>
        <v-row class="px-5">
            <v-col>
                <v-card :elevation="focused == -1 ? 4 : 0" class="mx-n2 px-2 pt-2">
                    <v-row>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Description" @focus="focused = -1" v-model="new_package.name"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Length (cm)" @focus="focused = -1" v-model="new_package.dimensions[0]" :rules="[rules.isNumber]" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Width (cm)" @focus="focused = -1" v-model="new_package.dimensions[1]" :rules="[rules.isNumber]" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Height (cm)" @focus="focused = -1" v-model="new_package.dimensions[2]" :rules="[rules.isNumber]" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Package Materials (kg)" @focus="focused = -1" v-model="new_package.materials" :rules="[rules.isNumber]" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="3" md="2" lg="2" xl="2" class="pt-1 text-right">
                            <v-btn v-if="new_package.name" color="indigo" class="white--text mr-5" @click="add(new_package)">Save</v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="get_cubic(new_package.dimensions)">
                        <v-col class="caption">
                            Cubic Weight based on package dimensions = {{ get_cubic(new_package.dimensions) | formatNumber(2)}} kg
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="new_package.materials > 0.25">
                        <v-col>
                            <v-alert text outlined dense icon="mdi-alert" type="warning" class="ma-2">
                                <span class="font-weight-bold">WARNING:</span> The Package Materials weight entered appears high. Package Materials is the weight of additional packaging used to ship an order. It is added to the aggregate weight of the items in the order. It normally just reflects the weight of a packaging carton (ie an empty cardboard box).
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="px-5">
            <v-col class="d-flex align-center">
                <v-card-title class="pa-0" v-if="packages.defined.length">Existing Packages</v-card-title>
            </v-col>
        </v-row>
        <v-row class="px-5" v-for="(p, index) in packages.defined" :key="index">
            <v-col>
                <v-card :elevation="focused == p.id ? 4 : 0" class="mx-n2 px-2 pt-2">
                    <v-row>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Description" v-model="p.name" @focus="focused = p.id" @change="$emit('save')"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Length (cm)" v-model="p.dimensions[0]" @focus="focused = p.id" :rules="[rules.isNumber]" @change="$emit('save')" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Width (cm)" v-model="p.dimensions[1]" @focus="focused = p.id" :rules="[rules.isNumber]" @change="$emit('save')" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Height (cm)" v-model="p.dimensions[2]" @focus="focused = p.id" :rules="[rules.isNumber]" @change="$emit('save')" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" lg="2" xl="2" class="pt-0">
                            <v-text-field outlined dense hide-details="auto" label="Package Materials (kg)" v-model="p.materials" @focus="focused = p.id" :rules="[rules.isNumber]" @change="$emit('save')" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" lg="2" xl="2" class="py-0 mt-0 text-right">
                            <v-tooltip bottom color="indigo">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon x-large color="indigo" v-bind="attrs" v-on="on" @click="move_up(index)"><v-icon>arrow_circle_up</v-icon></v-btn>
                                </template>
                                <span>Move Package Up</span>
                            </v-tooltip>
                            <v-tooltip bottom color="indigo">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon x-large color="indigo" v-bind="attrs" v-on="on" @click="move_down(index)"><v-icon>arrow_circle_down</v-icon></v-btn>
                                </template>
                                <span>Move Package Down</span>
                            </v-tooltip>
                            <v-tooltip bottom color="red">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon x-large color="red" v-bind="attrs" v-on="on" @click="remove(index)"><v-icon>delete_forever</v-icon></v-btn>
                                </template>
                                <span>Delete Package</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-alert v-if="delete_error[packages.defined[index].id]" text outlined dense icon="mdi-alert" type="error" class="ma-2">{{ delete_error[packages.defined[index].id] }}</v-alert>
                    <v-divider class="mb-0"></v-divider>
                </v-card>
            </v-col>
        </v-row>
    </v-card>           
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
    data: function () {
        return {
            items: [],
            new_package: {
                name: null,
                dimensions: [null, null, null],
                min_weight: null,
                max_weight: null,
                materials: null
            },
            rules: {
                required: value => !!value || 'Required.',
                isNumber: value => !isNaN(value) || 'Must be a number.'
            },
            focused: null,
            delete_error:{}
        }
    },
	components: {
    },
    methods: {
        add: function (p) {
            this.save(p)
            this.new_package = {
                name: null,
                dimensions: [null, null, null],
                min_weight: null,
                max_weight: null,
                materials: null
            }
        },
        save: function (p) {
            this.packages.defined.push({
                id: p.id ? p.id : `${p.name}_${Math.floor(Math.random() * 1000000)}`,
                sort: p.sort ? p.sort : this.packages.defined.length,
                name: p.name,
                dimensions: [p.dimensions[0], p.dimensions[1], p.dimensions[2]],
                min_weight: p.min_weight,
                max_weight: p.max_weight,
                materials: p.materials
            })
            this.packages.defined.sort(function(a, b) {
                return parseFloat(a.sort) - parseFloat(b.sort);
            });
            this.$emit('save')
        },
        move_up: function (index) {
            if(index > 0) {
                const p = this.packages.defined.splice(index, 1)[0]
                this.packages.defined.splice(index - 1, 0, p)
                this.packages.defined.forEach( (p, index) => p.sort = index )
                this.$emit('save')
            }
        },
        move_down: function (index) {
            if(index < this.packages.defined.length - 1) {
                const p = this.packages.defined.splice(index, 1)[0]
                this.packages.defined.splice(index + 1, 0, p)
                this.packages.defined.forEach( (p, index) => p.sort = index )
                this.$emit('save')
            }
        },
        remove: function (index) {
            if(this.packages.defined[index].id == this.packages.default) {
                Vue.set(this.delete_error, this.packages.defined[index].id, `The package named ${this.packages.defined[index].name} cannot be deleted as it is the Default Package. Change the Default Package first.`)
            } else {
                this.packages.defined.splice(index, 1)
                this.packages.defined.forEach( (p, index) => p.sort = index )
                this.$emit('save')
                this.delete_error = {}
            }

        },
        get_cubic(dims) {
            return (dims[0] * dims[1] * dims[2]) / 1000000 * 250
        }
    },
    mounted: function () {
    },
    computed: {
		...mapGetters({
            carriers: 'Carriers/carriers',
            packages: 'Packages/packages',
        })
    },
}
</script>

