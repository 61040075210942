<template>
    <Startup title="Authenticating Account" message="Please wait while we authenticate your account with Shopify..."/>
</template>

<script>
import { mapState } from 'vuex'
import {graphql} from "@/V2/helpers/shopify";
import {Redirect} from '@shopify/app-bridge/actions';
import orderTools from "@/V2/helpers/orderTools";
import Startup from '@/V2/components/Utilities/Startup.vue'

export default {
    name: 'Authenticate',
    components: {
        Startup
    },
    methods: {
        gql_billing_status: async function () {
            const query = `{
                currentAppInstallation{
                    id
                    activeSubscriptions {
                        createdAt
                    }
                }
            }`
            try {
                await graphql({
                    data: {
                        graphql: {
                            query: query
                        }
                    }
                }).then(response => {
                    if(response.data.data.currentAppInstallation.activeSubscriptions.length) {
                        this.$router.push('/init')
                    } else {
                        this.init_billing()
                    }
                });
                
            } catch (error) {
                console.log('err', error)
//                this.init_billing()
            }

        },
        billing_status: function () {
            this.axios.get(`${this.env.api_server}/api/billings/status.json`, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.$store.getters['Shopify/token']}`
                }
            }).then(response => {
                if(response.data.billing && response.data.billing.status == 'active') {
                    const host = atob(this.$route.query.host)
                    const api_key = process.env.VUE_APP_SHOPIFY_APP_KEY
                    window.location.assign(`https://${host}/apps/${api_key}/`)
                } else {
                    this.init_billing()
                }
            })
            .catch(error => {
                orderTools.log_error({message: 'Network Error', stack: 'BillingStatus/created', data: error})
            })

        },
        init_billing: function () {
            this.axios.post(`${this.env.api_server}/api/billings/initiate.json`, {return_url: '/billing/activate'}, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.$store.getters['Shopify/token']}`
                }
            }).then(response => {
                if(response.data.billing){
                    if(this.$route.query.embedded){
                        const redirect = Redirect.create(this.Shopify.app);
                        redirect.dispatch(Redirect.Action.REMOTE, {
                            url: response.data.billing.confirmation_url,
                            newContext:false 
                        })
                    } else {
                        window.location.assign(response.data.billing.confirmation_url)
                    }
                }
            })
        }
    },
    mounted: function () {
        let body = {
            ... this.$route.query,
            app: this.env.app_name,
            path: this.$route.fullPath
        }
        this.axios.post(`${this.env.api_server}/api/register/auth.json`, body, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if(response.data && response.data.success){
                this.$store.commit('Shopify/setCredentials', {
                    ... this.$route.query,
                    ... response.data,
                    token: response.data.data.token,
                })
                if(this.$route.query.embedded){
                    this.gql_billing_status()
                } else {
                    this.billing_status()
                }
            } else {
                this.$router.push('/unauthorised')
            }
        })
        .catch(error => {
            console.log('error', error)
//            this.errored = true
//			orderTools.log_error({message: 'Network Error', stack: 'Authenticate/mounted', data: error})
        })
    },
    computed: {
        ...mapState(['env', 'Shopify'])
    }
}
</script>