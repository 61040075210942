<template>
    <div>
        <v-card elevation="5" class="ma-2">
            <v-row no-gutters>
                <v-col cols="3">
                    <v-card-title class="text-h6 pb-1">
                        {{ customer.address.first_name }} {{ customer.address.last_name }}
                    </v-card-title>
                    <v-card-text>
                        <div v-if="customer.address.company">{{ customer.address.company }}</div>
                        <div v-if="customer.address.address1">{{ customer.address.address1 }}</div>
                        <div v-if="customer.address.address2">{{ customer.address.address2 }}</div>
                        <div class="mb-8">{{ customer.address.city }} {{ customer.address.province_code }} {{ customer.address.zip }} {{ customer.address.country_code }}</div>
                        <v-card flat max-width="280">
                            <div><v-btn class="text-none mb-2" color="success" width="100%" @click="merge()" v-if="selected.length > 1">Merge Selected Orders</v-btn></div>
                            <div><v-btn  class="text-none" color="error" width="100%" @click="unmerge()" v-if="selected.length && type != 'unmerged'">Unmerge Selected Orders</v-btn></div>
                        </v-card>
                    </v-card-text>
                </v-col>
                <v-col cols="9" class="px-0">
                    <template v-if="type == 'partial'">
                        <v-card-title>Available to Merge</v-card-title>
                        <div v-for="(o, index) in customer.orders" :key="'A' + index">
                            <v-row no-gutters v-if="!merge_list[o.order.id]">
                                <v-col cols="1">
                                    <v-checkbox v-model="selected" :value="o.order.id"></v-checkbox>
                                </v-col>
                                <v-col cols="11">
                                    <Order :order="o.order" :last="index + 1 == customer.orders.length ? true : false"/>
                                </v-col>
                            </v-row>
                        </div>
                        <v-card-title>Already Merged</v-card-title>
                        <div v-for="(o, index) in customer.orders" :key="'B' + index">
                            <v-row no-gutters v-if="merge_list[o.order.id]">
                                <v-col cols="1">
                                    <v-checkbox v-model="selected" :value="o.order.id"></v-checkbox>
                                </v-col>
                                <v-col cols="11">
                                    <Order :order="o.order" :last="index + 1 == customer.orders.length ? true : false"/>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                    <template v-else>
                        <v-row no-gutters v-for="(order, index) in customer.orders" :key="'C' + index">
                            <v-col cols="1">
                                <v-checkbox v-model="selected" :value="order.order.id"></v-checkbox>
                            </v-col>
                            <v-col cols="11">
                                <Order :order="order.order" :last="index + 1 == customer.orders.length ? true : false"/>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>
            </v-row>
        </v-card>

    </div>
</template>

<script>
import Vue from 'vue'
import Order from '@/V2/components/Merge/Order'

export default {
    props: ['customer', 'type'],
    data: function () {
        return {
            selected: [],
            loaded: false
        }
    },
	components: { 
        Order
    },
    methods: {
        merge: function() {
            const arr = []
            this.customer.orders.forEach(o => {
                const is_selected = this.selected.find(id => id == o.order.id)
                if(is_selected) {
                    arr.push(o.order)
                }
            })
            this.$store.dispatch('Merge/merge', {customer: this.customer, orders: arr})
        },
        unmerge: function() {
            const arr = []
            this.customer.orders.forEach(o => {
                const is_selected = this.selected.find(id => id == o.order.id)
                if(is_selected) {
                    this.$store.dispatch('Merge/unmerge', {id: this.customer.id, order: o.order.id})
                }
            })
            this.$store.dispatch('Merge/save')

        }
    },
    mounted: function () {
        if(!this.loaded){
            this.customer.orders.forEach(o => {
                this.selected.push(o.order.id)
            })
            this.loaded = true
        }
    },
    computed: {
        merge_list() {
            const keys = {}
            this.merge_data.children.forEach(c => {
                keys[c.id] = c.name
            })
            return keys
        },
        merge_data() {
            return this.$store.getters[`Merge/merge_data`](this.customer.id)
        }
    },
}
</script>

