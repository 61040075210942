<template>
    <v-card v-if="debug">
        <v-card-title v-if="title">{{ title }}</v-card-title>
        <vue-json-pretty :data="data" :showDoubleQuotes="false" :showLineNumber="true" :showIcon="true"></vue-json-pretty>
    </v-card>
</template>

<script>
import VueJsonPretty from '@souljorje/vue-json-pretty'
import '@souljorje/vue-json-pretty/lib/styles.css';

export default {
    name: 'Response',
    components: {
        VueJsonPretty
    },
    props: ['data', 'title'],
    computed: {
        debug: function () {
            return process.env.VUE_APP_DEBUG == 'true' ? true : false
        }
    }
}
</script>