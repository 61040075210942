import axios, { AxiosResponse, Method } from 'axios';
import store from "@/V2/store/index"
import {getSessionToken} from '@shopify/app-bridge-utils';

interface ApiRequestConfig {
  endpoint?: string;
  method?: Method;
  data?: any;
  params?: any;
  headers?: any;
}

export const graphql = async ({
  endpoint = `${process.env.VUE_APP_V2_SHOPIFY_API}/graphql`,
  method = 'POST',
  data = null,
  params = null,
  headers = null
}: ApiRequestConfig): Promise<AxiosResponse<any>> => {
//  console.trace()

  try {
    const token = await getSessionToken(store.getters[`Shopify/app`]);

    const response = await axios({
      url: endpoint,
      method,
      data,
      params,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        ...headers
      },
    });

    return response;

  } catch (error: any) {
    throw error.response ? error.response : error.message;
  }
};
