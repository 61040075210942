<template>
    <v-card flat>
        <v-row no-gutters>
            <v-col class="font-weight-bold">Invoice No.</v-col>
            <v-col>{{ order.name }}</v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="font-weight-bold">Order Date</v-col>
            <v-col>{{ order.created_at | formatDate('DD MMM YYYY') }}</v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="font-weight-bold">Payment Method</v-col>
            <v-col>
                <span v-for="(gateway, index) in order.payment_gateway_names" :key="index">{{ gateway | gateway() }} </span></v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="font-weight-bold">Shipping Method</v-col>
            <v-col>{{ order.shipping_lines[0] ? order.shipping_lines[0].title : 'Not specified' }}</v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: ['order'],
    computed : {
        ...mapState('User', ['address']),
    }
}
</script>