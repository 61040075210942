<template>
    <v-card flat :style="{'font-size': this.invoice_options.items_font || '18px'}">
        <hr class="thick" />
        <v-row dense class="font-weight-bold px-3">
            <v-col v-if="invoice_options.images.show" cols="2">Image</v-col>
            <v-col :cols="invoice_options.images.show ? 5 : 7">Item</v-col>
            <v-col cols="1" class="text-right">Qty</v-col>
            <v-col cols="2" class="text-right">Price</v-col>
            <v-col cols="2" class="text-right">Total</v-col>
        </v-row>
        <hr class="thin" />
        <v-container v-for="item in order.line_items" :key="item.id">
            <v-row v-if="item.quantity - (refunded_items[item.id] ? refunded_items[item.id] : 0)">
                <v-col v-if="invoice_options.images.show" cols="2">
                    <img v-if="images[`${item.product_id}|${item.variant_id}`]" :src="images[`${item.product_id}|${item.variant_id}`].url" :style="{ 'max-width': '100px', 'max-height': '100px' }"/>
                </v-col>
                <v-col :cols="invoice_options.images.show ? 5 : 7">
                    <div>{{ orderTools.original_item(order, item).name }}</div>
                    <div>{{ item.sku }}</div>
                    <div v-if="item.properties && invoice_options.show_item_properties">
                        <v-row v-for="(p, index) of item.properties" v-bind:key="index" no-gutters>
                            <v-col class="caption font-weight-bold">{{ p.name }}:</v-col>
                            <v-col class="caption">{{ p.value }}</v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="1" class="text-right">{{ item.quantity - (refunded_items[item.id] ? refunded_items[item.id] : 0) }}</v-col>
                <v-col cols="2" class="text-right">${{ orderTools.original_item(order, item).price | formatNumber(2)}}</v-col>
                <v-col cols="2" class="text-right">${{ orderTools.original_item(order, item).price * (item.quantity - (refunded_items[item.id] ? refunded_items[item.id] : 0)) | formatNumber(2)}}</v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";

export default {
    props: ['order'],
    data: function () {
        return {
            orderTools: orderTools,
        }
    },
    methods: {
    },
    computed : {
        ...mapState('User', ['invoice_options']),
        ...mapGetters({
            images: 'Images/images'
        }),
        refunded_items: function() {
            try {
                return orderTools.line_item_refunds(this.order)
            } catch (e) {
                return 0
            }
        }
    }
}
</script>

