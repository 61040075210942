<template>
    <v-card class="mt-4" outlined>
        <v-toolbar color="indigo" dark>
            <v-toolbar-title>Backup Rates</v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle>If the carrier's rate quoting system is unavailable (ie during system maintenance or an outage) you can define backup rates which will be provided to your customer. </v-card-subtitle>
        <v-card-subtitle class="mt-n4">This ensures that your customer will always receive a shipping quote, even if the carrier is unavailable.</v-card-subtitle>
        <v-card-subtitle class="mt-n4">If you specify a Weight Based Rate it is highly recommended that you set a minimum price for the rate quote.</v-card-subtitle>
        <v-card-subtitle class="mt-n4">For example if you set a Price per 100 grams of $1 and a customer only purchases a single 100 gram item the calculated price would be $1. If your parcels cost you a minimum of $8 to ship you may want the minimum price charged to the customer to be set at $8.</v-card-subtitle>
        <div class="pl-4 font-weight-bold">Add a Rate</div>
        <v-row class="mx-0">
            <v-col cols="10">
                <v-row>
                    <v-col cols="6" sm="4" md="3" lg="3" class="pb-0">
                        <v-select ref="type" outlined dense v-model="type" label="Rate Quote Type" :items="[{text: 'Flat', value: 'flat'}, {text: 'Weight Based (price per kg)', value: 'perkg'}, {text: 'Weight Based (price per 100g)', value: 'per100g'}]" :rules="[rules.required]"></v-select>
                    </v-col>
                    <v-col cols="6" sm="4" md="3" lg="3" class="pb-0">
                        <v-select ref="region" outlined dense v-model="region" label="Destination" :items="['Domestic', 'International']" :rules="[rules.required]"></v-select>
                    </v-col>
                    <v-col cols="6" sm="4" md="3" lg="3" class="pb-0">
                        <v-text-field ref="name" outlined dense label="Rate Name (appears in checkout)" v-model="name" :rules="[rules.required]" class="caption"></v-text-field>
                    </v-col>
                    <v-col v-if="type == 'flat'" cols="6" sm="4" md="3" lg="3" class="pb-0 caption">
                        <v-text-field ref="price" outlined dense label="Price" v-model="price" :rules="[rules.isNumber]"></v-text-field>
                    </v-col>
                    <template v-if="type && type != 'flat'">
                        <v-col cols="6" sm="4" md="3">

                        </v-col>
                        <v-col v-if="type == 'perkg'" cols="6" sm="4" md="3">
                            <v-text-field ref="price" outlined dense label="Price per kg" v-model="price" :rules="[rules.isNumber]"></v-text-field>
                        </v-col>
                        <v-col v-if="type == 'per100g'" cols="6" sm="4" md="3">
                            <v-text-field ref="price" outlined dense label="Price per 100g" v-model="price" :rules="[rules.isNumber]"></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4" md="3">
                            <v-text-field ref="min_price" outlined dense label="Minimum Price" v-model="min_price" :rules="[rules.numberOrEmpty]"></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4" md="3">
                            <v-text-field ref="max_price" outlined dense label="Maximum Price" v-model="max_price" :rules="[rules.numberOrEmpty]"></v-text-field>
                        </v-col>
                    </template>
                </v-row>
            </v-col>
            <v-col cols="2">
                <v-row>
                    <v-col>
                        <v-btn class="white--text" color="indigo" @click="save">Save</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <div v-if="settings.backup_rates.length" class="pl-4 mb-2 font-weight-bold">Saved Rates</div>
        <v-row class="mx-0" v-for="(q, index) in settings.backup_rates" :key="index">
            <v-col>
                <v-text-field readonly dense hide-details label="Type" v-model="rate_names[q.type]"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field readonly dense hide-details label="Destination" v-model="q.region"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field readonly dense hide-details label="Rate Name" v-model="q.name"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field v-if="q.type == 'flat'" readonly dense hide-details label="Price" v-model="q.price"></v-text-field>
                <v-text-field v-if="q.type == 'perkg'" readonly dense hide-details label="Price per kg" v-model="q.price"></v-text-field>
                <v-text-field v-if="q.type == 'per100g'" readonly dense hide-details label="Price per 100g" v-model="q.price"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field v-if="q.type != 'flat'" readonly dense hide-details label="Min Price" v-model="q.min_price"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field v-if="q.type != 'flat'" readonly dense hide-details label="Max Price" v-model="q.max_price"></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-btn class="white--text" color="red" @click="remove(index)">Delete</v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data: function () {
        return {
            type: null,
            region: '',
            name: null,
            price: null,
            min_price: null,
            max_price: null,
            rules: {
                required: value => !!value || 'Required.',
                isNumber: value => !isNaN(value) || 'Must be a number.',
                numberOrEmpty: value => null || !isNaN(value) || 'Must be a number.'
            },
            formHasErrors: false
        }
    },
    methods: {
        save: function () {
            try {
                this.formHasErrors = false

                Object.keys(this.form).forEach(f => {
                    if(this.$refs[f]){
                        if(!this.$refs[f].validate(true)) {
                            this.formHasErrors = true
                        }
                    }
                })
                if(!this.formHasErrors) {
                    try {
                        this.settings.backup_rates.push(this.form)
                        this.resetForm()
                        this.$emit('save')
                    } catch (e) {
                        console.log(e)
                    }
                }

            } catch (e) {
                console.log(e)
            }
        },
        remove: function (index) {
            this.settings.backup_rates.splice(index, 1)
            this.$emit('save')
        },
        resetForm () {
            this.formHasErrors = false
            Object.keys(this.form).forEach(f => {
                if(this.$refs[f]){
                    this.$refs[f].reset()
                }
            })
        },
    },
    computed: {
		...mapState('LiveRates', ['settings']), 
        form () {
            return {
                type: this.type,
                region: this.region,
                name: this.name,
                price: this.price,
                min_price: this.min_price,
                max_price: this.max_price
            }
        },
        rate_names () {
            return {
                flat: 'Flat',
                perkg: 'Weight Based (price per kg)',
                per100g: 'Weight Based (price per 100g)',
            }
        }
    }
}
</script>

