<template>
    <div>
        <div v-if="selected">
            <v-card v-bind:outlined="layout == 'expanded'" v-bind:flat="layout != 'expanded'" class="pl-2 mb-0">
                <v-row class="font-weight-bold text-caption">
                    <v-col cols="12" xl="3" class="pt-0 pb-1" v-if="carrier_count > 1">                    
                        <v-responsive max-height="100%" max-width="100%">
                            <v-img
                                :src="carrier_logo(selected.carrier_type)"
                                aspect-ratio="2.85"
                                class="grey lighten-2"
                                :max-width="80"
                                :max-height="35"
                                >
                            </v-img>
                        </v-responsive>
                    </v-col>
                    <v-col cols="12" :xl="carrier_count > 1 ? 9 : 12" class="pt-0 pb-0 pl-0 pr-7 text-right">
                        <v-menu	bottom offset-y>
                            <template v-bind:selected="selected" v-slot:activator="{ on }">
                                <v-btn dark v-on="on" height="30px" width="100%" :color="service_bg_color" class="ml-2 mb-1 py-0 px-1 text-none"  :class="service_text_color">
                                    <v-row no-gutters class="font-weight-bold text-caption">
                                        <v-col cols="9" class="text-left py-0 text-truncate">
                                            <span v-if="selected">{{ selected.description }}</span>
                                        </v-col>
                                        <v-col cols="3" class="text-right py-0">
                                            <span v-if="selected">${{ selected.price | formatNumber(2)}}</span>
                                        </v-col>
                                    </v-row>
                                    
                                    <v-icon class="d-none d-lg-block">mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense class="pointer">
                                <div v-for="(carrier, index) in prices" :key="index">
                                    <template v-if="carrier.length">
                                        <v-list-item>
                                            <v-list-item-content class="pr-5">
                                                <v-img v-if="carrier[0]" :src="carrier_logo(carrier[0].carrier_type)" aspect-ratio="2.85" class="grey lighten-2" :max-width="100" :max-height="35"></v-img>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                <v-list-item-title v-if="carrier[0]">
                                                    {{ carrier[0].carrier_name || carrier[0].carrier_type }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="ma-0"></v-divider>
                                    </template>
                                    <v-list-item link dense v-for="price in carrier" :key="price.code" @click="select_rate(price)">
                                        <v-list-item-content class="pa-0" :class="{ 'font-weight-bold': selected && price.carrier_id == selected.carrier_id && price.code == selected.code, 'grey--text': price.carrier_id != selected.carrier_id || price.code != selected.code }">
                                            <v-tooltip v-if="price.additional_data" bottom min-width="400">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-row>
                                                        <v-col cols="9">
                                                            <v-list-item-title>{{ price.description }}</v-list-item-title>
                                                        </v-col>
                                                        <v-col v-bind="attrs" v-on="on" cols="3" class="text-right">
                                                            <v-list-item-title>${{ price.price |formatNumber(2) }}</v-list-item-title>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                                <v-row>
                                                    <v-col class="font-weight-bold">Additional Information</v-col>
                                                </v-row>
                                                <v-row v-for="(val, key) in price.additional_data" :key="key" class="py-0 my-0">
                                                    <v-col class="py-0 my-0">{{ key }}</v-col>
                                                    <v-col class="py-0 my-0 text-right">{{ val }}</v-col>
                                                </v-row>
                                            </v-tooltip>
                                            <v-row v-else>
                                                <v-col cols="9">
                                                    <v-list-item-title>{{ price.description }}</v-list-item-title>
                                                </v-col>
                                                <v-col cols="3" class="text-right">
                                                    <v-list-item-title>${{ price.price |formatNumber(2) }}</v-list-item-title>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <div class="pr-4 caption text-muted text-right" v-if="carrier[0]">{{ plan_description(carrier[0].carrier_type, carrier[0].carrier_id) }}</div>
                                    
                                </div>
                            </v-list>
                        </v-menu>						

                    </v-col>
                    <v-col cols=12 class="py-0 pr-5 mr-2 text-right">
                        <div v-if="order.rule_used || order.rule_keywords || !order.rule_found">
                            <span class="caption text-muted" v-if="order.rule_used">Service selected by "{{ order.rule_used }}"</span> 
                            <span class="caption text-muted" v-if="order.rule_keywords && order.rule_keywords.length">Service selected by "{{ order.rule_keywords.join(" & ") }}" keyword rule<span v-if="order.rule_keywords && order.rule_keywords.length > 1">s</span></span> 
                            <span class="caption text-muted orange--text" v-if="layout != 'table' && !order.rule_found"><v-icon class="orange--text" small>mdi-alert</v-icon>No matching rules - Cheapest rate used</span> 

                        </div>
                        <div v-else>
                            <span class="caption text-muted">Service selected by user</span> 
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div v-else>
            <div v-if="!requoting">
                <v-row class="font-weight-bold text-caption text-sm-body-2 text-lg-caption text-xl-body-2">
                    <v-col cols="12" v-if="!order.shipping_address">
                        Order has no shipping address so is probably a Pickup Order. No shipping rates are available.
                    </v-col>
                    <v-col cols="12" v-if="order.shipping_address && !requoting && prices.length" class="pl-5 pt-1">
                        <div v-if="order.errors && (order.errors.address || order.errors.packages)">
                            <div v-if="order.errors.address" class="mb-1 red--text"><v-icon color="error">mdi-alert</v-icon>An address error is preventing a rate quote from being obtained.</div> 
                            <div v-if="order.errors.packages" class="mb-1 red--text"><v-icon color="error">mdi-alert</v-icon>{{ order.errors.packages }}</div>
                        </div>
                        <div v-else>
                            <v-row>
                                <v-col cols="9" class="offset-3 pt-0 pb-0 pl-0 pr-7">
                                    <v-menu	bottom offset-y>
                                        <template v-bind:selected="selected" v-slot:activator="{ on }">
                                            <v-btn dark v-on="on" width="100%" color="blue-grey lighten-5" class="ml-2 mb-2 py-0 px-1 text-none"  :class="service_text_color">
                                                <v-row class="font-weight-bold text-caption text-sm-body-2 text-lg-caption text-xl-body-2">
                                                    <v-col cols="8" class="text-left">
                                                        Select a service
                                                    </v-col>
                                                </v-row>
                                                
                                                <v-icon>mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list dense class="pointer">
                                            <div v-for="(carrier, index) in prices" :key="index">
                                                <v-list-item>
                                                    <v-list-item-content class="pr-5">
                                                        <v-img v-if="carrier[0]" :src="carrier_logo(carrier[0].carrier_type)" aspect-ratio="2.85" class="grey lighten-2" :max-width="100" :max-height="35"></v-img>
                                                    </v-list-item-content>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-if="carrier[0]">
                                                            {{ carrier[0].carrier_name || carrier[0].carrier_type }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="ma-0"></v-divider>
                                                <v-list-item link dense v-for="price in carrier" :key="price.code" @click="select_rate(price)">
                                                    <v-list-item-content class="pa-0">
                                                        <v-row>
                                                            <v-col cols="9" class="pt-1 pb-0">
                                                                <v-list-item-title class="grey--text">
                                                                {{ price.description }}</v-list-item-title>
                                                            </v-col>
                                                            <v-col cols="3" class="text-right pt-1 pb-0">
                                                                <v-list-item-title class="grey--text">
                                                                    ${{ price.price |formatNumber(2) }}
                                                                </v-list-item-title>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-list>
                                    </v-menu>						
                                </v-col>
                            </v-row>
                            <div class="mb-2 mt-5">No services were found that match your current settings.</div> 
                            <div v-if="default_carrier">An error or your "Rules" and your carrier's "Available Services" may be preventing services from being displayed.</div>
                            <div v-else>You should select a Default Carrier in your "Rules" page</div>
                        </div>

                    </v-col>
                    <v-col cols="12" v-if="order.shipping_address && !requoting && !prices.length">No rate quotes available. <span v-if="!carriers.length">You don't have any carriers connected.</span> </v-col>
                </v-row>
            </div>
        </div>
        <v-row>
            <v-col cols="12" v-if="requoting  && !prices.length" class="caption pr-6">
                Updating quotes
                <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
        <v-card v-if="layout == 'expanded' && prices" v-bind:outlined="layout == 'expanded'" class="px-2 mb-2 mt-2">
            <v-row>
                <v-col cols="12">
                    <v-row v-for="(carrier_prices, index) in prices" :key="index">
                        <v-col cols="12" class="py-0" v-if="carrier_prices.length">
                            <v-row>
                                <v-col cols="4" class="py-2">                    
                                    <v-responsive max-height="100%" max-width="100%">
                                        <v-img
                                            :src="carrier_logo(carrier_prices[0].carrier_type)"
                                            aspect-ratio="2.85"
                                            class="grey lighten-2"
                                            :max-width="100"
                                            :max-height="35"
                                            >
                                        </v-img>
                                    </v-responsive>
                                </v-col>
                                <v-col cols="8" class="pt-4 pb-0">
                                    <span class="font-weight-bold" v-if="carrier_prices[0]">{{ carrier_prices[0].carrier_type }} {{ carrier_prices[0].carrier_name }}</span> 
                                </v-col>
                            </v-row>
                            <Prices :order="order" :prices="carrier_prices" :selected="selected" v-on:select="select()" />
                        </v-col>
                        <v-col cols="12" class="caption text-muted text-right" v-if="carrier_prices[0]">
                            {{ plan_description(carrier_prices[0].carrier_type, carrier_prices[0].carrier_id) }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-card-actions v-if="!Object.keys(carriers).length">
                    <v-spacer></v-spacer>
                    <v-btn color="indigo" text to="/carrier/summary">
                        Connect a Carrier
                    </v-btn>
                </v-card-actions>
            </v-row>
        </v-card>
        <v-alert v-if="order.errors && order.errors.quote" text outlined dense icon="mdi-alert" type="error" class="mt-2 mr-2 caption" @toggle="reset_error(order, 'quote')">
            <span v-html="order.errors.quote"></span>
        </v-alert>							

    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Prices from '@/V2/components/Orders/Prices'
import orderTools from '@/V2/helpers/orderTools'

export default {
    props: ['order'],
    data: function () {
        return {
            selected: null,
        }
    },
	components: {
        Prices,
    },
    watch: {
        prices: function(newVal, oldVal) {
            this.set_selected()
        },
    },
    methods: {
        carrier_logo: function (carrier) {
            return this.$store.getters[`Carriers/${carrier}/logo`]
        },
        plan_description: function (carrier_type, carrier_id) {
            try {
                return this.$store.getters[`Carriers/${carrier_type}/plan_name`](this.$store.getters['Quotes/quote'](this.order).quotes[carrier_id])
            } catch (e) {
                return null
            }
        },
        select: function() {
            this.set_selected()
        },
        set_selected: async function () {
            this.selected = await orderTools.get_selected_rate(this.order, this.prices)
            if(this.selected){
                this.$store.dispatch(`Carriers/${this.selected.carrier_type}/set_order_options`, {order: this.order, carrier_id: this.selected.carrier_id})
            }
        },
        select_rate: function(rate) {
            this.$store.dispatch('Quotes/select_quote', {order: this.order, selected: { carrier_id: rate.carrier_id, code: rate.code }})
            Vue.delete(this.order, 'rule_used')
            Vue.delete(this.order, 'rule_keywords')
            this.set_selected()
        },
		reset_error: function (order, error) {
			Vue.delete(order.errors, error)
		},

    },
    mounted: async function () {
        this.set_selected()
    },
	computed : {
		...mapGetters({
            carriers: 'Carriers/carriers',
            requoting: 'Quotes/requoting',
            rules: 'Rules/all'
        }),
        default_carrier: function () {
            if(orderTools.is_domestic(this.order)){
                return this.rules.domestic.default.carrier
            } else {
                return this.rules.international.default.carrier
            }
        },
        layout: function () {
            return this.$store.getters[`Settings/order_layout`](this.order)
        },
        prices () {
            return orderTools.get_all_prices(this.order)
        },
        service_level () {
            try {
                if(this.selected) {
                    return this.$store.getters[`Carriers/${this.selected.carrier_type}/service_level`](this.selected)
                }
            } catch (e) {
                return null
            }
            return null
        },
        service_bg_color () {
            switch(this.service_level) {
				case 'express':
					return '#FFD200'
				case 'standard':
					return '#DC1928'
                case 'economy':
                    return '#80A3B0'
                default: 
                    return '#FFFFFF'
            }
        },
        service_text_color () {
            switch(this.service_level) {
				case 'standard':
					return 'white--text'
                default: 
                    return 'black--text'
            }
        },
        carrier_count () {
            return Object.keys(this.carriers).length
        }
	}
}
</script>
<style scoped>
    .price-row{
        cursor: pointer;
    } 
    .debug-borderx {
        border: 1px solid green;
    }
</style>
