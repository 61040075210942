// Settings
import Vue from 'vue'
import _ from 'lodash';
import { display_settings } from './settings_defaults'
import orderTools from "@/V2/helpers/orderTools";
export default {
	namespaced: true,
	state: {
        window: {
            width: 0,
            height: 0
		},
		order_fields: [
			{
				name: 'id',
				enabled: true,
				logged: true,
			},
			{
				name: 'email',
				enabled: true,
				logged: false,
			},
			{
				name: 'created_at',
				enabled: true,
				logged: true,
			},
			{
				name: 'updated_at',
				enabled: true,
				logged: true,
			},
			{
				name: 'location_id',
				enabled: true,
				logged: false,
			},
			{
				name: 'metafields',
				enabled: true,
				logged: false,
			},
			{
				name: 'note',
				enabled: true,
				logged: false,
			},
			{
				name: 'name',
				enabled: true,
				logged: true,
			},
			{
				name: 'order_number',
				enabled: true,
				logged: false,
			},
			{
				name: 'fulfillment_status',
				enabled: true,
				logged: true,
			},
			{
				name: 'payment_gateway_names',
				enabled: true,
				logged: false,
			},
			{
				name: 'subtotal_price_set',
				enabled: true,
				logged: false,
			},
			{
				name: 'total_price_set',
				enabled: true,
				logged: false,
			},
			{
				name: 'line_items',
				enabled: true,
				logged: false,
			},
			{
				name: 'total_line_items_price',
				enabled: true,
				logged: false,
			},
			{
				name: 'total_discounts',
				enabled: true,
				logged: false,
			},
			{
				name: 'discount_codes',
				enabled: true,
				logged: false,
			},
			{
				name: 'total_price',
				enabled: true,
				logged: false,
			},
			{
				name: 'total_tax',
				enabled: true,
				logged: false,
			},
			{
				name: 'total_weight',
				enabled: true,
				logged: false,
			},
			{
				name: 'shipping_lines',
				enabled: true,
				logged: false,
			},
			{
				name: 'shipping_address',
				enabled: true,
				logged: false,
			},			
			{
				name: 'billing_address',
				enabled: true,
				logged: false,
			},			
			{name: 'consignment', logged: true},	
			{name: 'orderOptions', logged: true},			
			{name: 'packages', logged: true},			
			{name: 'package', logged: true},			
			{name: 'selected', logged: true},			
			{name: 'manually_selected', logged: true},			
			{name: 'dimensions', logged: true},			
			{name: 'weight', logged: true},			
			{name: 'materials', logged: true},			
			{name: 'deferred', logged: true},			
//			{name: 'completed_fulfillments', logged: true},			
//			{name: 'completed_consignments', logged: true},			
			{name: 'fulfillmentOrders', logged: true},			
			{name: 'merge_data', logged: true},			
			{name: 'override_line_items', logged: true},			
		],
	},
	mutations: {
		setWindowSize: function(state, payload) {
			state.window.width = payload.width
			state.window.height = payload.height
		},
		set_defaults: function(state) {
			Vue.set(state, 'display_settings', JSON.parse(JSON.stringify(display_settings)))
			Vue.set(state, 'default_display_settings', JSON.parse(JSON.stringify(display_settings)))
		},
		update_settings: function (state, settings) {
			state.display_settings = _.merge(state.display_settings, settings)
		},
		set_items: function(state, payload) {
			state.display_settings.items[payload.layout] = payload.items
		},
		set_layout: function(state, layout) {
			state.display_settings.layout = layout
		}

	},
	getters: {
		loggable_fields: state => {
			let obj = {}
			const loggable = state.order_fields.filter(obj => {
				return obj.logged === true
			})
			loggable.forEach(e => {
				obj[e.name] = true
			})
			return loggable.reduce((acc,curr)=> (acc[curr.name]=true,acc),{});
		},
		screenSize: state => {
			if(state.window.width > 998) {
				return 'lg'
			}
			return 'sm'
		},
		order_fields: state => {
			return state.order_fields
		},
		order_fields_str: state => {
			const arr: Array<string> = []
			state.order_fields.forEach( (fld) => {
				if(fld.enabled) {
					arr.push(fld.name)
				}
			})
			return arr.join(',')
		},
		display_settings: state => {
			return state.display_settings
		},
		fullscreen: state => {
			if(state.display_settings) {
				return state.display_settings.fullscreen
			} 
			return false
		},
		default_display_settings: state => {
			return state.default_display_settings
		},
		items_per_page: state => {
			return state.display_settings.items
		},
		layout: (state) => {
			if(state.display_settings) {
				return state.display_settings.layout
			}
			return null
        },
		order_layout: (state) => (order) => {
			if(order && order.layout) return order.layout
			if(state.display_settings) {
				return state.display_settings.layout
			}
			return null
        },
	},
	actions: {
        init: function ({ state, commit }) {
			try {
				window.addEventListener('resize', () => {
					commit('setWindowSize', {
						width: window.innerWidth,
						height: window.innerHeight
					})
				})
				commit('setWindowSize', {
					width: window.innerWidth,
					height: window.innerHeight
				})
				commit('set_defaults')
				const settings = localStorage.getItem('display_settings')
				if(settings) {
					commit('update_settings', JSON.parse(settings))
				}

			} catch (e) {
				orderTools.log_error({message: 'Init Error', stack: 'Settings/init', data: e})
			}
		},
		save_display_settings: function ({ state }) {
			localStorage.setItem('display_settings', JSON.stringify(state.display_settings))
		}
	},
	modules: {
	}
}
