import Vue from 'vue'
import VueRouter from 'vue-router'
import Init from '@/V2/views/Init.vue'
import Home from '@/V2/views/Home.vue'
import Install from '@/V2/views/Install/Install.vue'
import Authenticate from '@/V2/views/Install/Authenticate.vue'
import BillingStatus from '@/V2/views/Install/BillingStatus.vue'
import BillingInit from '@/V2/views/Install/BillingInit.vue'
import BillingActivate from '@/V2/views/Install/BillingActivate.vue'
import Unauthorised from '@/V2/views/Errors/Unauthorised.vue'
import Help from '@/V2/views/Help.vue'
import Test from '@/V2/views/Test.vue'
import Settings from '@/V2/views/Settings.vue'
import Tracking from '@/V2/views/Tracking.vue'
import CarrierSummary from '@/V2/views/Carrier/Summary.vue'
import CarrierAdd from '@/V2/views/Carrier/Add.vue'
import CarrierEdit from '@/V2/views/Carrier/Edit.vue'
import DomesticRules from '@/V2/views/Rules/Domestic.vue'
import InternationalRules from '@/V2/views/Rules/International.vue'
import PackagingRules from '@/V2/views/Rules/Packaging.vue'
import Packages from '@/V2/views/Packages.vue'
import Rules from '@/V2/views/Rules.vue'
import News from '@/V2/views/News.vue'
import LiveRates from '@/V2/views/LiveRates.vue'
import Reports from '@/V2/views/Reports.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Init,
  },
  {
    path: '/init',
    name: 'Init',
    component: Init,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/install',
    name: 'Install',
    component: Install
  },
  {
    path: '/authenticate',
    name: 'Authenticate',
    component: Authenticate
  },
  {
    path: '/billing/status',
    name: 'BillingStatus',
    component: BillingStatus
  },
  {
    path: '/billing/init',
    name: 'BillingInit',
    component: BillingInit
  },
  {
    path: '/billing/activate/*',
    name: 'BillingActivate',
    component: BillingActivate
  },
  {
    path: '/unauthorised',
    name: 'Unauthorised',
    component: Unauthorised
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: Tracking
  },
  {
    path: '/help/:id',
    name: 'Help',
    component: Help
  },
  {
    path: '/liverates',
    name: 'LiveRates',
    component: LiveRates
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/carrier/summary',
    name: 'CarrierSummary',
    component: CarrierSummary
  },
  {
    path: '/carrier/add/:carrier_id',
    name: 'CarrierAdd',
    component: CarrierAdd
  },
  {
    path: '/carrier/edit/:carrier_id/:carrier_account_id',
    name: 'CarrierEdit',
    component: CarrierEdit
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/rules/domestic',
    name: 'DomesticRules',
    component: DomesticRules
  },
  {
    path: '/rules/international',
    name: 'InternationalRules',
    component: InternationalRules
  },
  {
    path: '/rules/packaging',
    name: 'PackagingRules',
    component: PackagingRules
  },
  {
    path: '/packages',
    name: 'Packages',
    component: Packages
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
