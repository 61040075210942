import store from "@/V2/store/index"
import Vue from 'vue'
import axios from 'axios'

export default {
    build_request: function (order, item) {
        return {
            rate: {
                origin: {
                    country: item.location.country_code,
                    postal_code: item.location.zip,
                    province: item.location.province_code,
                    city: item.location.city,
                    name: item.location.name,
                    address1: item.location.address1,
                    address2: item.location.address2
                },
                destination: {
                    country: order.shipping_address.country_code,
                    postal_code: order.shipping_address.zip,
                    province: order.shipping_address.province_code,
                    city: order.shipping_address.city,
                    name: order.shipping_address.name,
                    address1: order.shipping_address.address1,
                    address2: order.shipping_address.address2
                },
                items: this.get_line_items(item),
                currency: 'AUD',
                locale: 'en',
                debug: true 
            }
        }
    },
    get_line_items: function (item) {
        const arr: Array<any> = []
        item.lineItems.forEach(i => {
            arr.push(
                {
                    name: i.name,
                    sku: i.sku,
                    quantity: i.quantity,
                    grams: i.grams,
                    price: i.price,
                    vendor: i.vendor,
                    requires_shipping: i.requires_shipping,
                    taxable: i.taxable,
                    fulfillment_service: i.fulfillment_service,
                    properties: i.properties,
                    product_id: i.product_id,
                    variant_id: i.variant_id
                }
            )
        });
        return arr
    },
    request: function(body) {
        if(!body) {
            return null
        }
        return axios.post(`https://shopify.sellerdash.io/rates/quote/${store.getters['Shopify/shop']}`, body, {
        headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
            return response.data
        })
    }
}