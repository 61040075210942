<template>
    <div>
        <div v-for="item in items" :key="item.id">
            <v-row no-gutters class="pa-3" v-if="item.fulfillable_quantity">
                <v-col cols="2" class="py-0 pr-2">
                <v-responsive max-height="100%" max-width="100%" v-if="images[`${item.product_id}|${item.variant_id}`]">
                        <v-img
                            :src="images[`${item.product_id}|${item.variant_id}`].url"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            :max-height="50"
                            :max-width="50"
                            @error="reload_image(item.product_id, item.variant_id)"
                            >
                        </v-img>
                    </v-responsive>
                </v-col>
                <v-col cols="10" class="caption">
                    <v-row no-gutters>
                        <v-col cols="7">
                            <span class="font-weight-bold">{{ item.fulfillable_quantity }} x </span>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ item.name }}</span>
                                </template>
                                <span>{{ item.name }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <div class="caption grey--text">{{ item.sku }}</div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import orderTools from "@/V2/helpers/orderTools";
import { mapGetters } from 'vuex'

export default {
    name: 'Items',
    props: ['items'],
    data: function () {
        return {
            orderTools: orderTools,
        }
    },
    methods: {
        reload_image(product_id, variant_id) {
            this.$store.dispatch('Images/get_image', { image: `${product_id}|${variant_id}`, update: true })
        }
    },
	computed : {
        ...mapGetters({
            images: 'Images/images'
        }),
    }
}
</script>

