<template>
    <v-dialog persistent v-model="dialog" :max-width="size" @keydown.esc="cancel" style="z-index: 400">
        <v-card class="pa-5">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="cancel">Close</v-btn>
                <v-btn v-if="isSafari" @click="generatePDF">Generate PDF</v-btn>
                <v-btn color="primary" size="large" v-print="printObj">Print</v-btn>
            </v-card-actions>
            <vue-html2pdf
                :enable-download="isSafari"
                :show-layout="false"
                :float-layout="true"
                :preview-modal="true"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="770px"

                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
            >
                <section slot="pdf-content">
                    <div v-for="(order, index) in orders" :key="index">
                        <section class="pdf-item">
                            <Invoice v-if="type == 'invoice'" :order="order"/>
                            <Picklist v-if="type == 'picklist'" :order="order"/>
<!--                            <component :is="templateFile" :order="order"/>-->
                        </section>
                        <div v-if="(index + 1) < orders.length" class="html2pdf__page-break"/>
                    </div>  
                </section>
            </vue-html2pdf>
            <div class="alert alert-danger" role="alert" v-if="isSafari">
                <span class="font-weight-bold">Attention Safari Users: </span>
                Some features of this app are not available in the Safari browser. We highly recommend downloading Google Chrome or Mozilla Firefox to enable all features.
            </div>
            <div id="content">
                <div v-for="(order, index) in orders" :key="index">
                    <Invoice v-if="type == 'invoice'" :order="order"/>
                    <Picklist v-if="type == 'picklist'" :order="order"/>
<!--                    <component :is="templateFile" :order="order"/>-->
                    <div v-if="type != 'picklist' || (type == 'picklist' && picklist_options.page_breaks)">
                        <div v-if="(index + 1) < orders.length" style="page-break-after: always">&nbsp;</div>
                    </div>
                </div>  
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import { mapState } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";
import Invoice from '@/V2/components/Templates/invoice/default.vue'
import Picklist from '@/V2/components/Templates/picklist/default.vue'

export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        type: '',
        orders: [],
        cssText: '',
        printObj: {
            id: "content",
        }

    }),
    methods: {
        generatePDF () {
            this.$refs.html2Pdf.generatePdf()
        },
        open(type, orders) {
            this.type = type
            this.dialog = true
            this.orders = orders
            orderTools.log([{
                id: 'DEBUG',
                type: this.type,
                data: orders
            }])

            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        },
    },
    components: {
        VueHtml2pdf,
        Invoice,
        Picklist
    },
    computed: {
        ...mapState('User', ['picklist_options']),
        size () {
            const size = {xs:'95%',sm:'1163',lg:'1163',xl:'1163'}[this.$vuetify.breakpoint.name];
            return size
        },
        template () {
            return this.$store.getters['Templates/template'](this.type)
        },
        templateFile() {
            let type = this.type
            let template = this.template
            return () => import(`@/V2/components/Templates/${type}/${template}.vue`);
        },
        isSafari() {
            return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;
        }
	}	
}
</script>

