<template>
    <v-card class="pa-5 mb-2" :style="{'font-size': this.picklist_options.heading_font || '18px'}">
        <v-row >
            <v-col cols="4">
                <v-row>
                    <v-col class="h3 pt-0 mb-0">
                        {{ this.picklist_options.name }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <Summary :order="order"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" class="pt-4 mt-5">
                <span class="h5">Billing Address:</span>
                <BillingAddress :order="order" class="mt-3"/>
            </v-col>
            <v-col cols="4" class="pt-4 mt-5">
                <span class="h5">Shipping Address:</span>
                <ShippingAddress :order="order" class="mt-3"/>
            </v-col>
        </v-row>
        <v-row v-if="this.picklist_options.show_orders_count">
            <v-col>
                Total orders for this customer: {{  orders_count }}
            </v-col>
        </v-row>
        <Items :order="order"/>
        <v-row :style="{'font-size': this.picklist_options.notes_font || '18px'}">
            <v-col cols="7" class="pt-5">
                <div v-if="order.note && this.picklist_options.show_notes" class="mt-5">
                    <span class="h5">Order Notes:</span>
                    <div class="mt-5">{{ order.note }}</div>
                </div>
            </v-col>
        </v-row>
        <div v-if="order.note_attributes && picklist_options.show_note_attributes" :style="{'font-size': this.picklist_options.notes_font || '18px'}">
            <v-row v-for="(n, index) of order.note_attributes" v-bind:key="index" no-gutters>
                <v-col cols="3" class="font-weight-bold">{{ n.name }}:</v-col>
                <v-col cols="9">{{ n.value }}</v-col>
            </v-row>
        </div>

    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Summary from '@/V2/components/Templates/invoice/Generic/summary'
import BillingAddress from '@/V2/components/Templates/invoice/Generic/billingAddress'
import ShippingAddress from '@/V2/components/Templates/invoice/Generic/shippingAddress'
import Items from '@/V2/components/Templates/picklist/Generic/items'

export default {
    props: ['order'],
	components: {
        Summary,
        BillingAddress,
        ShippingAddress,
        Items,
    },
    computed : {
        ...mapState('User', ['address', 'picklist_options']),
        ...mapGetters({
            customers: 'Orders/customers'
        }),
        orders_count: function () {
            if(this.customers[this.order.customer.id]) {
                return this.customers[this.order.customer.id].orders_count
            }
            return null
        }

    }
}
</script>

<style>
    hr.thick {
        display: block; 
        height: 1px; 
        background: transparent; 
        width: 100%; 
        border: none; 
        border-top: solid 2px #333;
    }
    hr.thin {
        display: block; 
        height: 1px; 
        background: transparent; 
        width: 100%; 
        border: none; 
        border-top: solid 1px #333;
    }
</style>