<template>
  <div>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import createApp from '@shopify/app-bridge';
import {Redirect} from '@shopify/app-bridge/actions';
import { getSessionToken } from '@shopify/app-bridge-utils';
import axios from 'axios'

export default {
    name: 'Install',
    methods: {
        checkUser: async function (app) {
            const token = await getSessionToken(app)
            if(!token) return false

            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/shopify`, { 
                method: 'GET', 
                resource: 'shop.json',
            },
            {
                headers: { 
                    'Authorization': token
                }
            })
            .then(response => {
                try {
                    return response.data.result.shop.id
                } catch (e) {
                    return false
                }
            })
        }
    },
    created: async function () {
        if(this.$route.query.embedded) {
            const app = await createApp({
                apiKey: process.env.VUE_APP_SHOPIFY_APP_KEY,
                host: this.$route.query.host
            })
            this.$store.commit('Shopify/setApp', app)
//            const user_exists = await this.checkUser(app) 
            this.$router.push({
                path: '/authenticate',
                query: { ... this.$route.query }
            })
            return
        }

        if(this.$route.query.shop) {
            const permissionUrl = `/oauth/authorize?client_id=${process.env.VUE_APP_SHOPIFY_APP_KEY}&scope=${this.env.shopify_scope}&redirect_uri=${this.env.redirectUri}`;
            window.location.assign(`https://${this.$route.query.shop}/admin${permissionUrl}`)
        } else {
            this.$router.push('/unauthorised')
        }
    },
    computed: {
        ...mapState(['env'])
    }
}
</script>