<template>
    <div>
        <v-card elevation="5" class="ma-2">
            <v-row no-gutters>
                <v-col cols="3">
                    <v-card-title class="text-h6 pb-1">
                        {{ customer.address.first_name }} {{ customer.address.last_name }}
                    </v-card-title>
                    <v-card-text>
                        <div v-if="customer.address.company">{{ customer.address.company }}</div>
                        <div v-if="customer.address.address1">{{ customer.address.address1 }}</div>
                        <div v-if="customer.address.address2">{{ customer.address.address2 }}</div>
                        <div class="mb-8">{{ customer.address.city }} {{ customer.address.province_code }} {{ customer.address.zip }} {{ customer.address.country_code }}</div>
                        <v-card flat max-width="280">
                            <div><v-btn color="success" width="100%" class="mb-2" @click="merge(true)" v-if="selected.length > 1">Merge Selected Orders</v-btn></div>
                            <div><v-btn color="error" width="100%" @click="merge(false)" v-if="selected.length">Unmerge Selected Orders</v-btn></div>
                        </v-card>
                    </v-card-text>
                </v-col>
                <v-col cols="9" class="px-0">
                    <v-row>
                        <v-col>
                            Customer: {{ customer }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            {{ merged }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            {{ error_list }}
                        </v-col>
                    </v-row>
                    <v-card-title>Orders with Errors</v-card-title>
                    <template v-for="(order, index) in merged[customer.id].children">
                        <v-row no-gutters :key="index" v-if="error_list[order.id]">
                            <v-col cols="2">
                                <v-card-title class="pt-0 pb-0">{{ order.name }}</v-card-title>
                            </v-col>
                            <v-col cols="7" class="pt-1 error--text">
                                {{ error_list[order.id] }}
                            </v-col>
                            <v-col cols="3">
                                <v-btn small color="error" class="text-none" @click="unmerge(order.id)">Remove from Merge</v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <v-card-title>Orders without Errors</v-card-title>
                    <template v-for="(order, index) in merged[customer.id].children">
                        <v-row no-gutters :key="index" v-if="!error_list[order.id]">
                            <v-col>
                                <Order :order="$store.getters['Orders/order'](order.id)"/>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>
            </v-row>
        </v-card>

    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Order from '@/V2/components/Merge/Order'

export default {
    props: ['customer', 'type'],
    data: function () {
        return {
            selected: [],
            loaded: false
        }
    },
    watch: {
        customer: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if(JSON.stringify(newVal) != JSON.stringify(oldVal)) {
                    //this.$emit('changed')
                }
            }
        }
    },
	components: { 
        Order
    },
    methods: {
        merge: function(val) {
            const arr = []
            this.customer.orders.forEach(o => {
                const is_selected = this.selected.find(id => id == o.order.id)
                if(val == true) {
                    if(is_selected) {
                        arr.push(o.order)
                    }
                }
                if(val == false){
                    if(!is_selected) {
                        arr.push(o.order)
                    }
                }
            })
            this.$store.dispatch('Merge/merge', {customer: this.customer, orders: arr})
        },
        unmerge: function(val) {
            this.$store.dispatch('Merge/unmerge', {id: this.customer.id, order: val})
            this.$store.dispatch('Merge/save')
            this.$store.dispatch('Merge/update_errors')
        }
    },
    computed: {
		...mapGetters({
            merged: 'Merge/merged',
            errors: 'Merge/errors'
		}),
        error_list() {
            const keys = {}
            this.customer.errors.forEach(e => {
                keys[e.id] = e.message
            })
            return keys
        },
        merge_data() {
            return this.$store.getters[`Merge/merge_data`](this.customer.id)
        }

    },
}
</script>

