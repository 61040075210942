<template>
    <div>
        <v-row class="px-5">
            <v-col class="d-flex align-center">
                <v-card-title class="pa-0 info--text">Shipping Method</v-card-title>
            </v-col>
        </v-row>
        <v-row class="px-5">
            <v-col class="d-flex align-center">
                <v-card-title class="pa-0">Add a new rule</v-card-title>
            </v-col>
        </v-row>
        <v-row class="px-5">
            <v-col cols="12" lg="3" class="pb-0">
                <v-text-field 
                    v-model="new_rule.keywords" 
                    outlined 
                    dense
                    placeholder="Enter keywords" 
                    label="Keywords" 
                    class="mb-0"
                ></v-text-field>
            </v-col>
            <v-col cols="3" sm="9" md="5" lg="3" class="pb-0">
                <v-select
                    v-model="new_rule.carrier"
                    :items="carrier_list"
                    item-text="description"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    dense
                    :clearable="true"
                    label="Selected Carrier"
                    placeholder="Select a carrier"
                    class="mb-0"
                ></v-select> 
            </v-col>
            <v-col cols="3" sm="9" md="5" lg="4" class="pb-0" v-if="new_rule.carrier && !grouped_services[new_rule.carrier].length">
                <v-select
                    v-model="new_rule.service"
                    multiple
                    :items="services[new_rule.carrier]"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    dense
                    :clearable="true"
                    label="Selected services"
                    placeholder="Select services"
                    class="mb-0"
                ></v-select> 
            </v-col>
            <v-col cols="3" sm="9" md="5" lg="4" class="pb-0" v-if="new_rule.carrier && grouped_services[new_rule.carrier].length">
                <v-select
                    v-model="new_rule.grouped_services"
                    @change="select_group"
                    :items="grouped_services[new_rule.carrier]"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    dense
                    :clearable="true"
                    label="Selected services"
                    placeholder="Select services"
                    class="mb-0"
                ></v-select> 
            </v-col>
            <v-col cols="3" md="2" lg="2" xl="2" class="pt-3 mt-3 text-right">
                <v-btn v-if="new_rule.service" color="indigo" class="white--text mr-5" @click="add(new_rule)">Save</v-btn>
            </v-col>

        </v-row>
        <v-row class="px-5">
            <v-col class="d-flex align-center">
                <v-card-title class="pa-0" v-if="rules.keywords.length">Existing Rules</v-card-title>
            </v-col>
        </v-row>
        <div v-for="(rule, index) in rules.keywords" :key="index">
            <v-row class="px-5">
                <v-col cols="12" lg="3" class="pb-0">
                    <v-text-field 
                        v-model="rule.keywords" 
                        outlined
                        dense 
                        placeholder="Enter keywords" 
                        label="Keywords" 
                        class="mb-0"
                        @change="$emit('save')"
                    ></v-text-field>
                </v-col>
                <v-col cols="9" md="5" lg="3" class="pb-0">
                    <v-select
                        v-model="rule.carrier"
                        :items="carrier_list"
                        item-text="description"
                        item-value="id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        outlined
                        dense
                        :clearable="true"
                        label="Selected Carrier"
                        placeholder="Select a carrier"
                        class="mb-0"
                        @change="$emit('save')"
                    ></v-select> 
                </v-col>
                <v-col cols="9" md="5" lg="4" class="pb-0"> 
                    <v-select
                        v-model="rule.service"
                        multiple
                        :items="services[rule.carrier]"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ bottom: true, offsetY: true }"
                        outlined
                        dense
                        hide-details
                        :clearable="true"
                        label="Selected services"
                        placeholder="Select services"
                        class="mb-0"
                        @change="$emit('save')"
                    >
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0">{{ item.name }}</span>
                        </template>                    
                        <template v-if="Array.isArray(rule.service)" v-slot:label="">
                            <span>Selected services : <span class="info--text">{{ rule.service.length }} selected</span></span>
                        </template>                    
                    </v-select> 
                    
                </v-col>
                <v-col cols="3" md="2" lg="2" xl="2" class="pt-1 mt-0 text-right">
                    <v-tooltip bottom color="indigo">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon x-large color="indigo" v-bind="attrs" v-on="on" @click="move_up(index)"><v-icon>arrow_circle_up</v-icon></v-btn>
                        </template>
                        <span>Move Rule Up</span>
                    </v-tooltip>
                    <v-tooltip bottom color="indigo">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon x-large color="indigo" v-bind="attrs" v-on="on" @click="move_down(index)"><v-icon>arrow_circle_down</v-icon></v-btn>
                        </template>
                        <span>Move Rule Down</span>
                    </v-tooltip>
                    <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon x-large color="red" v-bind="attrs" v-on="on" @click="remove(index)"><v-icon>delete_forever</v-icon></v-btn>
                        </template>
                        <span>Delete Rule</span>
                    </v-tooltip>
                </v-col>
            </v-row>

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Response from '@/V2/components/Utilities/Response'

export default {
    props: ['rules', 'domestic'],
    components: {
//        Response
    },
    data: function () {
        return {
            new_rule: {
                keywords: null,
                carrier: null,
                service: null,
                grouped_services: null
            },
            grouped_services: {},
            services: {},
            selected_group: []
        }
    },
    watch: {
        carrier_list: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if(JSON.stringify(newVal) != JSON.stringify(oldVal)) {
                    this.init()
                }
            }
        }
    },
    methods: {
        add: function (rule) {
            this.save(rule)
            this.new_rule = {
                keywords: null,
                carrier: null,
                service: null,
                grouped_services: null
            }
        },
        save: function (rule) {
            this.rules.keywords.push({
                sort: rule.sort ? rule.sort : this.rules.keywords.length,
                keywords: rule.keywords,
                carrier: rule.carrier,
                service: rule.service,
                grouped_services: rule.grouped_services
            })
            this.rules.keywords.sort(function(a, b) {
                return parseFloat(a.sort) - parseFloat(b.sort);
            });
            this.$emit('save')
        },
        select_group: function() {
            const grouped_services = this.grouped_services[this.new_rule.carrier].find(item => {
                return item.id == this.new_rule.grouped_services
            })
            Vue.set(this.new_rule, 'service', grouped_services.services)
        },
        move_up: function (index) {
            if(index > 0) {
                const rule = this.rules.keywords.splice(index, 1)[0]
                this.rules.keywords.splice(index - 1, 0, rule)
                this.rules.keywords.forEach( (rule, index) => rule.sort = index )
                this.$emit('save')
            }
        },
        move_down: function (index) {
            if(index < this.rules.keywords.length - 1) {
                const rule = this.rules.keywords.splice(index, 1)[0]
                this.rules.keywords.splice(index + 1, 0, rule)
                this.rules.keywords.forEach( (rule, index) => rule.sort = index )
                this.$emit('save')
            }
        },
        remove: function (index) {
            this.rules.keywords.splice(index, 1)
            this.rules.keywords.forEach( (rule, index) => rule.sort = index )
            this.$emit('save')
        },
        update_services: function (carrier) {
            try {
                Vue.set(this.services, carrier, this.$store.getters['Carriers/services_list'](carrier, this.domestic))
                Vue.set(this.grouped_services, carrier, this.$store.getters['Carriers/grouped_services_list'](carrier, this.domestic))
            } catch (e) {
                console.log(e)
            }
        },
        init: function () {
            this.$store.getters['Carriers/carrier_list'].forEach(carrier => {
                this.update_services(carrier.id)
            })
        }
    },
    computed: {
		...mapGetters({
            carrier_list: 'Carriers/carrier_list',
        })
    },
    mounted: function () {
        this.init()
    }
}
</script>
