<template>
    <div>
        <div v-if="prices[0]">
            <div v-for="price in prices" :key="price.code">
                <v-row 
                    v-if="!hidden[price.code]"
                    class="price-row py-0 font-weight-light text-caption text-sm-body-2 text-lg-caption text-xl-body-2" 
                    @click="select(price)" 
                    :class="{'font-weight-bold': selected && price.carrier_id == selected.carrier_id && price.code == selected.code}">

                    <v-col cols="8" xl="10" class="py-0">{{ price.description }}</v-col>
                    <v-col cols="4" xl="2" class="py-0 text-right">${{ price.price | formatNumber(2)}}</v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    props: ['order', 'prices', 'selected'],
    data: function () {
        return {
            hidden: {}
        }
    },
    methods: {
        select: function(rate) {
            if(this.order.consignment && this.order.consignment.locked){
                this.$store.dispatch('Orders/init_error', { order: this.order, type: 'label', error: 'The consignment has already been finalised and cannot be updated.' }, { root: true })
                return
            } else {
                this.$store.dispatch('Quotes/select_quote', {order: this.order, selected: { carrier_id: rate.carrier_id, code: rate.code }})
                Vue.delete(this.order, 'rule_used')
                this.$emit('select')
            }
        },
    },
    computed: {
    },
    mounted: function () {
        const carrier = this.$store.getters['Carriers/carrier'](this.prices[0].carrier_id) 
        if(carrier.settings.hidden){
            for(let code of carrier.settings.hidden) {
                Vue.set(this.hidden, code, true)
            }
        }
    }
}
</script>
<style scoped>
    .price-row{
        cursor: pointer;
    } 
</style>
